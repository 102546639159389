import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Formik } from "formik";
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Divider,
  Typography
} from "@material-ui/core";

import Autocomplete from '@material-ui/lab/Autocomplete';
import {global} from "common/global";


const AvailabilityType = ['Inoperative', 'Operative'];

const ChangeAvailabilityForm = (props) => {
  const { className, history, user, ...rest } = props;
  const classes = useStyles();


  const [selectedAvailabilityType, setSelectedAvailabilityType] = useState('');


  const handleSubmit = (event, values) => {
    event.preventDefault();

    var json =
      `{"command":"ChangeAvailability",
      "body": {
        "connectorId": ${values.connectorId},
        "type": "${selectedAvailabilityType}",
        "statId": "${values.statId}"
        }
      } `;

    global.ws.send(json);
  };


  return (

    <div>
      <Formik
        enableReinitialize={false}
        initialValues={{
          connectorId: "1",
          type: "",
          statId:"",
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          isSubmitting,
          touched,
          values,
          setFieldValue
        }) => (
          <form
            className={clsx(classes.root, className)}
            onSubmit={(e) => handleSubmit(e, values)}
          >

            <Card>
              <CardHeader
                title="충전기 상태 변경"
                titleTypographyProps={{ color: 'inherit', align: 'center', variant: 'h3' }}
                // subheader="TestCase - 048_3"
                // subheaderTypographyProps={{ color: 'inherit', align: 'left', variant: 'h5' }}
              />


              <CardContent>

                {/* <Grid item md={12} spacing={2}>
                  <Typography variant="h6">048_3 - PASS, connector id &gt; 0</Typography>
                </Grid> */}

                <div className={classes.content}></div>

                <Grid item md={12} spacing={0}>
                  <Typography variant="h4">파라미터</Typography>
                </Grid>
                <Divider variant="inset" />


                <Grid container item spacing={2} >

                  <Grid container item md={12} spacing={0}>
                  </Grid>


                  <Grid item md={2} xs={4}>
                    <TextField
                      error={Boolean(touched.connectorId && errors.connectorId)}
                      fullWidth
                      helperText={touched.connectorId && errors.connectorId}
                      label="Connector Id"
                      name="connectorId"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.connectorId}
                      variant="outlined"
                      // inputProps={{ maxLength: MAX_LENGTH.APPLICANT }}
                      required
                    />
                  </Grid>

                  <Grid item md={3} xs={6}>
                    <Autocomplete
                      id="availability_select"
                      options={AvailabilityType}
                      classes={{ option: classes.options, }}
                      autoHighlight
                      getOptionSelected={(option, value) => option === value}
                      value={selectedAvailabilityType}
                      renderOption={(option) => (
                        <React.Fragment>
                          {option}
                        </React.Fragment>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Type"
                          variant="outlined"
                          required
                          fullWidth
                          inputProps={{
                            ...params.inputProps,
                          }}
                        />
                      )}

                      onChange={(event, newValue) => {
                        if (newValue) {
                          setSelectedAvailabilityType(newValue);
                        }
                      }}
                    />

                  </Grid>

                  <Grid item md={2} xs={4}>
                    <TextField
                      error={Boolean(touched.statId && errors.statId)}
                      fullWidth
                      helperText={touched.statId && errors.statId}
                      label="충전소ID"
                      name="statId"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.statId}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>


                <Grid container item
                  className={classes.action}
                  direction="row" alignItems="center" justify="center">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="large"
                    disabled={isSubmitting}
                    className={classes.action}
                  >
                    Perform
                  </Button>

                </Grid>
              </CardContent>
            </Card>
          </form>
        )}
      </Formik>
    </div>
  );
};

ChangeAvailabilityForm.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object,
};


let mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps)(ChangeAvailabilityForm);


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    direction: "row",
    alignItems: "center",
    justify: "center"
  },
  content2: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  action: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },
  queryField: {
    // width: 300
    flexBasis: 260,
    marginRight: 20,
  },
  bulkAction: {
    marginLeft: theme.spacing(2),
  },

  divider: {
    margin: theme.spacing(2, 0)
  },
}));
