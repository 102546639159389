import React, {useState, useEffect} from "react";
import { connect, useSelector } from "react-redux";
import { withRouter } from 'react-router-dom';
import { makeStyles } from "@material-ui/styles";
import PropTypes from 'prop-types';
import KepcoUnitPriceUpdateForm from "./KepcoUnitPriceUpdateForm";
import { NEONPV_CONFIG } from 'config.js';


const KepcoUnitPriceUpdate = (props) => {

  const {className, match, user, history, ...rest } = props;
  const classes = useStyles();

  const [unitPriceInfo, setUnitPriceInfo] = useState(null);


  const createDefaultValue = () => {
    console.log('디폴트값으로 신규 생성');

    var listData = [];

    for (var i = 0; i < 24; i++) {
      var stime = i.toString().padStart(2, '0');
      var etime = (i + 1).toString().padStart(2, '0');

      var row = {
        member_id: user.admin_id,
        // startTime: '00',
        // endTime: '01',
        startTime: stime,
        endTime: etime,
        lowPress1: '69.10',
        lowPress2: '56.30',
        lowPress3: '63.30',
        lowPress4: '145.20',
        highPress1: '63.0',
        highPress2: '51.4',
        highPress3: '57.70',
        highPress4: '110.60',
      };

      listData.push(row);
    }


    console.log(listData);

    listData = listData.sort();

    console.log(listData);


    fetch(`${NEONPV_CONFIG.HOSTNAME}/unitprice/createKepcoUnitPrice`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        list: listData,
      }),
    })
    .then((response) => {
      return response.json();
    })
    .then((responseData) => {
      if (responseData.resultCode === "000") {
        // alert("업데이트에 성공했습니다.");
        alert('디폴트값으로 신규 생성했습니다.');

        setTimeout(() => {
          loadData();
        }, 500);
      } else {
        //에러
        alert(`디폴트값으로 신규 생성에 실패했습니다.\n(${responseData.resultCode}):${responseData.resultMessage}`);
      }
    })
    .catch((error) => {
      console.error(error);
      alert("디폴트값으로 신규 생성에 실패했습니다.");
    });
  }


  const loadData = () => {

    var list = [];

    // console.log(userAuth);

    var url = `${NEONPV_CONFIG.HOSTNAME}/unitprice/queryKepcoUnitPrice?member_id=${user.admin_id}`;

    fetch(url)
    .then(res => res.json())
    .then(snapshot => {

      console.log(snapshot.resultData);

      //만약 null이면, 신규 default 값으로 생성함.

      if (snapshot.resultData){
        snapshot.resultData.forEach(element => {

          list.push({
            id: element.seqNo,
            member_id: element.member_id,
            startTime: element.startTime,
            endTime: element.endTime,
            time : element.startTime + ' ~ ' + element.endTime + '시',
            lowpress1: element.lowPrice1,
            lowpress2: element.lowPrice2,
            lowpress3: element.lowPrice3,
            lowpress4: element.lowPrice4,
            highpress1: element.highPrice1,
            highpress2: element.highPrice2,
            highpress3: element.highPrice3,
            highpress4: element.highPrice4,
          });
        });

        setUnitPriceInfo(list);
      } else {
        createDefaultValue();
      }
    });
  }


  useEffect( () => {
    loadData();
  }, []);


  return (
    <div className={classes.root}>
      <div className={classes.content}>
        { unitPriceInfo && <KepcoUnitPriceUpdateForm unitPriceInfo={unitPriceInfo} history={history} /> }
      </div>
    </div>
  );
};


KepcoUnitPriceUpdate.propTypes = {
  history: PropTypes.object
};


let mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps)(withRouter(KepcoUnitPriceUpdate));


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));