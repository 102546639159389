import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Formik } from "formik";
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Divider,
  Typography,
} from "@material-ui/core";
import {global} from "common/global";


const CertificateSignedForm = (props) => {
  const { className, history, user, ...rest } = props;
  const classes = useStyles();


  const handleSubmit = (event, values) => {
    event.preventDefault();

    var json =
      `{"command":"CertificateSigned",
        "body":
        {
          "certificateChain": "${values.csr}"
        }
      } `;


    global.ws.send(json);

  };


  const handleBack = () => {
    history.goBack();
  };


  return (

          // csr: `-----BEGIN CERTIFICATE-----MIIDMjCCAhoCCQDqrL3Gk2aCUzANBgkqhkiG9w0BAQUFADBTMQswCQYDVQQGEwJLUjERMA8GA1UECAwIU2VvbmduYW0xETAPBgNVBAcMCFNlb25nbmFtMRAwDgYDVQQKDAdDYXN0cHJvMQwwCgYDVQQLDANSTkQwHhcNMjIwMzA4MDg0NzQ4WhcNMjMwMzA4MDg0NzQ4WjBjMQswCQYDVQQGEwJJTjEVMBMGA1UECBMMVXR0YXJQcmFkZXNoMQ4wDAYDVQQHEwVOb2lkYTEOMAwGA1UEChMFVkVOSUQxCzAJBgNVBAsTAklUMRAwDgYDVQQDEwdDQlNFUk5PMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAtGndwLI2aYlUyjcSdQXi+nJydng1+7meGAoDLGua/c9Mp0yjssu2pw4cwRSsCLjc1fQaEaDSTlwdkpcz2Kmccoljyvn5rbzz4wYvcea9Nh/XzHIQ/XdIc2BJp+Kj3E0aC5kKWPWiEC0gm9w1Nq6h1arweEupdrIMlMQmlrxNV4wNb/mL1xdbJhUAT+d1NEZRLcRhJQuVKUTHjLj16fuc7gbpLilzxEDy61Og96sbFoubpvy6eX8bIyJUBVEmbflByFfIfzQv9VTpYv48SzFEKDS5hhT8WMSaj2DY94Fcfa1bVGXqjE/SLrh0A8Eh7rc3hP0RTO6r7ZKqu+d/Qc8QMwIDAQABMA0GCSqGSIb3DQEBBQUAA4IBAQBtccEomeBcfft0+mdy/oOKT2QQrXhwhg3ZuvyQ97xM2gcP/kx1f+yVel+NwZ4N34mc/CgSC52QqqdtiM3jxh5BP8yvG+QA3oFWxO/AJRZcDCrK2DB2QR33tdjFInnBiuRE27RqgXBJNA+b+VbBXxP/8FgA/kbmLaFnJWs35eR1C0tPpXlewDvev8l57Gx+J6mxnExS8rEjLSy4+752vsJnABrZhSU0ueu449ESQHhzqduea7LmJNdGTnOnlX1JMRZjOn6rkbaP1tCfTRDdTayGWYGpUuYrVfU6xBfPw7rxB0IatDeef7bR8zqHkPPWoIwsETNSlKHAZ9y5hoyLHlLo——END CERTIFICATE——`
          // csr: `-----BEGIN CERTIFICATE-----
          // MIIDXTCCAkWgAwIBAgIUK761qtzldoXHrSNelkcgF7cSEhQwDQYJKoZIhvcNAQEL
          // BQAwXzELMAkGA1UEBhMCS1IxDjAMBgNVBAgMBXNlb3VsMQ4wDAYDVQQHDAVzZW91
          // bDEQMA4GA1UECgwHY2FzdHBybzEMMAoGA1UECwwDZGV2MRAwDgYDVQQDDAdiaWdo
          // YXZlMB4XDTIyMDMxMTAyMDI0MVoXDTIzMDMxMTAyMDI0MVowRzELMAkGA1UEBhMC
          // S1IxDjAMBgNVBAgMBVNlb3VsMQwwCgYDVQQKDANEZXYxGjAYBgNVBAMMEXd3dy5j
          // YXN0cHJvLmNvLmtyMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA5S9V
          // 2VUMtsIJ36vINoKdqDex6j7AnEgXicY//3ocVRK8TwR0w7u4D5k9IFpyjq3szk/u
          // PamCM4IFLd+XxfF5bjPxtasQ5oct2slQYmWzs73T3cYlh5ZALuZW6z0OWrdjkHOS
          // tj38I6hDUcNcnUJQ6HdooauustWOkaB0Y8hPgZ6nrDgEUVhZxJg3xz1E+tona03y
          // 6Zzb6EMefqT/dOBe0RaLrcObhjJ+E4eLT60S25aFYJf3NEUSVho9lM4D8BJdljpA
          // VSFRI4u/bdFCM7EgVzx4Qabb4WvaJQ61mCDeH4mL6DFJHWZGejat96S468Z/IoRg
          // zfEfHN4kHJPVUUi6BwIDAQABoykwJzAlBgNVHREEHjAcghpjYXN0cHJvLmNvLmty
          // IElQOjEyNy4wLjAuMTANBgkqhkiG9w0BAQsFAAOCAQEAuE4LK0Lx2U5NmyiwkJRi
          // dHGk+ttGItRon1i7JmHLtY9xu5SJ2oBXpCnt7pAJJkZoCVjNWok5I0c2vmxKgpUE
          // Qbq8P8xo940EalIUY2y241VTABxfp+gpEm1/gS6s5Kv53ZHn7L9RpD3FVKYPxD2p
          // v3HokRR4AJW8DqDiDpkwSyiIuCGE7KSdvzELifC5B6M3QL6S5CsZT1vxd5dpTEgl
          // 78w1VJMCCaNXW/ehjgImbhM2sz7FJ0BAf2sEpVKSSt7G5HaWWosfE/t9af9UKO+u
          // TLEuH46/7oV/Btk4/2EHJztUzigjmjCvquZS5a0GiV/9e9uowvWNKZLIfck/GmUB
          // jQ==
          // -----END CERTIFICATE-----`
          // csr : `-----BEGIN CERTIFICATE-----MIIDXTCCAkWgAwIBAgIUK761qtzldoXHrSNelkcgF7cSEhQwDQYJKoZIhvcNAQELBQAwXzELMAkGA1UEBhMCS1IxDjAMBgNVBAgMBXNlb3VsMQ4wDAYDVQQHDAVzZW91bDEQMA4GA1UECgwHY2FzdHBybzEMMAoGA1UECwwDZGV2MRAwDgYDVQQDDAdiaWdoYXZlMB4XDTIyMDMxMTAyMDI0MVoXDTIzMDMxMTAyMDI0MVowRzELMAkGA1UEBhMCS1IxDjAMBgNVBAgMBVNlb3VsMQwwCgYDVQQKDANEZXYxGjAYBgNVBAMMEXd3dy5jYXN0cHJvLmNvLmtyMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA5S9V2VUMtsIJ36vINoKdqDex6j7AnEgXicY//3ocVRK8TwR0w7u4D5k9IFpyjq3szk/uPamCM4IFLd+XxfF5bjPxtasQ5oct2slQYmWzs73T3cYlh5ZALuZW6z0OWrdjkHOStj38I6hDUcNcnUJQ6HdooauustWOkaB0Y8hPgZ6nrDgEUVhZxJg3xz1E+tona03y6Zzb6EMefqT/dOBe0RaLrcObhjJ+E4eLT60S25aFYJf3NEUSVho9lM4D8BJdljpAVSFRI4u/bdFCM7EgVzx4Qabb4WvaJQ61mCDeH4mL6DFJHWZGejat96S468Z/IoRgzfEfHN4kHJPVUUi6BwIDAQABoykwJzAlBgNVHREEHjAcghpjYXN0cHJvLmNvLmtyIElQOjEyNy4wLjAuMTANBgkqhkiG9w0BAQsFAAOCAQEAuE4LK0Lx2U5NmyiwkJRidHGk+ttGItRon1i7JmHLtY9xu5SJ2oBXpCnt7pAJJkZoCVjNWok5I0c2vmxKgpUEQbq8P8xo940EalIUY2y241VTABxfp+gpEm1/gS6s5Kv53ZHn7L9RpD3FVKYPxD2pv3HokRR4AJW8DqDiDpkwSyiIuCGE7KSdvzELifC5B6M3QL6S5CsZT1vxd5dpTEgl78w1VJMCCaNXW/ehjgImbhM2sz7FJ0BAf2sEpVKSSt7G5HaWWosfE/t9af9UKO+uTLEuH46/7oV/Btk4/2EHJztUzigjmjCvquZS5a0GiV/9e9uowvWNKZLIfck/GmUBjQ==-----END CERTIFICATE-----`

    <div>
      <Formik
        enableReinitialize={false}
        initialValues={{
          csr : `-----BEGIN CERTIFICATE-----MIIDbTCCAlWgAwIBAgIJANRCD1oS9GtlMA0GCSqGSIb3DQEBBQUAMF8xCzAJBgNVBAYTAktSMQ4wDAYDVQQIEwVzZW91bDEOMAwGA1UEBxMFc2VvdWwxEDAOBgNVBAoTB2Nhc3Rwcm8xDDAKBgNVBAsTA2RldjEQMA4GA1UEAxMHYmlnaGF2ZTAeFw0yMjAzMTEwMjI2MDhaFw0yMzAzMTEwMjI2MDhaMGIxCzAJBgNVBAYTAklOMRUwEwYDVQQIEwxVdHRhclByYWRlc2gxDjAMBgNVBAcTBU5vaWRhMQ4wDAYDVQQKEwVWRU5JRDELMAkGA1UECxMCSVQxDzANBgNVBAMTBkdMVEVTVDCCASIwDQYJKoZIhvcNAQEBBQADggEPADCCAQoCggEBAJH1WK8wXqyrHBChZ0Cp7TXlhPtVl9CooUnT7vcf8RQIVSg+N1mvEdQHzVfTwiBI+3V0umXbCQjhrlmmJ5Z0wL5cyCC9R8EKNPL+ky7jtfRevi8C427OZWfzSWOKkCKTjpzLQBhlI7+dzt2i6JpIqv669od5uF8uoNgd3uzGLxXHA8Oht3RPAj/VWuMtDcJXqW97WEOyvD9vTePFn+SGyBtjBZmvXUmXuNsUQJDRAkOeB9yOhCqLFf4I9WWW9xu0dzqjc32J183tNInHDeVCy/n+8jmo3UVmTlzreeKXhUG4Gx6p0IWo1O0RXLwQ/bLIU26nsvd3mw5+xDzA8JhGjAsCAwEAAaMpMCcwJQYDVR0RBB4wHIIaY2FzdHByby5jby5rciBJUDoxMjcuMC4wLjEwDQYJKoZIhvcNAQEFBQADggEBABfqQ+8H2ek62E+bcBiD5obTY3mlMdVOHgqLe0nqwf2DZS2f71fAI3upYEtA3JfYWT0GwLPExax5ptC6BOfFnzafNDKB8Mc8loOIMvqchLB2xLfbzYPXGm5zjZdRLc61/g/Z+0AhCu0hsk6H0kgVZ+xVJ8H13SU1EqcOKxwhN/erKiQcvismuwOup2+KCK9GD6WhIhyeSXB1XzHOjdFZwi/CN2pb7MFBjgS+y+yNx9PFEyx24BjnBP5dYXqWc6Xnq4+dauSVhSnQ7RpnSNptSMnfkIOXwkoCIZR+gP+mVgnxRZb4ZL/sC26qmmdltKm0dVeiCIw+eHqSYIlzZWzkpNM=-----END CERTIFICATE-----`
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          isSubmitting,
          touched,
          values,
          setFieldValue
        }) => (
          <form
            className={clsx(classes.root, className)}
            onSubmit={(e) => handleSubmit(e, values)}
          >

            <Card>
              <CardHeader
                title="Certificate Signed"
                titleTypographyProps={{ color: 'inherit', align: 'center', variant: 'h3' }}
                subheader="TestCase - 074, 077"
                subheaderTypographyProps={{ color: 'inherit', align: 'left', variant: 'h5' }}
              />

              <CardContent>

                <Grid item md={12} spacing={2}>
                  <Typography variant="h6">074 - PASS?</Typography>
                  <Typography variant="h6">077 - PASS</Typography>
                </Grid>

                <div className={classes.content}></div>

                <Grid item md={12} spacing={0}>
                  <Typography variant="h4">Parameters</Typography>
                </Grid>

                <Divider variant="inset" />


                <Grid container item spacing={2} >

                  <Grid container item md={12} spacing={0}>
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <TextField
                      error={Boolean(touched.csr && errors.csr)}
                      fullWidth
                      helperText={touched.csr && errors.csr}
                      label="csr"
                      name="csr"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.csr}
                      variant="outlined"
                      required
                    />
                  </Grid>

               </Grid>


                <Grid container item
                  className={classes.action}
                  direction="row" alignItems="center" justify="center">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="large"
                    disabled={isSubmitting}
                    className={classes.action}
                  >
                    Perform
                  </Button>

                  <Button
                    variant="outlined"
                    size="large"
                    onClick={handleBack}
                    className={classes.action}
                  >
                    취소
                  </Button>
                </Grid>
              </CardContent>
            </Card>
          </form>
        )}
      </Formik>
    </div>
  );
};

CertificateSignedForm.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object,
};


let mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps)(CertificateSignedForm);


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    direction: "row",
    alignItems: "center",
    justify: "center"
  },
  content2: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  action: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },
  queryField: {
    // width: 300
    flexBasis: 260,
    marginRight: 20,
  },
  bulkAction: {
    marginLeft: theme.spacing(2),
  },

  divider: {
    margin: theme.spacing(2, 0)
  },
}));
