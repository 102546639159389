import React, {useState, useEffect} from "react";
import { makeStyles } from "@material-ui/styles";
import PropTypes from 'prop-types';
import MemberUpdateForm from "./MemberUpdateForm";
import { NEONPV_CONFIG } from 'config.js';
import { connect, useSelector} from 'react-redux';
import { USER_AUTH } from 'common/constants';

const MemberUpdate = (props) => {

  const {className, match, history, ...rest } = props;
  const classes = useStyles();

  const userAuth = useSelector((state) => state.user.user_auth);
  if(userAuth === USER_AUTH.MEMBER) {
    window.location.href = '/';
  }

  const [memberInfo, setMemberInfo] = useState(null);

  const loadData = (memberId) => {

    var url = `${NEONPV_CONFIG.HOSTNAME}/member/queryMemberInfo?memberId=`+memberId;

    fetch(url)
    .then(res => res.json())
    .then(snapshot => {

      var memberinfo = {
        member_id: snapshot.resultData.member_id,
        member_name: snapshot.resultData.member_name,
        member_telno: snapshot.resultData.member_telno,
        member_postcode: snapshot.resultData.member_postcode,
        member_addr1: snapshot.resultData.member_addr1,
        member_addr2: snapshot.resultData.member_addr2,
        member_admin_name: snapshot.resultData.member_admin_name,
        member_admin_telno: snapshot.resultData.member_admin_telno,
        member_admin_email: snapshot.resultData.member_admin_email,
        member_note: snapshot.resultData.member_note,
        member_bid: snapshot.resultData.member_bid,
        member_bkey: snapshot.resultData.member_bkey,

        member_env_integrated: snapshot.resultData.member_env_integrated,
      };

      setMemberInfo(memberinfo);
    });
  }


  useEffect( () => {
    loadData(match.params.memberId);
  }, []);


  return (
    <div className={classes.root}>
      <div className={classes.content}>
        {memberInfo && <MemberUpdateForm memberInfo={memberInfo} history={history} /> }
      </div>
    </div>
  );
};

MemberUpdate.propTypes = {
  history: PropTypes.object
};


let mapStateToProps = (state) => {
  return {
    user: state.user
  };
}

export default connect(
  mapStateToProps,
)(MemberUpdate);


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));
