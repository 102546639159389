import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Formik } from "formik";
import { connect, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Modal,
  Box,
  Typography
} from "@material-ui/core";

import md5 from 'md5';
import { NEONPV_CONFIG } from "config.js";
import { USER_AUTH } from 'common/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  action: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },
}));

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const UserAddForm = (props) => {
  const { className, history, user, ...rest } = props;
  const classes = useStyles();

  const userAuth = useSelector((state) => state.user.user_auth);

  const [userKind, setUserKind] = useState('');
  const [userCardStop, setUserCardStop] = useState('N');

  const handleUserKindChange = (event) => {
    setUserKind(event.target.value);
  };

  const handleUserCardStop = (event) => {
    setUserCardStop(event.target.value);
  };

  const handleSubmit = (event, values) => {
    event.preventDefault();


    fetch(`${NEONPV_CONFIG.HOSTNAME}/createUserInfo`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        user_login_type:'user_email',
        user_card_number: values.user_card_number,
        user_email: values.user_email,
        user_password: md5(values.user_password),
        user_nickname: values.user_nickname,
        member_id: userAuth == USER_AUTH.MEMBER ? user.admin_id : '', //회원사라면 회원사ID, 관리자면 null

        user_card_stop : userCardStop,
        user_kind: userKind,

        bid: user.bid,
        bkey: user.bkey,
      }),
    })
    .then((response) => {
      return response.json();
    })
    .then((responseData) => {
      if (responseData.resultCode === "000") {
        alert("회원 추가에 성공했습니다.");

        setTimeout(() => {
          history.goBack();
        }, 500);
      } else {
        //에러
        alert(`회원 추가에 실패했습니다.\n(${responseData.resultCode}):${responseData.resultMessage}`);
      }
    })
    .catch((error) => {
      console.error(error);
      alert("회원 추가에 실패했습니다.");
    });
  };

  const handleBack = () => {
    history.goBack();
  };

  return (
    <Formik
      initialValues={{
        user_id:  "",
        user_login_type: "",
        user_email: "",
        user_nickname: "",
        user_password: "",
        user_card_number: "",
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          className={clsx(classes.root, className)}
          onSubmit={(e) => handleSubmit(e, values)}
        >
          <Card>
            <CardContent>
              <Grid container spacing={2}>
              
                <Grid item md={2} xs={4}>
                  <TextField
                    fullWidth
                    label="운영회원사"
                    name="businessName"
                    value={user.admin_name}
                    variant="outlined"
                    inputProps={{ maxLength: 12 }}
                    // disabled={userAuth === 2} // 운영사 로그인 시 입력 불가
                    disabled
                  />
                </Grid>

                <Grid item md={10}></Grid>

                <Grid item md={3} xs={6}>
                  <TextField
                    error={Boolean(touched.user_email && errors.user_email)}
                    fullWidth
                    helperText={touched.user_email && errors.user_email}
                    label="이메일(30자)"
                    // disabled
                    name="user_email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.user_email}
                    variant="outlined"
                    inputProps={{ maxLength: 30 }}
                  />
                </Grid>

                <Grid item md={3} xs={6}>
                  <TextField
                    error={Boolean(touched.user_password && errors.user_password)}
                    fullWidth
                    helperText={touched.user_password && errors.user_password}
                    label="패스워드"
                    // disabled
                    name="user_password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.user_password}
                    variant="outlined"
                    inputProps={{ maxLength: 32 }}
                  />
                </Grid>

                <Grid item md={3} xs={6}>
                  <TextField
                    error={Boolean(touched.user_card_number && errors.user_card_number)}
                    fullWidth
                    helperText={touched.user_card_number && errors.user_card_number}
                    label="회원카드번호(16자리)"
                    name="user_card_number"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.user_card_number}
                    variant="outlined"
                    inputProps={{ maxLength: 16 }}
                  />
                </Grid>


                <Grid item md={3} xs={6}>
                  <FormControl sx={{ m: 1, minWidth: 120 }} fullWidth>
                    <InputLabel variant="outlined" id="demo-simple-select-label-2">카드정지여부</InputLabel>

                    <Select
                      labelId="demo-simple-select-label2"
                      id="demo-simple-select-2"
                      value={userCardStop}
                      label="userCardStop"
                      onChange={handleUserCardStop}
                      variant="outlined"
                    >

                      <MenuItem value={'N'}>사용</MenuItem>
                      <MenuItem value={'Y'}>정지</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>


                <Grid item md={3} xs={6}>
                  <TextField
                    error={Boolean(touched.user_nickname && errors.user_nickname)}
                    fullWidth
                    helperText={touched.user_nickname && errors.user_nickname}
                    label="닉네임(20자)"
                    name="user_nickname"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.user_nickname}
                    variant="outlined"
                    inputProps={{ maxLength: 20 }}
                  />
                </Grid>


                <Grid item md={3} xs={6}>

                  <FormControl sx={{ m: 1, minWidth: 120 }} fullWidth>
                    <InputLabel variant="outlined" id="demo-simple-select-label">회원구분</InputLabel>

                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={userKind}
                      label="Kind"
                      onChange={handleUserKindChange}
                      variant="outlined"
                    >
                      <MenuItem value={'과금'}>과금</MenuItem>
                      <MenuItem value={'무과금'}>무과금</MenuItem>
                    </Select>

                  </FormControl>

                </Grid>

              </Grid>

              <Grid container item
              direction="row" alignItems="center" justify="center"
              className={classes.action}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  size="large"
                  disabled={isSubmitting}
                  className={classes.action}
                >
                  회원 추가
                </Button>

                <Button
                  variant="outlined"
                  size="large"
                  onClick={handleBack}
                  className={classes.action}
                >
                  취소
                </Button>

               
              </Grid>
            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
};

UserAddForm.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object,
};

let mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps)(UserAddForm);
