import React, {useState, useEffect} from "react";
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { makeStyles } from "@material-ui/styles";
import PropTypes from 'prop-types';
import StationUpdateForm from "./StationUpdateForm";
import { NEONPV_CONFIG } from 'config.js';
import { connect} from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));


const StationUpdate = (props) => {

  const {className, match, user, history, ...rest } = props;
  const classes = useStyles();
  
  useEffect( () => {
    //loadData(match.params.statId);
  }, []);


  return (
    <div className={classes.root}>
      <div className={classes.content}>
        { <StationUpdateForm history={history} /> }
      </div>
    </div>
  );
};


StationUpdate.propTypes = {
  history: PropTypes.object
};


let mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps)(withRouter(StationUpdate));
