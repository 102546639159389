import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Formik } from "formik";
import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Divider,
} from "@material-ui/core";

import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment';

import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { NEONPV_CONFIG } from "config.js";
import { installation_status_list, getInstallationStatusCode, MAX_LENGTH, 
  getInstallationStatusLabel,
  mail_send_type_list, getMailSendTypeCode, getMailSendTypeLabel,
  mail_send_work_type_list, getMailSendWorkTypeCode, getMailSendWorkTypeLabel,
} from "common/constants";


const InstallationUpdateForm = (props) => {
  const { className, history, installationInfo, ...rest } = props;
  const classes = useStyles();

  //진행상태
  const [selectedProcessStatus, setSelectedProcessStatus] =
    useState(getInstallationStatusLabel(installationInfo.status));

  const [selectedSentMail1, setSelectedSentMail1] = useState( getMailSendTypeLabel( installationInfo.sent_email1) );
  const [selectedSentMail2, setSelectedSentMail2] = useState( getMailSendWorkTypeLabel( installationInfo.sent_email2) );

  //신청일시
  const [applyDate, setApplyDate] = useState( installationInfo.applyDate ? moment(installationInfo.applyDate).format("YYYY-MM-DD HH:mm") : '');

  //상태변경일시
  const [statusdate, setStatusDate] = useState( installationInfo.status_date ? moment(installationInfo.status_date).format("YYYY-MM-DD HH:mm") : '');


  const handleSubmit = (event, values) => {
    event.preventDefault();

    fetch(`${NEONPV_CONFIG.HOSTNAME}/installation/updateInstallationInfo`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        seqNo: installationInfo.seqNo,
        applyDate: applyDate ? moment(applyDate).format("YYYY-MM-DD HH:mm") : null,
        applicant: values.applicant,
        corporate_name: values.corporate_name,
        applicant_tel_no: values.applicant_tel_no,
        applicant_mobile_no: values.applicant_mobile_no,
        applicant_email: values.applicant_email,
        applicant_region: values.applicant_region,
        applicant_lot: values.applicant_lot,
        operation_type: values.operation_type,
        worker: values.worker,
        status: getInstallationStatusCode(selectedProcessStatus),
        status_date: statusdate ? moment(statusdate).format("YYYY-MM-DD HH:mm") : null,
        sent_email1: getMailSendTypeCode(selectedSentMail1),
        sent_email2: getMailSendWorkTypeCode(selectedSentMail2),
        inspector: values.inspector,
      }),
    })
    .then((response) => {
      return response.json();
    })
    .then((responseData) => {
      if (responseData.resultCode === "000") {
        alert("업데이트에 성공했습니다.");
        setTimeout(() => {
          history.goBack();
        }, 500);
      } else {
        //에러
        alert(`업데이트에 실패했습니다.\n(${responseData.resultCode}):${responseData.resultMessage}`);
      }
    })
    .catch((error) => {
      console.error(error);
      alert("업데이트 실패했습니다.");
    });
  };


  /** 설치신청 삭제하기 */
  const handleRemoveInstalltionInfo = (event) => {
    event.preventDefault();

    fetch(`${NEONPV_CONFIG.HOSTNAME}/installation/removeInstallationInfo`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        seqNo: installationInfo.seqNo,
      }),
    })
    .then((response) => {
      return response.json();
    })
    .then((responseData) => {
      if (responseData.resultCode === "000") {
        alert("설치신청 삭제에 성공했습니다.");
        setTimeout(() => {
          history.goBack();
        }, 500);
      } else {
        //에러
        alert(`설치신청 삭제에 실패했습니다.\n(${responseData.resultCode}):${responseData.resultMessage}`);
      }
    })
    .catch((error) => {
      console.error(error);
      alert("설치신청 삭제에 실패했습니다.");
    });
  }


  const handleBack = () => {
    history.goBack();
  };


  return (
    <div>
      <Formik
        enableReinitialize={false}
        initialValues={{
          applicant: installationInfo.applicant,
          corporate_name: installationInfo.corporate_name, //법인명
          applicant_tel_no: installationInfo.applicant_tel_no, //신청인 유선전화
          applicant_mobile_no: installationInfo.applicant_mobile_no, //신청인 휴대전화
          applicant_email: installationInfo.applicant_email, //신청인 이메일
          applicant_region: installationInfo.applicant_region, //신고자 지역
          applicant_lot: installationInfo.applicant_lot, //주차대수
          operation_type: installationInfo.operation_type, //운영형태
          worker: installationInfo.worker, //작업자
          // sent_email1: installationInfo.sent_email1, //메일발송(상담)
          // sent_email2: installationInfo.sent_email2, //메일발송(실사)
          inspector: installationInfo.inspector, //실사업체
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          isSubmitting,
          touched,
          values,
          setFieldValue
        }) => (
          <form
            className={clsx(classes.root, className)}
            onSubmit={(e) => handleSubmit(e, values)}
          >
            <Card>
              <CardContent>
                <Grid container spacing={2} >
                  <Grid item md={2} xs={4}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDateTimePicker
                        autoOk
                        variant="inline"
                        ampm={false}
                        id="date-picker-dialog"
                        format="yyyy-MM-dd HH:mm"
                        fontSize="30px"
                        label="신청일시"
                        onChange={setApplyDate}
                        value={applyDate ? applyDate : null}
                        KeyboardButtonProps={{ 'aria-label': 'change date', }}
                        className={classes.datepicker}
                        InputProps={{
                          style: {
                            fontSize: 16,
                            height: 44
                          }
                        }}
                        style={{
                          width: 200,
                          marginLeft: 20,
                          marginRight: 20,
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid item md={2} xs={4}>
                    <TextField
                      error={Boolean(touched.applicant && errors.applicant)}
                      fullWidth
                      helperText={touched.applicant && errors.applicant}
                      label="신청인"
                      name="applicant"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.applicant}
                      variant="outlined"
                      inputProps={{ maxLength: MAX_LENGTH.APPLICANT }}
                    />
                  </Grid>

                  <Grid item md={3} xs={6}>
                    <TextField
                      error={Boolean(touched.corporate_name && errors.corporate_name)}
                      fullWidth
                      helperText={touched.corporate_name && errors.corporate_name}
                      label="법인명"
                      name="corporate_name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.corporate_name}
                      variant="outlined"
                      inputProps={{ maxLength: MAX_LENGTH.CORPORATE_NAME }}
                    />
                  </Grid>

                  <Grid item md={2} xs={4}>
                    <TextField
                      error={Boolean(touched.applicant_tel_no && errors.applicant_tel_no)}
                      fullWidth
                      helperText={touched.applicant_tel_no && errors.applicant_tel_no}
                      label="유선전화"
                      name="applicant_tel_no"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.applicant_tel_no}
                      variant="outlined"
                      inputProps={{ maxLength: MAX_LENGTH.APPLICANT_TEL_NO }}
                    />
                  </Grid>

                  <Grid item md={2} xs={4}>
                    <TextField
                      error={Boolean(touched.applicant_mobile_no && errors.applicant_mobile_no)}
                      fullWidth
                      helperText={touched.applicant_mobile_no && errors.applicant_mobile_no}
                      label="휴대전화"
                      name="applicant_mobile_no"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.applicant_mobile_no}
                      variant="outlined"
                      inputProps={{ maxLength: MAX_LENGTH.APPLICANT_MOBILE_NO }}
                    />
                  </Grid>

                </Grid>

                <Divider className={classes.divider} variant="inset" />

                <Grid container spacing={2} >
                  <Grid item md={3} xs={6}>
                    <TextField
                      error={Boolean(touched.applicant_email && errors.applicant_email)}
                      fullWidth
                      helperText={touched.applicant_email && errors.applicant_email}
                      label="이메일"
                      name="applicant_email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.applicant_email}
                      variant="outlined"
                      inputProps={{ maxLength: MAX_LENGTH.APPLICANT_EMAIL }}
                    />
                  </Grid>

                  <Grid item md={3} xs={6}>
                    <TextField
                      error={Boolean(touched.applicant_region && errors.applicant_region)}
                      fullWidth
                      helperText={touched.applicant_region && errors.applicant_region}
                      label="지역"
                      name="applicant_region"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.applicant_region}
                      variant="outlined"
                      inputProps={{ maxLength: MAX_LENGTH.APPLICANT_REGION }}
                    />
                  </Grid>

                  <Grid item md={2} xs={4}>
                    <TextField
                      error={Boolean(touched.applicant_lot && errors.applicant_lot)}
                      fullWidth
                      helperText={touched.applicant_lot && errors.applicant_lot}
                      label="주차대수"
                      name="applicant_lot"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.applicant_lot}
                      variant="outlined"
                      inputProps={{ maxLength: MAX_LENGTH.APPLICANT_LOT }}
                    />
                  </Grid>

                  <Grid item md={2} xs={4}>
                    <TextField
                      error={Boolean(touched.operation_type && errors.operation_type)}
                      fullWidth
                      helperText={touched.operation_type && errors.operation_type}
                      label="운용형태"
                      name="operation_type"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.operation_type}
                      variant="outlined"
                      inputProps={{ maxLength: MAX_LENGTH.OPERATION_TYPE }}
                    />
                  </Grid>

                  <Grid item md={2} xs={4}>
                    <TextField
                      fullWidth
                      label="운영회원사"
                      name="businessName"
                      value={installationInfo.memberName}
                      variant="outlined"
                      inputProps={{ maxLength: 12 }}
                      // disabled={userAuth === 2} // 운영사 로그인 시 입력 불가
                      disabled
                    />
                  </Grid>
                </Grid>

                <Divider className={classes.divider} variant="inset" />

                <Grid container spacing={2} >
                  <Grid item md={2} xs={6}>
                    <Autocomplete
                      id="status_select"
                      options={installation_status_list}
                      classes={{ option: classes.options, }}
                      autoHighlight
                      getOptionSelected={(option, value) => option === value}
                      value={selectedProcessStatus}
                      renderOption={(option) => (
                        <React.Fragment>
                          {option}
                        </React.Fragment>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="진행상태"
                          variant="outlined"
                          fullWidth
                          inputProps={{
                            ...params.inputProps,
                          }}
                        />
                      )}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          console.log(newValue);
                          setSelectedProcessStatus(newValue);
                        }
                      }}
                    />
                  </Grid>

                  <Grid item md={2} xs={4}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDateTimePicker
                        autoOk={true}
                        variant="inline"
                        ampm={false}
                        id="date-picker-dialog"
                        format="yyyy-MM-dd HH:mm"
                        fontSize="30px"
                        label="상태변경일시"
                        onChange={setStatusDate}
                        value={statusdate ? statusdate : null}
                        KeyboardButtonProps={{ 'aria-label': 'change date', }}
                        className={classes.datepicker}
                        InputProps={{
                          style: {
                            fontSize: 16,
                            height: 44
                          }
                        }}
                        style={{
                          width: 200,
                          marginLeft: 20,
                          marginRight: 20,
                        }}
                        />

                    </MuiPickersUtilsProvider>
                  </Grid>


                  <Grid item md={2} xs={4}>
                    <TextField
                      error={Boolean(touched.worker && errors.worker)}
                      fullWidth
                      helperText={touched.worker && errors.worker}
                      label="작업자"
                      name="worker"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.worker}
                      variant="outlined"
                      inputProps={{ maxLength: MAX_LENGTH.WORKER }}
                    />
                  </Grid>

                  <Grid item md={2} xs={4}>
                    <Autocomplete
                      id="sent_email1"
                      options={mail_send_type_list}
                      classes={{ option: classes.options, }}
                      autoHighlight
                      getOptionSelected={(option, value) => option === value}
                      value={selectedSentMail1}
                      renderOption={(option) => (
                        <React.Fragment>
                          {option}
                        </React.Fragment>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="메일발송(상담)"
                          variant="outlined"
                          fullWidth
                          inputProps={{
                            ...params.inputProps,
                          }}
                        />
                      )}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          console.log(newValue);
                          setSelectedSentMail1(newValue);
                        }
                      }}
                    />
                  </Grid>

                  <Grid item md={2} xs={4}>
                    <Autocomplete
                      id="sent_email2"
                      options={mail_send_work_type_list}
                      classes={{ option: classes.options, }}
                      autoHighlight
                      getOptionSelected={(option, value) => option === value}
                      value={selectedSentMail2}
                      renderOption={(option) => (
                        <React.Fragment>
                          {option}
                        </React.Fragment>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="메일발송(실사)"
                          variant="outlined"
                          fullWidth
                          inputProps={{
                            ...params.inputProps,
                          }}
                        />
                      )}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          console.log(newValue);
                          setSelectedSentMail2(newValue);
                        }
                      }}
                    />
                  </Grid>

                  <Grid item md={2} xs={4}>
                    <TextField
                      error={Boolean(touched.inspector && errors.inspector)}
                      fullWidth
                      helperText={touched.inspector && errors.inspector}
                      label="실사업체"
                      name="inspector"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.inspector}
                      variant="outlined"
                      inputProps={{ maxLength: MAX_LENGTH.INSPECTOR }}
                    />
                  </Grid>
                </Grid>


                <Grid container item className={classes.action}>
                  <Grid container item md={6} xs={6}
                    className={classes.action}
                    direction="row" alignItems="center" justify="flex-end">
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      size="large"
                      disabled={isSubmitting}
                      className={classes.action}
                    >
                      수정하기
                    </Button>

                    <Button
                      variant="outlined"
                      size="large"
                      onClick={handleBack}
                      className={classes.action}
                    >
                      취소
                    </Button>
                  </Grid>

                  <Grid
                    container
                    direction="row" alignItems="center" justifyContent="flex-end"
                    item md={6} xs={6}>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      onClick={handleRemoveInstalltionInfo}
                      className={classes.action}
                    >
                      설치신청 삭제
                    </Button>
                  </Grid>

                </Grid>

              </CardContent>
            </Card>
          </form>
        )}
      </Formik>
    </div>
  );
};

InstallationUpdateForm.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object,
};


export default InstallationUpdateForm;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    direction: "row",
    alignItems: "center",
    justify: "center"
  },
  content2: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  action: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },
  queryField: {
    // width: 300
    flexBasis: 260,
    marginRight: 20,
  },
  bulkAction: {
    marginLeft: theme.spacing(2),
  },

  divider: {
    margin: theme.spacing(2, 0)
  },
}));
