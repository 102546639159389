import React from "react";
import { makeStyles } from "@material-ui/styles";
import PropTypes from 'prop-types';
import ChargerModelAddFrom from "./ChargerModelAddForm";
import { connect, useSelector} from 'react-redux';
import { USER_AUTH } from 'common/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));


const ChargerModelAdd = (props) => {

  const { history  } = props;
  const classes = useStyles();

  const userAuth = useSelector((state) => state.user.user_auth);
  if(userAuth === USER_AUTH.MEMBER) {
    window.location.href = '/';
  }

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <ChargerModelAddFrom history={history} />
      </div>
    </div>
  );
};

ChargerModelAdd.propTypes = {
  history: PropTypes.object
};


let mapStateToProps = (state) => {
  return {
    user: state.user
  };
}

export default connect(
  mapStateToProps,
)(ChargerModelAdd);
