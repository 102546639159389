import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  TableContainer,
  Paper,
  Grid,
  Typography
} from '@material-ui/core';
import moment from 'moment';
import { NEONPV_CONFIG } from "config.js";


const COLUMN_WIDTH = {
  DATETIME: "25%",
  TYPE1: "10%",
  TYPE2: "20%",
  ERROR_MESSAGE: "45%",
}



const ErrorTable = props => {
  const {className, detailData, selectedValue, selectedDate, ...rest} = props;

  const classes = useStyles();

  const [listData, setListData] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  const startRow = (page*rowsPerPage);


  useEffect( ()=> {

    //var url = `${NEONPV_CONFIG.ERROR_BACKEND}/error/v1/lastErrorReports`;
    var url = `${NEONPV_CONFIG.HOSTNAME}/error/v1/lastErrorReports`;

    fetch(url)
    .then( (res) => res.json() )
    .then( (snapshot) => {

      var list = [];
      if (snapshot.resultCount > 0){
        snapshot.resultData.forEach((doc)=>{
          doc.occurTime_format = moment(doc.occurTime).format('YY-MM-DD HH:mm:ss');
          list.push(doc);
        });

        setListData(list);
      }
    });


  },[]);

  return (

    <Card className={clsx(classes.root, className)} {...rest}>

    <CardContent className={classes.content}>
      <Grid container justifyContent="space-between" spacing={0}>
        <Grid item>
          <Typography className={classes.title} color="textSecondary" gutterBottom variant="h6">
            충전기 및 시스템 오류
          </Typography>
        </Grid>
      </Grid>

      <PerfectScrollbar>

        <TableContainer component={Paper} >
          <Table className={classes.table} size="small">
            <TableHead  >
              <TableRow>
                <TableCell width={COLUMN_WIDTH.DATETIME} align="center">일시</TableCell>
                <TableCell width={COLUMN_WIDTH.TYPE1} align="center">#1</TableCell>
                <TableCell width={COLUMN_WIDTH.TYPE2} align="center">#2</TableCell>
                <TableCell width={COLUMN_WIDTH.ERROR_MESSAGE} align="center">오류내용</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {listData.slice(0+startRow, rowsPerPage+(page*rowsPerPage)).map(output => (
                <TableRow key={output.seqno}>
                  <TableCell width={COLUMN_WIDTH.DATETIME} align="left"><Typography variant="body2" noWrap>{output.occurTime_format}</Typography></TableCell>
                  <TableCell width={COLUMN_WIDTH.TYPE1} align="left"><Typography variant="body2" noWrap>{output.component}</Typography></TableCell>
                  <TableCell width={COLUMN_WIDTH.TYPE2} align="left"><Typography variant="caption" noWrap>{output.element}</Typography></TableCell>
                  <TableCell width={COLUMN_WIDTH.ERROR_MESSAGE} align='left'><Typography variant="caption" noWrap={false}>{output.errorMsg}</Typography></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          </TableContainer>

      </PerfectScrollbar>
    </CardContent>


    <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={listData.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 20]}
        />
      </CardActions>
    </Card>
  );
};


ErrorTable.propTypes = {
  className: PropTypes.string
};

export default ErrorTable;

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    // padding: 16
  },
  inner: {
    // minWidth: 800
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  table: {
    // minWidth:650,
  },
  title: {
    fontWeight: 600,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));
