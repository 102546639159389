import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link as RouterLink, withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  InputAdornment,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  TablePagination,

  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@material-ui/core";

import Autocomplete from '@material-ui/lab/Autocomplete';


import {
  Search as SearchIcon,
} from "react-feather";

import { NEONPV_CONFIG } from "config.js";

import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

import {
  updateInstallationMemberName,
  updateInstallationApplyStartTime,
  updateInstallationApplyEndTime,
  updateInstallationApplicant,
  updateInstallationRegion,
  updateInstallationStatus,
} from "actions/query";


import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import { installation_status_list, getInstallationStatusCode, getInstallationStatusLabel,
  getMailSendTypeLabel,  getMailSendWorkTypeLabel, USER_AUTH } from "common/constants";


const InstallationTable = (props) => {
  const { className, user, queryParamsInstallation, ...rest } = props;

  const classes = useStyles();

  const userAuth = useSelector((state) => state.user.user_auth);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [listData, setListData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");

  const [memberName, setMemberName] = useState(userAuth === USER_AUTH.MEMBER ? user.admin_name : '');
  const [applyStartTime, setApplyStartTime] = useState(new Date((new Date()).valueOf() - (1000 * 60 * 60 * 24)));
  const [applyEndTime, setApplyEndTime] = useState(new Date());

  const [queryApplicant, setQueryApplicant] = useState("");
  const [queryRegion, setQueryRegion] = useState("");

  const [selectedStatus, setSelectedStatus] = useState('');


  const searchData = (autoload=false, memberName, stime, etime, applicant, region, statuscode) => {

    var url = `${NEONPV_CONFIG.HOSTNAME}/installation/queryInstallationList?`;

    var params = "";
    //회원사 계정이라면
    if (userAuth === USER_AUTH.MEMBER ) {
      params += "memberId=" + user.admin_id + "&";
    } else if (memberName) {
    //관리자 계정이라면
      params += "memberName=" + memberName + "&";
    }

    if (stime) {
      params += "applyStartTime=" + moment(stime).format('YYYY-MM-DD HH:mm') + '&';
    }

    if (etime) {
      params += "applyEndTime=" + moment(etime).format('YYYY-MM-DD HH:mm') + '&';
    }

    if (applicant) {
      params += "applicant=" + applicant + '&';
    }

    if (region) {
      params += "region=" + region + '&';
    }

    if (statuscode > 0) {
      params += "status=" + statuscode + '&';
    }

    url += params;

    // console.log(url);

    fetch(url)
      .then((res) => res.json())
      .then((snapshot) => {
        var i = 1;
        var list = [];
        if (snapshot.resultData != null) {
          snapshot.resultData.forEach((doc) => {
            doc.no = i++;
            list.push(doc);
          });
        }
        setListData(list);
        if (autoload === false && list.length === 0){
          setDialogMessage('검색 결과가 없습니다.');
          setOpenDialog(true);
        }

        setPage(0);
      });
  };



  const handleSearch = (event) => {
    if (applyStartTime !== null && applyEndTime !== null) {
      // console.log("reportStartTime: " + applyStartTime);
      // console.log("reportEndTime: " + applyEndTime);

      //'검색'버튼을 클릭하면, 검색어를 Redux에 저장한다.

      props.updateInstallationMemberName(memberName);
      props.updateInstallationApplyStartTime(applyStartTime);
      props.updateInstallationApplyEndTime(applyEndTime);
      props.updateInstallationApplicant(queryApplicant);
      props.updateInstallationRegion(queryRegion);
      props.updateInstallationStatus(selectedStatus);

      searchData(false, memberName, applyStartTime, applyEndTime, queryApplicant, queryRegion, getInstallationStatusCode(selectedStatus));
    } else {
      setDialogMessage('검색어를 입력해 주세요. ');
      setOpenDialog(true);
    }
  };

  const doKeyPress = (ev) => {
    if (ev.key === 'Enter') {
      handleSearch();
    }
  }


  /**
   * 검색 정보 지우기
   * @param {*} event
   */
  const handleClear = (event) => {
    props.updateInstallationMemberName('');
    props.updateInstallationApplyStartTime('');
    props.updateInstallationApplyEndTime('');
    props.updateInstallationApplicant("");
    props.updateInstallationRegion("");
    props.updateInstallationStatus("");

    setMemberName(userAuth === USER_AUTH.MEMBER ? user.admin_name : '');

    setApplyStartTime(new Date((new Date()).valueOf() - (1000 * 60 * 60 * 24)));
    setApplyEndTime(new Date());
    setQueryApplicant("");
    setQueryRegion("");
    setSelectedStatus("");

    setListData([]);
  };


  const handleQueryMemberNameChange = (event) => {
    event.persist();
    setMemberName(event.target.value);
  }

  const handleApplyStartTimeChange = (date) => {
    setApplyStartTime(date);
  };

  const handleApplyEndtimeChange = (date) => {
    setApplyEndTime(date);
  };

  const handleQueryApplicantChange = (event) => {
    event.persist();
    setQueryApplicant(event.target.value);
  };

  const handleQueryRegionChange = (event) => {
    event.persist();
    setQueryRegion(event.target.value);
  };

  const handleStatusChange = (event, newValue) => {
    event.persist();
    setSelectedStatus(newValue);
  }


  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
  };


  const startRow = (page * rowsPerPage);


  useEffect(() => {

    //queryParamsInstallation.queryInstallationMemberName || 는 제외하자
    if (queryParamsInstallation.queryInstallationApplyStartTime ||
      queryParamsInstallation.queryInstallationApplyEndTime ||
      queryParamsInstallation.queryInstallationApplicant ||
      queryParamsInstallation.queryInstallationRegion ||
      queryParamsInstallation.queryInstallationStatus) {

        searchData(true,
          queryParamsInstallation.queryInstallationMemberName,
          queryParamsInstallation.queryInstallationApplyStartTime,
          queryParamsInstallation.queryInstallationApplyEndTime,
          queryParamsInstallation.queryInstallationApplicant,
          queryParamsInstallation.queryInstallationRegion,
          getInstallationStatusCode(queryParamsInstallation.queryInstallationStatus));
    }

    if(userAuth === USER_AUTH.MEMBER) {
      setMemberName(user.admin_name);
    } else {
      setMemberName(queryParamsInstallation.queryInstallationMemberName);
    }

    if (queryParamsInstallation.queryInstallationApplyStartTime) {
      setApplyStartTime(queryParamsInstallation.queryInstallationApplyStartTime);
    }
    if (queryParamsInstallation.queryInstallationApplyEndTime) {
      setApplyEndTime(queryParamsInstallation.queryInstallationApplyEndTime);
    }

    setQueryApplicant(queryParamsInstallation.queryInstallationApplicant);
    setQueryRegion(queryParamsInstallation.queryInstallationRegion);
    setSelectedStatus(queryParamsInstallation.queryInstallationStatus);

    return () => { };
  }, []);


  return (

    <div>

      <Dialog open={openDialog} onClose={() => { setOpenDialog(false) }}>
        <DialogTitle>주의</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{dialogMessage}</DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" color="secondary" onClick={() => { setOpenDialog(false) }}>닫기</Button>
        </DialogActions>
      </Dialog>


      <Card className={clsx(classes.root, className)} {...rest}>
        <Box m={1} p={2}>

          <Box mt={2} display="flex" alignItems="center" justifyContent="start">

            <TextField className={classes.queryField_small}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action"><SearchIcon /></SvgIcon>
                  </InputAdornment>
                )
              }}

              disabled={userAuth === USER_AUTH.MEMBER}
              onChange={handleQueryMemberNameChange}
              label="운영회원사"
              placeholder="운영회원사"
              value={memberName}
              variant="outlined"
              onKeyUp={(ev) => doKeyPress(ev)}
            />

            {/* <Typography variant="h5" >신고일시</Typography> */}
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDateTimePicker
                autoOk
                variant="inline"
                id="date-picker-dialog"
                format="yyyy-MM-dd HH:mm"
                fontSize="30px"
                label="신청일시(시작)"
                required
                value={applyStartTime}
                onChange={handleApplyStartTimeChange}
                KeyboardButtonProps={{ 'aria-label': 'change date', }}
                className={classes.datepicker}
                InputProps={{
                  style: {
                    fontSize: 16,
                    height: 44
                  }
                }}
                style={{
                  width: 180,
                  marginLeft: 20,
                  marginRight: 20,
                }}
              />
              <Typography variant="h5">~</Typography>
              <KeyboardDateTimePicker
                autoOk
                variant="inline"
                id="date-picker-dialog"
                format="yyyy-MM-dd HH:mm"
                fontSize="30px"
                label="신청일시(종료)"
                required
                value={applyEndTime}
                onChange={handleApplyEndtimeChange}
                KeyboardButtonProps={{ 'aria-label': 'change date', }}
                className={classes.datepicker}
                InputProps={{
                  style: {
                    fontSize: 16,
                    height: 44
                  }
                }}
                style={{
                  width: 180,
                  marginLeft: 20,
                  marginRight: 20,
                }}
              />
            </MuiPickersUtilsProvider>


            <TextField
              className={classes.queryField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              // style={{ width: 80 }}
              onChange={handleQueryApplicantChange}
              label="신청인"
              placeholder="신청인"
              value={queryApplicant}
              variant="outlined"
              onKeyUp={(ev) => doKeyPress(ev)}
            />

            <TextField
              className={classes.queryField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              onChange={handleQueryRegionChange}
              label="지역"
              placeholder="지역"
              value={queryRegion}
              variant="outlined"
              onKeyUp={(ev) => doKeyPress(ev)}
            />


            <Autocomplete
              id="status_select"
              options={installation_status_list}
              classes={{ option: classes.options, }}
              autoHighlight
              getOptionSelected={(option, value) => option === value}
              value={selectedStatus}
              renderOption={(option) => (
                <React.Fragment>
                  {option}
                </React.Fragment>
              )}

              renderInput={(params) => (
                <TextField
                  {...params}
                  label="진행상태"
                  variant="outlined"
                  // fullWidth
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}

              onChange={handleStatusChange}
              style={{
                width: 180,
                // marginLeft: 20,
                marginRight: 20,
              }}
            />


            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classes.bulkAction}
              onClick={handleSearch}
            >
              검색
            </Button>

            <Button
              variant="outlined"
              size="large"
              className={classes.bulkAction}
              onClick={handleClear}
            >
              초기화
            </Button>
          </Box>
        </Box>

        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table size="medium">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" width={COLUMN_WIDTH.NO}>No</TableCell>
                    <TableCell align="center" width={COLUMN_WIDTH.APPLY_DATE}>신청일시</TableCell>
                    <TableCell align="center" width={COLUMN_WIDTH.APPLICANT}>신청인</TableCell>
                    <TableCell align="center" width={COLUMN_WIDTH.CORPORATE_NAME}>법인명</TableCell>
                    <TableCell align="center" width={COLUMN_WIDTH.APPLICANT_TEL_NO}>유선전화</TableCell>
                    <TableCell align="center" width={COLUMN_WIDTH.APPLICANT_MOBILE_NO}>휴대폰</TableCell>
                    <TableCell align="center" width={COLUMN_WIDTH.APPLICANT_EMAIL}>이메일</TableCell>
                    <TableCell align="center" width={COLUMN_WIDTH.APPLICANT_REGION}>지역</TableCell>
                    <TableCell align="center" width={COLUMN_WIDTH.APPLICANT_LOT}>주차</TableCell>
                    <TableCell align="center" width={COLUMN_WIDTH.OPERATIOPN_TYPE}><Typography variant="caption">운용형태</Typography></TableCell>
                    <TableCell align="center" width={COLUMN_WIDTH.MEMBER_NAME}><Typography variant="caption">운영회원사</Typography></TableCell>
                    <TableCell align="center" width={COLUMN_WIDTH.WORKER}><Typography variant="caption">작업자</Typography></TableCell>
                    <TableCell align="center" width={COLUMN_WIDTH.STATUS}>상태</TableCell>
                    <TableCell align="center" width={COLUMN_WIDTH.STATUS_DATE}>상태변경일</TableCell>
                    <TableCell align="center" width={COLUMN_WIDTH.SENT_EMAIL1}><Typography variant="caption">메일(상담)</Typography></TableCell>
                    <TableCell align="center" width={COLUMN_WIDTH.SENT_EMAIL2}><Typography variant="caption">메일(실사)</Typography></TableCell>
                    <TableCell align="center" width={COLUMN_WIDTH.INSPECTOR}><Typography variant="caption">실사업체</Typography></TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {listData.slice(0 + startRow, rowsPerPage + (page * rowsPerPage)).map((module) => (
                    <TableRow
                      className={classes.tableRow}
                      key={module.SeqNo}
                      component={RouterLink}
                      to={`/installations/update/${module.SeqNo}`}
                    >
                      <TableCell width={COLUMN_WIDTH.NO}>{module.no}</TableCell>
                      <TableCell width={COLUMN_WIDTH.APPLY_DATE}>
                        <div className={classes.nameContainer}>
                          <Typography variant="body2">
                            {moment(module.applyDate).format('YY-MM-DD HH:mm')}
                          </Typography>
                        </div>
                      </TableCell>
                      <TableCell width={COLUMN_WIDTH.APPLICANT}><Typography variant="body2">{module.applicant}</Typography></TableCell>
                      <TableCell width={COLUMN_WIDTH.CORPORATE_NAME}><Typography variant="body2">{module.corporate_name}</Typography></TableCell>
                      <TableCell width={COLUMN_WIDTH.APPLICANT_TEL_NO}><Typography variant="caption">{module.applicant_tel_no}</Typography></TableCell>
                      <TableCell width={COLUMN_WIDTH.APPLICANT_MOBILE_NO}><Typography variant="caption">{module.applicant_mobile_no}</Typography></TableCell>
                      <TableCell width={COLUMN_WIDTH.APPLICANT_EMAIL}><Typography variant="caption">{module.applicant_email}</Typography></TableCell>
                      <TableCell width={COLUMN_WIDTH.APPLICANT_REGION}><Typography variant="body2">{module.applicant_region}</Typography></TableCell>
                      <TableCell width={COLUMN_WIDTH.APPLICANT_LOT}><Typography variant="body2">{module.applicant_lot}</Typography></TableCell>
                      <TableCell width={COLUMN_WIDTH.OPERATIOPN_TYPE}><Typography variant="body2">{module.operation_type}</Typography></TableCell>
                      <TableCell width={COLUMN_WIDTH.MEMBER_NAME}><Typography variant="body2">{module.member_name}</Typography></TableCell>
                      <TableCell width={COLUMN_WIDTH.WORKER}><Typography variant="body2">{module.worker}</Typography></TableCell>
                      <TableCell width={COLUMN_WIDTH.STATUS}><Typography variant="body2">{getInstallationStatusLabel(module.status)}</Typography></TableCell>
                      <TableCell width={COLUMN_WIDTH.STATUS_DATE}><Typography variant="body2">{module.status_date ? moment(module.status_date).format('YY-MM-DD HH:mm') : ''}</Typography></TableCell>
                      <TableCell width={COLUMN_WIDTH.SENT_EMAIL1}><Typography variant="body2">{getMailSendTypeLabel(module.sent_email1)}</Typography></TableCell>
                      <TableCell width={COLUMN_WIDTH.SENT_EMAIL2}><Typography variant="body2">{getMailSendWorkTypeLabel(module.sent_email2)}</Typography></TableCell>
                      <TableCell width={COLUMN_WIDTH.INSPECTOR}><Typography variant="body2">{module.inspector}</Typography></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>

        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={listData.length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </CardActions>
      </Card>

    </div>
  );
};

InstallationTable.propTypes = {
  className: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { updateInstallationMemberName,
      updateInstallationApplyStartTime,
      updateInstallationApplyEndTime,
      updateInstallationApplicant,
      updateInstallationRegion,
      updateInstallationStatus,},
    dispatch
  );
};

let mapStateToProps = (state) => {
  return {
    queryParamsInstallation: state.queryParamsInstallation,
    user: state.user
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(InstallationTable));



const COLUMN_WIDTH = {
  NO: '3%',
  APPLY_DATE: '8%', //11
  APPLICANT: '5%', //16
  CORPORATE_NAME: '6%', //22
  APPLICANT_TEL_NO: '6%', //28
  APPLICANT_MOBILE_NO: '6%', //34
  APPLICANT_EMAIL: '6%', //40
  APPLICANT_REGION: '5%', //45
  APPLICANT_LOT: '5%', //50
  OPERATIOPN_TYPE: '6%', //56
  MEMBER_NAME: '7%', //63
  WORKER : '5%', //68
  STATUS: '6%', //74
  STATUS_DATE: '8%', //82
  SENT_EMAIL1: '6%', //88
  SENT_EMAIL2: '6%', //94
  INSPECTOR: '7%', //101
}


const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    // minWidth: 1050
  },
  nameContainer: {
    // display: 'flex',
    // alignItems: 'center',
    // marginLeft: theme.spacing(1)
  },
  queryField: {
    flexBasis: 220,
    marginRight: 20,
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: "flex-end",
  },
  bulkAction: {
    marginLeft: theme.spacing(2),
  },
}));
