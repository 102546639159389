import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Formik } from "formik";
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Divider,
  Typography,
  FormControl,
  FormControlLabel,
  Switch
} from "@material-ui/core";


import {global} from "common/global";


const RemoteStartTransactionForm = (props) => {
  const { className, history, user, ...rest } = props;
  const classes = useStyles();

  const [onChargingProfile, setOnChargingProfile] = useState(false);


  const handleChargingProfileChange = (event) => {
    // console.log(event.target.checked);
    setOnChargingProfile( event.target.checked );
  };


  const handleSubmit = (event, values) => {
    event.preventDefault();

    var json = '';

    // var json =
    //   `{"command":"RemoteStartTransaction",
    //     "body": {
    //       "connectorId": ${values.connectorId},
    //       "idTag": "${values.idTag}",
    //       "chargingProfile": {
    //         "chargingProfileId": ${values.chargingProfileId},
    //         "transactionId": ${values.transactionId},
    //         "stackLevel": ${values.stackLevel},
    //         "chargingProfilePurpose": "${values.chargingProfilePurpose}",
    //         "chargingProfileKind": "${values.chargingProfileKind}",
    //         "recurrencyKind": "${values.recurrencyKind}",
    //         "validFrom": "${values.validFrom}",
    //         "validTo": "${values.validTo}",

    //         "chargingSchedule": {
    //             "duration": ${values.duration},
    //             "startSchedule": "${values.startSchedule}",
    //             "chargingRateUnit": "${values.chargingRateUnit}",
    //             "chargingSchedulePeriod": [{
    //               "startPeriod": ${values.startPeriod},
    //               "limit": ${values.limit},
    //               "numberPhases": ${values.numberPhases}
    //             }],
    //             "minChargingRate": ${values.minChargingRate}
    //         }
    //       }
    //     }
    //   } `;


    if (onChargingProfile == false) {

      json= 
        `{"command":"RemoteStartTransaction",
        "body": {
          "connectorId": ${values.connectorId},
          "idTag": "${values.idTag}"
        }
      } `;
    } else {
      json =
      `{"command":"RemoteStartTransaction",
        "body": {
          "connectorId": ${values.connectorId},
          "idTag": "${values.idTag}",
          "chargingProfile": {
            "chargingProfileId": ${values.chargingProfileId},
            "transactionId": ${values.transactionId},
            "stackLevel": ${values.stackLevel},
            "chargingProfilePurpose": "${values.chargingProfilePurpose}",
            "chargingProfileKind": "${values.chargingProfileKind}",
            "recurrencyKind": "${values.recurrencyKind}",
            "validFrom": "${values.validFrom}",
            "validTo": "${values.validTo}",

            "chargingSchedule": {
                "duration": ${values.duration},
                "startSchedule": "${values.startSchedule}",
                "chargingRateUnit": "${values.chargingRateUnit}",
                "chargingSchedulePeriod": [{
                  "startPeriod": ${values.startPeriod},
                  "limit": ${values.limit},
                  "numberPhases": ${values.numberPhases}
                }],
                "minChargingRate": ${values.minChargingRate}
            }
          }
        }
      } `;

    }


    global.ws.send(json);
  };


  return (

    <div>
      <Formik
        enableReinitialize={false}
        initialValues={{
          connectorId: 1,
          idTag: "PTAG",
          chargingProfileId: 1,
          transactionId: 1,
          stackLevel: 1,
          chargingProfilePurpose: "TxProfile",
          chargingProfileKind: "Absolute",
          recurrencyKind: "Daily",
          validFrom: new Date().toISOString(),
          validTo: new Date().toISOString(),
          duration: 60,
          startSchedule: new Date().toISOString(),
          chargingRateUnit: "W",
          startPeriod: 1,
          limit: 1,
          numberPhases: 1,
          minChargingRate: 0,
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          isSubmitting,
          touched,
          values,
          setFieldValue
        }) => (
          <form
            className={clsx(classes.root, className)}
            onSubmit={(e) => handleSubmit(e, values)}
          >

            <Card>
              <CardHeader
                title="충전 시작 (원격)"
                titleTypographyProps={{ color: 'inherit', align: 'center', variant: 'h3' }}
                // subheader="TestCase - 011_1, 011_2, 015, 026, 032_1, 059"
                // subheaderTypographyProps={{ color: 'inherit', align: 'left', variant: 'h5' }}
              />

              <CardContent>

                {/* <Grid item md={12} spacing={2}>
                  <Typography variant="h6">011_1 - PASS</Typography>
                  <Typography variant="h6">011_2 - PASS</Typography>
                  <Typography variant="h6">015 (중간실행) - PASS</Typography>
                  <Typography variant="h6">026 - PASS</Typography>
                  <Typography variant="h6">032_1 - PASS</Typography>
                  <Typography variant="h6">059 - PASS</Typography>
                </Grid> */}

                <div className={classes.content}></div>

                <Grid item md={12} spacing={0}>
                  <Typography variant="h4">파라미터</Typography>
                </Grid>

                <Divider variant="inset" />


                <Grid container item spacing={2} >

                  <Grid container item md={12} spacing={0}>
                  </Grid>

                  <Grid item md={2} xs={4}>
                    <TextField
                      error={Boolean(touched.connectorId && errors.connectorId)}
                      fullWidth
                      helperText={touched.connectorId && errors.connectorId}
                      label="connectorId"
                      name="connectorId"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.connectorId}
                      variant="outlined"
                      required
                    />
                  </Grid>


                  <Grid item md={2} xs={4}>
                    <TextField
                      error={Boolean(touched.idTag && errors.idTag)}
                      fullWidth
                      helperText={touched.idTag && errors.idTag}
                      label="idTag"
                      name="idTag"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.idTag}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item md={8}></Grid>

                  <Grid item md={12}>
                    {/* <Typography variant="h6">ChargingProfile</Typography> */}
                    <FormControl component="fieldset" variant="standard">
                      <FormControlLabel
                        value="ChargingProfile" control={<Switch color="primary" checked={onChargingProfile} onChange={handleChargingProfileChange} name="onChargingProfile" /> }
                        label={<Typography variant="h6">ChargingProfile</Typography>}
                        labelPlacement="start"
                        />
                    </FormControl>
                  </Grid>



                  <Grid item md={2} xs={4}>
                    <TextField
                      error={Boolean(touched.chargingProfileId && errors.chargingProfileId)}
                      fullWidth
                      helperText={touched.chargingProfileId && errors.chargingProfileId}
                      label="chargingProfileId"
                      name="chargingProfileId"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.chargingProfileId}
                      variant="outlined"
                      required
                    />
                  </Grid>


                  <Grid item md={2} xs={4}>
                    <TextField
                      error={Boolean(touched.transactionId && errors.transactionId)}
                      fullWidth
                      helperText={touched.transactionId && errors.transactionId}
                      label="transactionId"
                      name="transactionId"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.transactionId}
                      variant="outlined"
                      // inputProps={{ maxLength: MAX_LENGTH.APPLICANT }}
                      required
                    />
                  </Grid>



                  <Grid item md={2} xs={4}>
                    <TextField
                      error={Boolean(touched.stackLevel && errors.stackLevel)}
                      fullWidth
                      helperText={touched.stackLevel && errors.stackLevel}
                      label="stackLevel"
                      name="stackLevel"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.stackLevel}
                      variant="outlined"
                      // inputProps={{ maxLength: MAX_LENGTH.APPLICANT }}
                      required
                    />
                  </Grid>


                  <Grid item md={2} xs={4}>
                    <TextField
                      error={Boolean(touched.chargingProfilePurpose && chargingProfilePurpose.stackLevel)}
                      fullWidth
                      helperText={touched.chargingProfilePurpose && errors.chargingProfilePurpose}
                      label="chargingProfilePurpose"
                      name="chargingProfilePurpose"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.chargingProfilePurpose}
                      variant="outlined"
                      // inputProps={{ maxLength: MAX_LENGTH.APPLICANT }}
                      required
                    />
                  </Grid>

                  <Grid item md={2} xs={4}>
                    <TextField
                      error={Boolean(touched.chargingProfileKind && errors.chargingProfileKind)}
                      fullWidth
                      helperText={touched.chargingProfileKind && errors.chargingProfileKind}
                      label="chargingProfileKind"
                      name="chargingProfileKind"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.chargingProfileKind}
                      variant="outlined"
                      // inputProps={{ maxLength: MAX_LENGTH.APPLICANT }}
                      required
                    />
                  </Grid>


                  <Grid item md={2} xs={4}>
                    <TextField
                      error={Boolean(touched.recurrencyKind && errors.recurrencyKind)}
                      fullWidth
                      helperText={touched.recurrencyKind && errors.recurrencyKind}
                      label="recurrencyKind"
                      name="recurrencyKind"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.recurrencyKind}
                      variant="outlined"
                      // inputProps={{ maxLength: MAX_LENGTH.APPLICANT }}
                      required
                    />
                  </Grid>


                  <Grid item md={3} xs={6}>
                    <TextField
                      error={Boolean(touched.validFrom && errors.validFrom)}
                      fullWidth
                      helperText={touched.validFrom && errors.validFrom}
                      label="validFrom"
                      name="validFrom"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.validFrom}
                      variant="outlined"
                      // inputProps={{ maxLength: MAX_LENGTH.APPLICANT }}
                      required
                    />
                  </Grid>


                  <Grid item md={3} xs={6}>
                    <TextField
                      error={Boolean(touched.validTo && errors.validTo)}
                      fullWidth
                      helperText={touched.validTo && errors.validTo}
                      label="validTo"
                      name="validTo"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.validTo}
                      variant="outlined"
                      // inputProps={{ maxLength: MAX_LENGTH.APPLICANT }}
                      required
                    />
                  </Grid>

                  <Grid item md={2} xs={4}>
                    <TextField
                      error={Boolean(touched.minChargingRate && errors.minChargingRate)}
                      fullWidth
                      helperText={touched.minChargingRate && errors.minChargingRate}
                      label="minChargingRate"
                      name="minChargingRate"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.minChargingRate}
                      variant="outlined"
                      // inputProps={{ maxLength: MAX_LENGTH.APPLICANT }}
                      required
                    />
                  </Grid>



                  <Grid item md={12}>
                    <Typography variant="h6">chargingSchedule</Typography>
                  </Grid>


                  <Grid item md={2} xs={4}>
                    <TextField
                      error={Boolean(touched.duration && errors.duration)}
                      fullWidth
                      helperText={touched.duration && errors.duration}
                      label="duration"
                      name="duration"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.duration}
                      variant="outlined"
                      // inputProps={{ maxLength: MAX_LENGTH.APPLICANT }}
                      required
                    />
                  </Grid>


                  <Grid item md={3} xs={6}>
                    <TextField
                      error={Boolean(touched.startSchedule && errors.startSchedule)}
                      fullWidth
                      helperText={touched.startSchedule && errors.startSchedule}
                      label="startSchedule"
                      name="startSchedule"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.startSchedule}
                      variant="outlined"
                      // inputProps={{ maxLength: MAX_LENGTH.APPLICANT }}
                      required
                    />
                  </Grid>


                  <Grid item md={2} xs={4}>
                    <TextField
                      error={Boolean(touched.chargingRateUnit && errors.chargingRateUnit)}
                      fullWidth
                      helperText={touched.chargingRateUnit && errors.chargingRateUnit}
                      label="chargingRateUnit"
                      name="chargingRateUnit"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.chargingRateUnit}
                      variant="outlined"
                      // inputProps={{ maxLength: MAX_LENGTH.APPLICANT }}
                      required
                    />
                  </Grid>


                  <Grid item md={12}>
                    <Typography variant="h6">chargingSchedulePeriod</Typography>
                  </Grid>


                  <Grid item md={2} xs={4}>
                    <TextField
                      error={Boolean(touched.startPeriod && errors.startPeriod)}
                      fullWidth
                      helperText={touched.startPeriod && errors.startPeriod}
                      label="startPeriod"
                      name="startPeriod"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.startPeriod}
                      variant="outlined"
                      // inputProps={{ maxLength: MAX_LENGTH.APPLICANT }}
                      required
                    />
                  </Grid>

                  <Grid item md={2} xs={4}>
                    <TextField
                      error={Boolean(touched.limit && errors.limit)}
                      fullWidth
                      helperText={touched.limit && errors.limit}
                      label="limit"
                      name="limit"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.limit}
                      variant="outlined"
                      // inputProps={{ maxLength: MAX_LENGTH.APPLICANT }}
                      required
                    />
                  </Grid>


                  <Grid item md={2} xs={4}>
                    <TextField
                      error={Boolean(touched.numberPhases && errors.numberPhases)}
                      fullWidth
                      helperText={touched.numberPhases && errors.numberPhases}
                      label="numberPhases"
                      name="numberPhases"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.numberPhases}
                      variant="outlined"
                      // inputProps={{ maxLength: MAX_LENGTH.APPLICANT }}
                      required
                    />
                  </Grid>


                </Grid>


                <Grid container item
                  className={classes.action}
                  direction="row" alignItems="center" justify="center">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="large"
                    disabled={isSubmitting}
                    className={classes.action}
                  >
                    Perform
                  </Button>

                </Grid>
              </CardContent>
            </Card>
          </form>
        )}
      </Formik>
    </div>
  );
};

RemoteStartTransactionForm.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object,
};


let mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps)(RemoteStartTransactionForm);


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    direction: "row",
    alignItems: "center",
    justify: "center"
  },
  content2: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  action: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },
  queryField: {
    // width: 300
    flexBasis: 260,
    marginRight: 20,
  },
  bulkAction: {
    marginLeft: theme.spacing(2),
  },

  divider: {
    margin: theme.spacing(2, 0)
  },
}));
