import palette from 'theme/palette';
import 'chartjs-plugin-datalabels';


export const options = {
  plugins: {
    title: {
      display: true,
      text: 'Chart.js Line Chart - Multi Axis',
    },

    paddingBelowLegends: true,

    datalabels: {
      anchor: 'end',
      align:'top',
      formatter: (value, ctx) => {
        // return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "원";
      },
      // formmater: function(value) { return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "원"; }
    }
  },
  stacked: false,


  responsive: true,
  maintainAspectRatio: false,
  animation: false,
  cornerRadius: 8,
  // tooltips: {
  //   enabled: true,
  //   mode: 'index',
  //   intersect: false,
  //   borderWidth: 1,
  //   borderColor: palette.divider,
  //   backgroundColor: palette.white,
  //   titleFontColor: palette.text.primary,
  //   bodyFontColor: palette.text.secondary,
  //   footerFontColor: palette.text.secondary
  // },
  layout: { 
    padding: {
    top: 20,
    // right: 200
    }
  },

  legend: {
    display: false,
    position: 'top',
    align:'start',

    item: {
      // paddingY: 20,
    },
    // spacing: 20,

    labels: {
      // padding: 20,
      // paddingBottom: 20,
    },
    title: {
      // padding: 20,
    },
    // layout: {
    //   100
    // },
  },

  scales: {
    xAxes: [
      {
        // type: 'time',
        ticks: {
          fontColor: palette.text.secondary,
          autoSkip: true,
          fontSize: 12,
          maxTicksLimit: 20 //tick간격
        },
        gridLines: {
          display: false,
          drawBorder: false
        }
      }
    ],
    yAxes: [
      // {
      //   id:'COUNT',
      //   ticks: {
      //     fontColor: palette.text.secondary,
      //     beginAtZero: true,
      //     //최소값의 90%
      //     // callback(value){
      //     //   return Number(value).toLocaleString('en')
      //     // }
      //   },
      //   gridLines: {
      //     borderDash: [2],
      //     borderDashOffset: [2],
      //     color: palette.divider,
      //     drawBorder: false,
      //     zeroLineBorderDash: [2],
      //     zeroLineBorderDashOffset: [2],
      //     zeroLineColor: palette.divider
      //   },

      //   scaleLabel: {
      //     display: true,
      //     labelString: '충전횟수',
      //   },
      // },

      {
        display:true,
        position:'left',
        ticks: {
          // fontColor: palette.text.secondary,
          beginAtZero: true,
          // min: 0
          // suggestedMin: 1346500,
          //최소값의 90%
          callback(value){
            return Number(value).toLocaleString('en')
          }
        },
        gridLines: {
          borderDash: [2],
          // borderDashOffset: [2],
          color: palette.divider,
          drawBorder: false,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
          zeroLineColor: palette.divider
        },

        scaleLabel: {
          display: true,
          labelString: '매출액[원]'
        },

        // ticks: {
        //   max: 1000,
        //   min: 0
        // }
      }
    ],

  },

};
