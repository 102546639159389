import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { RouteWithLayout } from './components';
import { AuthRouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

import {
  Dashboard as DashboardView,
  Typography as TypographyView,

  Account as AccountView,

  SignIn as SignInView,
  NotFound as NotFoundView,

  Stations as StationsView,
  StationUpdate as StationUpdateView,
  StationsAdd as StationsAddView,

  Chargers as ChargersView,
  ChargerAdd as ChargerAddView,
  ChargerUpdate as ChargerUpdateView,

  UsagesCharger as UsagesChargerView,
  UsagesUser as UsagesUserView,

  Installations as InstallationsView,
  InstallationAdd as InstallationAddView,
  InstallationUpdate as InstallationUpdateView,

  Malfunctions as MalfunctionsView,
  MalfunctionAdd as MalfunctionAddView,
  MalfunctionUpdate as MalfunctionUpdateView,

  SalesCharger as SalesChargerView,
  SalesUser as SalesUserView,
  SalesMember as SalesMemberView,
  SalesCardCompany as SalesCardCompanyView,
  SalesApprovedDetails as SalesApprovedDetailsView,

  Notices as NoticeView,
  NoticeAdd as NoticeAddView,
  NoticeUpdate as NoticeUpdateView,

  Members as MembersView,
  MemberAdd as MemberAddView,
  MemberUpdate as MemberUpdateView,

  Users as UsersView,
  UserAdd as UserAddView,
  UserUpdate as UserUpdateView,

  Roaming as RoamingView,
  RoamingAdd as RoamingAddView,

  RoamingGroup as RoamingGroupView,
  RoamingGroupDetail as RoamingGroupDetailView,
  RoamingGroupAdd as RoamingGroupAddView,

  Submember as SubmemberView,
  SubmemberAdd as SubmemberAddView,
  SubmemberEVC as SubmemberEVCView,
  SubmemberEVCAdd as SubmemberEVCAddView,

  KepcoUnitPriceUpdate as KepcoUnitPriceUpdateView,
  CastproUnitPriceUpdate as CastproUnitPriceUpdateView,
  SettingENV as SettingENVView,
  ChargerModel as ChargerModelView,
  ChargerModelAdd as ChargerModelAddView,
  ChargerModelUpdate as ChargerModelUpdateView,

  CommandNav as CommandNavView,

  BootNotification as BootNotificationView,
  ChangeAvailability as ChangeAvailabilityView,
  ChangeConfiguration as ChangeConfigurationView,
  ClearCache as ClearCacheView,
  GetDiagnostics as GetDiagnosticsView,
  RemoteStartTransaction as RemoteStartTransactionView,
  RemoteStopTransaction as RemoteStopTransactionView,
  Reset as ResetView,
  UnlockConnector as UnlockConnectorView,
  UpdateFirmware as UpdateFirmwareView,

  ReserveNow as ReserveNowView,
  CancelReservation as CancelReservationView,
  DataTransfer as DataTransferView,
  GetConfiguration as GetConfigurationView,
  GetLocalListVersion as GetLocalListVersionView,
  SendLocalList as SendLocalListView,
  TriggerMessage as TriggerMessageView,
  GetCompositeSchedule as GetCompositeScheduleView,
  ClearChargingProfile as ClearChargingProfileView,
  SetChargingProfile as SetChargingProfileView,
  SignedUpdateFirmware as SignedUpdateFirmwareView,
  GetLog as GetLogView,
  InstallCertificate as InstallCertificateView,
  ExtendedTriggerMessage as ExtendedTriggerMessageView,
  CertificateSigned as CertificateSignedView,
  DeleteCertificate as DeleteCertificateView,
  GetInstalledCertificateIds as GetInstalledCertificateIdsView,

  Credit as CreditView,
} from './views';


import thunkMiddleware from 'redux-thunk'
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import reducer from './reducers'
import { tempSetUser } from 'actions/user';
import { doLoginWithPW } from 'common/login';
import { createHashHistory } from 'history'
export const history = createHashHistory()


const middleware = applyMiddleware(thunkMiddleware)
const store = createStore(
  reducer,
  composeWithDevTools(middleware)
  );


const loadUser = () => {
  const userjson = localStorage.getItem('userinfo');

  if(!userjson) return;

  let user = JSON.parse(userjson);
  store.dispatch(tempSetUser(user));
}


const Routes = (props) => {

  const {className, user, ...rest } = props;

  loadUser();

  return (

    <Provider store={store}>

    <Switch>
      <Redirect exact from="/" to="/dashboard" />

      <RouteWithLayout component={DashboardView} exact layout={MainLayout} path="/dashboard" />

      <RouteWithLayout component={StationsView}  exact layout={MainLayout} path="/stations" />
      <RouteWithLayout component={StationsAddView} exact layout={MainLayout} path="/stations/add" />
      <RouteWithLayout component={StationUpdateView} exact layout={MainLayout} path="/stations/update/:statId" />

      <RouteWithLayout component={ChargersView} exact layout={MainLayout} path="/chargers" />
      <RouteWithLayout component={ChargersView} exact layout={MainLayout} path="/chargers/:statId" />
      <RouteWithLayout component={ChargerAddView} exact layout={MainLayout} path="/chargersAdd" />
      <RouteWithLayout component={ChargerUpdateView} exact layout={MainLayout} path="/chargers/update/:chargerSeqNo" />

      <RouteWithLayout component={UsagesChargerView} exact layout={MainLayout} path="/usages/charger" />
      <RouteWithLayout component={UsagesUserView} exact layout={MainLayout} path="/usages/user" />

      <RouteWithLayout component={InstallationsView} exact layout={MainLayout} path="/installations" />
      <RouteWithLayout component={InstallationAddView} exact layout={MainLayout} path="/installations/add" />
      <RouteWithLayout component={InstallationUpdateView} exact layout={MainLayout} path="/installations/update/:installationSeqNo" />

      <RouteWithLayout component={MalfunctionsView} exact layout={MainLayout} path="/malfunctions" />
      <RouteWithLayout component={MalfunctionAddView} exact layout={MainLayout} path="/malfunctions/add" />
      <RouteWithLayout component={MalfunctionUpdateView} exact layout={MainLayout} path="/malfunctions/update/:malfunctionSeqNo" />


      <RouteWithLayout component={SalesChargerView} exact layout={MainLayout} path="/sales/charger" />
      <RouteWithLayout component={SalesUserView} exact layout={MainLayout} path="/sales/user" />
      <RouteWithLayout component={SalesMemberView} exact layout={MainLayout} path="/sales/member" />
      <RouteWithLayout component={SalesCardCompanyView} exact layout={MainLayout} path="/sales/cardcompany" />
      <RouteWithLayout component={SalesApprovedDetailsView} exact layout={MainLayout} path="/sales/approvedDetails" />


      <RouteWithLayout component={UsersView} exact layout={MainLayout} path="/users" />
      <RouteWithLayout component={UserAddView} exact layout={MainLayout} path="/users/add" />
      <RouteWithLayout component={UserUpdateView} exact layout={MainLayout} path="/users/update/:userId" />

      <RouteWithLayout component={RoamingView} exact layout={MainLayout} path="/roaming" />
      <RouteWithLayout component={RoamingAddView} exact layout={MainLayout} path="/roaming/add" />
      
      <RouteWithLayout component={RoamingGroupView} exact layout={MainLayout} path="/roamingGroup" />
      <RouteWithLayout component={RoamingGroupDetailView} exact layout={MainLayout} path="/roamingGroup/:group_name" />
      <RouteWithLayout component={RoamingGroupAddView} exact layout={MainLayout} path="/add/roamingGroup" />
      


      <RouteWithLayout component={SubmemberView} exact layout={MainLayout} path="/submember" />
      <RouteWithLayout component={SubmemberAddView} exact layout={MainLayout} path="/submember/add" />
      <RouteWithLayout component={SubmemberEVCView} exact layout={MainLayout} path="/submember/evc/:member_id" />
      <RouteWithLayout component={SubmemberEVCAddView} exact layout={MainLayout} path="/submember/evc/add/:member_id" />
      

      <RouteWithLayout component={KepcoUnitPriceUpdateView} exact layout={MainLayout} path="/unitprice/kepco" />
      <RouteWithLayout component={CastproUnitPriceUpdateView} exact layout={MainLayout} path="/unitprice/castpro" />


      {/* 회원사관리, 공지사항은 관리자만 */}
      <RouteWithLayout component={MembersView} exact layout={MainLayout} path="/members" />
      <RouteWithLayout component={MemberAddView} exact layout={MainLayout} path="/members/add" />
      <RouteWithLayout component={MemberUpdateView} exact layout={MainLayout} path="/members/update/:memberId" />

      <RouteWithLayout component={NoticeView} exact layout={MainLayout} path="/notice"/>
      <RouteWithLayout component={NoticeAddView} exact layout={MainLayout} path="/notices/add"/>
      <RouteWithLayout component={NoticeUpdateView} exact layout={MainLayout} path="/notices/update/:notice_id" />


      <RouteWithLayout component={SettingENVView} exact layout={MainLayout} path="/settings/env" />
      <RouteWithLayout component={ChargerModelView} exact layout={MainLayout} path="/settings/chargermodel" />
      <RouteWithLayout component={ChargerModelAddView} exact layout={MainLayout} path="/settings/chargermodel/add" />
      <RouteWithLayout component={ChargerModelUpdateView} exact layout={MainLayout} path="/settings/chargermodel/update/:chargermodel_id" />

      <RouteWithLayout component={AccountView} exact layout={MainLayout} path="/account" />
      <RouteWithLayout component={TypographyView} exact layout={MainLayout} path="/typography" />

      <RouteWithLayout component={SignInView} exact layout={MinimalLayout} path="/sign-in" />

      <RouteWithLayout component={NotFoundView} exact layout={MinimalLayout} path="/not-found" />


      <RouteWithLayout component={CommandNavView} exact layout={MainLayout} path="/command" />
      <RouteWithLayout component={BootNotificationView} exact layout={MainLayout} path="/command/BootNotification" />
      <RouteWithLayout component={ChangeAvailabilityView} exact layout={MainLayout} path="/command/ChangeAvailability" />
      <RouteWithLayout component={ChangeConfigurationView} exact layout={MainLayout} path="/command/ChangeConfiguration" />
      <RouteWithLayout component={ClearCacheView} exact layout={MainLayout} path="/command/ClearCache" />
      <RouteWithLayout component={GetDiagnosticsView} exact layout={MainLayout} path="/command/GetDiagnostics" />
      <RouteWithLayout component={RemoteStartTransactionView} exact layout={MainLayout} path="/command/RemoteStartTransaction" />
      <RouteWithLayout component={RemoteStopTransactionView} exact layout={MainLayout} path="/command/RemoteStopTransaction" />
      <RouteWithLayout component={ResetView} exact layout={MainLayout} path="/command/Reset" />
      <RouteWithLayout component={UnlockConnectorView} exact layout={MainLayout} path="/command/UnlockConnector" />
      <RouteWithLayout component={UpdateFirmwareView} exact layout={MainLayout} path="/command/UpdateFirmware" />

      <RouteWithLayout component={ReserveNowView} exact layout={MainLayout} path="/command/ReserveNow" />
      <RouteWithLayout component={CancelReservationView} exact layout={MainLayout} path="/command/CancelReservation" />
      <RouteWithLayout component={DataTransferView} exact layout={MainLayout} path="/command/DataTransfer" />
      <RouteWithLayout component={GetConfigurationView} exact layout={MainLayout} path="/command/GetConfiguration" />
      <RouteWithLayout component={GetLocalListVersionView} exact layout={MainLayout} path="/command/GetLocalListVersion" />
      <RouteWithLayout component={SendLocalListView} exact layout={MainLayout} path="/command/SendLocalList" />
      <RouteWithLayout component={TriggerMessageView} exact layout={MainLayout} path="/command/TriggerMessage" />
      <RouteWithLayout component={GetCompositeScheduleView} exact layout={MainLayout} path="/command/GetCompositeSchedule" />
      <RouteWithLayout component={ClearChargingProfileView} exact layout={MainLayout} path="/command/ClearChargingProfile" />
      <RouteWithLayout component={SetChargingProfileView} exact layout={MainLayout} path="/command/SetChargingProfile" />
      <RouteWithLayout component={SignedUpdateFirmwareView} exact layout={MainLayout} path="/command/SignedUpdateFirmware" />
      <RouteWithLayout component={GetLogView} exact layout={MainLayout} path="/command/GetLog" />
      <RouteWithLayout component={InstallCertificateView} exact layout={MainLayout} path="/command/InstallCertificate" />
      <RouteWithLayout component={ExtendedTriggerMessageView} exact layout={MainLayout} path="/command/ExtendedTriggerMessage" />
      <RouteWithLayout component={CertificateSignedView} exact layout={MainLayout} path="/command/CertificateSigned" />
      <RouteWithLayout component={DeleteCertificateView} exact layout={MainLayout} path="/command/DeleteCertificate" />
      <RouteWithLayout component={GetInstalledCertificateIdsView} exact layout={MainLayout} path="/command/GetInstalledCertificateIds" />

      
      <RouteWithLayout component={CreditView} exact layout={MinimalLayout} path="/CreditCard/:member_id/:email/:user_card_number" />
      <RouteWithLayout component={CreditView} exact layout={MinimalLayout} path="/CreditCard/:member_id/:email" />
      <RouteWithLayout component={CreditView} exact layout={MinimalLayout} path="/CreditCard/:member_id" />
      
      <Redirect to="/not-found" />
    </Switch>
    </Provider>
  );
};

export default Routes;
