//firebase.js
import firebase from 'firebase/app' //after firebase sdk 8.0
import "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyBIouDpVNCvAR6zQ89d0SAjnKFn8lp9Bts",
    authDomain: "evchargerapp-539d2.firebaseapp.com",
    databaseURL: "https://evchargerapp-539d2-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "evchargerapp-539d2",
    storageBucket: "evchargerapp-539d2.appspot.com",
    messagingSenderId: "12589737494",
    appId: "1:12589737494:web:ee66c34bd7cf20175fb737"
  };
  
  const evchargerApp = firebase.initializeApp(firebaseConfig, 'evcharger');
  const authEvchargerApp = evchargerApp.auth();
  authEvchargerApp.signInAnonymously().then((userCredential) => {
    //console.log("익명 로그인 성공");
  }).catch((error) => {
    console.log('익명 로그인 실패', error.message);
  })

export default evchargerApp;

