import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Formik } from "formik";
import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Divider,
} from "@material-ui/core";

import Autocomplete from '@material-ui/lab/Autocomplete';
import { NEONPV_CONFIG } from "config.js";
import { MAX_LENGTH, charger_speed_list, charger_type_list, charger_status_list,
  getChargerTypeCode, getChargerStatusCode,
  USER_AUTH} from "common/constants";

import { bindActionCreators } from 'redux';
import {
  updateOpenDialog,
  updateSelectedStation
} from "actions/settings";

import {StationSearchDialog} from "views/StationSearchDialog";
import { loadChargerModelList } from "utils/LoadConfiguration";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";


const SubmemberEVCAddForm = (props) => {
  const { className, match, history, user, ...rest } = props;
  const classes = useStyles();
  const params = useParams();
  const userAuth = useSelector((state) => state.user.user_auth);

  const selector_openDialog = useSelector((state) => state.settings.openDialog);
  const selector_selectedStationInfo = useSelector((state) => state.settings.selectedStationInfo);

  const [listData, setListData] = useState([]);

  const [queryStationName, setQueryStationName] = useState("");
  const [queryStationAddr, setQueryStationAddr] = useState("");

  /** 충전기ID 중복체크 유무 */
  const [verifiedChargerId, setVerifiedChargerId] = useState(false);

  /** 충전기 시리얼번호 중복체크 유무 */
  const [verifiedSerialNumber, setVerifiedSerialNumber] = useState(false);

  const handleSubmit = (event, values) => {
    event.preventDefault();
    
    // if(!selector_selectedStationInfo.statId){
    //   alert("충전소ID가 비어 있습니다.\n충전소를 '충전소 검색' 버튼을 이용하여 추가 해 주십시오.");
    //   return;
    // }
    
    fetch(`${NEONPV_CONFIG.HOSTNAME}/subUser/querySubUserEVCAdd`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        statId: selector_selectedStationInfo.statId,
        member_id2: params.member_id,
      }),
    })
    .then((response) => {
      return response.json();
    })
    .then((responseData) => {

      if (responseData.resultCode === "000") {
        alert("충전소 추가에 성공했습니다.");

        props.updateSelectedStation({});

        setTimeout(() => {
          history.goBack();
        }, 500);
      } else {
        //에러
        alert(`충전소 추가에 실패했습니다.\n${responseData.resultCode}-${responseData.resultMessage}`);
      }
    })
    .catch((error) => {
      console.error(error);
      alert("충전소 추가에 실패했습니다.");
    });
  };


  const handleBack = () => {
    props.updateSelectedStation({});
    history.goBack();
  };

  const showChargerSearchDialog = () => {
    props.updateOpenDialog(true);
  }


  useEffect(() => {
    props.updateSelectedStation({});
  }, []);



  return (

    <div>
      <StationSearchDialog openDialog={selector_openDialog} />

      <Formik
        enableReinitialize={true}
        initialValues={{
          statId: "", //충전소를 선택하는 dialog box
          chargerId: "", // 충전기번호
          serialNo: "", //충전기 시리얼번호
          // chargerIP: "", //충전기 IP
          // chargerPort: "", //충전기 PORT
          chargerModel: "", //충전기 모델
          // chargerVersion: "", //충전기 버전
          chargerFirmwareVersion: "", //충전기 펌웨어버전
          chargerMacAddr: "", //Mac 주소
          chargerLTERouterNo: "", //LTE 라우터 번호
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          isSubmitting,
          touched,
          values,
          setFieldValue
        }) => (
          <form
            className={clsx(classes.root, className)}
            onSubmit={(e) => handleSubmit(e, values)}
          >

            <Card>
              <CardContent>
                <Grid container spacing={2} >
                  <Grid container item md={2} xs={12}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      size="large"
                      fullWidth
                      onClick={showChargerSearchDialog}
                    >
                      충전소 검색
                    </Button>
                  </Grid>

                  <Grid item md={2} xs={4} >
                    <TextField
                      error={Boolean(touched.statId && errors.statId)}
                      fullWidth
                      helperText={touched.statId && errors.statId}
                      label="충전소ID"
                      name="statId"
                      onBlur={handleBlur}
                      onChange={(e) => { handleChange(e); setVerifiedChargerId(false);
                        setVerifiedSerialNumber(false);
                        setFieldValue('statId', selector_selectedStationInfo.statId); }}
                      value={selector_selectedStationInfo.statId}
                      variant="outlined"
                      inputProps={{
                        maxLength: MAX_LENGTH.STAT_ID, readOnly: true
                      }}
                      InputLabelProps={{ shrink: true }}
                      required
                    />
                  </Grid>

                  <Grid item md={4} xs={6}>
                    <TextField
                      fullWidth
                      label="충전소 명칭"
                      name="statName"
                      value={selector_selectedStationInfo.statName}
                      variant="outlined"
                      inputProps={{ maxLength: MAX_LENGTH.STAT_NAME, readOnly: true, }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>

                  <Grid item md={4} xs={6}>
                    <TextField
                      fullWidth
                      label="충전소 주소"
                      name="statAddr"
                      value={selector_selectedStationInfo.addr}
                      variant="outlined"
                      inputProps={{ maxLength: MAX_LENGTH.STAT_ADDR, readOnly: true,  }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                </Grid>

                <Grid container item className={classes.action}
                    direction="row" alignItems="center" justifyContent="center">

                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="large"
                    disabled={isSubmitting}
                    className={classes.action}
                  >
                    충전소 추가
                  </Button>

                  <Button
                    variant="outlined"
                    size="large"
                    onClick={handleBack}
                    className={classes.action}
                  >
                    취소
                  </Button>
                </Grid>
              </CardContent>
            </Card>
          </form>
        )}
      </Formik>
    </div>
  );
};


SubmemberEVCAddForm.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object,
};


const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    updateOpenDialog,
    updateSelectedStation
  }, dispatch);
};


let mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps)
(SubmemberEVCAddForm);


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  action: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },

  queryField: {
    flexBasis: 220,
    marginRight: 20,
  },
  bulkAction: {
    marginLeft: theme.spacing(2),
  },

  divider: {
    margin: theme.spacing(2, 0)
  },
}));
