import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Button,
  Card,
  CardContent,
  CardActions,
  Grid,
  makeStyles,

  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,

  Typography,
  CardHeader,
  Box
} from "@material-ui/core";
import { NEONPV_CONFIG } from "config.js";
import { USER_AUTH } from 'common/constants';


const CustomTableCell = ({ row, name, onChange, width, label }) => {
  const classes = useStyles();

  if (row) {
    return (
      <TableCell  align="right" width={width} className={classes.tablecell}>
        <TextField
          // variant="standard"
          variant="outlined"
          // variant="filled"
          name={name}
          fullWidth
          align="right"
          value={row[name]}
          onChange={onChange}
          inputProps={{
            style: {
              textAlign: "right",
              padding: 8,
            },
            maxLength: 10,
          }}
        />
      </TableCell>
    );
  } else {
    return (<TableCell></TableCell>);
  }
};

const UnitPriceTableCell = (props) => {

  const {className, row, index, listChange, ...rest} = props;
  const classes = useStyles();
  const [module, setModule] = useState(row);

  const handleChange = (event) => {
    setModule({ ...module, [event.target.name]: event.target.value });
    listChange(index, event.target.name, event.target.value);
  }

  return (
    <TableRow
      className={classes.tableRow}
      key={module.id}
      style={{ height: "10px" }}
    >
      <TableCell align="center" width={COLUMN_WIDTH.TIMERANGE}>{module.time}</TableCell>
      <CustomTableCell {...{ row: module, name: "lowpress1", onChange: handleChange, width: COLUMN_WIDTH.LOW_PRESS1, label: '저압1' }} />
      <CustomTableCell {...{ row: module, name: "lowpress2", onChange: handleChange, width: COLUMN_WIDTH.LOW_PRESS2, label: '저압2' }} />
      <CustomTableCell {...{ row: module, name: "lowpress3", onChange: handleChange, width: COLUMN_WIDTH.LOW_PRESS3, label: '저압3' }} />
      <CustomTableCell {...{ row: module, name: "lowpress4", onChange: handleChange, width: COLUMN_WIDTH.LOW_PRESS4, label: '저압4' }} />
      <CustomTableCell {...{ row: module, name: "highpress1", onChange: handleChange, width: COLUMN_WIDTH.HIGH_PRESS1, label: '고압1' }} />
      <CustomTableCell {...{ row: module, name: "highpress2", onChange: handleChange, width: COLUMN_WIDTH.HIGH_PRESS2, label: '고압2' }} />
      <CustomTableCell {...{ row: module, name: "highpress3", onChange: handleChange, width: COLUMN_WIDTH.HIGH_PRESS3, label: '고압3' }} />
      <CustomTableCell {...{ row: module, name: "highpress4", onChange: handleChange, width: COLUMN_WIDTH.HIGH_PRESS4, label: '고압4' }} />
    </TableRow>
  )
};


const KepcoUnitPriceUpdateForm = (props) => {
  const { className, history, user, unitPriceInfo, ...rest } = props;
  const classes = useStyles();

  const userAuth = useSelector((state) => state.user.user_auth);

  const [listData, setListData] = useState(unitPriceInfo);


  const handleSubmit = (event) => {
    event.preventDefault();

    // console.log(listData);

    fetch(`${NEONPV_CONFIG.HOSTNAME}/unitprice/updateKepcoUnitPrice`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        list: listData,
      }),
    })
    .then((response) => {
      return response.json();
    })
    .then((responseData) => {
      if (responseData.resultCode === "000") {
        alert("업데이트에 성공했습니다.");
      } else {
        //에러
        alert(`업데이트에 실패했습니다.\n(${responseData.resultCode}):${responseData.resultMessage}`);
      }
    })
    .catch((error) => {
      console.error(error);
      alert("업데이트에 실패했습니다.");
    });
  };

  const handleBack = () => {
    // history.goBack();
    window.location.href = '/';
  };



  const listChange = (index, name, val) => {
    console.log('listChange: ', index, name, val);

    let newArr = [...listData];
    newArr[index][name] = val;
    setListData(newArr);

    // console.log(listData);
  }


  return (
    <div>
      <Card className={clsx(classes.root, className)} {...rest}>
        <CardHeader align='center' title={<Typography variant="h4">한전공급단가</Typography>} style={{ backgroundColor: 'lightgray' }} >
        </CardHeader>

        <CardContent className={classes.content}>

          <Grid container spacing={2} >
            <Grid item md={2} xs={4} >
              <TextField
                className={classes.queryField}
                label="운영회원사"
                name="businessName"
                value={userAuth === USER_AUTH.MEMBER ? user.admin_name : '관리자'}
                variant="outlined"
                inputProps={{ maxLength: 50 }}
                disabled
              />
            </Grid>
          </Grid>


          <Box sx={{ m: 2 }} />


          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center" width={COLUMN_WIDTH.TIMERANGE}>시간대</TableCell>
                <TableCell align="center" width={COLUMN_WIDTH.LOW_PRESS1}>저압1</TableCell>
                <TableCell align="center" width={COLUMN_WIDTH.LOW_PRESS2}>저압2</TableCell>
                <TableCell align="center" width={COLUMN_WIDTH.LOW_PRESS3}>저압3</TableCell>
                <TableCell align="center" width={COLUMN_WIDTH.LOW_PRESS4}>저압4</TableCell>
                <TableCell align="center" width={COLUMN_WIDTH.HIGH_PRESS1}>고압1</TableCell>
                <TableCell align="center" width={COLUMN_WIDTH.HIGH_PRESS2}>고압2</TableCell>
                <TableCell align="center" width={COLUMN_WIDTH.HIGH_PRESS3}>고압3</TableCell>
                <TableCell align="center" width={COLUMN_WIDTH.HIGH_PRESS4}>고압4</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {unitPriceInfo.map((module, index) => (
                < UnitPriceTableCell row={module} index={index} listChange={listChange} />
              ))}
            </TableBody>
          </Table>
        </CardContent>


        <CardActions className={classes.actions}>
          <Grid container item className={classes.action}
            direction="row" alignItems="center" justify="center">
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={handleSubmit}
              className={classes.action}
            >등록</Button>

            <Button
              variant="contained"
              size="large"
              className={classes.action}
              onClick={handleBack}
            >취소</Button>
          </Grid>
        </CardActions>
      </Card>
      </div>
  );
};


KepcoUnitPriceUpdateForm.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object,
};

let mapStateToProps = (state) => {
  return {
    user: state.user
  };
};


export default connect(mapStateToProps)(KepcoUnitPriceUpdateForm);


const COLUMN_WIDTH = {
  TIMERANGE: '12%',
  LOW_PRESS1: '11%', //30
  LOW_PRESS2: '11%', //40
  LOW_PRESS3: '11%', //50
  LOW_PRESS4: '11%', //60
  HIGH_PRESS1: '11%', //70
  HIGH_PRESS2: '11%', //80
  HIGH_PRESS3: '11%', //90
  HIGH_PRESS4: '11%', //100
}


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(1),
  },
  actions: {
    justifyContent: "flex-end",
  },
  action: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },
  tablecell: {
    "&.MuiTableCell-root": {
      padding: '8px',
      // margin:'8px'
    },
  },
  tableRow: {
    height: "20px",
    padding : "0px",
  },
  textfield: {
    "&.MuiOutlinedInput-input": {
      padding: '8px'
    }
  }
}));
