import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  Typography
} from '@material-ui/core';


import validate from 'validate.js';
import { NEONPV_CONFIG } from 'config.js';
import { USER_AUTH } from 'common/constants';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { doLogout } from 'actions/user.js';
import md5 from 'md5';


const schema = {
  username: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 32
    }
  },
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64
    }
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128
    }
  },

  confirm: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128
    }
  },

  userClassify: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128
    }
  }

};


const useStyles = makeStyles(() => ({
  root: {},

  action: {
    justifyContent:'center'
  }
}));

const AccountDetails = props => {
  const { className, user, history, doLogout, ...rest } = props;

  const classes = useStyles();


  const initialState = {
    admin_id: user.admin_id,
    admin_name: user.admin_name,
    password: '',
    confirm: '',
    member_bid: user.bid,
    member_bkey: user.bkey
  };

  const [formState, setFormState] = useState({
    isValid: false,
    values: initialState,
    touched: {},
    errors: {}
  });


  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));


  }, [formState.values]);


  const handleChange = event => {

    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };


  const handleSubmit = event => {

    if(formState.values.admin_id === ''){
      formState.touched.admin_id = true;
      formState.errors.admin_id = ['id is required'];
    }

    if(formState.values.password === ''){
      formState.touched.password = true;
      formState.errors.password = ['New Password is required'];
    }

    if(formState.values.confirm === ''){
      formState.touched.confirm = true;
      formState.errors.confirm = ['Confirm is required'];
    }

    //pw 일치 여부 check
    if (formState.values.password !== formState.values.confirm){
      formState.errors.confirm = ['패스워드가 일치하지 않습니다.'];
    }


    if(hasError('admin_id') || hasError('password') || hasError('confirm')){
      setFormState(formState => ({
        ...formState,
        errors: formState.errors,
      }));
      return;
    }



    if (user.user_auth === USER_AUTH.ADMIN){
      console.log('관리자');
      console.log(formState.values);

      fetch(`${NEONPV_CONFIG.HOSTNAME}/admin/updateAdmin`,
      {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body:
          JSON.stringify({
            admin_id: formState.values.admin_id,
            admin_name: formState.values.admin_name,
            admin_password: md5(formState.values.password),
            bid : formState.values.member_bid,
            bkey: formState.values.member_bkey
          })
      })
      .then( response => {
        return response.json();
      })
      .then (responseData => {

        if (responseData.resultCode === "000") {
          alert("업데이트에 성공했습니다.");

          localStorage.setItem('userinfo',
            JSON.stringify({
              status: 'on',
              user_auth: USER_AUTH.ADMIN,
              admin_id: formState.values.admin_id,
              admin_name: formState.values.admin_name,
              bid: formState.values.member_bid, //환경부GW BID
              bkey: formState.values.member_bkey, //환경부GW BKEY
            })
          );

          setTimeout( () => {
            window.location.href = '/';
          }, 500);

        } else {
          //에러
          alert(`업데이트에 실패했습니다.\n(${responseData.resultCode}):${responseData.resultMessage}`);
        }
      });
    } else {

      //회원사
      console.log('회원사');
      fetch(`${NEONPV_CONFIG.HOSTNAME}/member/updateMemberInfoSmall`,
      {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body:
          JSON.stringify({
            member_id: formState.values.admin_id,
            member_name: formState.values.admin_name,
            member_password: md5(formState.values.password),
            member_bid: formState.values.member_bid,
            member_bkey: formState.values.member_bkey
          })
      })
      .then( response => { return response.json(); })
      .then (responseData => {

        if (responseData.resultCode === "000") {
          alert("업데이트에 성공했습니다.");

          localStorage.setItem('userinfo',
            JSON.stringify({
              status: 'on',
              user_auth: USER_AUTH.MEMBER,
              admin_id: formState.values.admin_id,
              admin_name: formState.values.admin_name,
              bid: formState.values.member_bid, //환경부GW BID
              bkey: formState.values.member_bkey, //환경부GW BKEY
            })
          );

          setTimeout( () => {
            window.location.href = '/';
          }, 500);

        } else {
          //에러
          alert(`업데이트에 실패했습니다.\n${responseData.resultCode}`);
        }
      });
    }

  }


  const handleLogout = event => {

    props.doLogout();

    setTimeout( () => {
      window.location.href = '/sign-in';
    }, 500);
  }


  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;


  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <form autoComplete="off" noValidate>
        <CardHeader align="center"
          title={<Typography variant="h4">계정 프로필</Typography>}
          // style={{ backgroundColor: 'lightgray'}}
        />
        <Divider />

        <CardContent>
          <Grid container spacing={2}>

            <Grid item md={12} xs={12}>
              <TextField
                label="id"
                name="admin_id"
                fullWidth
                error={hasError('admin_id')}
                helperText={
                  hasError('admin_id') ? formState.errors.admin_id[0] : null
                }
                onChange={handleChange}
                disabled
                type="text"
                value={formState.values.admin_id || ''}
                variant="outlined"
              />
            </Grid>

            <Grid item md={12} xs={12}>
              <TextField
                label="이름"
                name="admin_name"
                fullWidth
                error={hasError('admin_name')}
                helperText={
                  hasError('admin_name') ? formState.errors.admin_name[0] : null
                }
                onChange={handleChange}
                type="text"
                value={formState.values.admin_name || ''}
                variant="outlined"
              />
            </Grid>

            <Grid item md={12} xs={12} >
              <TextField
                fullWidth
                label="새 비밀번호"
                name="password"
                onChange={handleChange}
                type="password"
                error={hasError('password')}
                helperText={hasError('password') ? formState.errors.password[0] : null }
                value={formState.values.password || ''}
                variant="outlined"
              />
            </Grid>


            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                label="새 비밀번호 확인"
                name="confirm"
                onChange={handleChange}
                type="password"
                error={hasError('confirm')}
                helperText={
                  hasError('confirm') ? formState.errors.confirm[0] : null
                }
                value={formState.values.confirm || ''}
                variant="outlined"
              />
            </Grid>


            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                label="환경부기관ID"
                name="member_bid"
                onChange={handleChange}
                type="text"
                error={hasError('member_bid')}

                helperText={
                  hasError('member_bid') ? formState.errors.member_bid[0] : null
                }
                value={formState.values.member_bid || ''}
                variant="outlined"
                inputProps={{ maxLength: 2 }}
              />
            </Grid>

            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                label="환경부기관인증키"
                name="member_bkey"
                onChange={handleChange}
                type="text"
                error={hasError('member_bkey')}

                helperText={
                  hasError('member_bkey') ? formState.errors.member_bkey[0] : null
                }
                value={formState.values.member_bkey || ''}
                variant="outlined"
                inputProps={{ maxLength: 16 }}
              />
            </Grid>

          </Grid>
        </CardContent>

        <Divider />

        <CardActions className={classes.action}>
          <Button
            color="primary"
            variant="contained"
            onClick={handleSubmit}
          >
            수정
          </Button>

          <Button
          color="secondary"
          variant="contained"
          onClick={handleLogout}
          >
          로그아웃
          </Button>

        </CardActions>
      </form>
    </Card>
  );
};

AccountDetails.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object
};


const mapDispatchToProps = dispatch => {
  return bindActionCreators({ doLogout }, dispatch)
}

let mapStateToProps = (state) => {
  return {
    user: state.user
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountDetails);

