import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography
} from '@material-ui/core';
import { NEONPV_CONFIG } from 'config.js';



const useStyles = makeStyles(theme => ({
  root: {
    // backgroundColor: '#fff9c4'
  },
  content: {
    // marginTop:10
  }
}));


/**
 * 공지사항 게시판
 * @param {*} props 
 */
const NoticeBoard = props => {
  const {className, ...rest} = props;

  const classes = useStyles();

  return (

    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >

    {/* <CardHeader align='center' title="※공지사항※" /> */}
    {/* <Divider /> */}
    <CardContent className={classes.content}>
      <Typography variant="h5" align="center">※공지사항</Typography>
      <Typography variant="body1" align="center">
      {NEONPV_CONFIG.DASHBOARD_NOTICE}
      </Typography>
    </CardContent>
    </Card>
  );
};

NoticeBoard.propTypes = {
  className: PropTypes.string
};

export default NoticeBoard;