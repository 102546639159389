import React, {useState, useEffect} from "react";
import { makeStyles } from "@material-ui/styles";
import PropTypes from 'prop-types';
import ChargerModelUpdateForm from "./ChargerModelUpdateForm";
import { NEONPV_CONFIG } from 'config.js';
import { connect, useSelector} from 'react-redux';
import { USER_AUTH } from 'common/constants';
import moment from "moment";



const ChargerModelUpdate = (props) => {

  const {className, match, history, ...rest } = props;
  const classes = useStyles();

  const userAuth = useSelector((state) => state.user.user_auth);
  if(userAuth === USER_AUTH.MEMBER) {
    window.location.href = '/';
  }

  const [chargerModelInfo, setChargerModelInfo] = useState(null);

  const loadData = (chargermodel_id) => {

    var url = `${NEONPV_CONFIG.HOSTNAME}/util/getChargerModelInfo?id=`+chargermodel_id;

    fetch(url)
    .then(res => res.json())
    .then(snapshot => {

      console.log(snapshot);

      var chargermodel = {
        id: snapshot.resultData.id,
        chargerModel: snapshot.resultData.chargerModel,
        memo: snapshot.resultData.memo,
        updateTime: snapshot.resultData.updateTime ? moment(snapshot.resultData.updateTime).format("YYYY-MM-DD HH:mm:ss") : "",
      };

      setChargerModelInfo(chargermodel);
    }
    );
  }


  useEffect( () => {
    loadData(match.params.chargermodel_id);
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        { chargerModelInfo && <ChargerModelUpdateForm chargerModelInfo={chargerModelInfo} history={history} /> }
      </div>
    </div>
  );
};


ChargerModelUpdate.propTypes = {
  history: PropTypes.object
};

let mapStateToProps = (state) => {
  return {
    user: state.user
  };
}

export default connect(
  mapStateToProps,
)(ChargerModelUpdate);



const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));