import React, {useState, useEffect} from "react";
import { connect, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import PropTypes from 'prop-types';
import MalfunctionUpdateForm from "./MalfunctionUpdateForm";
import { NEONPV_CONFIG } from 'config.js';

import { bindActionCreators } from 'redux';
import {
  updateSelectedStation
} from "actions/settings";



const MalfunctionUpdate = (props) => {

  const {className, match, history, ...rest } = props;
  const classes = useStyles();

  const [malfunctionInfo, setMalfunctionInfo] = useState(null);

  const loadData = (malfunctionSeqNo) => {

    var url = `${NEONPV_CONFIG.HOSTNAME}/malFunction/queryMalfunctionInfo?malfunctionSeqNo=`+malfunctionSeqNo;

    fetch(url)
    .then(res => res.json())
    .then(snapshot => {

      console.log(snapshot);

      var malfunction = {
        seqNo: snapshot.resultData.SeqNo,
        reportDate : snapshot.resultData.reportDate,
        reporter_name: snapshot.resultData.reporter_name,
        corporate_name: snapshot.resultData.corporate_name,
        reporter_tel_no: snapshot.resultData.reporter_tel_no,
        reporter_mobile_no: snapshot.resultData.reporter_mobile_no,
        reporter_email: snapshot.resultData.reporter_email,
        statId: snapshot.resultData.statId,
        statName: snapshot.resultData.statName,
        addr: snapshot.resultData.addr,
        reporter_region: snapshot.resultData.reporter_region,
        charger_name: snapshot.resultData.charger_name,
        charger_info: snapshot.resultData.charger_info,

        process_content: snapshot.resultData.process_content, // 고장 처리 내용
        manager: snapshot.resultData.manager, // 처리 담당자
        report_content: snapshot.resultData.report_content, // 고장 신고 내용

        status: snapshot.resultData.status,
        register_date: snapshot.resultData.register_date,
        process_date: snapshot.resultData.process_date,

        memberName: snapshot.resultData.member_name,
      };

      setMalfunctionInfo(malfunction);

    });
  }

  useEffect( () => {

    loadData(match.params.malfunctionSeqNo);
  }, []);


  return (
    <div className={classes.root}>
      <div className={classes.content}>
        { malfunctionInfo && <MalfunctionUpdateForm malfunctionInfo={malfunctionInfo} history={history} /> }
      </div>
    </div>
  );
};


MalfunctionUpdate.propTypes = {
  history: PropTypes.object
};


const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    updateSelectedStation
  }, dispatch);
};


export default connect(mapDispatchToProps)(MalfunctionUpdate);



const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));
