import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Formik } from "formik";
import { connect, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,

  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { USER_AUTH } from 'common/constants';
import { NEONPV_CONFIG } from "config.js";

const RoamingAddForm = (props) => {
  const { className, history, user, ...rest } = props;
  const classes = useStyles();
  
  const ref = useRef(null);
  const [isOpenPost, setIsOpenPost] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogMessage, setDialogMessage] = useState('');
  const userAuth = useSelector((state) => state.user.user_auth);
  const validate = (values) => {
    let errors = {};

    //비밀번호 일치 여부 check
    if (values.memberPassword !== values.memberPasswordConfirm) {
      errors.memberPasswordConfirm = "비밀번호가 일치하지 않습니다.";
    }

    return errors;
  }


  const handleSubmit = async (event, values) => {
    event.preventDefault();
        fetch(`${NEONPV_CONFIG.HOSTNAME}/roaming/MemberRoaming/add`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            member_id: user.user_auth == USER_AUTH.ADMIN ? values.adminId : user.admin_id,
            bid: values.bid,
            roaming_member_id: values.member_id,
          }),
        })
        .then((response) => {
            return response.json();
        })
        .then((responseData) => {
          if (responseData.resultCode === "000") {
            alert("로밍 추가에 성공했습니다.");
          } else {
            //에러
            alert(`로밍 추가에 실패했습니다.\nError: ${responseData.resultMessage}`);
          }
        })
        .catch((error) => {
          // 에러
          alert("로밍 추가에 실패했습니다.");
        });
    };

  const handleBack = () => {
    history.goBack();
  };


  return (
    <div>
      <Dialog open={openDialog} onClose={() => { setOpenDialog(false) }}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{dialogMessage}</DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" color="secondary" onClick={() => { setOpenDialog(false) }}>닫기</Button>
        </DialogActions>
      </Dialog>


      <Formik
        innerRef={ref}
        enableReinitialize={false}
        initialValues={{
          memberId: "",
          memberName: "",
          memberTelNo: "",

          memberPassword: '',
          memberPasswordConfirm: '',

          memberPostCode: "",
          memberAddr: "",
          memberAddrDetail: "",

          adminName: "",
          adminTelNo: "",
          adminEmail: "",

          note: "",

          member_bid: '',
          member_bkey : "",

          member_env_integrated : true,

          adminId: user.user_auth === USER_AUTH.ADMIN ? '' : user.admin_id
        }}
        validate={validate}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          isSubmitting,
          touched,
          values,
        }) => (
          <form
            className={clsx(classes.root, className)}
            onSubmit={(e) => handleSubmit(e, values)}
          >
            <Card>
              <CardContent>
                <Grid container spacing={2}>

                  <Grid item md={2} xs={4}>
                      <TextField
                        error={Boolean(touched.adminId && errors.adminId)}
                        fullWidth
                        helperText={touched.adminId && errors.adminId}
                        label="운영회원사"
                        name="adminId"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        value={values.adminId}
                        disabled={user.user_auth !== USER_AUTH.ADMIN}
                        variant="outlined"
                        inputProps={{ maxLength: 12 }}
                      />
                    </Grid>

                    <Grid item md={9} xs={8}></Grid>
                  
                  <Grid item md={2} xs={4}>
                    <TextField
                      error={Boolean(touched.member_bid && errors.member_bid)}
                      fullWidth
                      helperText={touched.member_bid && errors.member_bid}
                      label="로밍사 기관ID"
                      name="bid"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      required
                      variant="outlined"
                      inputProps={{ maxLength: 2 }}
                    />
                  </Grid>

                  <Grid item md={2} xs={4}>
                    <TextField
                      error={Boolean(touched.member_bkey && errors.member_bkey)}
                      fullWidth
                      helperText={touched.member_bkey && errors.member_bkey}
                      label="로밍회원사"
                      name="member_id"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      inputProps={{ maxLength: 12 }}
                    />
                  </Grid>

                  <Grid item md={4} xs={8}></Grid>

                  
                </Grid>


                <Grid container item className={classes.action}
                  direction="row" alignItems="center" justify="center">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="large"
                    disabled={isSubmitting}
                    className={classes.action}
                  >
                    로밍 추가
                  </Button>

                  <Button
                    variant="outlined"
                    size="large"
                    onClick={handleBack}
                    className={classes.action}
                  >
                    취소
                  </Button>
                </Grid>
              </CardContent>
            </Card>
          </form>
        )}
      </Formik>
    </div>
  );
};

RoamingAddForm.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object,
};

let mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

//export default connect(mapStateToProps)(SubmemberAddForm);
export default connect(mapStateToProps)(RoamingAddForm);
//export default RoamingAddForm;


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  action: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },
  dividerInset: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    color: '#616161'
  },
  postCodeStyle: {
    display: 'block',
    position: 'relative',
    top: '0%',
    width: '400px',
    // height: '400px',
    // padding: '7px',
  },
}));
