import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
import {
  updateOpenDialog,
  updateSelectedStation
} from "actions/settings";

import {
  InputAdornment,
  SvgIcon,
  Box,
  Button,
  TextField,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";

import PerfectScrollbar from 'react-perfect-scrollbar';
import SearchIcon from '@material-ui/icons/Search';
import { USER_AUTH} from "common/constants";
import { NEONPV_CONFIG } from "config.js";


const StationSearchDialog = (props) => {

  const {user} = props;

  const classes = useStyles();

  const userAuth = useSelector((state) => state.user.user_auth);
  const selector_openDialog = useSelector((state) => state.settings.openDialog);

  const [listData, setListData] = useState([]);
  const [queryStationID, setQueryStationID] = useState("");
  const [queryStationName, setQueryStationName] = useState("");
  const [queryStationAddr, setQueryStationAddr] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);


  const closeDialog = () => {
    props.updateOpenDialog(false);
  }

  const selectStation = (event, module) => {
    props.updateSelectedStation(module);
    closeDialog();
  }

  const handleQueryStationID = (event) => {
    event.persist();
    setQueryStationID(event.target.value);
  };

  const handleQueryStationNameChange = (event) => {
    event.persist();
    setQueryStationName(event.target.value);
  };

  const handleQueryStationAddrChange = (event) => {
    event.persist();
    setQueryStationAddr(event.target.value);
  };

  const handleStationSearch = (event) => {
    searchStationData(queryStationID, queryStationName, queryStationAddr);
  };


  const searchStationData = (statId, statName, addr) => {

    var url = `${NEONPV_CONFIG.HOSTNAME}/station/queryStationList?`;

    var params = "";

    if (userAuth === USER_AUTH.MEMBER){
      params += 'memberName=' + user.admin_name + '&';
    }

    if (statId) {
      params += "statId=" + statId + '&';
    }

    if (statName) {
      params += "statName=" + statName + '&';
    }

    if (addr) {
      params += "addr=" + addr  + '&';
    }

    url += params;

    fetch(url)
      .then((res) => res.json())
      .then((snapshot) => {
        console.log(snapshot);
        var list = [];
        if (snapshot.resultData != null) {
          snapshot.resultData.forEach((doc) => {
            list.push(doc);
          });
        }
        setListData(list);
      });
  };


  const doKeyPress = (ev) => {
    if (ev.key === 'Enter') {
      handleStationSearch();
    }
  }


  const startRow = (page * rowsPerPage);


  return (
    <div className={classes.root}>

      <Dialog open={selector_openDialog} onClose={() => { closeDialog }}
        fullWidth={true}
        maxWidth="lg"
      >
        <DialogTitle align="center"><Typography variant="h3">충전소 검색</Typography></DialogTitle>
        <DialogContent>

          <Box m={1} p={1}>
            <Box
              mt={2}
              display="flex"
              alignItems="between"
              justifyContent="start"
              flexGrow={1}
            >

              <TextField
                className={classes.queryField}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                }}
                onChange={handleQueryStationID}
                label="충전소ID"
                placeholder="충전소ID"
                value={queryStationID}
                variant="outlined"
                onKeyPress={(ev) => doKeyPress(ev)}
              />



              <TextField
                className={classes.queryField}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                }}
                onChange={handleQueryStationNameChange}
                label="충전소 명칭"
                placeholder="충전소 명칭"
                value={queryStationName}
                variant="outlined"
                onKeyPress={(ev) => doKeyPress(ev)}
              />

              <TextField
                className={classes.queryField}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                }}
                onChange={handleQueryStationAddrChange}
                label="충전소 주소"
                placeholder="충전소 주소"
                value={queryStationAddr}
                variant="outlined"
                onKeyPress={(ev) => doKeyPress(ev)}
              />

              <TextField
                className={classes.queryField}
                label="운영회원사"
                name="businessName"
                value={userAuth === USER_AUTH.MEMBER ? user.admin_name : ''}
                variant="outlined"
                inputProps={{ maxLength: 50 }}
                disabled
              />

              <Button
                variant="contained"
                color="primary"
                size="large"
                className={classes.bulkAction}
                onClick={handleStationSearch}
              >
                검색
              </Button>
            </Box>
          </Box>

          <Box m={1} p={1}>
            <PerfectScrollbar>
              <div className={classes.inner}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell width="10%" align="center">충전소ID</TableCell>
                      <TableCell width="20%" align="center">충전소</TableCell>
                      <TableCell width="30%" align="center">주소</TableCell>
                      <TableCell width="15%" align="center">사용시간</TableCell>
                      <TableCell width="15%" align="center">주체</TableCell>
                      <TableCell width="10%" align="center">전화번호</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {listData.slice(0 + startRow, rowsPerPage + (page * rowsPerPage)).map(module => (
                      <TableRow
                        hover
                        className={classes.tableRow}
                        key={module.statId}
                        onClick={(event) => selectStation(event, module)}
                      >
                        <TableCell width="10%"><Typography variant="body1">{module.statId}</Typography></TableCell>
                        <TableCell width="20%"><Typography variant="body1">{module.statName}</Typography></TableCell>
                        <TableCell width="30%"><Typography variant="body2">{module.addr}</Typography></TableCell>
                        <TableCell width="15%"><Typography variant="body2">{module.useTime}</Typography></TableCell>
                        <TableCell width="15%"><Typography variant="body1">{module.businessName}</Typography></TableCell>
                        <TableCell width="10%"><Typography variant="body2">{module.businessTel}</Typography></TableCell>
                      </TableRow>
                    ))}

                  </TableBody>
                </Table>
              </div>
            </PerfectScrollbar>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" color="send" onClick={() => { closeDialog() }}>닫기</Button>
        </DialogActions>
      </Dialog>
    </div>

  )

};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    updateOpenDialog,
    updateSelectedStation
  }, dispatch);
};

let mapStateToProps = (state) => {
  return {
    settings: state.settings,
    user: state.user
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StationSearchDialog);


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  action: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },

  queryField: {
    flexBasis: 220,
    marginRight: 20,
  },
  bulkAction: {
    marginLeft: theme.spacing(2),
  },

  divider: {
    margin: theme.spacing(2, 0)
  },
}));

