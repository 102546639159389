import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link as RouterLink, withRouter } from "react-router-dom";
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardHeader,
  CardContent,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
} from '@material-ui/core';

import { NEONPV_CONFIG } from 'config.js';
import moment from 'moment';


const NoticeTable = props => {
  const { className, users, queryParams, ...rest } = props;

  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [listData, setListData] = useState([]);

  const searchData = () => {

    var url = `${NEONPV_CONFIG.HOSTNAME}/util/getChargerModelList`;

    fetch(url)
    .then(res => res.json())
    .then(snapshot => {
      var list  = [];
      if( snapshot.resultData != null){
        snapshot.resultData.forEach( doc => {
          list.push(doc);
        });
      }
      setListData( list );
    }
    );
  }


  useEffect( () => {

    searchData();

    return () => {};
  }, []);


  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };


  const startRow = (page*rowsPerPage);


  return (

    <Card className={clsx(classes.root, className)} {...rest} >

      <CardHeader
        title="충전기모델"
        titleTypographyProps={{ align: "center", variant:'h4' }}
      />

      <Divider />

      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width="10%" align="center">번호</TableCell>
                  <TableCell width="30%" align="center">충전기모델</TableCell>
                  <TableCell width="45%" align="center">부연설명</TableCell>
                  <TableCell width="15%" align="center">등록일시</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {listData.slice(0+startRow, rowsPerPage+(page*rowsPerPage)).map(module => (

                  <TableRow
                    className={classes.tableRow}
                    key={module.id}
                    component={RouterLink} to={`/settings/chargermodel/update/${module.id}`}
                    >

                    <TableCell width="10%" align="center">{module.id}</TableCell>
                    <TableCell width="30%" align="left">{module.chargerModel}</TableCell>
                    <TableCell width="45%" align="center">{module.memo}</TableCell>
                    <TableCell width="15%" align="center">{module.updateTime ? moment(module.updateTime).format("YYYY-MM-DD HH:mm:ss") : ""}</TableCell>
                  </TableRow>
                ))}

              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>

      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={listData.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
};

NoticeTable.propTypes = {
  className: PropTypes.string,
};

export default NoticeTable;


const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    // minWidth: 1050
  },
  nameContainer: {
    // display: 'flex',
    // alignItems: 'center',
    // marginLeft: theme.spacing(1)
  },
  queryField: {
    // width: 300
    flexBasis: 260,
    marginRight:20,
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  bulkAction: {
    marginLeft: theme.spacing(2)
  },
}));
