
// // import * as firebase from 'firebase/app';
// import * as firebase from 'firebase/app';
// import firestore from 'firebase/firestore'

import firebase from 'firebase/app' //after firebase sdk 8.0
import "firebase/auth";

// // const settings = {timestampsInSnapshots: true};

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

// import { getAnalytics } from "firebase/analytics";
// import "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBszK5r5PHZ-_46UtkYK7orLtWvIMR8Xy4",
  authDomain: "hellocharger-2a07c.firebaseapp.com",
  databaseURL: "https://hellocharger-2a07c-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "hellocharger-2a07c",
  storageBucket: "hellocharger-2a07c.appspot.com",
  messagingSenderId: "865962841599",
  appId: "1:865962841599:web:f17a0d74489530d161c3ca",
  measurementId: "G-2BTLDBJFW4"
};


const hellochargerApp = firebase.initializeApp(firebaseConfig, 'hellocharger')


// Initialize Firebase
// const firebase_app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(firebase_app);


hellochargerApp.auth().signInWithEmailAndPassword("bighavedev@gmail.com", "!test123")
  .then((userCredential) => {
    // Signed in
    var user = userCredential.user;
    // console.log("firebase user: ", user);
  })
  .catch((error) => {
    var errorCode = error.code;
    var errorMessage = error.message;

    alert("firebase auth error: "+ error);
    //십중팔구 계정이 없다.
    //https://console.firebase.google.com/project/hellocharger-2a07c/authentication/users?hl=ko
    //위 사이트에서 email 계정 추가할 것.
  });


export default hellochargerApp;

