import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Formik } from "formik";
import {
  Button,
  FormControlLabel,
  Checkbox,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  TextField,
  makeStyles,
} from "@material-ui/core";

import { NEONPV_CONFIG } from "config.js";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  action: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },
}));

const NoticeUpdateForm = (props) => {
  const { className, history, noticeInfo } = props;
  const classes = useStyles();

  // console.log(noticeInfo);

  const handleSubmit = (event, values) => {
    event.preventDefault();

    // console.log(values);

    fetch(`${NEONPV_CONFIG.HOSTNAME}/notice/updateNoticeInfo`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id: values.id,
        title: values.title,
        author: values.author,
        regdate: values.regdate,
        contents: values.contents,
        flag: values.valid === true ? "1" : "0",
      }),
    })
    .then((response) => {
      return response.json();
    })
    .then((responseData) => {
      if (responseData.resultCode === "000") {
        alert("업데이트에 성공했습니다.");

        setTimeout(() => {
          history.goBack();
        }, 500);
      } else {
        //에러
        alert(`업데이트에 실패했습니다.\n(${responseData.resultCode}):${responseData.resultMessage}`);
      }
    })
    .catch((error) => {
      console.error(error);
      alert("업데이트에 실패했습니다.");
    });
  };


  //공지사항 글 삭제하기
  const handleRemove = event => {

    if(window.confirm("공지사항 글을 삭제하시겠습니까?")){
      fetch(`${NEONPV_CONFIG.HOSTNAME}/notice/removeNoticeInfo`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          id: noticeInfo.id,
        }),
      })
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        if (responseData.resultCode === "000") {
          alert("삭제에 성공했습니다.");

          setTimeout(() => {
            history.goBack();
          }, 500);
        } else {
          //에러
          alert(`삭제에 실패했습니다.\n${responseData.resultCode}`);
        }
      })
      .catch((error) => {
        console.error(error);
        alert("삭제에 실패했습니다.");
      });
    }

  };


  const handleBack = () => {
    history.goBack();
  };

  return (
    <Formik
      initialValues={{
        id: noticeInfo.id || "",
        title: noticeInfo.title || "",
        author: noticeInfo.author || "",
        regdate: noticeInfo.regdate || "",
        contents: noticeInfo.contents || "",
        valid: noticeInfo.flag === "1" ? true : false,
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          className={clsx(classes.root, className)}
          onSubmit={(e) => handleSubmit(e, values)}
        >
          <Card>
            <CardHeader
              title="공지사항 수정하기"
              titleTypographyProps={{ align: "center", variant:'h4' }}
            />

            <Divider />

            <CardContent>
              <Grid container spacing={2}>

                <Grid item md={12} xs={12}>
                  <TextField
                    error={Boolean(touched.title && errors.title)}
                    fullWidth
                    helperText={touched.title && errors.title}
                    label="제목"
                    name="title"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.title}
                    variant="outlined"
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>

                <Grid item md={12} xs={12}>
                  <TextField
                    error={Boolean(touched.author && errors.author)}
                    fullWidth
                    helperText={touched.author && errors.author}
                    label="작성자"
                    name="author"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.author}
                    variant="outlined"
                    inputProps={{ maxLength: 20 }}
                  />
                </Grid>

                <Grid item md={12} xs={12}>
                  <TextField
                    error={Boolean(touched.regdate && errors.regdate)}
                    fullWidth
                    helperText={touched.regdate && errors.regdate}
                    label="등록일"
                    name="regdate"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.regdate}
                    variant="outlined"
                    inputProps={{ maxLength: 16 }}
                  />
                </Grid>

                <Grid item md={12} xs={12}>
                  <TextField
                    error={Boolean(touched.contents && errors.contents)}
                    fullWidth
                    helperText={touched.contents && errors.contents}
                    label="내용"
                    name="contents"
                    multiline
                    rows={10}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.contents}
                    variant="outlined"
                    inputProps={{ maxLength: 10000 }}
                  />
                </Grid>

                <Grid item md={12} xs={12}>
                  {/* <Checkbox
                    checked={values.valid}
                    name="valid"
                    onChange={handleChange}
                    >
                    표시
                    </Checkbox> */}

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.valid}
                        onChange={handleChange}
                        name="valid"
                        color="primary"
                      />
                      }
                    label="표시"
                  />
                </Grid>


                <Grid item md={6} xs={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="large"
                    disabled={isSubmitting}
                    className={classes.action}
                  >
                    수정
                  </Button>

                  <Button
                    variant="outlined"
                    size="large"
                    onClick={handleBack}
                    className={classes.action}
                  >
                    취소
                  </Button>
                </Grid>

                <Grid item md={6} xs={6} align="right">
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleRemove}
                    size="large"
                    className={classes.action}
                  >
                    삭제
                  </Button>
                </Grid>
              </Grid>


            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
};

NoticeUpdateForm.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object,
};

export default NoticeUpdateForm;
