import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Formik } from "formik";
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Divider,
  Typography,
} from "@material-ui/core";


import { NEONPV_CONFIG } from "config";
import {global} from "common/global";


const DataTransferForm = (props) => {
  const { className, history, user, ...rest } = props;
  const classes = useStyles();




  const handleSubmit = (event, values) => {
    event.preventDefault();

    var json =
      `{"command":"DataTransfer",
        "body": {
          "vendorId": "${values.vendorId}",
          "messageId": "${values.messageId}",
          "data": "${values.data}"
        }
      }`;


    global.ws.send(json);



  };

  const handleBack = () => {
    history.goBack();
  };


  return (

    <div>
      <Formik
        enableReinitialize={false}
        initialValues={{
          vendorId: "VENID",
          messageId: "MSGID",
          data: "data transfer sample data",
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          isSubmitting,
          touched,
          values,
          setFieldValue
        }) => (
          <form
            className={clsx(classes.root, className)}
            onSubmit={(e) => handleSubmit(e, values)}
          >

            <Card>
              <CardHeader
                title="Data Transfer"
                titleTypographyProps={{ color: 'inherit', align: 'center', variant: 'h3' }}
                subheader="TestCase - 062"
                subheaderTypographyProps={{ color: 'inherit', align: 'left', variant: 'h5' }}
              />

              <CardContent>

                <Grid item md={12} spacing={2}>
                  <Typography variant="h6">062 - PASS</Typography>
                </Grid>

                <div className={classes.content}></div>

                <Grid item md={12} spacing={0}>
                  <Typography variant="h4">Parameters</Typography>
                </Grid>

                <Divider variant="inset" />


                <Grid container item spacing={2} >
                  <Grid container item md={12} spacing={0}>
                  </Grid>

                  <Grid item md={2} xs={4}>
                    <TextField
                      error={Boolean(touched.vendorId && errors.vendorId)}
                      fullWidth
                      helperText={touched.vendorId && errors.vendorId}
                      label="vendorId"
                      name="vendorId"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.vendorId}
                      variant="outlined"
                      required
                    />
                  </Grid>


                  <Grid item md={2} xs={4}>
                    <TextField
                      error={Boolean(touched.messageId && errors.messageId)}
                      fullWidth
                      helperText={touched.messageId && errors.messageId}
                      label="messageId"
                      name="messageId"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.messageId}
                      variant="outlined"
                      required
                    />
                  </Grid>


                  <Grid item md={2} xs={4}>
                    <TextField
                      error={Boolean(touched.data && errors.data)}
                      fullWidth
                      helperText={touched.data && errors.data}
                      label="data"
                      name="data"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.data}
                      variant="outlined"
                      required
                    />
                  </Grid>

                </Grid>


                <Grid container item
                  className={classes.action}
                  direction="row" alignItems="center" justify="center">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="large"
                    disabled={isSubmitting}
                    className={classes.action}
                  >
                    Perform
                  </Button>

                  <Button
                    variant="outlined"
                    size="large"
                    onClick={handleBack}
                    className={classes.action}
                  >
                    취소
                  </Button>
                </Grid>
              </CardContent>
            </Card>
          </form>
        )}
      </Formik>
    </div>
  );
};

DataTransferForm.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object,
};


let mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps)(DataTransferForm);


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    direction: "row",
    alignItems: "center",
    justify: "center"
  },
  content2: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  action: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },
  queryField: {
    // width: 300
    flexBasis: 260,
    marginRight: 20,
  },
  bulkAction: {
    marginLeft: theme.spacing(2),
  },

  divider: {
    margin: theme.spacing(2, 0)
  },
}));
