import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Grid,
} from "@material-ui/core";

import { SummaryDaily, SummaryMonthly  } from "./components";

import AllChargersNew from "./AllChargersNew";
import FastCharger from "./FastCharger";
import SlowCharger from "./SlowCharger";
import LastChargingTable from "./LastChargingTable";
import ErrorTable from "./ErrorTable";
import { TextsmsTwoTone } from "@material-ui/icons";
import { USER_AUTH } from 'common/constants';
import { connect, useSelector} from 'react-redux';
import Map from '../../kakaomap/map';

const Dashboard = (props) => {
  const classes = useStyles();
  const user = JSON.parse(localStorage.getItem("userinfo"));

  const userAuth = useSelector((state) => state.user.user_auth);
  if(userAuth === USER_AUTH.SUB_MEMBER) {
      window.location.href = '/stations';
  }

  let SummaryMonthlymd_xs = 6;
  if(user.admin_id != "admin"){ SummaryMonthlymd_xs = 12; }
  let SummaryMonthlyItem = (
    <Grid item md={SummaryMonthlymd_xs} xs={SummaryMonthlymd_xs}>
      <SummaryMonthly />
    </Grid>
  );

  let ErrorTableItem = "";
  if(user.admin_id == "admin"){ ErrorTableItem = (
    <Grid item md={6} xs={6}>
      <ErrorTable />
    </Grid>
  ); }
  
  let kakaoMap = (
    <Grid item md={12} xs={12}>
      <Map admin_id={user.admin_id}/>
    </Grid>
  );

  return (
    <div className={classes.root}>
      <Grid container spacing={2} >
          <Grid item lg={4} xs={6} >
            <FastCharger />
          </Grid>

          <Grid item lg={4} xs={6} >
            <SlowCharger />
          </Grid>

          <Grid item lg={4} xs={6} >
            <AllChargersNew />
          </Grid>

          {kakaoMap}
          
        <Grid item md={12} xs={12}>
          <LastChargingTable key={11} />
        </Grid>


        <Grid item md={12} xs={12}>
          <SummaryDaily />
        </Grid>


        {SummaryMonthlyItem}
        
        {ErrorTableItem}

        

        <Grid item md={12} xs={12}>
        </Grid>

        {/* <Grid container item lg={3} xs={4} spacing={1}>
          <Grid item lg={6} xs={6} >
            <RegionalChargers />
          </Grid>

          <Grid item lg={6} xs={6} >
          <RegionalUsage />
          </Grid>
        </Grid>


        <Grid container item lg={9} xs={8} spacing={2} >
            <Grid item lg={3} xs={6} >
              <AllCharager />
            </Grid>

            <Grid item lg={3} xs={6} >
              <ReadyCharager />
            </Grid>
            <Grid item lg={3} xs={6} >
              <UsingCharager />
            </Grid>

            <Grid item lg={3} xs={6} >
              <ErrorCharager />
            </Grid>
        </Grid>
         */}

      </Grid>
    </div>
  );
};

export default Dashboard;


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  policy: {
    // marginTop: theme.spacing(1),
    display: "flex",
    alignItems: "center",
  },
}));