import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  TextField,
  Select,
  MenuItem,
  Box,
  Typography
} from "@material-ui/core";
import { NEONPV_CONFIG } from "config.js";
import { withRouter } from "react-router-dom";
import { USER_AUTH } from "common/constants";



const CustomTableCell = ({ row, name, onChange, width, label }) => {
  const classes = useStyles();

  if (row) {
    return (
      <TableCell align="right" width={width} className={classes.tablecell}>
        <TextField
          // siz="small"
          // variant="standard"
          variant="outlined"
          // variant="filled"
          name={name}
          // label={label}
          fullWidth
          align="right"
          value={row[name]}
          onChange={onChange}

          inputProps={{
            style: {
              textAlign: "right",
              padding: 8,
            },
            maxLength: 10,
          }}
        />
      </TableCell>
    );
  } else {
    return (<TableCell></TableCell>);
  }
};


const UnitPriceSubRegion = props => {

  const { className, statId, member_id, user, ...rest } = props;

  const classes = useStyles();

  const [myUnitPriceList, setMyUnitPriceList] = useState([]);

  const [priceType, setPriceType] = useState('lowPrice1');

  const [clientPriceVersionNo, setClientPriceVersionNo] = useState('');
  const [dataType, setDataType] = useState('defaultPrice');

  const userAuth = useSelector((state) => state.user.user_auth);

  const handlePriceTypeChange = (event) => {
    setPriceType(event.target.value);
  }

  const handleChange = (event, index) => {
    const val = event.target.value;
    const name = event.target.name;

    let newArr = [...myUnitPriceList];
    newArr[index][name] = val;
    setMyUnitPriceList(newArr);
  }

  //NOTE: 회원사 정보는 로그인한 회원사가 아닌, 충전소정보에 등록된 회원사정보에 기초한다.
  //      만약 등록된 회원사정보가 없다면, default price 표시

  async function loadMyUnitPriceList() {

    var url = `${NEONPV_CONFIG.HOSTNAME}/station/queryUnitPriceInfo?statId=${statId}&member_id=${member_id}&clientPriceVersioNo=-1`;
    const opt = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };

    let unitpriceList = [];
    let response = await fetch(url, opt);
    let responseData = await response.json();

    let rowid = 1;
    responseData.resultData.map(function (row) {

      setDataType(row.dataType);

      unitpriceList.push({
        id: rowid++,
        dataType: row.dataType,
        startTime: row.startTime,
        endTime: row.endTime,
        time: row.startTime + ' ~ ' + row.endTime + '시',
        castproPrice: row.castproPrice,
        otherPrice: row.otherPrice,
        envPrice: row.envPrice,
        roamingPrice: row.roamingPrice,
        lowPrice1: row.lowPrice1,
        lowPrice2: row.lowPrice2,
        lowPrice3: row.lowPrice3,
        lowPrice4: row.lowPrice4,
        highPrice1: row.highPrice1,
        highPrice2: row.highPrice2,
        highPrice3: row.highPrice3,
        highPrice4: row.highPrice4,
      });

      if (clientPriceVersionNo < row.unitPriceVersionNo){
        setClientPriceVersionNo(  row.unitPriceVersionNo );
      }
    });

    setMyUnitPriceList(unitpriceList);
  }



  const handleSaveBtn = () => {


    let priceList = [];
    myUnitPriceList.forEach((item) => {
      priceList.push({ startTime: item.startTime, endTime: item.endTime,
          castproPrice: item.castproPrice, otherPrice:item.otherPrice,
          envPrice: item.envPrice, roamingPrice: item.roamingPrice });
    });


    fetch(`${NEONPV_CONFIG.HOSTNAME}/station/setUnitPriceInfo`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        statId: statId,
        // member_id: user.admin_id,
        member_id: member_id, //
        newUnitPriceVersionNo: clientPriceVersionNo+1,
        priceList: priceList
      }),
    }).then((response) => {
      return response.json();
    }).then((responseData) => {
      if (responseData.resultCode === "000") {
        alert("충전소 자사 단가 저장에 성공했습니다.");

        setClientPriceVersionNo(clientPriceVersionNo+1);

        // setTimeout(() => {
        //   /** refresh */
        //   loadMyStationList();
        // }, 100);

      } else {
        //에러
        console.log(responseData.message);
        alert(`충전소 자사 단가에 실패했습니다.\n(${responseData.resultCode}):${responseData.resultMessage}`);
      }
    })
    .catch((error) => {
      console.error(error);
      alert("충전소 자사 단가에 실패했습니다.");
    });
  }


  useEffect(() => {
    loadMyUnitPriceList();
  }, []);



  return (

    <div style={{
      minHeight: 160,
      width: '100%',
      // paddingLeft: 100,
      // alignContent:'center',
      // alignItems:'center',
      // alignSelf:'center'
    }}>

      <Grid container spacing={2}
        direction="row" alignItems="center" justify="center"
        style={{
          paddingLeft: 10,
          paddingRight: 10,
          // marginTop: 20,
          // paddingTop: 20,
          marginBottom: 20,
        }}>

        <Grid container item lg={12} md={12} xs={12}
          direction="row" alignItems="center" justify="center"
          style={{
            marginTop: 10,
            // marginBottom: 10,
            paddingTop: 20,
            paddingBottom: 20,
            backgroundColor: "lightgray"
          }}>
          <Typography
            className={classes.dividerInset}
            // color="colorTextSecondary"
            display="block"
            variant="h4"
            fullWidth
            align="center"
            style={{
              // padding:16,
              display: "block"
            }}
          >
            단가 정보 목록
          </Typography>
          <Typography variant="body1">&nbsp;&nbsp;   [{dataType === 'stationPrice' ? '개별' : '디폴트'} ]&nbsp;&nbsp; (버전번호:   {('00000' + clientPriceVersionNo).slice(-5)})</Typography>
        </Grid>
      </Grid>


      <Grid container spacing={1}>

        <Grid item lg={6} md={6} xs={6} >
          <Table size="small"
            style={{borderRight: '1px solid #e0e0e0'}}
            >
            <TableHead>
              <TableRow>
                <TableCell align="center" width={COLUMN_WIDTH.TIMERANGE} >시간대</TableCell>
                <TableCell align="center" width={COLUMN_WIDTH.DEFAULT_PRICE} >회원</TableCell>
                <TableCell align="center" width={COLUMN_WIDTH.OTHER_PRICE}>비회원</TableCell>
                <TableCell align="center" width={COLUMN_WIDTH.ENV_PRICE}>환경부</TableCell>
                <TableCell align="center" width={COLUMN_WIDTH.ROAMING_PRICE}>로밍</TableCell>
                <TableCell align="center" width={COLUMN_WIDTH.LOW_PRESS1}>
                  <Box display="flex" justifyContent="center" alignItems="center">
                    한전단가&nbsp;&nbsp;
                    <Select
                      value={priceType}
                      label="한전단가"
                      onChange={handlePriceTypeChange}
                      variant="standard"
                    >
                      <MenuItem value={'lowPrice1'}>저압1</MenuItem>
                      <MenuItem value={'lowPrice2'}>저압2</MenuItem>
                      <MenuItem value={'lowPrice3'}>저압3</MenuItem>
                      <MenuItem value={'lowPrice4'}>저압4</MenuItem>
                      <MenuItem value={'highPrice1'}>고압1</MenuItem>
                      <MenuItem value={'highPrice2'}>고압2</MenuItem>
                      <MenuItem value={'highPrice3'}>고압3</MenuItem>
                      <MenuItem value={'highPrice4'}>고압4</MenuItem>
                    </Select>
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody >
              {myUnitPriceList.slice(0, 12).map((module, index) => (
                <TableRow className={classes.tableRow} key={module.id}>
                  <TableCell align="center" width={COLUMN_WIDTH.TIMERANGE}>{module.time}</TableCell>
                  <CustomTableCell {...{row: module, name: "castproPrice", onChange: (e => handleChange(e, index)),
                    width: COLUMN_WIDTH.DEFAULT_PRICE, label: '회원'}} />
                  <CustomTableCell {...{row: module, name: "otherPrice", onChange: (e => handleChange(e, index)),
                    width: COLUMN_WIDTH.OTHER_PRICE, label: '비회원'}} />
                  <CustomTableCell {...{row: module, name: "envPrice", onChange: (e => handleChange(e, index)),
                    width: COLUMN_WIDTH.ENV_PRICE, label: '환경부'}} />
                  <CustomTableCell {...{row: module, name: "roamingPrice", onChange: (e => handleChange(e, index)),
                    width: COLUMN_WIDTH.ROAMING_PRICE, label: '로밍'}} />
                  <TableCell align="right" width={COLUMN_WIDTH.LOW_PRESS1} style={{ paddingRight: 16 }}>
                    {priceType === 'lowPrice1' ? module.lowPrice1 :
                    priceType === 'lowPrice2' ? module.lowPrice2 :
                    priceType === 'lowPrice3' ? module.lowPrice3 :
                    priceType === 'lowPrice4' ? module.lowPrice4 :
                    priceType === 'highPrice1' ? module.highPrice1 :
                    priceType === 'highPrice2' ? module.highPrice2 :
                    priceType === 'highPrice3' ? module.highPrice3 :
                    priceType === 'highPrice4' ? module.highPrice4 : ''
                    }
                    </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>


        <Grid item lg={6} md={6} xs={6}>
          <Table size="small" style={{borderLeft: '1px solid #e0e0e0'}}>
            <TableHead>
              <TableRow>
                <TableCell align="center" width={COLUMN_WIDTH.TIMERANGE}>시간대</TableCell>
                <TableCell align="center" width={COLUMN_WIDTH.DEFAULT_PRICE} >회원</TableCell>
                <TableCell align="center" width={COLUMN_WIDTH.OTHER_PRICE}>비회원</TableCell>
                <TableCell align="center" width={COLUMN_WIDTH.ENV_PRICE}>환경부</TableCell>
                <TableCell align="center" width={COLUMN_WIDTH.ROAMING_PRICE}>로밍</TableCell>
                <TableCell align="center" width={COLUMN_WIDTH.LOW_PRESS1}>
                  <Box display="flex" justifyContent="center" alignItems="center">
                    한전단가&nbsp;&nbsp;
                    <Select
                      value={priceType}
                      label="한전단가"
                      onChange={handlePriceTypeChange}
                      variant="standard"
                    >
                      <MenuItem value={'lowPrice1'}>저압1</MenuItem>
                      <MenuItem value={'lowPrice2'}>저압2</MenuItem>
                      <MenuItem value={'lowPrice3'}>저압3</MenuItem>
                      <MenuItem value={'lowPrice4'}>저압4</MenuItem>
                      <MenuItem value={'highPrice1'}>고압1</MenuItem>
                      <MenuItem value={'highPrice2'}>고압2</MenuItem>
                      <MenuItem value={'highPrice3'}>고압3</MenuItem>
                      <MenuItem value={'highPrice4'}>고압4</MenuItem>
                    </Select>
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {myUnitPriceList.slice(12, 24).map((module, index) => (
                <TableRow className={classes.tableRow} key={module.id}>
                  <TableCell align="center" width={COLUMN_WIDTH.TIMERANGE}>{module.time}</TableCell>
                  <CustomTableCell {...{row: module, name: "castproPrice", onChange: (e => handleChange(e, 12+index)),
                    width: COLUMN_WIDTH.DEFAULT_PRICE, label: '자사단가'}} />
                  <CustomTableCell {...{row: module, name: "otherPrice", onChange: (e => handleChange(e, 12+index)),
                    width: COLUMN_WIDTH.OTHER_PRICE, label: '비회원'}} />
                  <CustomTableCell {...{row: module, name: "envPrice", onChange: (e => handleChange(e, 12+index)),
                    width: COLUMN_WIDTH.ENV_PRICE, label: '환경부'}} />
                  <CustomTableCell {...{row: module, name: "roamingPrice", onChange: (e => handleChange(e, 12+index)),
                    width: COLUMN_WIDTH.ROAMING_PRICE, label: '로밍'}} />

                  <TableCell align="right" width={COLUMN_WIDTH.LOW_PRESS1} style={{ paddingRight: 16 }}>
                    {priceType === 'lowPrice1' ? module.lowPrice1 :
                    priceType === 'lowPrice2' ? module.lowPrice2 :
                    priceType === 'lowPrice3' ? module.lowPrice3 :
                    priceType === 'lowPrice4' ? module.lowPrice4 :
                    priceType === 'highPrice1' ? module.highPrice1 :
                    priceType === 'highPrice2' ? module.highPrice2 :
                    priceType === 'highPrice3' ? module.highPrice3 :
                    priceType === 'highPrice4' ? module.highPrice4 : ''
                    }
                    </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>

      </Grid>

      {userAuth === USER_AUTH.SUB_MEMBER ? '' : (<Grid container style={{ marginTop: 20 }}>
        <Grid item md={12} align="center">
          <Button
            style={{ marginRight: 30 }}
            variant="contained" align="center" color="primary" onClick={handleSaveBtn}>
            저장
          </Button>
        </Grid>
      </Grid>)}
    </div>
  );
};


UnitPriceSubRegion.propTypes = {
  className: PropTypes.string,
};

let mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps)(withRouter(UnitPriceSubRegion));


const COLUMN_WIDTH = {
  TIMERANGE: '14%',
  DEFAULT_PRICE: '16%',
  OTHER_PRICE: '16%',
  ENV_PRICE: '16%',
  ROAMING_PRICE: '16%',
  LOW_PRESS1: '22%',
}


const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  tableRow: {
    height: 20,
  },
  queryField: {
    flexBasis: 220,
    marginRight: 20,
  },

  bulkAction: {
    marginLeft: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0)
  },

  tablecell: {
    "&.MuiTableCell-root": {
      padding: '8px',
    },
  },
  tableRow: {
    height: "20px",
    padding : "0px",
  },
  textfield: {
    "&.MuiOutlinedInput-input": {
      padding: '8px'
    }
  }
}));