import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  Grid,
  Typography,
} from '@material-ui/core';


import { options } from './chart';
import moment from 'moment';
import numeral from 'numeral';
import palette from 'theme/palette';
import firebase from '../../../../Firebase';
import 'firebase/database';
import { connect, useSelector } from 'react-redux';



const SummaryDaily = props => {
  const { className, user, ...rest } = props;

  const admin_id = useSelector((state) => state.user.admin_id);

  const classes = useStyles();

  const [viewData, setViewData] = useState({});
  const [sumPrice, setSumPrice] = useState(0);


  useEffect(() => {

    let cntData = [];
    let amountData = [];
    let label_data = [];
    let datas = [];

    var minDate = moment().add(-30, "days").format("'YY-MM-DD");

    firebase.database().ref('/dashboard/' + admin_id).child("salesdaily").on('value', function (snapshot) {

      snapshot.forEach(function (childSnapshot) {
        var childKey = childSnapshot.key;
        var childData = childSnapshot.val();

        if (childKey >= minDate) {
          datas[childKey] = childData;
        }
      })


      for (var key in datas) {
        label_data.push(key);
      }

      label_data.sort();

      let sum1 = 0;

      for (var i = 0; i < label_data.length; i++) {
        cntData.push(datas[label_data[i]].cnt);
        amountData.push(datas[label_data[i]].amountPrice);

        sum1 += datas[label_data[i]].amountPrice;
      }

      setSumPrice(sum1);

      setViewData({
        labels: label_data,
        datasets: [
          {
            yAxisID: 'COUNT',
            type: 'line',
            label: "충전횟수",
            data: cntData,
            fill: false,
            borderColor: palette.primary.main2,
            borderWidth: 2,
            order: 0,
          },
          {
            yAxisID: 'PRICE',
            type: 'bar',
            label: "매출액",
            data: amountData,
            backgroundColor: 'rgba(201, 203, 207, 0.3)',
            order: 1,
          },
        ],
      });
    });

  }, [admin_id]);


  // const pligins = [
  //   // {
  //   //   beforeInit: function (chart) {
  //   //     chart.legend.afterFit = function () {
  //   //       chart.legend.options.labels.padding = 50;
  //   //       chart.height += 30;
  //   //     };
  //   //   },
  //   // },
  //   {
  //   beforeInit: function (chart, options) {
  //     chart.legend.afterFit = function () {
  //       if (chart.legend.margins) {
  //       chart.legend.options.labels.padding=100;
  //       chart.height+=40
  //       }
  //     };
  //   }
  // }
  // ];


  return (
    <Card className={clsx(classes.root, className)}>

      <CardContent>

        <Grid container justifyContent="space-between" spacing={0}>
          <Grid item lg={12} md={12} align="left">
            <Typography className={classes.title} color="textSecondary" gutterBottom variant="h6">
              일자별 충전현황
            </Typography>
          </Grid>


          <Grid item lg={12} md={12} align="right">
            <Typography color="textSecondary" variant="h6" style={{ marginRight: 20 }}>누적매출액:&nbsp;&nbsp;&nbsp;&nbsp;
              <strong>{numeral(sumPrice).format('0,0')}</strong>원</Typography>
            <Typography variant="body2" style={{ marginRight: 20 }}>최근1개월</Typography>
          </Grid>
        </Grid>

        <div className={classes.chartContainer}>
          <Bar
            data={viewData}
            options={options}
          // plugins={pligins}
          />
        </div>
      </CardContent>
    </Card>
  );
};

SummaryDaily.propTypes = {
  className: PropTypes.string
};

let mapStateToProps = (state) => {
  return {
    user: state.user
  };
}

export default connect(mapStateToProps)(SummaryDaily);


const useStyles = makeStyles((theme) => ({
  root: {},
  chartContainer: {
    //360px 이상
    ['@media (min-height:360px)']: {// eslint-disable-line no-useless-computed-key
      height: 360,
    },
    //360px 이하
    ['@media (max-height:360px)']: {// eslint-disable-line no-useless-computed-key
      height: 220,
    },
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  title: {
    fontWeight: 600,
    // marginTop: theme.spacing(1),
    // marginBottom: theme.spacing(2),
  },
  title2: {
    fontWeight: 800,
  }
}));
