import React from "react";
import { makeStyles } from "@material-ui/styles";
import PropTypes from 'prop-types';
import RoamingAddForm from "./RoamingAddForm";


const RoamingGroupAdd = (props) => {

  const {className, history, user, ...rest } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <RoamingAddForm history={history} />
      </div>
    </div>
  );
};


RoamingGroupAdd.propTypes = {
  history: PropTypes.object
};

export default RoamingGroupAdd;


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));
