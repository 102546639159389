import React from 'react';
import ReactDOM from 'react-dom';

import * as serviceWorker from './serviceWorker';
import App from './App';
import { WindowSharp } from '@mui/icons-material';

// import { composeWithDevTools } from 'redux-devtools-extension';
// import { createStore, applyMiddleware } from 'redux';
// import thunkMiddleware from 'redux-thunk'
// import { Provider } from 'react-redux';

// import reducer from './reducers'

// const middleware = applyMiddleware(thunkMiddleware)
// const store = createStore(
//   reducer, 
//   composeWithDevTools(middleware)
//   );



// ReactDOM.render(
//   <Provider store={store}>
//     <App />
//   </Provider>,
//  document.getElementById('root'));

ReactDOM.render(
    <App />,
 document.getElementById('root'));

serviceWorker.unregister();
