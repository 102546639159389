import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { Bar } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/styles';

import {
  Card,
  CardHeader,
  CardContent,
  Divider,
} from '@material-ui/core';

import { options } from './chart';

const useStyles = makeStyles(() => ({
  root: {},
  chartContainer: {
    //360px 이상
    ['@media (min-height:360px)']: {// eslint-disable-line no-useless-computed-key
      height: 360,
    },
    //360px 이하
    ['@media (max-height:360px)']: {// eslint-disable-line no-useless-computed-key
      height: 220,
    },    
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));


const GainRatio = props => {
  const { className, 
    selectedValue, selectedDate, 
    graphDataForOracle, 
    ...rest } = props;


  const classes = useStyles();


  return (

    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >

      <CardHeader
        title="태양광 발전량 현황"
        subheader={`${selectedValue} 누적 (${selectedDate})`}
        titleTypographyProps={{ align: "left" }}
        subheaderTypographyProps={{ align: "center" }}
      />

      <Divider />

      <CardContent>
        <div className={classes.chartContainer}>
          <Bar
            data={graphDataForOracle}
            options={options}
          />
        </div>

      </CardContent>

    </Card>
  );
};


GainRatio.propTypes = {
  className: PropTypes.string
};

export default GainRatio;
