import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Formik } from "formik";
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  TextField,
  makeStyles,
} from "@material-ui/core";

import { NEONPV_CONFIG } from "config.js";
import { connect } from 'react-redux';


const ChargerModelAddForm = (props) => {
  const { className, user, history, stationInfo } = props;
  const classes = useStyles();

  const handleSubmit = (event, values) => {
    event.preventDefault();

    fetch(`${NEONPV_CONFIG.HOSTNAME}/util/addChargerModel`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        chargerModel: values.chargerModel,
        memo: values.memo,
      }),
    })
    .then((response) => {
      return response.json();
    })
    .then((responseData) => {
      if (responseData.resultCode === "000") {
        alert("충전기모델 추가에 성공했습니다.");

        setTimeout(() => {
          history.goBack();
        }, 500);
      } else {
        //에러
        alert(`충전기모델 추가에 실패했습니다.\n(${responseData.resultCode}):${responseData.resultMessage}`);
      }
    })
    .catch((error) => {
      console.error(error);
      alert("충전기모델 추가에 실패했습니다.");
    });
  };


  const handleBack = () => {
    history.goBack();
  };


  return (
    <Formik
      initialValues={{
        chargerModel: "",
        memo: "",
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          className={clsx(classes.root, className)}
          onSubmit={(e) => handleSubmit(e, values)}
        >
          <Card>

            <CardHeader
              title="충전기모델 추가"
              titleTypographyProps={{ align: "center", variant:'h4' }}
            />

            <Divider />

            <CardContent>
              <Grid container spacing={2}>

                <Grid item md={12} xs={12}>
                  <TextField
                    error={Boolean(touched.chargerModel && errors.chargerModel)}
                    fullWidth
                    helperText={touched.chargerModel && errors.chargerModel}
                    label="충전기모델(최대20자)"
                    name="chargerModel"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.chargerModel}
                    variant="outlined"
                    inputProps={{ maxLength: 20 }}
                  />
                </Grid>

                <Grid item md={12} xs={12}>
                  <TextField
                    error={Boolean(touched.memo && errors.memo)}
                    fullWidth
                    helperText={touched.memo && errors.memo}
                    label="부연설명(최대100자)"
                    name="memo"
                    multiline
                    rows={3}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    // required
                    value={values.memo}
                    variant="outlined"
                    inputProps={{ maxLength: 100 }}
                  />
                </Grid>


              </Grid>

              <Grid item className={classes.action}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  size="large"
                  disabled={isSubmitting}
                  className={classes.action}
                >
                  추가
                </Button>

                <Button
                  variant="outlined"
                  size="large"
                  onClick={handleBack}
                  className={classes.action}
                >
                  취소
                </Button>
              </Grid>
            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
};

ChargerModelAddForm.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object,
};


let mapStateToProps = (state) => {
  return {
    user: state.user
  };
}

export default connect(
  mapStateToProps
)(ChargerModelAddForm);


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  action: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },
}));