import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Formik } from "formik";
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  TextField,
  makeStyles,
} from "@material-ui/core";

import { NEONPV_CONFIG } from "config.js";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  action: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },
}));

const ChargerModelUpdateForm = (props) => {
  const { className, history, chargerModelInfo } = props;
  const classes = useStyles();


  const handleSubmit = (event, values) => {
    event.preventDefault();

    fetch(`${NEONPV_CONFIG.HOSTNAME}/util/updateChargerModelInfo`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id: values.id,
        chargerModel: values.chargerModel,
        memo: values.memo,
        updateTime: values.updateTime,
      }),
    })
    .then((response) => {
      return response.json();
    })
    .then((responseData) => {
      if (responseData.resultCode === "000") {
        alert("업데이트에 성공했습니다.");

        setTimeout(() => {
          history.goBack();
        }, 500);
      } else {
        //에러
        alert(`업데이트에 실패했습니다.\n(${responseData.resultCode}):${responseData.resultMessage}`);
      }
    })
    .catch((error) => {
      console.error(error);
      alert("업데이트에 실패했습니다.");
    });
  };



  const handleRemove = event => {

    if(window.confirm("충전기 모델을 삭제하시겠습니까?")){
      fetch(`${NEONPV_CONFIG.HOSTNAME}/util/removeChargerModelInfo`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          id: chargerModelInfo.id,
        }),
      })
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        if (responseData.resultCode === "000") {
          alert("삭제에 성공했습니다.");

          setTimeout(() => {
            history.goBack();
          }, 500);
        } else {
          //에러
          alert(`삭제에 실패했습니다.\n${responseData.resultCode}`);
        }
      })
      .catch((error) => {
        console.error(error);
        alert("삭제에 실패했습니다.");
      });
    }

  };


  const handleBack = () => {
    history.goBack();
  };

  return (
    <Formik
      initialValues={{
        id: chargerModelInfo.id || "",
        chargerModel: chargerModelInfo.chargerModel || "",
        memo: chargerModelInfo.memo || "",
        updateTime: chargerModelInfo.updateTime || "",
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          className={clsx(classes.root, className)}
          onSubmit={(e) => handleSubmit(e, values)}
        >
          <Card>
            <CardHeader
              title="충전기모델 수정하기"
              titleTypographyProps={{ align: "center", variant:'h4' }}
            />

            <Divider />

            <CardContent>
              <Grid container spacing={2}>

                <Grid item md={12} xs={12}>
                  <TextField
                    error={Boolean(touched.chargerModel && errors.chargerModel)}
                    fullWidth
                    helperText={touched.chargerModel && errors.chargerModel}
                    label="충전기모델"
                    name="chargerModel"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.chargerModel}
                    variant="outlined"
                    inputProps={{ maxLength: 20 }}
                  />
                </Grid>

                <Grid item md={12} xs={12}>
                  <TextField
                    error={Boolean(touched.memo && errors.memo)}
                    fullWidth
                    helperText={touched.memo && errors.memo}
                    label="부연설명"
                    name="memo"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    multiline
                    rows={3}
                    value={values.memo}
                    variant="outlined"
                    inputProps={{ maxLength: 100 }}
                  />
                </Grid>

                <Grid item md={12} xs={12}>
                  <TextField
                    error={Boolean(touched.updateTime && errors.updateTime)}
                    fullWidth
                    helperText={touched.updateTime && errors.updateTime}
                    label="등록일시"
                    name="updateTime"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    // required
                    value={values.updateTime}
                    variant="outlined"
                    inputProps={{ maxLength: 20 }}
                  />
                </Grid>


                <Grid item md={6} xs={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="large"
                    disabled={isSubmitting}
                    className={classes.action}
                  >
                    수정
                  </Button>

                  <Button
                    variant="outlined"
                    size="large"
                    onClick={handleBack}
                    className={classes.action}
                  >
                    취소
                  </Button>
                </Grid>

                <Grid item md={6} xs={6} align="right">
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleRemove}
                    size="large"
                    className={classes.action}
                  >
                    삭제
                  </Button>
                </Grid>
              </Grid>


            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
};

ChargerModelUpdateForm.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object,
};

export default ChargerModelUpdateForm;
