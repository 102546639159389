import React, {useState, useEffect} from "react";
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { makeStyles } from "@material-ui/styles";
import PropTypes from 'prop-types';
import StationUpdateForm from "./StationUpdateForm";
import { NEONPV_CONFIG } from 'config.js';
import { connect} from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));


const StationUpdate = (props) => {

  const {className, match, user, history, ...rest } = props;
  const classes = useStyles();

  const [stationInfo, setStationInfo] = useState(null);

  const loadData = (statId) => {

    var url = `${NEONPV_CONFIG.HOSTNAME}/station/queryStationInfo?statId=`+statId;

    fetch(url)
    .then(res => res.json())
    .then(snapshot => {

      var station = {
        statId: snapshot.resultData.statId,
        statName: snapshot.resultData.statName,
        statAddr: snapshot.resultData.addr,
        latitude: snapshot.resultData.latitude,
        longitude: snapshot.resultData.longitude,
        useTime: snapshot.resultData.useTime,
        businessId: snapshot.resultData.businessId,
        businessName: snapshot.resultData.businessName,
        businessTel: snapshot.resultData.businessTel,
        parkingFree: snapshot.resultData.parkingFree,
        zipcode: snapshot.resultData.zipcode,
        note: snapshot.resultData.note,


        ownerName: snapshot.resultData.ownerName,
        adminName: snapshot.resultData.adminName,
        adminType: snapshot.resultData.adminType,
        postcode: snapshot.resultData.postcode,
        statAddrDetail: snapshot.resultData.addr2,

        stationType: snapshot.resultData.stationType,
        stationDetailType: snapshot.resultData.stationDetailType,
        stationOperationType: snapshot.resultData.stationOperationType,
        member_id : snapshot.resultData.member_id || user.admin_id, // 테이블에 등록된 회원사ID가 없다면, 로그인한 사용자 ID를 전달함. user.admin_id
        memberName: snapshot.resultData.member_name,
      };

      setStationInfo(station);
    }
    );
  }


  useEffect( () => {
    loadData(match.params.statId);
  }, []);


  return (
    <div className={classes.root}>
      <div className={classes.content}>
        { stationInfo && <StationUpdateForm stationInfo={stationInfo} history={history} /> }
      </div>
    </div>
  );
};


StationUpdate.propTypes = {
  history: PropTypes.object
};


let mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps)(withRouter(StationUpdate));
