
export const OPEN_DIALOG = 'OPEN_DIALOG';

export const updateOpenDialog = isOpen => {
  return{
    type: OPEN_DIALOG,
    payload: isOpen
  }
}


export const SELECT_STATION = 'SELECT_STATION';

export const updateSelectedStation = selectedStation => {
  return {
    type: SELECT_STATION,
    payload: selectedStation
  }
}
