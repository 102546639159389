import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Formik } from "formik";
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Divider,
  Typography,

  Box,
  Switch,
  FormControl,
  FormControlLabel,
} from "@material-ui/core";


import { NEONPV_CONFIG } from "config";
import {global} from "common/global";

import Autocomplete from '@material-ui/lab/Autocomplete';


const SendLocalListForm = (props) => {
  const { className, history, user, ...rest } = props;
  const classes = useStyles();

  const UpdateType = ['Full', 'Differential'];

  const [selectedUpdateType, setSelectedUpdateType] = useState('Full');

  const [onLocalAuthorizationList, setOnLocalAuthorizationList] = useState(false);

  const handleLocalAuthorizationListChange = (event) => {
    // console.log(event.target.checked);
    setOnLocalAuthorizationList(event.target.checked);
  }


  const handleSubmit = (event, values) => {
    event.preventDefault();

    var json = '';
    // var json =
    //   `{"command":"SendLocalList",
    //     "body": {
    //       "listVersion": ${values.listVersion},
    //       "localAuthorizationList": [
    //         {
    //           "idTag":"${values.idTag}",
    //           "idTagInfo": {
    //             "expiryDate": "${values.expiryDate}",
    //             "parentIdTag": "${values.parentIdTag}",
    //             "status": "${values.status}"
    //           }
    //         }
    //       ],
    //       "updateType": "${selectedUpdateType}"
    //     }
    //   }`;


    if (onLocalAuthorizationList === false) {
      json = `{"command":"SendLocalList",
        "body": {
          "listVersion": ${values.listVersion},
          "updateType": "${selectedUpdateType}"
        }
      }`;
    } else {
      json =
      `{"command":"SendLocalList",
        "body": {
          "listVersion": ${values.listVersion},
          "localAuthorizationList": [
            {
              "idTag":"${values.idTag}",
              "idTagInfo": {
                "expiryDate": "${values.expiryDate}",
                "parentIdTag": "${values.parentIdTag}",
                "status": "${values.status}"
              }
            }
          ],
          "updateType": "${selectedUpdateType}"
        }
      }`;

    }

    global.ws.send(json);

  };



  return (

    <div>
      <Formik
        enableReinitialize={false}
        initialValues={{
          listVersion: 0,
          idTag: "PTAG",
          expiryDate: new Date().toISOString(),
          parentIdTag: "PPTAG",
          status: "Accepted",

          // localAuthorizationList: "D",
          // updateType: "Differential",
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          isSubmitting,
          touched,
          values,
          setFieldValue
        }) => (
          <form
            className={clsx(classes.root, className)}
            onSubmit={(e) => handleSubmit(e, values)}
          >

            <Card>
              <CardHeader
                title="사용자 인증 기록 전송"
                titleTypographyProps={{ color: 'inherit', align: 'center', variant: 'h3' }}
                // subheader="TestCase - 043_1, 043_3, 043_4, 043_5"
                // subheaderTypographyProps={{ color: 'inherit', align: 'left', variant: 'h5' }}
              />

              <CardContent>

                {/* <Grid item md={12} spacing={2}>
                  <Typography variant="h6">043_1 - PASS (update type: Full)</Typography>
                  <Typography variant="h6">043_3 - PASS (update type: Full)</Typography>
                  <Typography variant="h6">043_4 - PASS (update type: Full)</Typography>
                  <Typography variant="h6">043_5 (중간실행) - PASS (update type: Differential)</Typography>
                </Grid> */}

                <div className={classes.content}></div>

                <Grid item md={12} spacing={0}>
                  <Typography variant="h4">파라미터</Typography>
                </Grid>

                <Divider variant="inset" />


                <Grid container item spacing={2} >
                  <Grid container item md={12} spacing={0}>
                  </Grid>

                  <Grid item md={2} xs={4}>
                    <TextField
                      error={Boolean(touched.listVersion && errors.listVersion)}
                      fullWidth
                      helperText={touched.listVersion && errors.listVersion}
                      label="listVersion"
                      name="listVersion"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.listVersion}
                      variant="outlined"
                      required
                    />
                  </Grid>


                  <Grid item md={2} xs={4}>
                    {/* <TextField
                      error={Boolean(touched.updateType && errors.updateType)}
                      fullWidth
                      helperText={touched.updateType && errors.updateType}
                      label="updateType"
                      name="updateType"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.updateType}
                      variant="outlined"
                      required
                    /> */}

                    <Autocomplete
                      id="update_select"
                      options={UpdateType}
                      classes={{ option: classes.options, }}
                      autoHighlight
                      getOptionSelected={(option, value) => option === value}
                      value={selectedUpdateType}
                      renderOption={(option) => (
                        <React.Fragment>
                          {option}
                        </React.Fragment>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Type"
                          variant="outlined"
                          required
                          fullWidth
                          inputProps={{
                            ...params.inputProps,
                          }}
                        />
                      )}

                      onChange={(event, newValue) => {
                        if (newValue) {
                          setSelectedUpdateType(newValue);
                        }
                      }}
                    />
                  </Grid>

                </Grid>


                <Box p={2} />

                <Grid item md={12}>
                    {/* <Typography variant="h6">localAuthorizationList</Typography> */}
                    <FormControl component="fieldset" variant="standard">
                      <FormControlLabel
                        value="localAuthorizationList" control={<Switch color="primary" checked={onLocalAuthorizationList} onChange={handleLocalAuthorizationListChange} name="onLocalAuthorizationList" /> }
                        label={<Typography variant="h6">localAuthorizationList</Typography>}
                        labelPlacement="start"
                        />
                    </FormControl>
                </Grid>


                <Box m={1} />

                <Grid container item spacing={2} >

                  <Grid item md={2} xs={4}>
                    <TextField
                      error={Boolean(touched.idTag && errors.idTag)}
                      fullWidth
                      helperText={touched.idTag && errors.idTag}
                      label="idTag"
                      name="idTag"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.idTag}
                      variant="outlined"
                      required
                    />
                  </Grid>


                  <Grid item md={2} xs={4}>
                    <TextField
                      error={Boolean(touched.expiryDate && errors.expiryDate)}
                      fullWidth
                      helperText={touched.expiryDate && errors.expiryDate}
                      label="expiryDate"
                      name="expiryDate"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.expiryDate}
                      variant="outlined"
                      required
                    />
                  </Grid>


                  <Grid item md={2} xs={4}>
                    <TextField
                      error={Boolean(touched.parentIdTag && errors.parentIdTag)}
                      fullWidth
                      helperText={touched.parentIdTag && errors.parentIdTag}
                      label="parentIdTag"
                      name="parentIdTag"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.parentIdTag}
                      variant="outlined"
                      required
                    />
                  </Grid>

                  <Grid item md={2} xs={4}>
                    <TextField
                      error={Boolean(touched.status && errors.status)}
                      fullWidth
                      helperText={touched.status && errors.status}
                      label="status"
                      name="status"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.status}
                      variant="outlined"
                      required
                    />
                  </Grid>

                </Grid>



                <Grid container item
                  className={classes.action}
                  direction="row" alignItems="center" justify="center">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="large"
                    disabled={isSubmitting}
                    className={classes.action}
                  >
                    Perform
                  </Button>

                </Grid>
              </CardContent>
            </Card>
          </form>
        )}
      </Formik>
    </div>
  );
};

SendLocalListForm.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object,
};


let mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps)(SendLocalListForm);


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    direction: "row",
    alignItems: "center",
    justify: "center"
  },
  content2: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  action: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },
  queryField: {
    // width: 300
    flexBasis: 260,
    marginRight: 20,
  },
  bulkAction: {
    marginLeft: theme.spacing(2),
  },

  divider: {
    margin: theme.spacing(2, 0)
  },
}));
