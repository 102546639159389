import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  Typography
} from '@material-ui/core';
import { NEONPV_CONFIG } from 'config.js';
import moment from 'moment';


const SettingENVForm = (props) => {
  const { className, history, ...rest } = props;
  const classes = useStyles();

  const [lastUpdateTime, setLastUpdateTime] = useState(null);



  const doUpdateENVCommonCode = (event) => {
    event.preventDefault();

    fetch(`${NEONPV_CONFIG.HOSTNAME}/util/doUpdateENVCommonCode`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({}),
    })
    .then((response) => {
      return response.json();
    })
    .then((responseData) => {
      if (responseData.resultCode === "000") {
        alert("공통코드 다운로드에 성공했습니다.");
        setLastUpdateTime(moment().format("YYYY-MM-DD HH:mm"));

      } else {
        //에러
        alert(`공통코드 다운로드에 실패했습니다.\n(${responseData.resultCode}):${responseData.resultMessage}`);
      }
    })
    .catch((error) => {
      console.error(error);
      alert("공통코드 다운로드에 실패했습니다.");
    });
  };



  const loadData = () => {

    var url = `${NEONPV_CONFIG.HOSTNAME}/util/getENVCommonCodeInfo`;

    fetch(url)
      .then(res => res.json())
      .then(snapshot => {
        setLastUpdateTime(snapshot.resultData.lastUpdateTime);
      }
      );
  }


  useEffect(() => {

    loadData();
  }, []);


  return (
    <Card {...rest} className={clsx(classes.root, className)}>

      <CardHeader align='center'
          title={<Typography variant="h4">환경부 환경설정</Typography>}
          style={{ backgroundColor: 'lightgray' }} />
      {/* <Divider /> */}


      <CardContent>
        <Grid container spacing={2} direction="row" alignItems="center">

          <Grid container item md={2} xs={4} className={classes.title} justifyContent="center" >
            환경부 공통코드
          </Grid>

          <Grid container item md={4} xs={6} justifyContent="center" >
            마지막 다운로드 일시: {lastUpdateTime}
          </Grid>

          <Grid item md={3} xs={4}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              // fullWidth
              onClick={doUpdateENVCommonCode}
            >
              환경부 공통조회 다운로드
            </Button>
          </Grid>
        </Grid>

      </CardContent>

    </Card>
  );
};

SettingENVForm.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object
};


export default SettingENVForm;


const useStyles = makeStyles((theme) => ({
  root: {},

  action: {
    justifyContent: 'center'
  },
  title: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.regional_color,
  },
}));
