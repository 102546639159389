import React, { useState, useRef } from "react";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Formik } from "formik";
import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,

  Divider,
} from "@material-ui/core";

import { NEONPV_CONFIG } from "config.js";

import Autocomplete from '@material-ui/lab/Autocomplete';
import DaumPostcode from 'react-daum-postcode';
import {
  MAX_LENGTH,
  amdin_type_list, getAdminTypeCode, getAdminTypeLabel,
  station_type_list, getStationTypeCode, getStationTypeLabel,
  station_detail_type_list, getStationDetailTypeCode, getStationDetailTypeLabel,
  station_operation_type_list, getStationOperationTypeCode, getStationOperationTypeLabel,
  USER_AUTH
} from "common/constants";


const StationsAddForm = (props) => {
  const { className, history, user, ...rest } = props;
  const classes = useStyles();

  const userAuth = useSelector((state) => state.user.user_auth);

  const ref = useRef(null);
  const [isOpenPost, setIsOpenPost] = useState(false);

  const [selectedAdminType, setSelectedAdminType] = useState('');
  const [selectedStationType, setSelectedStationType] = useState('');
  const [selectedStationDetailType, setSelectedStationDetailType] = useState('');
  const [selectedStationOperationType, setSelectedStationOperationType] = useState('');

  /** 중복체크 유무 */
  const [verifiedStatId, setVerifiedStatId] = useState(false);


  const onChangeOpenPost = () => {
    setIsOpenPost(!isOpenPost);
  };

  const onCompletePost = (data) => {
    console.log(data);
    let fullAddr = data.address; //도로명 주소
    let extraAddr = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddr += data.bname; //(구) 동 이름
      }
      if (data.buildingName !== '') {
        extraAddr += extraAddr !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      // fullAddr += extraAddr !== '' ? ` (${extraAddr})` : '';
      extraAddr = extraAddr !== '' ? ` (${extraAddr})` : '';
    }

    ref.current.values.postCode = data.zonecode;
    ref.current.values.statAddr = data.address;
    ref.current.values.statAddrDetail = extraAddr;

    setIsOpenPost(false);
  };


  /**
   * 중복체크
   */
   const doCheckExistedStatId = (event, statId) => {
    event.preventDefault();

    console.log("statId: "+ statId);

    if (statId === ''){
      alert('충전소ID를 입력해주세요.');
      setVerifiedStatId(false);
      return;
    }
    else if(statId.length != 6){
      alert('충전소ID를 6자리에 맞춰주세요.');
      setVerifiedStatId(false);
      return;
    }
    else if(statId.length != 6){
      alert('충전소ID를 6자리에 맞춰주세요.');
      return;
    }


    var url = `${NEONPV_CONFIG.HOSTNAME}/station/isExistStation?statId=`+statId;

    fetch(url)
    .then(res => res.json())
    .then(snapshot => {

      console.log(snapshot);
      console.log(snapshot.resultData);

      if (snapshot.resultCode == '023'){
        alert('입력 가능한 충전소 ID입니다.');
        setVerifiedStatId(true);
      }

      if (snapshot.resultCode == '000'){
        alert('이미 등록된 충전소 ID입니다.');
        setVerifiedStatId(false);
      }

    });
  }


  const handleSubmit = (event, values) => {
    event.preventDefault();
    
    if (verifiedStatId == false){
      alert('충전소ID 중복체크를 해 주세요.');
      return;
    }

    const fetchURL = userAuth === USER_AUTH.SUB_MEMBER ? `${NEONPV_CONFIG.HOSTNAME}/subUser/createStationInfo` : `${NEONPV_CONFIG.HOSTNAME}/station/createStationInfo`;
    fetch(fetchURL, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        statId: values.statId,
        statName: values.statName,
        addr: values.statAddr,
        lat: values.latitude,
        lng: values.longitude,
        useTime: values.useTime,
        businessId: values.businessId,
        businessName: values.businessName,
        businessTel: values.businessTel,
        parkingfree: values.parkingFree,
        note: values.note,
        zipcode: "",

        ownerName: values.ownerName,
        adminName: values.adminName,
        adminType: getAdminTypeCode(selectedAdminType),
        stationType: getStationTypeCode(selectedStationType),
        stationDetailType: getStationDetailTypeCode(selectedStationDetailType),
        stationOperationType: getStationOperationTypeCode(selectedStationOperationType),

        postCode: values.postCode,
        addr2: values.statAddrDetail,
        member_id: user.admin_id
      }),
    })
    .then((response) => {
      return response.json();
    })
    .then((responseData) => {
      if (responseData.resultCode === "000") {
        alert("충전소 추가에 성공했습니다.");
        setTimeout(() => {
          history.goBack();
        }, 500);
      } else {
        //에러
        alert(`충전소 추가에 실패했습니다.\n(${responseData.resultCode}):${responseData.resultMessage}`);
      }
    })
    .catch((error) => {
      console.error(error);
      alert("충전소 추가에 실패했습니다.");
    });
  };

  const handleBack = () => {
    history.goBack();
  };

  return (
    <Formik
      innerRef={ref}
      initialValues={{
        statId: "",
        statName: "",
        ownerName: "",
        adminName: "",
        adminType: "",
        postCode: "",
        statAddr: "",
        statAddrDetail: "",

        latitude: "",
        longitude: "",
        useTime: "",
        businessId: "",
        businessName: "",
        businessTel: "",
        parkingFree: "",
        note: "",
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          className={clsx(classes.root, className)}
          onSubmit={(e) => handleSubmit(e, values)}
        >
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item md={2} xs={4}>
                  <TextField
                    error={Boolean(touched.statId && errors.statId)}
                    fullWidth
                    helperText={touched.statId && errors.statId}
                    label="충전소ID"
                    name="statId"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.statId}
                    variant="outlined"
                    inputProps={{ maxLength: MAX_LENGTH.STAT_ID }}
                  />
                </Grid>

                <Grid container item md={2} xs={4}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Button
                      variant={verifiedStatId?"contained": "outlined"}
                      color="primary"
                      size="large"
                      fullWidth
                      onClick={(e) => doCheckExistedStatId(e, values.statId)}
                    >
                      중복체크
                    </Button>
                  </Grid>


                <Grid item md={2} xs={4}>
                  <TextField
                    error={Boolean(touched.statName && errors.statName)}
                    fullWidth
                    helperText={touched.statName && errors.statName}
                    label="충전소"
                    name="statName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.statName}
                    variant="outlined"
                    inputProps={{ maxLength: 100 }}
                  />
                </Grid>

                <Grid item md={2} xs={4}>
                  <TextField
                    error={Boolean(touched.ownerName && errors.ownerName)}
                    fullWidth
                    helperText={touched.ownerName && errors.ownerName}
                    label="건물주이름"
                    name="ownerName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.ownerName}
                    variant="outlined"
                    inputProps={{ maxLength: 20 }}
                  />
                </Grid>

                <Grid item md={2} xs={4}>
                  <TextField
                    error={Boolean(touched.adminName && errors.adminName)}
                    fullWidth
                    helperText={touched.adminName && errors.adminName}
                    label="관리자이름"
                    name="adminName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.adminName}
                    variant="outlined"
                    inputProps={{ maxLength: 20 }}
                  />
                </Grid>

                <Grid item md={2} xs={4}>
                  <Autocomplete
                    id="admin_type"
                    options={amdin_type_list}
                    classes={{ option: classes.options, }}
                    autoHighlight
                    getOptionSelected={(option, value) => option === value}
                    value={selectedAdminType}
                    renderOption={(option) => (
                      <React.Fragment>
                        {option}
                      </React.Fragment>
                    )}

                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="관리자유형"
                        variant="outlined"
                        // required
                        fullWidth
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}

                    onChange={(event, newValue) => {
                      if (newValue) {
                        setSelectedAdminType(newValue);
                      }
                    }}
                  />
                </Grid>




                <Grid container item md={12} xs={12} style={{ paddingBlock: 8 }} />


                <Grid container item md={1} xs={2}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    size="large"
                    fullWidth
                    onClick={onChangeOpenPost}
                  >검색</Button>
                </Grid>

                {isOpenPost && <DaumPostcode autoClose onComplete={onCompletePost} className={classes.postCodeStyle} />}

                <Grid item md={1} xs={2}>
                  <TextField
                    error={Boolean(touched.postCode && errors.postCode)}
                    fullWidth
                    helperText={touched.postCode && errors.postCode}
                    label="우편번호"
                    name="statPostCode"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.postCode}
                    variant="outlined"
                    inputProps={{ maxLength: 8 }}
                  />
                </Grid>

                <Grid item md={5} xs={12}>
                  <TextField
                    error={Boolean(touched.statAddr && errors.statAddr)}
                    fullWidth
                    helperText={touched.statAddr && errors.statAddr}
                    label="주소"
                    name="statAddr"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    // required
                    value={values.statAddr}
                    variant="outlined"
                    inputProps={{ maxLength: 150 }}
                  />
                </Grid>

                <Grid item md={5} xs={8}>
                  <TextField
                    error={Boolean(touched.statAddrDetail && errors.statAddrDetail)}
                    fullWidth
                    helperText={touched.statAddrDetail && errors.statAddrDetail}
                    label="상세주소"
                    name="statAddrDetail"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.statAddrDetail}
                    variant="outlined"
                    inputProps={{ maxLength: 60 }}
                  />
                </Grid>


                <Grid container item md={12} xs={12} style={{ paddingBlock: 8 }} />


                <Grid item md={2} xs={12}>
                  <TextField
                    error={Boolean(touched.businessTel && errors.businessTel)}
                    fullWidth
                    helperText={touched.businessTel && errors.businessTel}
                    label="전화번호"
                    name="businessTel"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.businessTel}
                    variant="outlined"
                    inputProps={{ maxLength: 20 }}
                  />
                </Grid>

                <Grid item md={2} xs={4}>
                  <Autocomplete
                    id="station_type"
                    options={station_type_list}
                    classes={{ option: classes.options, }}
                    autoHighlight
                    getOptionSelected={(option, value) => option === value}
                    value={selectedStationType}
                    renderOption={(option) => (
                      <React.Fragment>
                        {option}
                      </React.Fragment>
                    )}

                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="충전소유형"
                        variant="outlined"
                        // required
                        fullWidth
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}

                    onChange={(event, newValue) => {
                      if (newValue) {
                        setSelectedStationType(newValue);
                      }
                    }}
                  />
                </Grid>


                <Grid item md={2} xs={4}>
                  <Autocomplete
                    id="station_detail_type"
                    options={station_detail_type_list}
                    classes={{ option: classes.options, }}
                    autoHighlight
                    getOptionSelected={(option, value) => option === value}
                    value={selectedStationDetailType}
                    renderOption={(option) => (
                      <React.Fragment>
                        {option}
                      </React.Fragment>
                    )}

                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="충전소상세유형"
                        variant="outlined"
                        // required
                        fullWidth
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}

                    onChange={(event, newValue) => {
                      if (newValue) {
                        setSelectedStationDetailType(newValue);
                      }
                    }}
                  />
                </Grid>


                <Grid item md={3} xs={6}>
                  <Autocomplete
                    id="station_operation_type"
                    options={station_operation_type_list}
                    classes={{ option: classes.options, }}
                    autoHighlight
                    getOptionSelected={(option, value) => option === value}
                    value={selectedStationOperationType}
                    renderOption={(option) => (
                      <React.Fragment>
                        {option}
                      </React.Fragment>
                    )}

                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="운영형태"
                        variant="outlined"
                        // required
                        fullWidth
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}

                    onChange={(event, newValue) => {
                      if (newValue) {
                        setSelectedStationOperationType(newValue);
                      }
                    }}
                  />
                </Grid>


                {/* 로그인한 계정이 회원사라면, 회원사이름 표시, 관리자라면 공백으로 둔다.
                  '회원사관리' 화면에서 충전소를 회원사에 할당하기 때문임.
                */}
                <Grid item md={2} xs={4}>
                  <TextField
                    fullWidth
                    label="운영회원사"
                    value={ userAuth === USER_AUTH.MEMBER ? user.admin_name : '' }
                    variant="outlined"
                    inputProps={{ maxLength: 50 }}
                    disabled={ true }
                  />
                </Grid>


                <Grid item md={2} xs={4}>
                  <TextField
                    error={Boolean(touched.latitude && errors.latitude)}
                    fullWidth
                    helperText={touched.latitude && errors.latitude}
                    label="위도"
                    name="latitude"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.latitude}
                    variant="outlined"
                    inputProps={{ maxLength: 20 }}
                  />
                </Grid>

                <Grid item md={2} xs={4}>
                  <TextField
                    error={Boolean(touched.longitude && errors.longitude)}
                    fullWidth
                    helperText={touched.longitude && errors.longitude}
                    label="경도"
                    name="longitude"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.longitude}
                    variant="outlined"
                    inputProps={{ maxLength: 20 }}
                  />
                </Grid>

                <Grid item md={2} xs={4}>
                  <TextField
                    error={Boolean(touched.useTime && errors.useTime)}
                    fullWidth
                    helperText={touched.useTime && errors.useTime}
                    label="사용시간"
                    name="useTime"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.useTime}
                    variant="outlined"
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>

                <Grid item md={2} xs={4}>
                  <TextField
                    error={Boolean(touched.businessId && errors.businessId)}
                    fullWidth
                    helperText={touched.businessId && errors.businessId}
                    label="대리점ID"
                    name="businessId"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    // required
                    value={values.businessId}
                    variant="outlined"
                    inputProps={{ maxLength: 4 }}
                  />
                </Grid>

                <Grid item md={2} xs={4}>
                  <TextField
                    error={Boolean(touched.businessName && errors.businessName)}
                    fullWidth
                    helperText={touched.businessName && errors.businessName}
                    label="운영주체"
                    name="businessName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    // required
                    value={values.businessName}
                    variant="outlined"
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>



                <Grid item md={2} xs={4}>
                  <TextField
                    error={Boolean(touched.parkingFree && errors.parkingFree)}
                    fullWidth
                    helperText={touched.parkingFree && errors.parkingFree}
                    label="주차가능"
                    name="parkingFree"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.parkingFree}
                    variant="outlined"
                    inputProps={{ maxLength: 1 }}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.note && errors.note)}
                    fullWidth
                    helperText={touched.note && errors.note}
                    label="메모"
                    name="note"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.note}
                    variant="outlined"
                    inputProps={{ maxLength: 200 }}
                  />
                </Grid>
              </Grid>


              <Divider className={classes.divider} variant="fullWidth" />


              <Grid container item className={classes.action}
                direction="row" alignItems="center" justify="center">
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  size="large"
                  disabled={isSubmitting}
                  className={classes.action}
                >
                  충전소 추가
                </Button>

                <Button
                  variant="outlined"
                  size="large"
                  onClick={handleBack}
                  className={classes.action}
                >
                  취소
                </Button>
              </Grid>
            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
};

StationsAddForm.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object,
};

let mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps)(StationsAddForm);


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  action: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },
  divider: {
    margin: theme.spacing(2, 0),
    // color: '#616161'
  },
}));