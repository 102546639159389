import palette from 'theme/palette';
import 'chartjs-plugin-datalabels';


const basis = 5134/100;
const basis_yes = 6780/100;
export const data = {
  labels: [ ['01#B1', '7.1 hrs'], ['02#B2', '화이튼멤브레인'], ['03#B3', '자갈'], ['04#B4', '콘크리트'], ['05#B5', '녹색'] , ['06#B6', '황토'], ['07#A', 'NeON2 410W'], ['08#D', 'Mono Xplus 375W'], ['09#H', 'P-Type 375W'], ['10#E', 'P-Type H/C 415W']],
  datasets: [
    {
      label: '오늘',
      backgroundColor: palette.primary.main,
      data: [(7130/basis).toFixed(1), (6964/basis).toFixed(1), (6023/basis).toFixed(1), (6511/basis).toFixed(1), (6109/basis).toFixed(1), (6298/basis).toFixed(1), (5681/basis).toFixed(1), (5571/basis).toFixed(1), (5402/basis).toFixed(1), (5134/basis).toFixed(1) ]
    },
    { 
      label: '어제',
      backgroundColor: palette.neutral,
      data: [(9015/basis_yes).toFixed(1), (8838/basis_yes).toFixed(1), (7744/basis_yes).toFixed(1), (8338/basis_yes).toFixed(1), (7843/basis_yes).toFixed(1), (8130/basis_yes).toFixed(1), (7380/basis_yes).toFixed(1), (7301/basis_yes).toFixed(1), (7034/basis_yes).toFixed(1), (6780/basis_yes).toFixed(1)]
    }
  ]
};


export const options = {
  responsive: true,
  maintainAspectRatio: false,
  animation: false,
  legend: { display: false },
  cornerRadius: 5,
  tooltips: {
    enabled: true,
    mode: 'index',
    intersect: false,
    borderWidth: 1,
    borderColor: palette.divider,
    backgroundColor: palette.white,
    titleFontColor: palette.text.primary,
    bodyFontColor: palette.text.secondary,
    footerFontColor: palette.text.secondary
  },
  layout: { padding: {
    top: 20
  } },
  scales: {
    xAxes: [
      {
        // barThickness: 18,
        // maxBarThickness: 16,
        // barPercentage: 0.5,
        // barPercentage: 0.7,
        // categoryPercentage: 0.5,
        ticks: {
          fontColor: palette.text.secondary,
          autoSkip: false,
          fontSize: 13,
        },
        gridLines: {
          display: false,
          drawBorder: false
        }
      }
    ],
    yAxes: [
      {
        ticks: {
          fontColor: palette.text.secondary,
          // beginAtZero: false,
          // min: 80,
          suggestedMin: 80,
          // steps: 20,
          // stepValue: 10,
          stepSize:10,
          callback(value){
            return Number(value).toLocaleString('en') + '%';
          }
        },
        gridLines: {
          borderDash: [2],
          borderDashOffset: [2],
          color: palette.divider,
          drawBorder: false,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
          zeroLineColor: palette.divider
        },
        scaleLabel: {
          display: true,
          labelString: 'ratio %'
        }
      }
    ]
  },
  //data value label
  plugins: {
    datalabels: {
      display: true,
      color: palette.text.secondary,
      anchor: 'end',
      align: 'top',
      formatter: function(value, context){
        return Number(value).toLocaleString('en') +'%';
      }
    }
  }
};
