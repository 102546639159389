import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { NEONPV_CONFIG } from 'config';  // config.js 파일의 경로를 올바르게 지정하십시오.
import {TextField} from '@mui/material';
import { ca } from 'date-fns/locale';
function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://castprocsms.kr/sign-in#/">
        castpro
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const defaultTheme = createTheme();

export default function Credit(props) {
  const { match } = props;

  const handleCardNumberChange = (event) => {
    let value = event.target.value.replace(/\D/g, ''); // 숫자 이외의 문자 제거

    // 정규식을 이용하여 4자리씩 묶음으로 하이픈 추가
    value = value.replace(/(\d{4})(?=\d)/g, '$1-');

    setCardNumber(value);
  };

  const handleExpChange = (event) => {
    let value = event.target.value.replace(/\D/g, ''); // 숫자 이외의 문자 제거

    // 정규식을 이용하여 4자리씩 묶음으로 하이픈 추가
    value = value.replace(/(\d{2})(?=\d)/g, '$1/');

    setExp(value);
  };

  

  // 시간 데이터 생성
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  const EdiDate = year + month + day + hours + minutes + seconds;

  const niceBillingConnect = async () => {
    const obj = {
      'cardNumber': document.getElementById('cardNumber').value,
      'exp': document.getElementById('exp').value,
      'IDNo': document.getElementById('IDNo').value,
      'CardPw': document.getElementById('CardPw').value,
      'member_id': document.getElementById('member_id').value,
      'email': document.getElementById('email').value,
      'user_card_number': document.getElementById('user_card_number').value
    }

    try {
      fetch(`${NEONPV_CONFIG.HOSTNAME}/credit/billing`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(obj)
      })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if(data.resultCode == 200){
          alert('카드등록 완료');
        }
        else{
          alert(data.resultMessage);
        }
      })
      .catch((err) => {
        alert(err);
      });
      
    } catch (error) {
      console.error('서버와의 통신이 올바르지 않습니다.', error);
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            카드등록
          </Typography>

          <form id="payForm" name='payForm' method="post">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    required
                    autoFocus
                    fullWidth
                    id="cardNumber"
                    name="cardNumber"
                    value='4140-0315-1823-3976'
                    onChange={handleCardNumberChange}
                    label="카드번호 예시) 1234-1234-1234-1234"
                    inputProps={{maxLength: 19}}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="exp"
                    name="exp"
                    value="09/28"
                    onChange={handleExpChange}
                    label="유효기간(월/년) 예시) 06/24"
                    inputProps={{maxLength: 5}}
                  />
                </Grid>


                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="IDNo"
                    name="IDNo"
                    value="2308104019"
                    label="생년월일(6) 또는 사업자번호(10)"
                    inputProps={{maxLength: 10}}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="CardPw"
                    name="CardPw"
                    value="71"
                    label="카드 비밀번호 앞 2자리"
                    inputProps={{maxLength: 2}}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <input type="hidden" id='member_id' name="member_id" value={match.params.member_id} />
                <input type="hidden" id='email' name="email" value={match.params.email} />
                <input type="hidden" id='user_card_number' name="user_card_number" value={match.params.user_card_number} />
              </Grid>

              <Button
                type="button"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 3 }}
                id="addCardBtn"
                onClick={niceBillingConnect}
              >
                카드등록
              </Button>
            </form>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="https://castprocsms.kr/sign-in#/" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}
