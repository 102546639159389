import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link as RouterLink, withRouter } from "react-router-dom";
import { makeStyles } from '@material-ui/styles';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  InputAdornment,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  TablePagination,
  TableSortLabel,


  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@material-ui/core';

import {
  Search as SearchIcon,
} from 'react-feather';

import { NEONPV_CONFIG } from 'config.js';


import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateMemberName, updateMemberAddr, updateMemberAdminName, updateMemberAdminTelNo } from 'actions/query';


const COLUMN_WIDTH = {
  MEMBER_ID: '7%',
  MEMBER_NAME: '8%', //15
  MEMBER_TELNO: '8%', //23
  MEMBER_POSTCODE: '7%', //30
  MEMBER_ADDR1: '15%', //45
  MEMBER_ADDR2: '14%', //59
  MEMBER_ADMIN_NAME: '8%', //67
  // MEMBER_ADMIN_TELNO: '8%', //75
  MEMBER_ENV_INTEGRATED: '7%',//74
  MEMBER_ADMIN_EMAIL: '10%', //84
  MEMBER_NOTE: '10%', //94
}



function descendingComparator(a, b, orderBy) {

  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}


function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}


function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}



const headCells = [
  {
    id: 'member_id',
    numeric: false,
    disablePadding: true,
    width:COLUMN_WIDTH.MEMBER_ID,
    label: 'ID',
  },
  {
    id: 'member_name',
    numeric: false,
    disablePadding: false,
    width:COLUMN_WIDTH.MEMBER_NAME,
    label: '회원사',
  },
  {
    id: 'member_telno',
    numeric: false,
    disablePadding: false,
    width:COLUMN_WIDTH.MEMBER_TELNO,
    label: '전화번호',
  },
  {
    id: 'MEMBER_POSTCODE',
    numeric: false,
    disablePadding: false,
    width:COLUMN_WIDTH.MEMBER_POSTCODE,
    labelSmall : true,
    label: '우편No',
  },
  {
    id: 'member_addr1',
    numeric: false,
    disablePadding: false,
    width:COLUMN_WIDTH.MEMBER_ADDR1,
    label: '주소',
  },
  {
    id: 'member_addr2',
    numeric: false,
    disablePadding: true,
    width:COLUMN_WIDTH.MEMBER_ADDR2,
    label: '상세주소',
  },
  {
    id: 'member_admin_name',
    numeric: false,
    disablePadding: false,
    width:COLUMN_WIDTH.MEMBER_ADMIN_NAME,
    label: '관리자',
  },
  // {
  //   id: 'member_admin_telno',
  //   numeric: false,
  //   disablePadding: true,
  //   width:COLUMN_WIDTH.MEMBER_ADMIN_TELNO,
  //   label: 'TEL',
  // },
  {
    id: 'member_env_integrated',
    numeric: false,
    disablePadding: true,
    width:COLUMN_WIDTH.MEMBER_ENV_INTEGRATED,
    label: '환경부연동',
  },
  {
    id: 'member_admin_email',
    numeric: false,
    disablePadding: true,
    width:COLUMN_WIDTH.MEMBER_ADMIN_EMAIL,
    label: '이메일',
  },
  {
    id: 'member_note',
    numeric: false,
    disablePadding: true,
    width:COLUMN_WIDTH.MEMBER_NOTE,
    label: '비고',
  },
];


function EnhancedTableHead(props) {
  const { order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            // align={headCell.numeric ? 'right' : 'center'}
            align='center'
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            width={headCell.width}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.labelSmall ? (<Typography variant="body2">{headCell.label}</Typography>) : (
                headCell.label )}
              {/* {headCell.label} */}
              {/* {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null} */}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};



const MemberTable = props => {
  const { className, users, queryParamsMember, ...rest } = props;

  const classes = useStyles();

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('member_id');

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [listData, setListData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");


  const [memberName, setMemberName] = useState('');
  const [memberAddr, setMemberAddr] = useState('');
  const [adminName, setAdminName] = useState('');
  const [adminTelNo, setAdminTelNo] = useState('');


  const searchData = (autoload=false, membername, memberaddr, adminname, admintelno) => {


    var url = `${NEONPV_CONFIG.HOSTNAME}/member/queryMemberList?`;

    var params = '';
    if (membername) {
      params = 'membername=' + membername;
    }

    if (memberaddr) {
      params += params.length > 0 ? '&' : '';
      params += 'memberaddr=' + memberaddr;
    }

    if (adminname) {
      params += params.length > 0 ? '&' : '';
      params += 'adminname=' + adminname;
    }

    if (admintelno) {
      params += params.length > 0 ? '&' : '';
      params += 'admintelno=' + admintelno;
    }

    url += params;

    // console.log('searchData ', url);

    fetch(url)
      .then(res => res.json())
      .then(snapshot => {

        var list = [];
        if (snapshot.resultData != null) {
          snapshot.resultData.forEach(doc => {
            list.push(doc);
          });
        }
        setListData(list);

        if (autoload === false && list.length === 0){
          setDialogMessage('검색 결과가 없습니다.');
          setOpenDialog(true);
        }

        setPage(0);
      });
  }



  /**
   * NOTE: 회원사는 그 리스트가 적으므로, "전체" 검색이 되도록, '검색어' 지정 제약을 푼다.
   */
  const handleSearch = event => {

    // if (memberName !== '' || memberAddr !== '' || adminName !== '' || adminTelNo !== '') {
      searchData(false, memberName, memberAddr, adminName, adminTelNo);
    // }else{
    //   setDialogMessage('검색어를 입력해 주세요. ');
    //   setOpenDialog(true);
    // }
  }

  const doKeyPress = (ev) => {
    if (ev.key === 'Enter') {
      handleSearch();
    }
  }


  /**
   * 검색 정보 지우기
   * @param {*} event
   */
  const handleClear = event => {

    setMemberName('');
    setMemberAddr('');
    setAdminName('');
    setAdminTelNo('');
    props.updateMemberName('');
    props.updateMemberAddr('');
    props.updateMemberAdminName('');
    props.updateMemberAdminTelNo('');
    setListData([]);
  }

  const handleMemberName = (event) => {
    event.persist();
    setMemberName(event.target.value);
    props.updateMemberName(event.target.value);
  }

  const handleMemberAddr = (event) => {
    event.persist();
    setMemberAddr(event.target.value);
    props.updateMemberAddr(event.target.value);
  }

  const handleAdminName = (event) => {
    event.persist();
    setAdminName(event.target.value);
    props.updateMemberAdminName(event.target.value);
  }

  const handleAdminTelNo = (event) => {
    event.persist();
    setAdminTelNo(event.target.value);
    props.updateMemberAdminTelNo(event.target.value);
  }


  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };


  const startRow = (page * rowsPerPage);


  useEffect(() => {

    if (queryParamsMember.queryMemberName || queryParamsMember.queryMemberAddr ||
      queryParamsMember.queryMemberAdminName || queryParamsMember.queryMemberAdminTelNo) {

        searchData(true, queryParamsMember.queryMemberName, queryParamsMember.queryMemberAddr,
          queryParamsMember.queryMemberAdminName, queryParamsMember.queryMemberAdminTelNo);
    }else{
      handleSearch();
    }

    setMemberName(queryParamsMember.queryMemberName);
    setMemberAddr(queryParamsMember.queryMemberAddr);
    setAdminName(queryParamsMember.queryMemberAdminName);
    setAdminTelNo(queryParamsMember.queryMemberAdminTelNo);

    return () => { };
  }, []);



  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };


  return (
    <div>

    <Dialog open={openDialog} onClose={() => { setOpenDialog(false) }}>
      <DialogTitle>주의</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{dialogMessage}</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={() => { setOpenDialog(false) }}>닫기</Button>
      </DialogActions>
    </Dialog>

    <Card className={clsx(classes.root, className)} {...rest} >
      <Box m={1} p={2}>
        <Box mt={2}
          display="flex"
          alignItems="center"
          justifyContent="start"
          flexGrow={1}>

          <TextField className={classes.queryField}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="action"><SearchIcon /></SvgIcon>
                </InputAdornment>
              )
            }}
            onChange={handleMemberName}
            label="회원사 이름"
            placeholder="회원사 이름"
            value={memberName}
            variant="outlined"
            onKeyUp={(ev) => doKeyPress(ev)}
          />

          <TextField className={classes.queryField}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="action"><SearchIcon /></SvgIcon>
                </InputAdornment>
              )
            }}
            onChange={handleMemberAddr}
            label="회원사 주소"
            placeholder="회원사 주소"
            value={memberAddr}
            variant="outlined"
            onKeyUp={(ev) => doKeyPress(ev)}
          />

          <TextField className={classes.queryField}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="action"><SearchIcon /></SvgIcon>
                </InputAdornment>
              )
            }}
            onChange={handleAdminName}
            label="관리자 이름"
            placeholder="관리자 이름"
            value={adminName}
            variant="outlined"
            onKeyUp={(ev) => doKeyPress(ev)}
          />

          <TextField className={classes.queryField}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="action"><SearchIcon /></SvgIcon>
                </InputAdornment>
              )
            }}
            onChange={handleAdminTelNo}
            label="관리자 전화번호"
            placeholder="관리자 전화번호"
            value={adminTelNo}
            variant="outlined"
            onKeyUp={(ev) => doKeyPress(ev)}
          />

          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.bulkAction}
            onClick={handleSearch}
          >검색</Button>

          <Button
            variant="outlined"
            size="large"
            className={classes.bulkAction}
            onClick={handleClear}
          >초기화</Button>
        </Box>
      </Box>

      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>

              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={listData.length} />

              <TableBody>
                { stableSort(listData, getComparator(order, orderBy)).slice(0 + startRow, rowsPerPage + (page * rowsPerPage)).map(module => (

                  <TableRow
                    className={classes.tableRow}
                    key={module.member_id}
                    component={RouterLink} to={`/members/update/${module.member_id}`}
                  >

                    <TableCell className={classes.nameContainer} width={COLUMN_WIDTH.MEMBER_ID} align="left">{module.member_id}</TableCell>
                    <TableCell width={COLUMN_WIDTH.MEMBER_NAME} align="left">{module.member_name}</TableCell>
                    <TableCell width={COLUMN_WIDTH.MEMBER_TELNO} align="left">{module.member_telno}</TableCell>
                    <TableCell width={COLUMN_WIDTH.MEMBER_POSTCODE} align="left"><Typography variant="body2">{module.member_postcode}</Typography></TableCell>
                    <TableCell width={COLUMN_WIDTH.MEMBER_ADDR1} align="left">{module.member_addr1}</TableCell>
                    <TableCell width={COLUMN_WIDTH.MEMBER_ADDR2} align="left"><Typography variant="body2">{module.member_addr2}</Typography></TableCell>
                    <TableCell width={COLUMN_WIDTH.MEMBER_ADMIN_NAME} align="left">{module.member_admin_name}</TableCell>
                    {/* <TableCell width={COLUMN_WIDTH.MEMBER_ADMIN_TELNO} align="left">{module.member_admin_telno}</TableCell> */}
                    <TableCell width={COLUMN_WIDTH.MEMBER_ENV_INTEGRATED} align="left">{module.member_env_integrated === 1 ? '연동' : '미연동'}</TableCell>
                    <TableCell width={COLUMN_WIDTH.MEMBER_ADMIN_EMAIL} align="left">{module.member_admin_email}</TableCell>
                    <TableCell width={COLUMN_WIDTH.MEMBER_NOTE} align="left"><Typography variant="body2">{module.member_note}</Typography></TableCell>
                  </TableRow>
                ))}

              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>

      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={listData.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>

    </div>
  );
};

MemberTable.propTypes = {
  className: PropTypes.string,
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    updateMemberName,
    updateMemberAddr,
    updateMemberAdminName,
    updateMemberAdminTelNo
  }, dispatch);
};


let mapStateToProps = (state) => {
  return {
    queryParamsMember: state.queryParamsMember,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MemberTable));




const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    // minWidth: 1050
  },
  nameContainer: {
    // display: 'flex',
    // alignItems: 'center',
    // marginLeft: theme.spacing(1)
  },
  queryField: {
    // width: 300
    flexBasis: 260,
    marginRight: 20,
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  bulkAction: {
    marginLeft: theme.spacing(2)
  },
}));
