import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Button,
  Card,
  CardContent,
  CardActions,
  Grid,
  makeStyles,

  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  CardHeader,
  Box,
  Select, MenuItem
} from "@material-ui/core";


import { NEONPV_CONFIG } from "config.js";
import { USER_AUTH } from 'common/constants';


const CustomTableCell = ({ row, name, onChange, width, label }) => {
  const classes = useStyles();

  if (row) {
    return (
      <TableCell  align="right" width={width} className={classes.tablecell}>
        <TextField
        // siz="small"
          // variant="standard"
          variant="outlined"
          // variant="filled"
          name={name}
          // label={label}
          fullWidth
          align="right"
          value={row[name]}
          onChange={onChange}

          inputProps={{
            style: {
              textAlign: "right",
              padding: 8,
            },
            maxLength: 10,
          }}
        />
      </TableCell>

    );
  } else {
    return (<TableCell></TableCell>);
  }
};



const CastproUnitPriceUpdateForm = (props) => {
  const { className, history, user, clientPriceVersionNo, unitPriceInfo, ...rest } = props;
  const classes = useStyles();

  const userAuth = useSelector((state) => state.user.user_auth);

  const [listData, setListData] = useState(unitPriceInfo);

  const [newUnitPriceVersionNo, setNewUnitPriceVersionNo] = useState(clientPriceVersionNo);


  const [priceType, setPriceType] = useState('lowPrice1');

  const handlePriceTypeChange = (event) => {
    setPriceType(event.target.value);
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    fetch(`${NEONPV_CONFIG.HOSTNAME}/unitprice/updateCastproUnitPrice`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        newUnitPriceVersionNo: Number(newUnitPriceVersionNo)+1,
        list: listData,
      }),
    })
    .then((response) => {
      return response.json();
    })
    .then((responseData) => {
      if (responseData.resultCode === "000") {
        alert("업데이트에 성공했습니다.");
        setNewUnitPriceVersionNo(Number(newUnitPriceVersionNo)+1);
      } else {
        //에러
        alert(`업데이트에 실패했습니다.\n${responseData.resultCode}`);
      }
    })
    .catch((error) => {
      console.error(error);
      alert("업데이트에 실패했습니다.");
    });
  };

  const handleBack = () => {
    window.location.href = '/';
  };


  const handleChange = (event, index) => {
    const val = event.target.value;
    const name = event.target.name;

    let newArr = [...listData];
    newArr[index][name] = val;
    setListData(newArr);
  }


  return (
    <div>
      <Card className={clsx(classes.root, className)} {...rest} >
        <CardHeader align='center'
          title={<Typography variant="h4">충전단가</Typography>}
          style={{ backgroundColor: 'lightgray' }}
          subheader={ <Typography variant="body1">&nbsp;&nbsp;    (버전번호: { ('00000'+newUnitPriceVersionNo).slice(-5) })</Typography> }
          />

        <CardContent className={classes.content}>

        <Grid container spacing={2} >
            <Grid item md={2} xs={4} >
              <TextField
                className={classes.queryField}
                label="운영회원사"
                name="businessName"
                value={userAuth === USER_AUTH.MEMBER ? user.admin_name : '관리자'}
                variant="outlined"
                inputProps={{ maxLength: 50 }}
                disabled
              />
            </Grid>
          </Grid>

          <Box sx={{ m: 2 }} />

          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center" width={COLUMN_WIDTH.TIMERANGE}>시간대</TableCell>
                <TableCell align="center" width={COLUMN_WIDTH.DEFAULT_PRICE}>회원</TableCell>
                <TableCell align="center" width={COLUMN_WIDTH.OTHER_PRICE}>비회원</TableCell>
                <TableCell align="center" width={COLUMN_WIDTH.ENV_PRICE}>환경부</TableCell>
                <TableCell align="center" width={COLUMN_WIDTH.ROAMING_PRICE}>로밍</TableCell>
                <TableCell align="center" width={COLUMN_WIDTH.LOW_PRESS1}>
                  <Box display="flex" justifyContent="center" alignItems="center">
                    한전단가  &nbsp;&nbsp;
                    <Select
                        value={priceType}
                        label="한전단가"
                        onChange={handlePriceTypeChange}
                        variant="standard"
                        >
                        <MenuItem value={'lowPrice1'}>저압1</MenuItem>
                        <MenuItem value={'lowPrice2'}>저압2</MenuItem>
                        <MenuItem value={'lowPrice3'}>저압3</MenuItem>
                        <MenuItem value={'lowPrice4'}>저압4</MenuItem>
                        <MenuItem value={'highPrice1'}>고압1</MenuItem>
                        <MenuItem value={'highPrice2'}>고압2</MenuItem>
                        <MenuItem value={'highPrice3'}>고압3</MenuItem>
                        <MenuItem value={'highPrice4'}>고압4</MenuItem>
                      </Select>
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {listData.map((module, index) => (
                <TableRow className={classes.tableRow} key={module.id}>
                  <TableCell align="center" width={COLUMN_WIDTH.TIMERANGE}>{module.time}</TableCell>
                  <CustomTableCell {...{ row: module, name: "defaultprice", onChange: (e => handleChange(e, index)),
                    width: COLUMN_WIDTH.DEFAULT_PRICE, label: '회원' }} />
                  <CustomTableCell {...{ row: module, name: "otherPrice", onChange: (e => handleChange(e, index)),
                    width: COLUMN_WIDTH.OTHER_PRICE, label: '비회원' }} />
                  <CustomTableCell {...{ row: module, name: "envPrice", onChange: (e => handleChange(e, index)),
                    width: COLUMN_WIDTH.ENV_PRICE, label: '환경부' }} />
                  <CustomTableCell {...{ row: module, name: "roamingPrice", onChange: (e => handleChange(e, index)),
                    width: COLUMN_WIDTH.ROAMING_PRICE, label: '로밍' }} />

                  <TableCell align="right" width={COLUMN_WIDTH.LOW_PRESS1} style={{ paddingRight: 16 }}>
                    {priceType === 'lowPrice1' ? module.lowPrice1 :
                    priceType === 'lowPrice2' ? module.lowPrice2 :
                    priceType === 'lowPrice3' ? module.lowPrice3 :
                    priceType === 'lowPrice4' ? module.lowPrice4 :
                    priceType === 'highPrice1' ? module.highPrice1 :
                    priceType === 'highPrice2' ? module.highPrice2 :
                    priceType === 'highPrice3' ? module.highPrice3 :
                    priceType === 'highPrice4' ? module.highPrice4 : ''
                    }
                    </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>

        <CardActions className={classes.actions}>
          <Grid container item className={classes.action}
            direction="row" alignItems="center" justify="center">
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={handleSubmit}
              className={classes.action}
            >등록</Button>

            <Button
              variant="contained"
              size="large"
              className={classes.action}
              onClick={handleBack}
            >취소</Button>

          </Grid>
        </CardActions>
      </Card>
      </div>
  );
};

CastproUnitPriceUpdateForm.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object,
};

let mapStateToProps = (state) => {
  return {
    user: state.user
  };
};


export default connect(mapStateToProps)(CastproUnitPriceUpdateForm);


const COLUMN_WIDTH = {
  TIMERANGE: '25%',
  DEFAULT_PRICE: '15%',
  OTHER_PRICE: '15%',
  ENV_PRICE: '15%',
  ROAMING_PRICE: '15%',
  LOW_PRESS1: '15%',
}


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(1),
  },
  actions: {
    justifyContent: "flex-end",
  },
  action: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },
  tablecell: {
    "&.MuiTableCell-root": {
      padding: '8px',
    },
  },
  tableRow: {
    height: "20px",
    padding : "0px",
  },
  textfield: {
    "&.MuiOutlinedInput-input": {
      padding: '8px'
    }
  }
}));