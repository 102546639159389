import React, {useState, useEffect} from "react";
import { makeStyles } from "@material-ui/styles";
import PropTypes from 'prop-types';
import InstallationUpdateForm from "./InstallationUpdateForm";
import { NEONPV_CONFIG } from 'config.js';


const InstallationAdd = (props) => {

  const {className, match, history, ...rest } = props;
  const classes = useStyles();

  const [installationInfo, setInstallationInfo] = useState(null);

  const loadData = (installationSeqNo) => {

    var url = `${NEONPV_CONFIG.HOSTNAME}/installation/queryInstallationInfo?installationSeqNo=`+installationSeqNo;

    fetch(url)
    .then(res => res.json())
    .then(snapshot => {

      // console.log(snapshot);

      var installation = {
        seqNo: snapshot.resultData.SeqNo,
        applyDate : snapshot.resultData.applyDate,
        applicant: snapshot.resultData.applicant,
        corporate_name: snapshot.resultData.corporate_name,
        applicant_tel_no: snapshot.resultData.applicant_tel_no,
        applicant_mobile_no: snapshot.resultData.applicant_mobile_no,
        applicant_email: snapshot.resultData.applicant_email,
        applicant_region: snapshot.resultData.applicant_region,
        applicant_lot: snapshot.resultData.applicant_lot,
        operation_type: snapshot.resultData.operation_type,
        worker: snapshot.resultData.worker,
        status: snapshot.resultData.status,
        status_date: snapshot.resultData.status_date,
        sent_email1: snapshot.resultData.sent_email1,
        sent_email2: snapshot.resultData.sent_email2,
        inspector: snapshot.resultData.inspector,
        memberName: snapshot.resultData.member_name,

      };

      setInstallationInfo(installation);
      // console.log("installationInfo: "+ JSON.stringify(installation));
    }
    );
  }

  useEffect( () => {
    loadData(match.params.installationSeqNo);
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        { installationInfo && <InstallationUpdateForm installationInfo={installationInfo} history={history} /> }
      </div>
    </div>
  );
};


InstallationAdd.propTypes = {
  history: PropTypes.object
};

export default InstallationAdd;


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));
