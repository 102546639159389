import { combineReducers } from 'redux'

import {
  // UPDATE_USER_EMAIL,
  // UPDATE_USER_NAME,
  // UPDATE_PASSWORD,
  LOGOUT,
  // UPDATE_GROUP,
  UPDATE_ADMIN_ID,
  TEMP_SET_USER,

  ADMIN_NAME,
  USER_AUTH,
} from 'actions/user';

import {
  /** 충전소 검색 조회 검색어 */
  QUERY_STATION_MEMBER_NAME,
  QUERY_PARAM_STATION_ID,
  QUERY_PARAM_STATION_NAME,
  QUERY_PARAM_STATION_ADDR,
  QUERY_STATION_ADMIN_NAME,


  /** 충전기 검색 조회 검색어 */
  QUERY_CHARGER_MEMBER_NAME,
  QUERY_PARAM_CHARGER_STAT_ID,
  QUERY_PARAM_STATION_NAME2,
  QUERY_PARAM_STATION_ADDR2,
  QUERY_PARAM_CHARGER_NO,
  QUERY_PARAM_CHARGER_STATUS,

  /** 회원관리 조회 검색어 */
  QUERY_PARAM_LOGIN_TYPE,
  QUERY_PARAM_USER_CARD_NUMBER,
  QUERY_PARAM_USER_EMAIL,
  QUERY_PARAM_USER_NICKNAME,


  /** 충전기 충전이력 조회 검색어 */
  QUERY_PARAM_USAGE_MEMBER_NAME,
  QUERY_PARAM_USAGE_CHARGER_START_TIME,
  QUERY_PARAM_USAGE_CHARGER_END_TIME,
  QUERY_PARAM_USAGE_CHARGER_STAT_NAME,


  /** 회원충전이력 조회 검색어 */
  QUERY_PARAM_USAGE_USER_MEMEBR_NAME,
  QUERY_PARAM_USAGE_USER_START_TIME,
  QUERY_PARAM_USAGE_USER_END_TIME,
  QUERY_PARAM_USAGE_USER_EMAIL,
  QUERY_PARAM_USAGE_USER_NICKNAME,


  /** 고장신고 검색어 */
  QUERY_PARAM_MALFUNCTION_MEMBER_NAME,
  QUERY_PARAM_MALFUNCTION_REPORT_START_TIME,
  QUERY_PARAM_MALFUNCTION_REPORT_END_TIME,
  QUERY_PARAM_MALFUNCTION_STAT_NAME,
  QUERY_PARAM_MALFUNCTION_REGION,
  QUERY_PARAM_MALFUNCTION_STATUS,


  /** 설치신청 검색어 */
  QUERY_PARAM_INSTALLATION_MEMBER_NAME,
  QUERY_PARAM_INSTALLATION_APPLY_START_TIME,
  QUERY_PARAM_INSTALLATION_APPLY_END_TIME,
  QUERY_PARAM_INSTALLATION_APPLICANT,
  QUERY_PARAM_INSTALLATION_REGION,
  QUERY_PARAM_INSTALLATION_STATUS,


  /** 회원사 검색어 */
  QUERY_PARAM_MEMBER_NAME,
  QUERY_PARAM_MEMBER_ADDR,
  QUERY_PARAM_MEMBER_ADMIN_NAME,
  QUERY_PARAM_MEMBER_ADMN_TELNO

} from 'actions/query';


const initialState = {
  status: '',
  admin_id: '',
  admin_name: '',
  user_auth: '', //1: admin, 2: 회원사
  // userEmail: '',
  // password: '',
  // username:'',
  // group:'',
}


const user = (state = initialState, action) => {

  switch(action.type){
    // case UPDATE_USER_EMAIL:
    //   return {...state, userEmail:action.payload}

    // case UPDATE_USER_NAME:
    //   return {...state, username:action.payload}

    // case UPDATE_PASSWORD:
    //   return {...state, password:action.payload}

    case LOGOUT:
      return action.payload;

    case UPDATE_ADMIN_ID:
      return {...state, password:action.payload}


    case ADMIN_NAME:
      return {...state, admin_name:action.payload}

    case USER_AUTH:
      return {...state, user_auth:action.payload}

    // case UPDATE_GROUP:
    //   return {...state, group: action.payload}

    case TEMP_SET_USER:
      return action.payload;

    default:
      return state;
  }
}


const initQuery = {
  queryStationMemberName: '',
  queryStatId: '',
  queryStatName : '',
  queryStatAddr : '',
  queryStatAdminName: '',


  queryChargerMemberName : '',
  queryChargerStatId: '',
  queryStatName2 : '',
  queryStatAddr2 : '',
  queryChargerNo : '',
  queryChargerStatus : '-',

  /**
   * 회원관리 조회화면, 검색 파라미트
   */
  queryLoginType : '',
  queryUserCardNumber : '',
  queryUserEmail : '',
  queryUserNickname : '',

  // queryMalfunctionReportStartTime: '',
  // queryMalfunctionReportEndTime : '',
  // queryMalfunctionStatName : '',
  // queryMalfunctionRegion : '',
  // queryMalfunctionStatus : ''
}

const queryParams = (state = initQuery, action) => {

  switch(action.type){
    case QUERY_STATION_MEMBER_NAME:
      return {...state, queryStationMemberName: action.payload}

    case QUERY_PARAM_STATION_ID:
      return {...state, queryStatId: action.payload}

    case QUERY_PARAM_STATION_NAME:
      return {...state, queryStatName: action.payload}

    case QUERY_PARAM_STATION_ADDR:
      return {...state, queryStatAddr: action.payload}

    case QUERY_STATION_ADMIN_NAME:
      return {...state, queryStatAdminName: action.payload}



    case QUERY_CHARGER_MEMBER_NAME:
      return {...state, queryChargerMemberName: action.payload}

    case QUERY_PARAM_CHARGER_STAT_ID:
      return {...state, queryChargerStatId: action.payload}

    case QUERY_PARAM_STATION_NAME2:
      return {...state, queryStatName2: action.payload}

    case QUERY_PARAM_STATION_ADDR2:
      return {...state, queryStatAddr2: action.payload}

    case QUERY_PARAM_CHARGER_NO:
      return {...state, queryChargerNo: action.payload}

    case QUERY_PARAM_CHARGER_STATUS:
      return {...state, queryChargerStatus: action.payload}

    case QUERY_PARAM_LOGIN_TYPE:
      return {...state, queryLoginType: action.payload}

    case QUERY_PARAM_USER_CARD_NUMBER:
      return {...state, queryUserCardNumber: action.payload}

    case QUERY_PARAM_USER_EMAIL:
      return {...state, queryUserEmail: action.payload}

    case QUERY_PARAM_USER_NICKNAME:
      return {...state, queryUserNickname: action.payload}


    // case QUERY_PARAM_MALFUNCTION_REPORT_START_TIME:
    //   return {...state, queryMalfunctionReportStartTime: action.payload}

    // case QUERY_PARAM_MALFUNCTION_REPORT_END_TIME:
    //   return {...state, queryMalfunctionReportEndTime: action.payload}

    // case QUERY_PARAM_MALFUNCTION_STAT_NAME:
    //   return {...state, queryMalfunctionStatName: action.payload}

    // case QUERY_PARAM_MALFUNCTION_REGION:
    //   return {...state, queryMalfunctionRegion: action.payload}

    // case QUERY_PARAM_MALFUNCTION_STATUS:
    //   return {...state, queryMalfunctionStatus: action.payload}

    default:
      return state;
  }
}

/** 고장 신고 조회 검색어 */
const initMalfunctionQuery = {
  queryMalfunctionMemberName: '',
  queryMalfunctionReportStartTime: '',
  queryMalfunctionReportEndTime : '',
  queryMalfunctionStatName : '',
  queryMalfunctionRegion : '',
  queryMalfunctionStatus : ''
};

const queryParamMalfunction = (state = initMalfunctionQuery, action) => {
  switch(action.type){
    case QUERY_PARAM_MALFUNCTION_MEMBER_NAME:
      return {...state, queryMalfunctionMemberName: action.payload}

    case QUERY_PARAM_MALFUNCTION_REPORT_START_TIME:
      return {...state, queryMalfunctionReportStartTime: action.payload}

    case QUERY_PARAM_MALFUNCTION_REPORT_END_TIME:
      return {...state, queryMalfunctionReportEndTime: action.payload}

    case QUERY_PARAM_MALFUNCTION_STAT_NAME:
      return {...state, queryMalfunctionStatName: action.payload}

    case QUERY_PARAM_MALFUNCTION_REGION:
      return {...state, queryMalfunctionRegion: action.payload}

    case QUERY_PARAM_MALFUNCTION_STATUS:
      return {...state, queryMalfunctionStatus: action.payload}

    default:
      return state;
  }
}


/** 충전이력 조회 검색어 */
const initUsageQuery = {
  queryUsageMemberName: '',
  queryUsageChargerStartTime: '',
  queryUsageChargerEndTime: '',
  queryUsageChargerStatName: '',

  queryUsageUserMemberName: '',
  queryUsageUserStartTime: '',
  queryUsageUserEndTime: '',
  queryUsageUserEmail:'',
  queryUsageUserNickName:''
}

const queryParamUsage = (state = initUsageQuery, action) => {
  switch(action.type){
    case QUERY_PARAM_USAGE_MEMBER_NAME:
      return {...state, queryUsageMemberName: action.payload}

    case QUERY_PARAM_USAGE_CHARGER_START_TIME:
      return {...state, queryUsageChargerStartTime: action.payload}

    case QUERY_PARAM_USAGE_CHARGER_END_TIME:
      return {...state, queryUsageChargerEndTime: action.payload}

    case QUERY_PARAM_USAGE_CHARGER_STAT_NAME:
      return {...state, queryUsageChargerStatName: action.payload}


    case QUERY_PARAM_USAGE_USER_MEMEBR_NAME:
      return {...state, queryUsageUserMemberName: action.payload}

    case QUERY_PARAM_USAGE_USER_START_TIME:
      return {...state, queryUsageUserStartTime: action.payload}

    case QUERY_PARAM_USAGE_USER_END_TIME:
      return {...state, queryUsageUserEndTime: action.payload}

    case QUERY_PARAM_USAGE_USER_EMAIL:
      return {...state, queryUsageUserEmail: action.payload}

    case QUERY_PARAM_USAGE_USER_NICKNAME:
      return {...state, queryUsageUserNickName: action.payload}

    default:
      return state;
  }
}

/** 설치 신청 조회 */
const initQueryInstalltion = {
  queryInstallationMemberName: '',
  queryInstallationApplyStartTime: '',
  queryInstallationApplyEndTime: '',
  queryInstallationApplicant: '',
  queryInstallationRegion: '',
  queryInstallationStatus: ''
}

const queryParamsInstallation = (state = initQueryInstalltion, action) => {
  switch(action.type){
    case QUERY_PARAM_INSTALLATION_MEMBER_NAME:
      return {...state, queryInstallationMemberName: action.payload}

    case QUERY_PARAM_INSTALLATION_APPLY_START_TIME:
      return {...state, queryInstallationApplyStartTime: action.payload}

    case QUERY_PARAM_INSTALLATION_APPLY_END_TIME:
      return {...state, queryInstallationApplyEndTime: action.payload}

    case QUERY_PARAM_INSTALLATION_APPLICANT:
      return {...state, queryInstallationApplicant: action.payload}

    case QUERY_PARAM_INSTALLATION_REGION:
      return {...state, queryInstallationRegion: action.payload}

    case QUERY_PARAM_INSTALLATION_STATUS:
      return {...state, queryInstallationStatus: action.payload}

    default:
      return state;
  }
}



/**
 * 회원사 조회
 */
const initMemberQuery = {
  queryMemberName: '',
  queryMemberAddr: '',
  queryMemberAdminName: '',
  queryMemberAdminTelNo: ''
}

const queryParamsMember = (state = initMemberQuery, action) => {

  switch(action.type){
    case QUERY_PARAM_MEMBER_NAME:
      return {...state, queryMemberName: action.payload}

    case QUERY_PARAM_MEMBER_ADDR:
      return {...state, queryMemberAddr: action.payload}

    case QUERY_PARAM_MEMBER_ADMIN_NAME:
      return {...state, queryMemberAdminName: action.payload}

    case QUERY_PARAM_MEMBER_ADMN_TELNO:
      return {...state, queryMemberAdminTelNo: action.payload}

    default:
      return state;
  }
}


import {
  OPEN_DIALOG,
  SELECT_STATION
} from 'actions/settings';

const initSettings = {
  openDialog: false,
  selectedStationInfo: {},
}

const settings = (state = initSettings, action) => {

  switch (action.type) {

    case OPEN_DIALOG:
      return { ...state, openDialog: action.payload }

    case SELECT_STATION:
      return { ...state, selectedStationInfo: action.payload}

    default:
      return state;
  }
}



const rootReducer = combineReducers({
  user,
  queryParams,
  queryParamMalfunction,
  queryParamUsage,
  queryParamsInstallation,
  queryParamsMember,
  settings
});

export default rootReducer;

