import React from "react";
import { makeStyles } from "@material-ui/styles";
import PropTypes from 'prop-types';
import InstallationAddForm from "./InstallationAddForm";


const InstallationAdd = (props) => {

  const {className, history, ...rest } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <InstallationAddForm history={history} />
      </div>
    </div>
  );
};


InstallationAdd.propTypes = {
  history: PropTypes.object
};

export default InstallationAdd;


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));
