import React, { useState, useRef } from "react";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Formik } from "formik";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Switch,
  TextField,
  Typography,
  makeStyles,

  Divider,
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';

import DaumPostcode from 'react-daum-postcode';


import { NEONPV_CONFIG } from "config.js";
import { UnitPriceSubRegion } from "./components";
import { USER_AUTH, amdin_type_list, getAdminTypeCode, getAdminTypeLabel,
  station_type_list, getStationTypeCode, getStationTypeLabel,
  station_detail_type_list, getStationDetailTypeCode, getStationDetailTypeLabel,
  station_operation_type_list, getStationOperationTypeCode, getStationOperationTypeLabel} from "common/constants";


const StationUpdateForm = (props) => {
  const { className, history, queryParams, ...rest } = props;
  const classes = useStyles();


  const ref = useRef(null);
  
  const userAuth = useSelector((state) => state.user.user_auth);


  const handleSubmit = (event, values) => {
    event.preventDefault();


    fetch(`${NEONPV_CONFIG.HOSTNAME}/station/updateStationInfo`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        statId: values.statId,
        statName: values.statName,
        addr: values.statAddr,
        lat: values.latitude,
        lng: values.longitude,
        useTime: values.useTime,
        businessId: values.businessId,
        businessName: values.businessName,
        businessTel: values.businessTel,
        parkingfree: values.parkingFree,
        note: values.note,


        ownerName: values.ownerName,
        adminName: values.adminName,
        adminType: getAdminTypeCode(selectedAdminType),
        stationType: getStationTypeCode(selectedStationType),
        stationDetailType: getStationDetailTypeCode(selectedStationDetailType),
        stationOperationType: getStationOperationTypeCode(selectedStationOperationType),

        postCode: values.postCode,
        addr2: values.statAddrDetail
      }),
    })
    .then((response) => {
      return response.json();
    })
    .then((responseData) => {
      if (responseData.resultCode === "000") {
        alert("업데이트에 성공했습니다.");

        setTimeout(() => {
          history.goBack();
        }, 500);
      } else {
        //에러
        alert(`업데이트에 실패했습니다.\n(${responseData.resultCode}):${responseData.resultMessage}`);
      }
    })
    .catch((error) => {
      console.error(error);
      alert("업데이트에 실패했습니다.");
    });
  };


  /** 충전소정보 삭제하기 */
  const handleRemoveStationInfo = (event) => {
    event.preventDefault();

    fetch(`${NEONPV_CONFIG.HOSTNAME}/station/removeStationInfo`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        statId: stationInfo.statId,
      }),
    })
    .then((response) => {
      return response.json();
    })
    .then((responseData) => {
      if (responseData.resultCode === "000") {
        alert("충전소정보 삭제에 성공했습니다.");
        setTimeout(() => {
          history.goBack();
        }, 500);
      } else {
        //에러
        alert(`충전소정보 삭제에 실패했습니다.\n(${responseData.resultCode}):${responseData.resultMessage}`);
      }
    })
    .catch((error) => {
      console.error(error);
      alert("충전소정보 삭제에 실패했습니다.");
    });
  }

  const handleBack = () => {
    history.goBack();
  };

  return (
    <Formik
      innerRef={ref}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          className={clsx(classes.root, className)}
          onSubmit={(e) => handleSubmit(e, values)}
        >
          <Card>
            <CardContent>
              


              <UnitPriceSubRegion/>

              {/* <Divider className={classes.divider} variant="fullWidth" /> */}

            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
};

StationUpdateForm.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object,
};


let mapStateToProps = (state) => {
  return {
    queryParams: state.queryParams,
  };
};

export default connect(mapStateToProps)(StationUpdateForm);


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  action: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },
  divider: {
    margin: theme.spacing(2, 0),
    // color: '#616161'
  },
  dividerInset: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    color: '#616161'
  },
}));
