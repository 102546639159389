// export const UPDATE_USER_EMAIL = 'UPDATE_USER_EMAIL';
// export const UPDATE_USER_NAME = 'UPDATE_USER_NAME';
// export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const LOGOUT = 'LOGOUT';
export const UPDATE_ADMIN_ID = 'UPDATE_ADMIN_ID';
// export const UPDATE_GROUP = 'UPDATE_GROUP';
export const TEMP_SET_USER = 'TEMP_SET_USER';


// export const ADMIN_ID  = 'ADMIN_ID';
export const ADMIN_NAME = 'ADMIN_NAME';
export const USER_AUTH = 'USER_AUTH';


export const tempSetUser = user => {
  return {
    type: TEMP_SET_USER,
    payload: user
  }
}

export const updateAdminId = adminId => {
  return {
    type: UPDATE_ADMIN_ID,
    payload: adminId
  }
}

export const updateAdminName = adminName => {
  return {
    type: ADMIN_NAME,
    payload: adminName
  }
}

export const updateUserAuth = user_auth => {
  return {
    type: USER_AUTH,
    payload: user_auth
  }
}



// export const updateUserEmail = userEmail => {
//   return {
//     type: UPDATE_USER_EMAIL,
//     payload: userEmail
//   }
// }

// export const updateUserName = userName => {
//   return {
//     type: UPDATE_USER_NAME,
//     payload: userName
//   }
// }

// export const updatePassword = password => {
//   return {
//     type: UPDATE_PASSWORD,
//     payload: password
//   }
// }

// export const updateGroup = group => {
//   return {
//     type: UPDATE_GROUP,
//     payload: group
//   }
// }

export const doLogout = () => {
  
  const newuser = {
    status: '',
    admin_id: '',
    admin_name: '',
    user_auth : '', // 1: 관리자, 2: 회원사
    bid: '', //환경부 BID
    bkey: '', //환경부 BKEY
    // userEmail: '',
    // password: '',
    // username: '',
    // group: ''
  }

  localStorage.removeItem('userinfo');

  return {
    type: LOGOUT,
    payload: newuser
  }
}
