import React, {useState, useEffect} from "react";
import { makeStyles } from "@material-ui/styles";
import PropTypes from 'prop-types';
import ChargerUpdateForm from "./ChargerUpdateForm";
import { NEONPV_CONFIG } from 'config.js';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));


const ChargerUpdate = (props) => {

  const {className, match, history, ...rest } = props;
  const classes = useStyles();

  const [chargerInfo, setChargerInfo] = useState(null);

  const loadData = (chargerSeqNo) => {

    var url = `${NEONPV_CONFIG.HOSTNAME}/station/queryChargerInfo?chargerSeqNo=`+chargerSeqNo;

    fetch(url)
    .then(res => res.json())
    .then(snapshot => {

      var charger = {
        chargerSeqNo: snapshot.resultData.chargerSeqNo,
        statId : snapshot.resultData.statId,
        statName : snapshot.resultData.statName,
        statAddr :  snapshot.resultData.addr,
        chargerId : snapshot.resultData.chargerId,
        powerType : snapshot.resultData.powerType,
        chargerType : snapshot.resultData.chargerType,
        // chargerStatus : snapshot.resultData.status,
        // chargerIP : snapshot.resultData.chargerIP,
        // chargerPort : snapshot.resultData.chargerPort,
        chargerMac : snapshot.resultData.chargerMac,
        chargePointStatus: snapshot.resultData.chargePointStatus,
        lastHeartbeatTime: snapshot.resultData.lastHeartbeatTime ? moment(snapshot.resultData.lastHeartbeatTime).format("YYYY-MM-DD HH:mm:ss") : "",
        chargerModel : snapshot.resultData.chargerModel,
        chargerVersion : snapshot.resultData.chargerVersion,
        memberName : snapshot.resultData.member_name,
        serialNumber: snapshot.resultData.serialNumber,
        chargerFirmwareVersion: snapshot.resultData.chargerFirmwareVersion,
        chargerLTERouterNo: snapshot.resultData.chargerLTERouterNo,
      };

      setChargerInfo(charger);
    }
    );
  }

  useEffect( () => {
    loadData(match.params.chargerSeqNo);
  }, []);


  return (
    <div className={classes.root}>
      <div className={classes.content}>
        { chargerInfo && <ChargerUpdateForm chargerInfo={chargerInfo} history={history} /> }
      </div>
    </div>
  );
};

ChargerUpdate.propTypes = {
  history: PropTypes.object
};

export default ChargerUpdate;
