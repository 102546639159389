import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Formik } from "formik";
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Divider,
  Typography
} from "@material-ui/core";

import {global} from "common/global";


const ClearCacheForm = (props) => {
  const { className, history, user, ...rest } = props;
  const classes = useStyles();


  const handleSubmit = (event, values) => {
    event.preventDefault();

    var json = `{"command":"ClearCache",
        "body": {
        }
          }`;

    global.ws.send(json);

  };



  return (

    <div>
      <Formik
        enableReinitialize={false}
        initialValues={{
          connectorId: "",
          expiryDate: new Date().toISOString(),
          idTag: "RFID1",
          parentIdTag: "PTAG",
          reservationId: "10",
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          isSubmitting,
          touched,
          values,
          setFieldValue
        }) => (
          <form
            className={clsx(classes.root, className)}
            onSubmit={(e) => handleSubmit(e, values)}
          >

            <Card>

              <CardHeader
                title="캐시 초기화"
                titleTypographyProps={{ color: 'inherit', align: 'center', variant: 'h3' }}
                // subheader="TestCase - 061"
                // subheaderTypographyProps={{ color: 'inherit', align: 'left', variant: 'h5' }}
              />


              <CardContent>

              {/* <Grid item md={12} spacing={2}>
                <Typography variant="h6">061 - PASS</Typography>
              </Grid> */}
                <div className={classes.content}></div>


                {/* <Typography variant="h5">Parameters</Typography>
                <Divider variant="inset" />

                <Grid container spacing={2} >
                  <Divider variant="fullWidth" />
                </Grid> */}


                <Grid container item
                  className={classes.action}
                  direction="row" alignItems="center" justify="center">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="large"
                    disabled={isSubmitting}
                    className={classes.action}
                  >
                    Perform
                  </Button>

                </Grid>
              </CardContent>
            </Card>
          </form>
        )}
      </Formik>
    </div>
  );
};

ClearCacheForm.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object,
};


let mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps)(ClearCacheForm);


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    direction: "row",
    alignItems: "center",
    justify: "center"
  },
  content2: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  action: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },
  queryField: {
    // width: 300
    flexBasis: 260,
    marginRight: 20,
  },
  bulkAction: {
    marginLeft: theme.spacing(2),
  },

  divider: {
    margin: theme.spacing(2, 0)
  },
}));
