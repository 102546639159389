import React, { useState, useEffect } from "react";
// import clsx from "clsx";
import { connect } from "react-redux";
import { Link as RouterLink, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import validate, { contains } from "validate.js";
import { makeStyles } from "@material-ui/styles";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import {
  Grid,
  Button,
  IconButton,
  TextField,
  // Link,
  Typography,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";

import { bindActionCreators } from "redux";
import {
  updateAdminId
} from "actions/user.js";

import { doLoginWithPW } from "common/login";

import { NEONPV_CONFIG } from 'config.js';

import md5 from 'md5';

const theme2 = createMuiTheme({
  palette: {
    primary: {
      main: "#11a8df",
      contrastText: "#FFFFFF",
      // contrastText: white,
    },
  },
  raisedButton: {
    textColor: "#ffffff", // this should work
  },
});

const schema = {
  userid: {
    presence: { allowEmpty: false, message: "is required" },
    // userid: true,
    length: {
      maximum: 20,
    },
  },
  password: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 20,
    },
  },
};

// 도메인에 따른 로고 변경
const logoImageURL = window.location.hostname == "amanocsms.kr" ? "/images/logo_amano.png" : "/images/logo.png";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.white,
    height: "100%",
  },

  wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },

  contentContainer: {},
  content: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },

  contentBody: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },

  label: {
    color: theme.palette.text_label_color,
  },

  // root_container:{
  //   width: "100%",
  // },

  //  grid: {
  //     height: "100%",
  //   },
  quoteContainer: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // backgroundImage: "url(/images/logo.png)",
    // backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },

  quoteInner: {
    textAlign: "center",
    flexBasis: "100px",
    backgroundImage: `url(${logoImageURL})`,
    backgroundRepeat: "no-repeat",
  },
  quoteText: {
    // color: theme.palette.white,
    color: "rgba(188,0,78, 1)",
    fontWeight: 400,
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white,
  },
  bio: {
    color: theme.palette.white,
  },

  contentHeader: {
    display: "flex",
    alignItems: "center",

    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  logoImage: {
    marginLeft: theme.spacing(4),
  },

  //가운데 정렬
  logo: {
    // width:"300px",
    width: "90%",
    // paddingLeft: theme.spacing(1),
    // paddingRight: theme.spacing(1),
    // alignItems: "center",
    // justifyContent: "center",
    display: "block",
    // position:"absolute",
    marginLeft: "auto",
    marginRight: "auto",
  },

  form: {
    paddingLeft: 60,
    paddingRight: 60,
    // paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  // title: {
  //   marginTop: theme.spacing(3),
  // },
  // socialButtons: {
  //   marginTop: theme.spacing(3),
  // },
  // socialIcon: {
  //   marginRight: theme.spacing(1),
  // },
  sugestion: {
    marginTop: theme.spacing(2),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  signInButton: {
    margin: theme.spacing(2, 0),
  },
}));

const SignIn = (props) => {
  const { history } = props;

  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  const [pkey, setPkey] = useState();


  const publicKeyRequest = () => {
    fetch(`${NEONPV_CONFIG.HOSTNAME}/pkey`,
    {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })
    .then(response => {
      setPkey(response.data);
      console.log(response);
      // return response.json();
    })
    .then(responseData => {
      // console.log(responseData);
      // setPkey(response.data);


      // if (responseData.resultCode === '000') {
      //   //성공
      //   alert('업데이트에 성공했습니다.');

      //   setTimeout( () => {
      //     history.goBack();
      //   }, 500);          
      // } else {
      //   //에러
      //   // console.log('로그인 기록 실패: ', responseData.resultCode);
      //   alert(`업데이트에 실패했습니다.\n${responseData.resultCode}`);
      // }
    })
    .catch(error => {
      console.error(error);
      // alert('업데이트에 실패했습니다.')
    });

  };

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));

    // publicKeyRequest();

  }, [formState.values]);

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));

    // if (event.target.name === "userid")
    //   props.updateUserEmail(event.target.value);
    // else if (event.target.name === "password")
    //   props.updatePassword(event.target.value);
  };

  const handleSignIn = (event) => {
    event.preventDefault();

    var md5_pw = md5(formState.values.password);

    // doLoginWithPW(history, formState.values.userid, formState.values.password);
    doLoginWithPW(history, formState.values.userid, md5_pw);
  };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <ThemeProvider theme={theme2}>
      <div className={classes.root}>
        <div className={classes.wrapper}>
          <Grid item lg={4} md={5} xs={6}>
            <form className={classes.form} onSubmit={handleSignIn}>
              <img className={classes.logo} src={logoImageURL} />

              <TextField
                className={classes.textField}
                error={hasError("userid")}
                fullWidth
                helperText={
                  hasError("userid") ? formState.errors.userid[0] : null
                }
                label="ID"
                name="userid"
                onChange={handleChange}
                type="text"
                value={formState.values.userid || ""}
                variant="outlined"
              />
              <TextField
                className={classes.textField}
                error={hasError("password")}
                fullWidth
                helperText={
                  hasError("password") ? formState.errors.password[0] : null
                }
                label="Password"
                name="password"
                onChange={handleChange}
                type="password"
                value={formState.values.password || ""}
                variant="outlined"
              />

              <FormControlLabel
                classes={{ label: classes.label }}
                control={
                  <Checkbox style={{transform:"scale(.8)"}} color="default" value="true" />
                }
                label={
                <Typography variant="caption" style={{color: '#1a1a1a'}}>아이디저장</Typography>
                }
              />


              <Button
                className={classes.signInButton}
                color="primary"
                disabled={!formState.isValid}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                LOGIN
              </Button>


              {/* control={<Checkbox style={{color: '#b7b7b7'}} value="true" />} */}

              {/* <FormControlLabel
                classes={{ label: classes.label }}
                control={<Checkbox color="default" value="true" />}
                label="아이디저장"
                labelColor="primary"
              /> */}
            </form>
          </Grid>
        </div>
      </div>
    </ThemeProvider>
  );
};

SignIn.propTypes = {
  history: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { updateAdminId },
    dispatch
  );
};

let mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SignIn));
