import React from "react";
import { makeStyles } from "@material-ui/styles";
import { MalfunctionList } from "./components";
import { Link as RouterLink } from "react-router-dom";

import { Button, SvgIcon } from "@material-ui/core";

import {PlusCircle as PlusCircleIcon } from "react-feather";


const Malfunctions = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <span className={classes.spacer} />
        <Button
          color="primary"
          variant="contained"
          size="large"
          component={RouterLink} to={`/malfunctions/add`}
        >
          <SvgIcon fontSize="small" className={classes.actionIcon}>
            <PlusCircleIcon />
          </SvgIcon>
          고장 신고
        </Button>
      </div>

      <div className={classes.content}>
        <MalfunctionList />
      </div>
    </div>
  );
};

export default Malfunctions;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  actionIcon: {
    marginRight: theme.spacing(1)
  }
}));
