import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Formik } from "formik";
import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,

  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,

  FormControlLabel,
  Checkbox,
} from "@material-ui/core";

import { NEONPV_CONFIG } from "config.js";
import DaumPostcode from 'react-daum-postcode';
import md5 from 'md5';




const MemberAddForm = (props) => {
  const { className, history, ...rest } = props;
  const classes = useStyles();

  const ref = useRef(null);
  const [isOpenPost, setIsOpenPost] = useState(false);

  const onChangeOpenPost = () => {
    setIsOpenPost(!isOpenPost);
  };


  const onCompletePost = (data) => {
    console.log(data);
    let fullAddr = data.address; //도로명 주소
    let extraAddr = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddr += data.bname; //(구) 동 이름
      }
      if (data.buildingName !== '') {
        extraAddr += extraAddr !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      // fullAddr += extraAddr !== '' ? ` (${extraAddr})` : '';
      extraAddr = extraAddr !== '' ? ` (${extraAddr})` : '';
    }

    ref.current.values.memberPostCode = data.zonecode;
    ref.current.values.memberAddr = data.address;
    ref.current.values.memberAddrDetail = extraAddr;

    setIsOpenPost(false);
  };


  const [openDialog, setOpenDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogMessage, setDialogMessage] = useState('');

  const validate = (values) => {
    let errors = {};

    //비밀번호 일치 여부 check
    if (values.memberPassword !== values.memberPasswordConfirm) {
      errors.memberPasswordConfirm = "비밀번호가 일치하지 않습니다.";
    }

    return errors;
  }


  const handleSubmit = async (event, values) => {
    event.preventDefault();


    if ( values.memberPassword !== values.memberPasswordConfirm ) {
      alert("비밀번호가 일치하지 않습니다.");
      return;
    }


    var url = `${NEONPV_CONFIG.HOSTNAME}/member/queryMemberInfo?memberId=` + values.memberId;
    fetch(url)
      .then(res => res.json())
      .then(snapshot => {

        if (snapshot.resultCode == "000") {
          // alert('동일한 회원사ID가 존재합니다.');
          setDialogTitle('주의');
          setDialogMessage('동일한 회원사ID가 존재합니다.');
          setOpenDialog(true);
        } else {

          fetch(`${NEONPV_CONFIG.HOSTNAME}/member/createMemberInfo`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              member_id: values.memberId,
              member_name: values.memberName,
              member_telno: values.memberTelNo,
              member_password: md5(values.memberPassword),
              member_postcode: values.memberPostCode,
              member_addr1: values.memberAddr,
              member_addr2: values.memberAddrDetail,
              member_admin_name: values.adminName,
              member_admin_telno: values.adminTelNo,
              member_admin_email: values.adminEmail,
              member_note: values.note,
              member_bid: values.member_bid,
              member_bkey: values.member_bkey,

              member_env_integrated: values.member_env_integrated === true ? '1' : '0',
            }),
          })
            .then((response) => {
              return response.json();
            })
            .then((responseData) => {
              if (responseData.resultCode === "000") {
                alert("회원사 추가에 성공했습니다.");

                // setDialogTitle('성공');
                // setDialogMessage('회원사 추가에 성공했습니다.');
                // setOpenDialog(true);

                setTimeout(() => {
                  history.goBack();
                }, 500);
              } else {
                console.log(responseData);
                //에러
                // alert(`업데이트에 실패했습니다.\nError: ${responseData.resultMessage}`);
                setDialogTitle('주의');
                setDialogMessage(`회원사 추가에 실패했습니다.\nError: ${responseData.resultMessage}`);
                setOpenDialog(true);
              }
            })
            .catch((error) => {
              console.error(error);
              // alert("업데이트에 실패했습니다.");
              setDialogTitle('주의');
              setDialogMessage(`회원사 추가에 실패했습니다.\nError: ${error}`);
              setOpenDialog(true);
            });
        }
      });
  };

  const handleBack = () => {
    history.goBack();
  };


  return (
    <div>
      <Dialog open={openDialog} onClose={() => { setOpenDialog(false) }}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{dialogMessage}</DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" color="secondary" onClick={() => { setOpenDialog(false) }}>닫기</Button>
        </DialogActions>
      </Dialog>


      <Formik
        innerRef={ref}
        enableReinitialize={false}
        initialValues={{
          memberId: "",
          memberName: "",
          memberTelNo: "",

          memberPassword: '',
          memberPasswordConfirm: '',

          memberPostCode: "",
          memberAddr: "",
          memberAddrDetail: "",

          adminName: "",
          adminTelNo: "",
          adminEmail: "",

          note: "",

          member_bid: '',
          member_bkey : "",

          member_env_integrated : true
        }}
        validate={validate}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          isSubmitting,
          touched,
          values,
        }) => (
          <form
            className={clsx(classes.root, className)}
            onSubmit={(e) => handleSubmit(e, values)}
          >
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item md={2} xs={4}>
                    <TextField
                      error={Boolean(touched.memberId && errors.memberId)}
                      fullWidth
                      helperText={touched.memberId && errors.memberId}
                      label="회원사ID"
                      name="memberId"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      required
                      value={values.memberId}
                      variant="outlined"
                      inputProps={{ maxLength: 12 }}
                    />
                  </Grid>

                  <Grid item md={3} xs={6}>
                    <TextField
                      error={Boolean(touched.memberName && errors.memberName)}
                      fullWidth
                      helperText={touched.memberName && errors.memberName}
                      label="회원사이름"
                      name="memberName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      required
                      value={values.memberName}
                      variant="outlined"
                      inputProps={{ maxLength: 20 }}
                    />
                  </Grid>

                  <Grid item md={3} xs={6}>
                    <TextField
                      error={Boolean(touched.memberTelNo && errors.memberTelNo)}
                      fullWidth
                      helperText={touched.memberTelNo && errors.memberTelNo}
                      label="회원사 전화번호"
                      name="memberTelNo"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      required
                      value={values.memberTelNo}
                      variant="outlined"
                      inputProps={{ maxLength: 20 }}
                    />
                  </Grid>

                  <Grid item md={4} xs={8}></Grid>


                  <Grid container item md={2} xs={4} direction="row" alignItems="center">
                    <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.member_env_integrated}
                            onChange={handleChange}
                            name="member_env_integrated"
                            color="primary"
                          />
                          }
                        label="환경부 연동"
                      />
                  </Grid>


                  <Grid item md={3} xs={6}>
                    <TextField
                      error={Boolean(touched.member_bid && errors.member_bid)}
                      fullWidth
                      helperText={touched.member_bid && errors.member_bid}
                      label="환경부기관ID"
                      name="member_bid"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.member_bid}
                      variant="outlined"
                      inputProps={{ maxLength: 2 }}
                      disabled={values.member_env_integrated === false}
                    />
                  </Grid>

                  <Grid item md={3} xs={6}>
                    <TextField
                      error={Boolean(touched.member_bkey && errors.member_bkey)}
                      fullWidth
                      helperText={touched.member_bkey && errors.member_bkey}
                      label="환경부기관인증키"
                      name="member_bkey"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.member_bkey}
                      variant="outlined"
                      inputProps={{ maxLength: 16 }}
                      disabled={values.member_env_integrated === false}
                    />
                  </Grid>

                  <Grid item md={4} xs={8}></Grid>



                  <Grid item md={3} xs={6}>
                  <TextField
                      error={Boolean(touched.memberPassword && errors.memberPassword)}
                      fullWidth
                      helperText={touched.memberPassword && errors.memberPassword}
                      label="새 비밀번호"
                      type="password"
                      name="memberPassword"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.memberPassword}
                      variant="outlined"
                      inputProps={{ maxLength: 12 }}
                      required
                    />
                  </Grid>

                  <Grid item md={3} xs={6}>
                    <TextField
                        error={Boolean(touched.memberPasswordConfirm && errors.memberPasswordConfirm)}
                        fullWidth
                        helperText={touched.memberPasswordConfirm && errors.memberPasswordConfirm}
                        label="새 비밀번호 확인"
                        type="password"
                        name="memberPasswordConfirm"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.memberPasswordConfirm}
                        variant="outlined"
                        inputProps={{ maxLength: 12 }}
                        required
                      />
                  </Grid>

                  <Grid item md={6} xs={12}></Grid>



                  <Grid container item md={1} xs={2}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      size="large"
                      fullWidth
                      // onClick={showChargerSearchDialog}
                      onClick={onChangeOpenPost}
                    >검색</Button>
                  </Grid>

                  {isOpenPost && <DaumPostcode autoClose onComplete={onCompletePost} className={classes.postCodeStyle} />}

                  <Grid item md={1} xs={2}>
                    <TextField
                      error={Boolean(touched.memberPostCode && errors.memberPostCode)}
                      fullWidth
                      helperText={touched.memberPostCode && errors.memberPostCode}
                      label="우편번호"
                      name="memberPostCode"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      // required
                      value={values.memberPostCode}
                      variant="outlined"
                      inputProps={{ maxLength: 8 }}
                    />
                  </Grid>

                  <Grid item md={3} xs={8}>
                    <TextField
                      error={Boolean(touched.memberAddr && errors.memberAddr)}
                      fullWidth
                      helperText={touched.memberAddr && errors.memberAddr}
                      label="주소"
                      name="memberAddr"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.memberAddr}
                      variant="outlined"
                      inputProps={{ maxLength: 60 }}
                    />
                  </Grid>

                  <Grid item md={3} xs={4}>
                    <TextField
                      error={Boolean(touched.memberAddrDetail && errors.memberAddrDetail)}
                      fullWidth
                      helperText={touched.memberAddrDetail && errors.memberAddrDetail}
                      label="상세주소"
                      name="memberAddrDetail"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      // required
                      value={values.memberAddrDetail}
                      variant="outlined"
                      inputProps={{ maxLength: 60 }}
                    />
                  </Grid>

                  <Grid item md={4} xs={4}></Grid>

                  <Grid item md={2} xs={4}>
                    <TextField
                      error={Boolean(touched.adminName && errors.adminName)}
                      fullWidth
                      helperText={touched.adminName && errors.adminName}
                      label="관리자 이름"
                      name="adminName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.adminName}
                      variant="outlined"
                      inputProps={{ maxLength: 20 }}
                    />
                  </Grid>

                  <Grid item md={3} xs={4}>
                    <TextField
                      error={Boolean(touched.adminTelNo && errors.adminTelNo)}
                      fullWidth
                      helperText={touched.adminTelNo && errors.adminTelNo}
                      label="관리자 전화번호"
                      name="adminTelNo"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.adminTelNo}
                      variant="outlined"
                      inputProps={{ maxLength: 20 }}
                    />
                  </Grid>

                  <Grid item md={3} xs={4}>
                    <TextField
                      error={Boolean(touched.adminEmail && errors.adminEmail)}
                      fullWidth
                      helperText={touched.adminEmail && errors.adminEmail}
                      label="관리자 이메일"
                      name="adminEmail"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.adminEmail}
                      variant="outlined"
                      inputProps={{ maxLength: 40 }}
                    />
                  </Grid>
                  <Grid item md={4} xs={4}></Grid>

                  <Grid item md={8} xs={12}>
                    <TextField
                      error={Boolean(touched.note && errors.note)}
                      fullWidth
                      helperText={touched.note && errors.note}
                      label="비고"
                      name="note"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.note}
                      variant="outlined"
                      inputProps={{ maxLength: 100 }}
                    />
                  </Grid>
                </Grid>


                <Grid container item className={classes.action}
                  direction="row" alignItems="center" justify="center">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="large"
                    disabled={isSubmitting}
                    className={classes.action}
                  >
                    회원사 추가
                  </Button>

                  <Button
                    variant="outlined"
                    size="large"
                    onClick={handleBack}
                    className={classes.action}
                  >
                    취소
                  </Button>
                </Grid>
              </CardContent>
            </Card>
          </form>
        )}
      </Formik>
    </div>
  );
};

MemberAddForm.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object,
};


export default MemberAddForm;


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  action: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },
  dividerInset: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    color: '#616161'
  },
  postCodeStyle: {
    display: 'block',
    position: 'relative',
    top: '0%',
    width: '400px',
    // height: '400px',
    // padding: '7px',
  },
}));
