import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Formik } from "formik";
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Divider,
  Typography,
} from "@material-ui/core";
import {global} from "common/global";


const SignedUpdateFirmwareForm = (props) => {
  const { className, history, user, ...rest } = props;
  const classes = useStyles();



  const handleSubmit = (event, values) => {
    event.preventDefault();

    var json =
      `{"command":"SignedUpdateFirmware",
        "body": {
          "requestId": ${values.requestId},
          "retries": ${values.retries},
          "retryInterval": ${values.retryInterval},

          "firmware": {
            "location": "${values.location}",
            "retrieveDateTime": "${values.retrieveDateTime}",
            "installDateTime": "${values.installDateTime}",
            "signingCertificate": "signingCertificate",
            "signature": "signature"
          }
        }
      } `;

    global.ws.send(json);


  };

  const handleBack = () => {
    history.goBack();
  };


  return (

    <div>
      <Formik
        enableReinitialize={false}
        initialValues={{
          requestId: 1,
          retries: 1,
          retryInterval: 1,
          location: 'https://bighavesolution.com/pdf/aa.pdf',
          retrieveDateTime: new Date().toISOString(),
          installDateTime: new Date().toISOString(),
          signingCertificate: 'signingCertificate',
          signature: 'signature',
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          isSubmitting,
          touched,
          values,
          setFieldValue
        }) => (
          <form
            className={clsx(classes.root, className)}
            onSubmit={(e) => handleSubmit(e, values)}
          >

            <Card>
              <CardHeader
                title="Signed Update Firmware"
                titleTypographyProps={{ color: 'inherit', align: 'center', variant: 'h3' }}
                subheader="TestCase - 080"
                subheaderTypographyProps={{ color: 'inherit', align: 'left', variant: 'h5' }}
              />

              <CardContent>

                <Grid item md={12} spacing={2}>
                  <Typography variant="h6">080 - PASS</Typography>
                </Grid>

                <div className={classes.content}></div>

                <Grid item md={12} spacing={0}>
                  <Typography variant="h4">Parameters</Typography>
                </Grid>

                <Divider variant="inset" />


                <Grid container item spacing={2} >

                  <Grid container item md={12} spacing={0}>
                  </Grid>

                  <Grid item md={2} xs={4}>
                    <TextField
                      error={Boolean(touched.requestId && errors.requestId)}
                      fullWidth
                      helperText={touched.requestId && errors.requestId}
                      label="requestId"
                      name="requestId"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.requestId}
                      variant="outlined"
                      required
                    />
                  </Grid>


                  <Grid item md={2} xs={4}>
                    <TextField
                      error={Boolean(touched.retries && errors.retries)}
                      fullWidth
                      helperText={touched.retries && errors.retries}
                      label="retries"
                      name="retries"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.retries}
                      variant="outlined"
                      required
                    />
                  </Grid>

                  <Grid item md={2} xs={4}>
                    <TextField
                      error={Boolean(touched.retryInterval && errors.retryInterval)}
                      fullWidth
                      helperText={touched.retryInterval && errors.retryInterval}
                      label="retryInterval"
                      name="retryInterval"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.retryInterval}
                      variant="outlined"
                      required
                    />
                  </Grid>


                  <Grid item md={6} xs={8}>
                    <TextField
                      error={Boolean(touched.location && errors.location)}
                      fullWidth
                      helperText={touched.location && errors.location}
                      label="location"
                      name="location"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.location}
                      variant="outlined"
                      required
                    />
                  </Grid>


                  <Grid item md={4} xs={6}>
                    <TextField
                      error={Boolean(touched.retrieveDateTime && errors.retrieveDateTime)}
                      fullWidth
                      helperText={touched.retrieveDateTime && errors.retrieveDateTime}
                      label="retrieveDateTime"
                      name="retrieveDateTime"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.retrieveDateTime}
                      variant="outlined"
                      required
                    />
                  </Grid>

                  <Grid item md={3} xs={6}>
                    <TextField
                      error={Boolean(touched.installDateTime && errors.installDateTime)}
                      fullWidth
                      helperText={touched.installDateTime && errors.installDateTime}
                      label="installDateTime"
                      name="installDateTime"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.installDateTime}
                      variant="outlined"
                      required
                    />
                  </Grid>


                  <Grid item md={2} xs={4}>
                    <TextField
                      error={Boolean(touched.signingCertificate && errors.signingCertificate)}
                      fullWidth
                      helperText={touched.signingCertificate && errors.signingCertificate}
                      label="signingCertificate"
                      name="signingCertificate"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.signingCertificate}
                      variant="outlined"
                      required
                    />
                  </Grid>

                  <Grid item md={2} xs={4}>
                    <TextField
                      error={Boolean(touched.signature && errors.signature)}
                      fullWidth
                      helperText={touched.signature && errors.signature}
                      label="signature"
                      name="signature"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.signature}
                      variant="outlined"
                      required
                    />
                  </Grid>

               </Grid>


                <Grid container item
                  className={classes.action}
                  direction="row" alignItems="center" justify="center">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="large"
                    disabled={isSubmitting}
                    className={classes.action}
                  >
                    Perform
                  </Button>

                  <Button
                    variant="outlined"
                    size="large"
                    onClick={handleBack}
                    className={classes.action}
                  >
                    취소
                  </Button>
                </Grid>
              </CardContent>
            </Card>
          </form>
        )}
      </Formik>
    </div>
  );
};

SignedUpdateFirmwareForm.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object,
};


let mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps)(SignedUpdateFirmwareForm);


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    direction: "row",
    alignItems: "center",
    justify: "center"
  },
  content2: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  action: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },
  queryField: {
    // width: 300
    flexBasis: 260,
    marginRight: 20,
  },
  bulkAction: {
    marginLeft: theme.spacing(2),
  },

  divider: {
    margin: theme.spacing(2, 0)
  },
}));
