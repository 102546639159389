import React, { useState, useEffect } from 'react';
import clsx from "clsx";
import PropTypes from 'prop-types';
import {
  Card,
  Typography,
  makeStyles,
  CardContent,
  Grid
} from '@material-ui/core';

import CountUp from 'react-countup';
import firebase from '../../Firebase';
import 'firebase/database';
import { connect, useSelector } from 'react-redux';


const SlowCharger = props => {
  const { className, user, ...rest } = props;

  const admin_id = useSelector((state) => state.user.admin_id);

  const classes = useStyles();

  const [chargerCount, setChargerCount] = useState(0);
  const [chargerDiff, setChargerDiff] = useState(0);


  useEffect(() => {

    firebase.database().ref('/dashboard/' + admin_id + '/chargerCount/currentValue/slowcharger').on('value', function (snapshot) {
      snapshot.forEach(function (childSnapshot) {

        var childKey = childSnapshot.key;
        var childData = childSnapshot.val();

        if (childKey === "cur_value") {
          setChargerCount(childData);
        }
        else if (childKey === "diff_value") {
          setChargerDiff(childData);
        }

      });
    });

  }, [admin_id]);

  return (
    <Card className={clsx(classes.root, className)} >

      <CardContent>
        <Grid container justifyContent="space-between" spacing={0}>
          <Grid item>
            <Typography className={classes.title} color="textSecondary" gutterBottom variant="body1">
              완속충전기
            </Typography>

            <Typography variant="h4" >
              <CountUp end={chargerCount} duration={0.5} separator="," decimals={0} />대
            </Typography>

          </Grid>
        </Grid>

        <div className={classes.difference}>
          <Typography className={classes.differenceValue} variant="body1">
            {chargerDiff >= 0 ? (<span className={classes.green}><strong>▲</strong>{chargerDiff}</span>)
              : (<span className={classes.red}><strong>▼</strong>{chargerDiff}</span>)}
          </Typography>
        </div>
      </CardContent>

    </Card>
  );
}


SlowCharger.propTypes = {
  className: PropTypes.string
};

let mapStateToProps = (state) => {
  return {
    user: state.user
  };
}

export default connect(mapStateToProps)(SlowCharger);



const useStyles = makeStyles((theme) => ({

  root: {
  },
  title: {
    fontWeight: 500,
    marginBottom: theme.spacing(3),
  },
  differenceValue: {
    marginRight: theme.spacing(1),
  },
  difference: {
    display: 'flex',
    alignItems: 'center',
  },
  green: {
    color: theme.palette.success.light,
    fontWeight: 600,
  },
  red: {
    color: theme.palette.error.light,
    // color: colors.red[400],
    fontWeight: 600,
  }
}));
