import React, {useState, useEffect} from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { makeStyles } from "@material-ui/styles";
import PropTypes from 'prop-types';
import CastproUnitPriceUpdateForm from "./CastproUnitPriceUpdateForm";
import { NEONPV_CONFIG } from 'config.js';

import {
  Grid,
} from '@material-ui/core';

const CastproUnitPriceUpdate = (props) => {

  const {className, match, user, history, ...rest } = props;
  const classes = useStyles();

  const [unitPriceInfo, setUnitPriceInfo] = useState(null);

  const [clientPriceVersionNo, setClientPriceVersionNo] = useState(0);


  const createDefaultValue = () => {
    console.log('디폴트값으로 신규 생성');

    var listData = [];

    for (var i = 0; i < 24; i++) {
      var stime = i.toString().padStart(2, '0');
      var etime = (i + 1).toString().padStart(2, '0');

      var row = {
        member_id: user.admin_id,
        // startTime: '00',
        // endTime: '01',
        startTime: stime,
        endTime: etime,
        lowPress1: '69.10',
        lowPress2: '56.30',
        lowPress3: '63.30',
        lowPress4: '145.20',
        highPress1: '63.0',
        highPress2: '51.4',
        highPress3: '57.70',
        highPress4: '110.60',
      };

      listData.push(row);
    }


    listData = listData.sort();


    fetch(`${NEONPV_CONFIG.HOSTNAME}/unitprice/createKepcoUnitPrice`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        newUnitPriceVersionNo: 1,
        list: listData,
      }),
    })
    .then((response) => {
      return response.json();
    })
    .then((responseData) => {
      if (responseData.resultCode === "000") {
        // alert("업데이트에 성공했습니다.");
        alert('디폴트값으로 신규 생성했습니다.');

        setTimeout(() => {
          loadData();
        }, 500);
      } else {
        //에러
        alert(`디폴트값으로 신규 생성에 실패했습니다.\n${responseData.resultCode}`);
      }
    })
    .catch((error) => {
      console.error(error);
      alert("디폴트값으로 신규 생성에 실패했습니다.");
    });
  }


  const loadData = () => {

    var list = [];

    var url = `${NEONPV_CONFIG.HOSTNAME}/unitprice/queryCastproUnitPrice?member_id=${user.admin_id}`;

    fetch(url)
    .then(res => res.json())
    .then(snapshot => {

      // console.log(snapshot.resultData);

      if (snapshot.resultData){
        snapshot.resultData.forEach(element => {

          list.push({
            id: element.seqNo,
            startTime: element.startTime,
            endTime: element.endTime,
            time : element.startTime + ' ~ ' + element.endTime + '시',
            defaultprice: element.castproDefaultPrice,

            otherPrice: element.otherPrice,
            envPrice: element.envPrice,
            roamingPrice: element.roamingPrice,

            lowPrice1: element.lowPrice1,
            lowPrice2: element.lowPrice2,
            lowPrice3: element.lowPrice3,
            lowPrice4: element.lowPrice4,
            highPrice1: element.highPrice1,
            highPrice2: element.highPrice2,
            highPrice3: element.highPrice3,
            highPrice4: element.highPrice4,
          });

          if (clientPriceVersionNo < element.unitPriceVersionNo){
            setClientPriceVersionNo(  element.unitPriceVersionNo );
          }

        });

        // console.log("list: ", list);
        setUnitPriceInfo(list);
      }else{
        createDefaultValue();
      }
    });
  }


  useEffect( () => {
    loadData();
  }, []);


  return (
    <div className={classes.root} >
      <div className={classes.content}>
        <Grid container spacing={2}>
          <Grid item lg={10} md={10} sm={12}>
          { unitPriceInfo && <CastproUnitPriceUpdateForm clientPriceVersionNo={clientPriceVersionNo} unitPriceInfo={unitPriceInfo} history={history} /> }
          </Grid>
        </Grid>
      </div>
    </div>
  );
};


CastproUnitPriceUpdate.propTypes = {
  history: PropTypes.object
};

let mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps)(withRouter(CastproUnitPriceUpdate));


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));