import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Formik } from "formik";
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Divider,
  Typography,

  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,

} from "@material-ui/core";

import Autocomplete from '@material-ui/lab/Autocomplete';
import {global} from "common/global";


// const keyvalue = [
//   { index: 0, key: '', testcase: '' },
//   { index: 1, key: '', testcase: '002, 019_1' },
//   { index: 2, key: 'AuthorizeRemoteTxRequests', testcase: '015' },
//   { index: 3, key: 'SupportedFeatureProfiles',  testcase: '019_2' },
// ];


const GetConfigurationForm = (props) => {
  const { className, history, user, ...rest } = props;
  const classes = useStyles();

  const [selected, setSelected] = useState('');


  const configuration_list = ['AuthorizeRemoteTxRequests', 'SupportedFeatureProfiles'];
  const [selectedKey, setSelectedKey] = useState('');



  const handleClick = (event, index) => {
    setSelected(index);
  }

  const handleSubmit = (event, values) => {
    event.preventDefault();


    var json = '';

    // if (selected === 0){
    //   json =
    //   `{"command":"GetConfiguration",
    //     "body": {
    //       "key": [ "${values.inputedKey}" ]
    //     }
    //   }`;
    // } else if (selected === 1){
    //   json =
    //   `{"command":"GetConfiguration",
    //     "body": "{}"
    //   }`;

    // } else {
    //   json =
    //   `{"command":"GetConfiguration",
    //     "body": {
    //       "key": [ "${keyvalue[selected].key}" ]
    //     }
    //   }`;

    // }


    if (selectedKey === ''){
        json =
      `{"command":"GetConfiguration",
        "body": "{}"
      }`;
    } else {
      json =
      `{"command":"GetConfiguration",
        "body": {
          "key": [ "${selectedKey}" ]
        }
      }`;
    }


    console.log(selectedKey);

    global.ws.send(json);
  };



  const handleClear = () => {
    setSelectedKey('');
  }

  return (

    <div>
      <Formik
        enableReinitialize={false}
        initialValues={{
          inputedKey: '',
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          isSubmitting,
          touched,
          values,
          setFieldValue
        }) => (
          <form
            className={clsx(classes.root, className)}
            onSubmit={(e) => handleSubmit(e, values)}
          >

            <Card>
              <CardHeader
                title="설정 조회"
                titleTypographyProps={{ color: 'inherit', align: 'center', variant: 'h3' }}
                // subheader="TestCase - 002, 015, 019_1, 019_2"
                // subheaderTypographyProps={{ color: 'inherit', align: 'left', variant: 'h5' }}
              />

              <CardContent>

                {/* <Grid item md={12} spacing={2}>
                  <Typography variant="h6">002(중간실행) - PASS</Typography>
                  <Typography variant="h6">015 - PASS</Typography>
                  <Typography variant="h6">019_1 - PASS</Typography>
                  <Typography variant="h6">019_2 - PASS</Typography>
                </Grid> */}

                <div className={classes.content}></div>

                <Grid item md={12} spacing={0}>
                  <Typography variant="h4">파라미터</Typography>
                </Grid>

                <Divider variant="inset" />


                <Grid container item spacing={2} >
                  <Grid container item md={12} spacing={0}>
                  </Grid>


                  <Grid container item md={12} spacing={0}
                  direction="row" alignItems="center" justify="start"
                  >
                    <Grid item md={4} xs={8}>
                      <Autocomplete
                        freeSolo
                        autoSelect
                        id="configuration_list"
                        options={configuration_list}
                        classes={{ option: classes.options, }}
                        autoHighlight
                        getOptionSelected={(option, value) => option === value}
                        value={selectedKey}
                        renderOption={(option) => (
                          <React.Fragment>
                            {option}
                          </React.Fragment>
                        )}

                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Key"
                            variant="outlined"
                            fullWidth
                            inputProps={{
                              ...params.inputProps,
                            }}
                          />
                        )}

                        onChange={(event, newValue) => {
                          if (newValue) {
                            setSelectedKey(newValue);
                          }
                        }}
                      />
                    </Grid>

                    <Grid item md={8} xs={12}>
                      <Button
                        variant="outlined"
                        size="large"
                        onClick={handleClear}
                        className={classes.action2}
                      >
                        지우기
                      </Button>
                    </Grid>
                  </Grid>



                  {/* <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>check</TableCell>
                        <TableCell>key</TableCell>
                        <TableCell>TESTCASE</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>

                      <TableRow onClick={(event) => handleClick(event, 0)}>
                        <TableCell>
                          <Checkbox color="primary" checked={selected === 0 ? true : false}/>
                        </TableCell>
                        <TableCell>
                          <TextField
                            error={Boolean(touched.inputedKey && errors.inputedKey)}
                            fullWidth
                            helperText={touched.inputedKey && errors.inputedKey}
                            label="key"
                            name="inputedKey"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.inputedKey}
                            variant="outlined"
                          />
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>


                      {keyvalue.slice(1, keyvalue.length).map((row, index) => {
                        return (
                          <TableRow onClick={(event) => handleClick(event, row.index)}>
                            <TableCell>
                              <Checkbox color="primary"
                                // checked={isItemSelected}
                                checked={selected === index + 1 ? true : false}
                              />
                            </TableCell>
                            <TableCell>{row.key}</TableCell>
                            <TableCell>{row.testcase}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table> */}

                </Grid>


                <Grid container item
                  className={classes.action}
                  direction="row" alignItems="center" justify="center">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="large"
                    disabled={isSubmitting}
                    className={classes.action}
                  >
                    Perform
                  </Button>

                </Grid>
              </CardContent>
            </Card>
          </form>
        )}
      </Formik>
    </div>
  );
};

GetConfigurationForm.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object,
};


let mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps)(GetConfigurationForm);


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    direction: "row",
    alignItems: "center",
    justify: "center"
  },
  content2: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  action: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },

  action2: {
     marginLeft: theme.spacing(2),
     marginRight: theme.spacing(2),
  },

  queryField: {
    // width: 300
    flexBasis: 260,
    marginRight: 20,
  },
  bulkAction: {
    marginLeft: theme.spacing(2),
  },

  divider: {
    margin: theme.spacing(2, 0)
  },
}));
