import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link as RouterLink, withRouter } from "react-router-dom";
import { makeStyles } from '@material-ui/styles';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  InputAdornment,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  TablePagination,
  TableSortLabel,

  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,

  Grid
} from '@material-ui/core';


import {CSVLink} from "react-csv";

import {
  Search as SearchIcon,
} from 'react-feather';

import { NEONPV_CONFIG } from 'config.js';

import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  updateStationMemberName,
  updateQueryStationId,
  updateQueryStationName,
  updateQueryStationAddr,
  updateStationAdminName,
} from 'actions/query';

import Autocomplete from '@material-ui/lab/Autocomplete';


import {
  amdin_type_list, getAdminTypeCode, getAdminTypeLabel,
  station_type_list, getStationTypeCode, getStationTypeLabel,
  station_detail_type_list, getStationDetailTypeCode, getStationDetailTypeLabel,
  station_operation_type_list, getStationOperationTypeCode, getStationOperationTypeLabel,
  USER_AUTH,
} from "common/constants";



const COLUMN_WIDTH = {
  NO: '5%',
  STATION_ID: '5%',
  STATION_NAME: '15%', //25
  STATION_ADDR: '14%', //40
  STATION_ADDR_DETAIL: '8%', //48
  STATION_TYPE: '6%', //54
  DETAIL_TYPE: '8%', //62
  OPERATION_TYPE: '8%', //70
  ADMIN_TYPE: '9%', //80
  ADMIN: '7%', //87
  TELNO: '8%', //95
  MEMBER_NAME: '8%', //105
}


function descendingComparator(a, b, orderBy) {

  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}


function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}


function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}



const headCells = [
  {
    id: 'no',
    numeric: false,
    disablePadding: true,
    width:COLUMN_WIDTH.NO,
    label: 'No',
  },
  {
    id: 'statId',
    numeric: false,
    disablePadding: false,
    width:COLUMN_WIDTH.STATION_ID,
    label: 'ID',
  },
  {
    id: 'statName',
    numeric: false,
    disablePadding: false,
    width:COLUMN_WIDTH.STATION_NAME,
    label: '충전소',
  },
  {
    id: 'addr',
    numeric: false,
    disablePadding: false,
    width:COLUMN_WIDTH.STATION_ADDR,
    label: '주소',
  },
  {
    id: 'addr2',
    numeric: false,
    disablePadding: false,
    width:COLUMN_WIDTH.STATION_ADDR_DETAIL,
    label: '상세주소',
  },
  {
    id: 'stationType',
    numeric: false,
    disablePadding: true,
    width:COLUMN_WIDTH.STATION_TYPE,
    label: '유형',
  },
  {
    id: 'stationDetailType',
    numeric: false,
    disablePadding: false,
    width:COLUMN_WIDTH.DETAIL_TYPE,
    label: '상세유형',
  },
  {
    id: 'stationOperationType',
    numeric: false,
    disablePadding: true,
    width:COLUMN_WIDTH.OPERATION_TYPE,
    label: '운영형태',
  },
  {
    id: 'adminType',
    numeric: false,
    disablePadding: false,
    width:COLUMN_WIDTH.ADMIN_TYPE,
    label: '관리자유형',
  },
  {
    id: 'adminName',
    numeric: false,
    disablePadding: false,
    width:COLUMN_WIDTH.ADMIN,
    label: '관리자',
  },
  {
    id: 'businessTel',
    numeric: true,
    disablePadding: false,
    width:COLUMN_WIDTH.TELNO,
    label: '전화번호',
  },
  {
    id: 'member_name',
    numeric: false,
    disablePadding: false,
    width:COLUMN_WIDTH.MEMBER_NAME,
    label: '회원사',
  },
];


function EnhancedTableHead(props) {
  const { order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            // align={headCell.numeric ? 'right' : 'center'}
            align='center'
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            width={headCell.width}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {/* {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null} */}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};



const StationTable = props => {
  const { className, user, queryParams, ...rest } = props;

  const classes = useStyles();

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('no');


  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [listData, setListData] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");

  const [memberName, setMemberName] = useState("");
  const [queryStatId, setQueryStatId] = useState('');
  const [queryName, setQueryName] = useState('');
  const [queryAddr, setQueryAddr] = useState('');
  const [queryAdminName, setQueryAdminName] = useState('');
  const [selectedAdminType, setSelectedAdminType] = useState('');
  const [selectedStationType, setSelectedStationType] = useState('');
  const [selectedStationDetailType, setSelectedStationDetailType] = useState('');
  const [selectedStationOperationType, setSelectedStationOperationType] = useState('');

  const userAuth = useSelector((state) => state.user.user_auth);


  const searchData = (autoload = false, memberName, statId, statName, addr, adminName,
      adminType, stationType, stationDetailType, operationType) => {

    var url = `${NEONPV_CONFIG.HOSTNAME}/station/queryStationList?`;

    var params = '';
    
    // 콜센터 계정이라면
    if(user.admin_id == 'callcenter'){
      params += "memberId=castpro&";
    }
    //회원사 계정이라면
    else if (userAuth === USER_AUTH.MEMBER ) {
      params += "memberId=" + user.admin_id + "&";
    } 
    // 서브계정
    else if (userAuth === USER_AUTH.SUB_MEMBER){
      params += "memberId=" + user.admin_id + "&";
    }
    //관리자 계정이라면
    else if (memberName) {
      params += "memberName=" + memberName + "&";
    }
    

    if (statId){
      params += 'statId=' + statId + '&';
    }

    if (statName) {
      params += 'statName=' + statName + '&';
    }

    if (addr) {
      params += 'addr=' + addr + '&';
    }

    if (adminName) {
      params += 'adminName=' + adminName + '&';
    }

    if (adminType && adminType !== '-') {
      params += 'adminType=' + getAdminTypeCode(adminType);
    }

    if (stationType && stationType !== '-') {
      params += 'stationType=' + getStationTypeCode(stationType) + '&';
    }

    if (stationDetailType && stationDetailType !== '-') {
      params += 'stationDetailType=' + getStationDetailTypeCode(stationDetailType) + '&';
    }

    if (operationType && operationType !== '-') {
      params += 'operationType=' + getStationOperationTypeCode(operationType);
    }


    url += params;

    fetch(url)
      .then(res => res.json())
      .then(snapshot => {
        // console.log(snapshot);
        var i = 1;
        var list = [];
        var csvlist = [];
        if (snapshot.resultData != null) {
          snapshot.resultData.forEach(doc => {
            doc.no = i++;
            list.push(doc);

            csvlist.push({
              '충전소ID': '=""' + doc.statId + '""',
              '충전소명': doc.statName,
              // '건물주이름': doc.ownerName,
              '관리자이름': doc.adminName,
              '관리자유형': getAdminTypeLabel(doc.adminType),
              '전화번호': '=""' + doc.businessTel + '""',

              '주소': ""+doc.addr,
              '상세주소': ""+doc.addr2,

              '충전소유형': ""+getStationTypeLabel(doc.stationType),
              '충전소상세유형': ""+getStationDetailTypeLabel(doc.stationDetailType),
              '운영형태': ""+getStationOperationTypeLabel(doc.stationOperationType),
              '운영회원사': ""+doc.member_name||"",

              '위도': doc.latitude,
              '경도': doc.longitude,
              '사용시간': doc.useTime,
              '대리점ID': '=""'+doc.businessId + '""',
              '운영주체': ""+doc.businessName,
              '주차가능': ""+doc.parkingFree,
              '메모': ""+doc.note
            })
          });
        }
        setListData(list);
        setCsvData(csvlist);

        if (autoload === false && list.length === 0) {
          setDialogMessage('검색 결과가 없습니다.');
          setOpenDialog(true);
        }

        setPage(0);
      }
      );
  }


  const handleSearch = event => {

    // if (memberName !== '' || queryName !== '' || queryAddr !== '' || queryAdminName !== '' ||
    //   selectedAdminType.length > 1 ||
    //   selectedStationType.length > 1 ||
    //   selectedStationDetailType.length > 1 ||
    //   selectedStationOperationType.length > 1
    //   ) {
      searchData(false, memberName, queryStatId, queryName, queryAddr, queryAdminName, selectedAdminType,
          selectedStationType, selectedStationDetailType, selectedStationOperationType);
    // } else {
    //   setDialogMessage('검색어를 입력해 주세요. ');
    //   setOpenDialog(true);
    // }
  }

  const doKeyPress = (ev) => {
    if (ev.key === 'Enter') {
      handleSearch();
    }
  }


  /**
   * 검색 정보 지우기
   * @param {*} event 
   */
  const handleClear = event => {

    // props.updateMemberName('');
    props.updateQueryStationName('');
    props.updateQueryStationAddr('');
    props.updateStationAdminName('');

    setMemberName(userAuth === USER_AUTH.ADMIN ? '' : user.admin_name);
    setQueryStatId('');
    setQueryName('');
    setQueryAddr('');
    setQueryAdminName('');
    setSelectedAdminType('');
    setSelectedStationType('');
    setSelectedStationDetailType('');
    setSelectedStationOperationType('');
    setListData([]);
    setCsvData([]);
  }


  const handleQueryMemberNameChange = (event) => {
    event.persist();
    props.updateStationMemberName(event.target.value);
    setMemberName(event.target.value);
  }

  const handleQueryStatId = (event) => {
    event.persist();
    props.updateQueryStationId(event.target.value);
    setQueryStatId(event.target.value);
  }

  const handleQueryNameChange = (event) => {
    event.persist();
    props.updateQueryStationName(event.target.value);
    setQueryName(event.target.value);
  }

  const handleQueryAddrChange = (event) => {
    event.persist();
    props.updateQueryStationAddr(event.target.value);
    setQueryAddr(event.target.value);
  }

  const handleQueryAdminNameChange = (event) => {
    event.persist();
    props.updateStationAdminName(event.target.value);
    setQueryAdminName(event.target.value);
  }

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };


  const startRow = (page * rowsPerPage);


  useEffect(() => {
    

    if (queryParams.queryStationMemberName  ||
        queryParams.queryStatId || queryParams.queryStatName || queryParams.queryStatAddr || queryParams.queryStatAdminName) {
      searchData(true, queryParams.queryStationMemberName, queryParams.queryStatId,
        queryParams.queryStatName, queryParams.queryStatAddr, queryParams.queryStatAdminName);
    }else{
      handleSearch();
    }

    setQueryStatId(queryParams.queryStatId);
    setQueryName(queryParams.queryStatName);
    setQueryAddr(queryParams.queryStatAddr)
    setQueryAdminName(queryParams.queryStatAdminName);

    if(userAuth === USER_AUTH.MEMBER) {
      setMemberName(user.admin_name);
    } 
    else if(userAuth === USER_AUTH.SUB_MEMBER){
      setMemberName(user.admin_name);
    }
    else {
      setMemberName(queryParams.queryStationMemberName);
    }


    return () => { };
  }, []);


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };


  return (

    <div>

      <Dialog open={openDialog} onClose={() => { setOpenDialog(false) }}>
        <DialogTitle>주의</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{dialogMessage}</DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" color="secondary" onClick={() => { setOpenDialog(false) }}>닫기</Button>
        </DialogActions>
      </Dialog>

      <Card className={clsx(classes.root, className)} {...rest} >

        <Box m={1} p={2}>

          <Box mt={2}
            display="flex"
            alignItems="center"
            justifyContent="start"
            flexGrow={1}>

            <TextField className={classes.queryField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action"><SearchIcon /></SvgIcon>
                  </InputAdornment>
                )
              }}

              disabled={userAuth !== USER_AUTH.ADMIN}
              onChange={handleQueryMemberNameChange}
              label="운영회원사"
              placeholder="운영회원사"
              value={memberName}
              variant="outlined"
              onKeyUp={(ev) => doKeyPress(ev)}
            />


            <TextField className={classes.queryField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action"><SearchIcon /></SvgIcon>
                  </InputAdornment>
                )
              }}

              onChange={handleQueryStatId}
              label="충전소ID"
              placeholder="충전소ID"
              value={queryStatId}
              variant="outlined"
              onKeyUp={(ev) => doKeyPress(ev)}
            />

            <TextField className={classes.queryField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action"><SearchIcon /></SvgIcon>
                  </InputAdornment>
                )
              }}

              onChange={handleQueryNameChange}
              label="충전소"
              placeholder="충전소"
              value={queryName}
              variant="outlined"
              onKeyUp={(ev) => doKeyPress(ev)}
            />


            <TextField className={classes.queryField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action"><SearchIcon /></SvgIcon>
                  </InputAdornment>
                )
              }}

              onChange={handleQueryAddrChange}
              label="충전소 주소"
              placeholder="충전소 주소"
              value={queryAddr}
              variant="outlined"
              onKeyUp={(ev) => doKeyPress(ev)}
            />


            <TextField className={classes.queryField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action"><SearchIcon /></SvgIcon>
                  </InputAdornment>
                )
              }}

              onChange={handleQueryAdminNameChange}
              label="관리자 이름"
              placeholder="관리자 이름"
              value={queryAdminName}
              variant="outlined"
              onKeyUp={(ev) => doKeyPress(ev)}
            />


            <Autocomplete
              className={classes.queryFieldSmall}
              id="station_type"
              options={station_type_list}
              classes={{ option: classes.options, }}
              autoHighlight
              getOptionSelected={(option, value) => option === value}
              value={selectedStationType}
              renderOption={(option) => (
                <React.Fragment>
                  {option}
                </React.Fragment>
              )}

              renderInput={(params) => (
                <TextField
                  {...params}
                  label="충전소유형"
                  variant="outlined"
                  // required
                  fullWidth
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}

              onChange={(event, newValue) => {
                if (newValue) {
                  setSelectedStationType(newValue);
                }
              }}
            />

            <Autocomplete
            className={classes.queryFieldSmall}
              id="station_detail_type"
              options={station_detail_type_list}
              classes={{ option: classes.options, }}
              autoHighlight
              getOptionSelected={(option, value) => option === value}
              value={selectedStationDetailType}
              renderOption={(option) => (
                <React.Fragment>
                  {option}
                </React.Fragment>
              )}

              renderInput={(params) => (
                <TextField
                  {...params}
                  label="충전소상세유형"
                  variant="outlined"
                  // required
                  fullWidth
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}

              onChange={(event, newValue) => {
                if (newValue) {
                  setSelectedStationDetailType(newValue);
                }
              }}
            />

            <Autocomplete
            className={classes.queryFieldSmall}
              id="station_operation_type"
              options={station_operation_type_list}
              classes={{ option: classes.options, }}
              autoHighlight
              getOptionSelected={(option, value) => option === value}
              value={selectedStationOperationType}
              renderOption={(option) => (
                <React.Fragment>
                  {option}
                </React.Fragment>
              )}

              renderInput={(params) => (
                <TextField
                  {...params}
                  label="운영형태"
                  variant="outlined"
                  // required
                  fullWidth
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}

              onChange={(event, newValue) => {
                if (newValue) {
                  setSelectedStationOperationType(newValue);
                }
              }}
            />

            <Autocomplete
            className={classes.queryFieldSmall}
              id="admin_type"
              options={amdin_type_list}
              classes={{ option: classes.options, }}
              autoHighlight
              getOptionSelected={(option, value) => option === value}
              value={selectedAdminType}
              renderOption={(option) => (
                <React.Fragment>
                  {option}
                </React.Fragment>
              )}

              renderInput={(params) => (
                <TextField
                  {...params}
                  label="관리자유형"
                  variant="outlined"
                  // required
                  fullWidth
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}

              onChange={(event, newValue) => {
                if (newValue) {
                  setSelectedAdminType(newValue);
                }
              }}
            />


            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classes.bulkAction}
              onClick={handleSearch}
            >
              검색
            </Button>


            <Button
              variant="outlined"
              size="large"
              className={classes.bulkAction}
              onClick={handleClear}
            >
              초기화
            </Button>

          </Box>
        </Box>

        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={listData.length}
                  />

                <TableBody>
                  {/* {listData.slice(0 + startRow, rowsPerPage + (page * rowsPerPage)).map(module => ( */}
                  { stableSort(listData, getComparator(order, orderBy)).slice(0 + startRow, rowsPerPage + (page * rowsPerPage)).map(module => (

                    <TableRow
                      className={classes.tableRow}
                      key={module.statId}
                      component={RouterLink} to={`/stations/update/${module.statId}`}
                    >

                      <TableCell align="center" width={COLUMN_WIDTH.NO}><Typography variant="body2">{module.no}</Typography></TableCell>
                      <TableCell align="center" width={COLUMN_WIDTH.STAT_ID}><Typography variant="body2">{module.statId}</Typography></TableCell>
                      <TableCell width={COLUMN_WIDTH.STATION_NAME}><Typography variant="body2">{module.statName}</Typography></TableCell>
                      <TableCell width={COLUMN_WIDTH.STATION_ADDR}><Typography variant="body2">{module.addr}</Typography></TableCell>
                      <TableCell width={COLUMN_WIDTH.STATION_ADDR_DETAIL}><Typography variant="body2">{module.addr2}</Typography></TableCell>
                      <TableCell align="center" width={COLUMN_WIDTH.STATION_TYPE}><Typography variant="body2">{getStationTypeLabel(module.stationType)}</Typography></TableCell>
                      <TableCell align="center" width={COLUMN_WIDTH.DETAIL_TYPE}><Typography variant="body2">{getStationDetailTypeLabel(module.stationDetailType)}</Typography></TableCell>
                      <TableCell align="center" width={COLUMN_WIDTH.OPERATION_TYPE}><Typography variant="body2">{getStationOperationTypeLabel(module.stationOperationType)}</Typography></TableCell>

                      <TableCell align="center" width={COLUMN_WIDTH.ADMIN_TYPE}><Typography variant="body2">{getAdminTypeLabel(module.adminType)}</Typography></TableCell>
                      <TableCell align="center" width={COLUMN_WIDTH.ADMIN}><Typography variant="body2">{module.adminName}</Typography></TableCell>
                      <TableCell align="center" width={COLUMN_WIDTH.TELNO}><Typography variant="body2">{module.businessTel}</Typography></TableCell>
                      <TableCell align="center" width={COLUMN_WIDTH.MEMBER_NAME}><Typography variant="body2">{module.member_name}</Typography></TableCell>
                    </TableRow>
                  ))}

                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>

        <CardActions >

          <Grid container item className={classes.action}
            direction="row" alignItems="center" >

            <Grid item md={6} justify="flex-start">

              <CSVLink
                variant="contained"
                size="large"
                data={csvData}
                filename={`${new Date().toLocaleDateString()}_충전소목록.csv`}
                className="btn btn-primary"
                target="_blank"
                disabled={csvData.length === 0}
                data-interception='off'
              >

                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  className={classes.bulkAction}
                  disabled={csvData.length === 0}
                >
                  CSV 다운로드
                </Button>
              </CSVLink>
            </Grid>

            <Grid item md={6} justify="flex-end">
              <TablePagination
                component="div"
                count={listData.length}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handleRowsPerPageChange}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
              />
            </Grid>
          </Grid>

        </CardActions>
      </Card>
    </div>
  );
};

StationTable.propTypes = {
  className: PropTypes.string,
};


const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    updateStationMemberName,
    updateQueryStationId,
    updateQueryStationName,
    updateQueryStationAddr,
    updateStationAdminName},
    dispatch);
};

let mapStateToProps = (state) => {
  return {
    queryParams: state.queryParams,
    user: state.user
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(StationTable));




const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    // minWidth: 1050
  },
  nameContainer: {
    // display: 'flex',
    // alignItems: 'center',
    // marginLeft: theme.spacing(1)
  },
  queryField: {
    // width: 300
    flexBasis: 160,
    marginRight: 10,
  },


  queryFieldSmall: {
    // width: 300
    flexBasis: 140,
    marginRight: 10,
  },
  queryFieldSmall2: {
    // width: 300
    flexBasis: 100,
    marginRight: 10,
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  actions2: {
    justifyContent: 'flex-start'
  },
  bulkAction: {
    marginLeft: theme.spacing(2)
  },
}));
