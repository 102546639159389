

export const NEONPV_CONFIG = {
  // 개발
  // HOSTNAME : 'http://localhost:3002', //for develop
  // HOSTNAME : 'http://124.63.63.92:3002', //for develop
  // HOSTNAME : 'https://castprotest.kr:3003', //for develop
  // HOSTNAME : 'http://localhost:3002', //for develop
  // ERROR_BACKEND: 'http://15.164.70.192:3003', //error reporter backend
  // HOSTNAME : 'http://castpro.site:3002', //for dev
  // WEBSOCKET_URL : 'ws://localhost:3003/',


  //배포
  HOSTNAME : 'https://castprocsms.kr:3003', //for release
  ERROR_BACKEND: 'http://15.164.70.192:3003', //error reporter backend

  // AUTH_TYPE_ADMIN : 1,
  // AUTH_TYPE_MEMBER : 2,
};

