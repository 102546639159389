import React, {useState, useEffect} from "react";
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { makeStyles } from "@material-ui/styles";
import PropTypes from 'prop-types';
import NoticeUpdateForm from "./NoticeUpdateForm";
import { NEONPV_CONFIG } from 'config.js';
import { connect, useSelector} from 'react-redux';
import { USER_AUTH } from 'common/constants';



const NoticeUpdate = (props) => {

  const {className, match, history, ...rest } = props;
  const classes = useStyles();

  const userAuth = useSelector((state) => state.user.user_auth);
  if(userAuth === USER_AUTH.MEMBER) {
    window.location.href = '/';
  }

  const [noticeInfo, setNoticeInfo] = useState(null);

  const loadData = (notice_id) => {

    var url = `${NEONPV_CONFIG.HOSTNAME}/notice/queryNoticeInfo?id=`+notice_id;

    fetch(url)
    .then(res => res.json())
    .then(snapshot => {

      var notice = {
        id: snapshot.resultData.id,
        title: snapshot.resultData.title,
        author: snapshot.resultData.author,
        regdate: snapshot.resultData.regdate,
        contents: snapshot.resultData.contents,
        flag : snapshot.resultData.flag,
      };

      setNoticeInfo(notice);
    }
    );
  }


  useEffect( () => {
    loadData(match.params.notice_id);
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        { noticeInfo && <NoticeUpdateForm noticeInfo={noticeInfo} history={history} /> }
      </div>
    </div>
  );
};


NoticeUpdate.propTypes = {
  history: PropTypes.object
};

let mapStateToProps = (state) => {
  return {
    user: state.user
  };
}

export default connect(
  mapStateToProps,
)(NoticeUpdate);



const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));