import React, {useState, useEffect} from "react";
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { makeStyles } from "@material-ui/styles";
import PropTypes from 'prop-types';
import UserAddForm from "./UserAddForm";


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));


const UserAdd = (props) => {

  const {className, match, history, ...rest } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <UserAddForm history={history} />
      </div>
    </div>
  );
};


UserAdd.propTypes = {
  history: PropTypes.object
};

export default UserAdd;
