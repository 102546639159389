import { NEONPV_CONFIG } from 'config.js';
import { USER_AUTH } from './constants';

export const doLoginWithPW = (history, admin_id, admin_password) => {

  fetch(`${NEONPV_CONFIG.HOSTNAME}/admin/signin`,
  {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body:
      JSON.stringify({
        admin_id: admin_id,
        admin_password: admin_password,
      })
  })
  .then( response => {
    return response.json();
  }).then(responseData => {
    if(responseData.resultCode === '000'){
      //로그인 성공

      const newuser = {
        status: 'on',
        user_auth : responseData.user_auth, //1:admin, 2:회원사, 3:서브계정
        admin_id: responseData.admin_id, //관리자 아이디, 회원사 아이디
        admin_name : responseData.admin_name, //관리자 이름, 회원사 이름
        bid: responseData.bid, //환경부GW BID
        bkey: responseData.bkey, //환경부GW BKEY
      }

      localStorage.setItem('userinfo', JSON.stringify(newuser) );

      setTimeout( () => {
        const path = responseData.user_auth === USER_AUTH.SUB_MEMBER ? '/stations' : '/';
        history.push(path);
      }, 500);
      // doLoginHistory(history, userEmail);

    } else {
      alert(`로그인에 실패했습니다.\n${responseData.resultMessage}`);
      return 1;
    }
  })
  .catch( error => {
    console.error(error);
    alert('로그인에 실패했습니다.');
    return 2;
  });

};

