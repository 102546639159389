import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import SettingENVForm from './SettingENVForm';


const SettingENV = (props) => {
  const {className, history, ...rest } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item md={8} xs={12}>
          <SettingENVForm history={history}/>
        </Grid>
      </Grid>
    </div>
  );
};


SettingENV.propTypes = {
  history: PropTypes.object
};


export default SettingENV;


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}));