import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Formik } from "formik";
import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Divider,
} from "@material-ui/core";

import Autocomplete from '@material-ui/lab/Autocomplete';
import { NEONPV_CONFIG } from "config.js";
import { MAX_LENGTH, charger_speed_list, charger_type_list, charger_status_list,
  getChargerTypeCode, getChargerStatusCode,
  USER_AUTH} from "common/constants";

import { bindActionCreators } from 'redux';
import {
  updateOpenDialog,
  updateSelectedStation
} from "actions/settings";

import {StationSearchDialog} from "views/StationSearchDialog";
import { loadChargerModelList } from "utils/LoadConfiguration";


const ChargerAddForm = (props) => {
  const { className, history, user, ...rest } = props;
  const classes = useStyles();

  const userAuth = useSelector((state) => state.user.user_auth);

  const selector_openDialog = useSelector((state) => state.settings.openDialog);
  const selector_selectedStationInfo = useSelector((state) => state.settings.selectedStationInfo);

  const [listData, setListData] = useState([]);

  const [queryStationName, setQueryStationName] = useState("");
  const [queryStationAddr, setQueryStationAddr] = useState("");

  /** 충전기ID 중복체크 유무 */
  const [verifiedChargerId, setVerifiedChargerId] = useState(false);

  /** 충전기 시리얼번호 중복체크 유무 */
  const [verifiedSerialNumber, setVerifiedSerialNumber] = useState(false);

  /** 충전기 시리얼번호 */
  const [serialNo, setSerialNo] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [itsStationInfo, setItsStationInfo] = useState({});

  // const charger_speed_list = ['급속', '완속', '중속'];
  const [selectedChargerSpeed, setSelectedChargerSpeed] = useState('');

  // const charger_type_list = ['B타입(5핀)', 'C타입(5핀)', 'BC타입(5핀)', 'BC타입(7핀)', 'DC차데모', 'AC3상', 'DC콤보', 'DC차데모 +DC콤보'];
  const [selectedChargerType, setSelectedChargerType] = useState('');


  // // 1 : 충전가능, 2 : 충전중, 3 : 고장/점검, 4 : 통신장애, 5 : 통신미연결
  // // const charger_status_list = ['충전가능', '충전중', '고장/점검', '통신장애', '통신미연결'];
  // const [selectedChargerStatus, setSelectedChargerStatus] = useState('');


  // 충전기 모델
  const [selectedChargerModel, setSelectedChargerModel] = useState('');

  //충전기모델 리스트
  const [chargerModelList, setChargerModelList] = useState([]);



  /**
   * 충전기ID - 중복체크
   */
  const doCheckExistedChargerId = (event, statId, chargerId) => {
    event.preventDefault();


    if (statId === undefined || statId.length == 0 ){
      alert('충전소를 선택해주세요.');
      return;
    }
    if (chargerId.length == 0){
      alert('충전기번호를 입력해주세요.');
      return;
    }

    if ( isNaN(Number(chargerId)) ){
      alert('충전기번호는 01~99 범위로 입력해주세요.');
      return;
    }

    var url = `${NEONPV_CONFIG.HOSTNAME}/station/isExistCharger?statId=`+statId+`&chargerId=`+chargerId;

    fetch(url)
    .then(res => res.json())
    .then(snapshot => {

      console.log(snapshot);
      console.log(snapshot.resultData);

      if (snapshot.resultCode == '023'){
        alert('입력 가능한 "충전기번호"입니다.');
        setVerifiedChargerId(true);

        setSerialNo(statId+chargerId);
      }

      if (snapshot.resultCode == '000'){
        alert('이미 등록된 "충전기번호"입니다.');
        setVerifiedChargerId(false);
      }

    });
  }


  /**
   * 충전기 시리얼번호 - 중복체크
   */
  const doCheckExistedChargerSerialNumber = (event, chargerSerialNumber) => {
    event.preventDefault();

    var url = `${NEONPV_CONFIG.HOSTNAME}/station/isExistSerialNumber?serialNumber=`+chargerSerialNumber;

    fetch(url)
    .then(res => res.json())
    .then(snapshot => {
      console.log(snapshot);

      if (snapshot.resultCode == '023'){
        alert('입력 가능한 "시리얼번호"입니다.');
        setVerifiedSerialNumber(true);
      }

      if (snapshot.resultCode == '000'){
        alert('이미 등록된 "시리얼번호"입니다.');
        setVerifiedSerialNumber(false);
      }
    });

  }


  const handleSubmit = (event, values) => {
    event.preventDefault();

    if (verifiedChargerId == false){
      alert('"충전기번호" 중복체크를 해 주세요.');
      return;
    }

    if (verifiedSerialNumber == false){
      alert('충전기 "시리얼번호" 중복체크를 해 주세요.');
      return;
    }


    fetch(`${NEONPV_CONFIG.HOSTNAME}/station/createChargerInfo`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        statId: selector_selectedStationInfo.statId,

        chargerId: values.chargerId,
        chargerSpeed: selectedChargerSpeed,
        chargerType: getChargerTypeCode(selectedChargerType),
        // chargerStatus: getChargerStatusCode(selectedChargerStatus),
        // chargerIP: values.chargerIP,
        // chargerPort: values.chargerPort,
        // chargerMacAddr: values.chargerMacAddr,
        // chargerModel: getChargerModelCode(selectedChargerModel),
        chargerModel: selectedChargerModel,
        // chargerVersion: values.chargerVersion,
        chargerFirmwareVersion: values.chargerFirmwareVersion,
        chargerMacAddr:values.chargerMacAddr,
        chargerLTERouterNo:values.chargerLTERouterNo
      }),
    })
    .then((response) => {
      return response.json();
    })
    .then((responseData) => {

      if (responseData.resultCode === "000") {
        alert("충전기 추가에 성공했습니다.");

        props.updateSelectedStation({});

        setTimeout(() => {
          history.goBack();
        }, 500);
      } else {
        //에러
        alert(`충전기 추가에 실패했습니다.\n${responseData.resultCode}-${responseData.resultMessage}`);
      }
    })
    .catch((error) => {
      console.error(error);
      alert("충전기 추가에 실패했습니다.");
    });
  };


  const handleBack = () => {
    props.updateSelectedStation({});
    history.goBack();
  };

  const showChargerSearchDialog = () => {
    props.updateOpenDialog(true);
  }


  useEffect(() => {
    props.updateSelectedStation({});

    async function loadChargerModel() {
      let models = await loadChargerModelList();

      setChargerModelList(models);
    }

    loadChargerModel();
  }, []);



  return (

    <div>
      <StationSearchDialog openDialog={selector_openDialog} />

      <Formik
        enableReinitialize={true}
        initialValues={{
          statId: "", //충전소를 선택하는 dialog box
          chargerId: "", // 충전기번호
          serialNo: "", //충전기 시리얼번호
          // chargerIP: "", //충전기 IP
          // chargerPort: "", //충전기 PORT
          chargerModel: "", //충전기 모델
          // chargerVersion: "", //충전기 버전
          chargerFirmwareVersion: "", //충전기 펌웨어버전
          chargerMacAddr: "", //Mac 주소
          chargerLTERouterNo: "", //LTE 라우터 번호
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          isSubmitting,
          touched,
          values,
          setFieldValue
        }) => (
          <form
            className={clsx(classes.root, className)}
            onSubmit={(e) => handleSubmit(e, values)}
          >

            <Card>
              <CardContent>
                <Grid container spacing={2} >
                  <Grid container item md={2} xs={12}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      size="large"
                      fullWidth
                      onClick={showChargerSearchDialog}
                    >
                      충전소 검색
                    </Button>
                  </Grid>

                  <Grid item md={2} xs={4} >
                    <TextField
                      error={Boolean(touched.statId && errors.statId)}
                      fullWidth
                      helperText={touched.statId && errors.statId}
                      label="충전소ID"
                      name="statId"
                      onBlur={handleBlur}
                      onChange={(e) => { handleChange(e); setVerifiedChargerId(false);
                        setVerifiedSerialNumber(false);
                        setFieldValue('statId', selector_selectedStationInfo.statId); }}
                      value={selector_selectedStationInfo.statId}
                      variant="outlined"
                      inputProps={{
                        maxLength: MAX_LENGTH.STAT_ID, readOnly: true
                      }}
                      InputLabelProps={{ shrink: true }}
                      required
                    />
                  </Grid>

                  <Grid item md={4} xs={6}>
                    <TextField
                      fullWidth
                      label="충전소 명칭"
                      name="statName"
                      value={selector_selectedStationInfo.statName}
                      variant="outlined"
                      inputProps={{ maxLength: MAX_LENGTH.STAT_NAME, readOnly: true, }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>

                  <Grid item md={4} xs={6}>
                    <TextField
                      fullWidth
                      label="충전소 주소"
                      name="statAddr"
                      value={selector_selectedStationInfo.addr}
                      variant="outlined"
                      inputProps={{ maxLength: MAX_LENGTH.STAT_ADDR, readOnly: true,  }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                </Grid>

                <Divider className={classes.divider} variant="inset" />

                <Grid container spacing={2} >
                  <Grid item md={2} xs={4}>
                    <TextField
                      error={Boolean(touched.chargerId && errors.chargerId)}
                      fullWidth
                      helperText={touched.chargerId && errors.chargerId}
                      label="충전기번호(최대2자)"
                      name="chargerId"
                      onBlur={handleBlur}
                      onChange={ (e) => { handleChange(e); setVerifiedChargerId(false); }}
                      required
                      value={values.chargerId}
                      variant="outlined"
                      inputProps={{ maxLength: MAX_LENGTH.CHARGER_ID }}
                    />
                  </Grid>

                  <Grid container item md={1} xs={2}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Button
                      variant={verifiedChargerId?"contained": "outlined"}
                      color="primary"
                      size="large"
                      fullWidth
                      onClick={(e) => doCheckExistedChargerId(e, selector_selectedStationInfo.statId, values.chargerId)}
                    >
                      중복체크
                    </Button>
                  </Grid>



                  <Grid item md={2} xs={4}>
                    <TextField
                      fullWidth
                      // helperText={touched.serialNumber && errors.serialNumber}
                      label="충전기시리얼번호(최대25자)"
                      name="serialNumber"
                      onBlur={handleBlur}
                      onChange={ (e) => { setSerialNo(e.target.value); setVerifiedSerialNumber(false); }}
                      required
                      value={serialNo}
                      variant="outlined"
                      inputProps={{ maxLength: MAX_LENGTH.CHARGER_SERIALNUMBER }}
                    />
                  </Grid>


                  <Grid container item md={1} xs={2}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Button
                      variant={verifiedSerialNumber?"contained": "outlined"}
                      color="primary"
                      size="large"
                      fullWidth
                      onClick={(e) => doCheckExistedChargerSerialNumber(e, serialNo)}
                    >
                      중복체크
                    </Button>
                  </Grid>


                  <Grid item md={2} xs={4}>
                    <Autocomplete
                      id="charger_speed_select"
                      options={charger_speed_list}
                      classes={{ option: classes.options, }}
                      autoHighlight
                      getOptionSelected={(option, value) => option === value}
                      value={selectedChargerSpeed}
                      renderOption={(option) => (
                        <React.Fragment>
                          {option}
                        </React.Fragment>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="충전속도 선택"
                          variant="outlined"
                          required
                          fullWidth
                          inputProps={{
                            ...params.inputProps,
                          }}
                        />
                      )}

                      onChange={(event, newValue) => {
                        if (newValue) {
                          setSelectedChargerSpeed(newValue);
                        }
                      }}
                    />
                  </Grid>

                  <Grid item md={2} xs={4}>
                    <Autocomplete
                      id="charger_type_select"
                      options={charger_type_list}
                      classes={{ option: classes.options, }}
                      autoHighlight
                      getOptionSelected={(option, value) => option === value}
                      value={selectedChargerType}
                      renderOption={(option) => (
                        <React.Fragment>
                          {option}
                        </React.Fragment>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="충전기 타입 선택"
                          variant="outlined"
                          fullWidth
                          inputProps={{
                            ...params.inputProps,
                          }}
                        />
                      )}

                      onChange={(event, newValue) => {
                        if (newValue) {
                          setSelectedChargerType(newValue);
                        }
                      }}
                    />
                  </Grid>

                  {/* <Grid item md={2} xs={4}>
                    <Autocomplete
                      id="charger_status_select"
                      options={charger_status_list}
                      classes={{ option: classes.options, }}
                      autoHighlight
                      getOptionSelected={(option, value) => option === value}
                      value={selectedChargerStatus}
                      renderOption={(option) => (
                        <React.Fragment>
                          {option}
                        </React.Fragment>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="충전기 상태 선택"
                          variant="outlined"
                          fullWidth
                          inputProps={{
                            ...params.inputProps,
                          }}
                        />
                      )}

                      onChange={(event, newValue) => {
                        if (newValue) {
                          setSelectedChargerStatus(newValue);
                        }
                      }}
                    />
                  </Grid> */}

                  {/* 로그인한 계정이 회원사라면, 회원사이름 표시, 관리자라면 공백으로 둔다.
                    '회원사관리' 화면에서 충전소를 회원사에 할당하기 때문임.
                  */}
                  <Grid item md={2} xs={4}>
                    <TextField
                      fullWidth
                      label="운영회원사"
                      value={userAuth === USER_AUTH.MEMBER ? user.admin_name : ''}
                      variant="outlined"
                      inputProps={{ maxLength: 50 }}
                      disabled
                    />
                  </Grid>


                  <Grid item md={2} xs={4}>
                    <Autocomplete
                      id="charger_model_list"
                      options={chargerModelList}
                      classes={{ option: classes.options, }}
                      autoHighlight
                      getOptionSelected={(option, value) => option === value}
                      value={selectedChargerModel}
                      renderOption={(option) => (
                        <React.Fragment>
                          {option}
                        </React.Fragment>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="충전기 모델"
                          variant="outlined"
                          fullWidth
                          inputProps={{
                            ...params.inputProps,
                          }}
                        />
                      )}

                      onChange={(event, newValue) => {
                        if (newValue) {

                          setSelectedChargerModel(newValue);
                        }
                      }}
                    />
                  </Grid>

                  <Grid item md={2} xs={4}>
                    <TextField
                      error={Boolean(touched.chargerFirmwareVersion && errors.chargerFirmwareVersion)}
                      fullWidth
                      helperText={touched.chargerFirmwareVersion && errors.chargerFirmwareVersion}
                      label="펌웨어버전"
                      name="chargerFirmwareVersion"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.chargerFirmwareVersion}
                      variant="outlined"
                      inputProps={{ maxLength: MAX_LENGTH.CHARGER_FIRMWARE_VERSION }}
                    />
                  </Grid>


                  <Grid item md={2} xs={4}>
                    <TextField
                      error={Boolean(touched.chargerMacAddr && errors.chargerMacAddr)}
                      fullWidth
                      helperText={touched.chargerMacAddr && errors.chargerMacAddr}
                      label="MAC주소(최대17자)"
                      name="chargerMacAddr"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.chargerMacAddr}
                      variant="outlined"
                      inputProps={{ maxLength: MAX_LENGTH.CHARGER_MAC_ADDR }}
                    />
                  </Grid>

                  <Grid item md={2} xs={4}>
                    <TextField
                      error={Boolean(touched.chargerLTERouterNo && errors.chargerLTERouterNo)}
                      fullWidth
                      helperText={touched.chargerLTERouterNo && errors.chargerLTERouterNo}
                      label="LTE라우터번호(최대20자)"
                      name="chargerLTERouterNo"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.chargerLTERouterNo}
                      variant="outlined"
                      inputProps={{ maxLength: MAX_LENGTH.CHARGER_LTE_ROUTER_NO }}
                    />
                  </Grid>


                  {/* <Grid item md={6} xs={12}>
                    <TextField
                      error={Boolean(touched.chargerVersion && errors.chargerVersion)}
                      fullWidth
                      helperText={touched.chargerVersion && errors.chargerVersion}
                      label="충전기버전(최대20자)"
                      name="chargerVersion"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.chargerVersion}
                      variant="outlined"
                      inputProps={{ maxLength: MAX_LENGTH.CHARGER_VERSION }}
                    />
                  </Grid> */}

                </Grid>

                <Grid container item className={classes.action}
                    direction="row" alignItems="center" justifyContent="center">

                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="large"
                    disabled={isSubmitting}
                    className={classes.action}
                  >
                    충전기 추가
                  </Button>

                  <Button
                    variant="outlined"
                    size="large"
                    onClick={handleBack}
                    className={classes.action}
                  >
                    취소
                  </Button>
                </Grid>
              </CardContent>
            </Card>
          </form>
        )}
      </Formik>
    </div>
  );
};


ChargerAddForm.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object,
};


const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    updateOpenDialog,
    updateSelectedStation
  }, dispatch);
};


let mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps)
(ChargerAddForm);


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  action: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },

  queryField: {
    flexBasis: 220,
    marginRight: 20,
  },
  bulkAction: {
    marginLeft: theme.spacing(2),
  },

  divider: {
    margin: theme.spacing(2, 0)
  },
}));
