import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Formik } from "formik";
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Divider,
  Typography
} from "@material-ui/core";

import { NEONPV_CONFIG } from "config";
import {global} from "common/global";


const CancelReservationForm = (props) => {
  const { className, history, user, ...rest } = props;
  const classes = useStyles();



  const handleSubmit = (event, values) => {
    event.preventDefault();

    var json =
      `{"command":"CancelReservation",
        "body": {
          "reservationId": ${values.reservationId}
        }
      }`;

    global.ws.send(json);

  };


  return (

    <div>
      <Formik
        enableReinitialize={false}
        initialValues={{
          reservationId: 10
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          isSubmitting,
          touched,
          values,
          setFieldValue
        }) => (
          <form
            className={clsx(classes.root, className)}
            onSubmit={(e) => handleSubmit(e, values)}
          >

            <Card>
              <CardHeader
                title="예약 취소"
                titleTypographyProps={{ color: 'inherit', align: 'center', variant: 'h3' }}
                // subheader="TestCase - 051, 052"
                // subheaderTypographyProps={{ color: 'inherit', align: 'left', variant: 'h5' }}
              />


              <CardContent>

                {/* <Grid item md={12} spacing={2}>
                  <Typography variant="h6">051 (중간실행) - PASS</Typography>
                  <Typography variant="h6">052 (중간실행) - PASS</Typography>
                </Grid> */}

                <div className={classes.content}></div>

                <Grid item md={12} spacing={0}>
                  <Typography variant="h4">파라미터</Typography>
                </Grid>
                <Divider variant="inset" />


                <Grid container spacing={2} >

                  <Grid container item md={12} spacing={0}></Grid>

                  <Grid item md={2} xs={4}>
                  <TextField
                      error={Boolean(touched.reservationId && errors.reservationId)}
                      fullWidth
                      helperText={touched.reservationId && errors.reservationId}
                      label="reservationId"
                      name="reservationId"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.reservationId}
                      variant="outlined"
                      required
                    />
                  </Grid>

                </Grid>


                <Grid container item
                  className={classes.action}
                  direction="row" alignItems="center" justify="center">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="large"
                    disabled={isSubmitting}
                    className={classes.action}
                  >
                    Perform
                  </Button>

                </Grid>
              </CardContent>
            </Card>
          </form>
        )}
      </Formik>
    </div>
  );
};

CancelReservationForm.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object,
};


let mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps)(CancelReservationForm);


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    direction: "row",
    alignItems: "center",
    justify: "center"
  },
  content2: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  action: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },
  queryField: {
    // width: 300
    flexBasis: 260,
    marginRight: 20,
  },
  bulkAction: {
    marginLeft: theme.spacing(2),
  },

  divider: {
    margin: theme.spacing(2, 0)
  },
}));
