import React, {useState, useEffect} from "react";
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { makeStyles } from "@material-ui/styles";
import PropTypes from 'prop-types';
import UserUpdateForm from "./UserUpdateForm";
import { NEONPV_CONFIG } from 'config.js';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));


const UserUpdate = (props) => {

  const {className, match, history, ...rest } = props;
  const classes = useStyles();

  const [userInfo, setUserInfo] = useState(null);

  const loadData = (userId) => {

    var url = `${NEONPV_CONFIG.HOSTNAME}/user/queryUserInfo?userId=`+userId;

    fetch(url)
    .then(res => res.json())
    .then(snapshot => {

      if (snapshot.resultData){
        var member = {
          user_id: snapshot.resultData.id,
          user_login_type: snapshot.resultData.user_login_type,
          user_email: snapshot.resultData.user_email,
          user_password: snapshot.resultData.user_password,
          user_nickname: snapshot.resultData.user_nickname,
          user_card_number: snapshot.resultData.user_card_number,
          user_card_stop: snapshot.resultData.user_card_stop,
          user_kind: snapshot.resultData.user_kind,
        };

        setUserInfo(member);
      }
    }
    );
  }


  useEffect( () => {
    loadData(match.params.userId);
  }, []);


  return (
    <div className={classes.root}>
      <div className={classes.content}>
        { userInfo && <UserUpdateForm userInfo={userInfo} history={history} /> }
      </div>
    </div>
  );
};


UserUpdate.propTypes = {
  history: PropTypes.object
};

export default withRouter(UserUpdate);
