import React from 'react';
import {Route} from 'react-router-dom';
import PropTypes from 'prop-types';
import {ForbiddenPage} from '../../views';

import { connect } from 'react-redux';

/*
 * SUPER 권한자에게만 페이지를 표시하고, 그외는 ForbiddenPage를 표시함 
 */
const AuthRouteWithLayout = props => {
  const { auth, user, layout: Layout, component: Component, ...rest } = props;

  // console.log("user: " , user);

  return (
    <Route
      {...rest}
      render={matchProps => {

        if(user.group === 'SUPER') {
          return (
          <Layout>
            <Component {...matchProps} />
          </Layout>
          )
        } 
        else {
          return(
            
          <Layout>
            {/* <NotFound /> */}
            <ForbiddenPage />
          </Layout>
          )
        }
      }}
    />
  );

};

AuthRouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string,
};


let mapStateToProps = (state) => {
  return {
    user: state.user
  };
}

export default connect(
  mapStateToProps
)(AuthRouteWithLayout);
