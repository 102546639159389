import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Formik } from "formik";
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Divider,
  Typography,
} from "@material-ui/core";

import {global} from "common/global";



const GetLocalListVersionForm = (props) => {
  const { className, history, user, ...rest } = props;
  const classes = useStyles();


  const handleSubmit = (event, values) => {
    event.preventDefault();

    var json =
      `{"command":"GetLocalListVersion",
        "body": {
        }
      }`;

    global.ws.send(json);
  };



  return (

    <div>
      <Formik
        enableReinitialize={false}
        initialValues={{
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          isSubmitting,
          touched,
          values,
          setFieldValue
        }) => (
          <form
            className={clsx(classes.root, className)}
            onSubmit={(e) => handleSubmit(e, values)}
          >

            <Card>
              <CardHeader
                title="사용자 인증 기록 조회"
                titleTypographyProps={{ color: 'inherit', align: 'center', variant: 'h3' }}
                // subheader="TestCase - 042_2, 043_5"
                // subheaderTypographyProps={{ color: 'inherit', align: 'left', variant: 'h5' }}
              />

              <CardContent>

                {/* <Grid item md={12} spacing={2}>
                  <Typography variant="h6">042_2 - PASS</Typography>
                  <Typography variant="h6">043_5 - PASS, 이후 SendLocalList</Typography>
                </Grid> */}

                <div className={classes.content}></div>

                <Grid item md={12} spacing={0}>
                  <Typography variant="h4">파라미터</Typography>
                </Grid>

                <Divider variant="inset" />


                <Grid container item spacing={2} >
                  <Grid container item md={12} spacing={0}>
                  </Grid>

                </Grid>


                <Grid container item
                  className={classes.action}
                  direction="row" alignItems="center" justify="center">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="large"
                    disabled={isSubmitting}
                    className={classes.action}
                  >
                    Perform
                  </Button>

                </Grid>
              </CardContent>
            </Card>
          </form>
        )}
      </Formik>
    </div>
  );
};

GetLocalListVersionForm.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object,
};


let mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps)(GetLocalListVersionForm);


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    direction: "row",
    alignItems: "center",
    justify: "center"
  },
  content2: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  action: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },
  queryField: {
    // width: 300
    flexBasis: 260,
    marginRight: 20,
  },
  bulkAction: {
    marginLeft: theme.spacing(2),
  },

  divider: {
    margin: theme.spacing(2, 0)
  },
}));
