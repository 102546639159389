import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  Grid,
  Typography,
} from '@material-ui/core';
import { connect, useSelector } from 'react-redux';
import { options } from './chart';
import numeral from 'numeral';
import firebase from '../../../../Firebase';
import 'firebase/database';


const SummaryMonthly = props => {
  const { className, user, ...rest } = props;

  const admin_id = useSelector((state) => state.user.admin_id);

  const classes = useStyles();

  const [viewData, setViewData] = useState({});
  const [sumPrice, setSumPrice] = useState(0);


  useEffect(() => {

    var label_data = [];
    var chart_data = [];
    let datas = {};
    let sum1 = 0;

    firebase.database().ref('/dashboard/' + admin_id).child("salesmonthly").on('value', function (snapshot) {

      snapshot.forEach(function (childSnapshot) {
        var childKey = childSnapshot.key;
        var childData = childSnapshot.val();

        datas[childKey] = childData;
      });

      for (var key in datas) {
        label_data.push(key);
      }

      label_data.sort();

      for (var i = 0; i < label_data.length; i++) {
        chart_data.push(datas[label_data[i]]);
        sum1 += datas[label_data[i]];
      }

      setSumPrice(sum1);

      setViewData({
        labels: label_data,
        datasets: [
          {
            type: 'bar',
            label: "매출액",
            data: chart_data,
            backgroundColor: 'rgba(121, 134, 203, 0.5)', //indigo
          },
        ],
      });

    });


  }, [admin_id]);


  return (
    <Card className={clsx(classes.root, className)}>
      <CardContent>

        <Grid container justifyContent="space-between" spacing={0} style={{ marginBottom: 20 }}>
          <Grid item lg={12} md={12} align="left" >
            <Typography className={classes.title} color="textSecondary" gutterBottom variant="h6">
              월별 매출현황
            </Typography>
          </Grid>

          <Grid item lg={12} md={12} align="left">
            <Typography color="textSecondary" variant="h6" style={{ marginLeft: 20 }}>
              <strong>{numeral(sumPrice).format('0,0')}</strong>원</Typography>
          </Grid>

        </Grid>

        <div className={classes.chartContainer}>
          <Bar
            data={viewData}
            options={options}
          />
        </div>
      </CardContent>
    </Card>
  );
};

SummaryMonthly.propTypes = {
  className: PropTypes.string
};

let mapStateToProps = (state) => {
  return {
    user: state.user
  };
}

export default connect(mapStateToProps)(SummaryMonthly);


const useStyles = makeStyles((theme) => ({
  root: {},
  chartContainer: {
    //360px 이상
    ['@media (min-height:360px)']: {// eslint-disable-line no-useless-computed-key
      height: 360,
    },
    //360px 이하
    ['@media (max-height:360px)']: {// eslint-disable-line no-useless-computed-key
      height: 220,
    },
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  title: {
    fontWeight: 600,
    // marginTop: theme.spacing(1),
    // marginBottom: theme.spacing(2),
  },
  title2: {
    fontWeight: 800,
  }
}));
