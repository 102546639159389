import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
  Grid,
  Typography
} from '@material-ui/core';

import firebase from '../../Firebase';
import 'firebase/database';
import numeral from 'numeral';
import moment from 'moment';
import { colors } from '@material-ui/core';
import { connect, useSelector } from 'react-redux';


const COLUMN_WIDTH = {
  CHARGING_DATE: '20%',
  CHARGING_AMOUNT: '14%',
  CHARGING_AMOUNT_DIFF: '6%',
  CHARGING_TIME: '14%',
  CHARGING_TIME_DIFF: '6%',
  CHARGING_COUNT: '14%',
  CHARGING_COUNT_DIFF: '6%',
  CHARGING_PRICE: '14%',
  CHARGING_PRICE_DIFF: '6%',
}



const LastChargingTable = props => {
  const { className, user, ...rest } = props;

  const admin_id = useSelector((state) => state.user.admin_id);

  const classes = useStyles();
  const [listData, setListData] = useState([]);

  useEffect(() => {

    let datas = {};
    let label_data = [];
    var data1 = [];


    if (admin_id === '') return;

    var minDate = moment().add(-4, "days").format("'YY-MM-DD");


    const fetchData = (snapshot) => {

      snapshot.forEach(function (childSnapshot) {
        var childKey = childSnapshot.key;
        var childData = childSnapshot.val();

        if (childKey >= minDate) {
          childData.chargingDate = childKey;
          datas[childKey] = childData;
        }
      });


      for (var key in datas) {
        label_data.push(key);
      }

      label_data.reverse();


      //증감분
      for (var i = 0; i < label_data.length - 1; i++) {
        datas[label_data[i]].diff_Wh = datas[label_data[i]].sumWh - datas[label_data[i + 1]].sumWh
        // datas[ label_data[i] ].diff_t = datas[ label_data[i] ].amountUsageT - datas[ label_data[i+1] ].amountUsageT
        let diff_time = datas[label_data[i]].amountUsageT - datas[label_data[i + 1]].amountUsageT

        let hour = parseInt(diff_time / 3600)
        let min = Math.abs(parseInt((diff_time % 3600) / 60))
        min = String(min).padStart(2, '0')
        let sec = Math.abs(parseInt(diff_time % 60))
        sec = String(sec).padStart(2, '0')

        datas[label_data[i]].diff_time = diff_time;
        datas[label_data[i]].diff_time_str = `${hour}:${min}:${sec}`;
        datas[label_data[i]].diff_cnt = datas[label_data[i]].cnt - datas[label_data[i + 1]].cnt
        datas[label_data[i]].diff_amountPrice = datas[label_data[i]].amountPrice - datas[label_data[i + 1]].amountPrice
      }

      for (var i = 0; i < label_data.length; i++) {
        data1.push(datas[label_data[i]]);
      }

      data1.pop();

      // console.log(data1);
      setListData(data1);

    }


    const unsubscribe = firebase.database().ref('/dashboard/' + admin_id)
      .child("salesdaily").on('value', function (snapshot) {
        fetchData(snapshot);
      });


    return () => {
      firebase.database().ref('/dashboard/' + admin_id)
        .child("salesdaily").off('value', unsubscribe);
    }


  }, [admin_id]);



  return (

    <Card className={clsx(classes.root, className)}>

      <CardContent className={classes.content}>
        <Grid container justifyContent="space-between" spacing={0}>
          <Grid item lg={12} md={12} align="left">
            <Typography className={classes.title} color="textSecondary" gutterBottom variant="h6">
              전일대비 충전량
            </Typography>


          </Grid>

          <Grid item lg={12} md={12} align="right">
            <Typography color="textSecondary" gutterBottom variant="body2">
              최근 3일
            </Typography>
          </Grid>
        </Grid>

        <PerfectScrollbar>
          <div className={classes.inner}>

            <TableContainer component={Paper}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell width={COLUMN_WIDTH.CHARGING_DATE} align="left">일자</TableCell>
                    <TableCell width={COLUMN_WIDTH.CHARGING_AMOUNT} align="right">충전량[Wh]</TableCell>
                    <TableCell width={COLUMN_WIDTH.CHARGING_AMOUNT_DIFF} align="center">△</TableCell>
                    <TableCell width={COLUMN_WIDTH.CHARGING_TIME} align="right">사용시간</TableCell>
                    <TableCell width={COLUMN_WIDTH.CHARGING_TIME_DIFF} align="center">△</TableCell>
                    <TableCell width={COLUMN_WIDTH.CHARGING_COUNT} align="right">충전횟수</TableCell>
                    <TableCell width={COLUMN_WIDTH.CHARGING_PRICE_DIFF} align="center">△</TableCell>
                    <TableCell width={COLUMN_WIDTH.CHARGING_PRICE} align="right">충전요금[원]</TableCell>
                    <TableCell width={COLUMN_WIDTH.CHARGING_PRICE_DIFF} align="center">△</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {listData.map(output => (
                    <TableRow key={output.chargingDate} >
                      <TableCell width={COLUMN_WIDTH.CHARGING_DATE} align="left">{output.chargingDate}</TableCell>
                      <TableCell width={COLUMN_WIDTH.CHARGING_AMOUNT} align="right">{numeral(output.sumWh).format('0,0')}</TableCell>
                      <TableCell width={COLUMN_WIDTH.CHARGING_AMOUNT_DIFF} align="right">
                        {output.diff_Wh > 0 ?
                          (<span className={classes.green}><strong>▲</strong>{numeral(output.diff_Wh).format('0,0')}</span>) :
                          (<span className={classes.red}><strong>▼</strong>{numeral(output.diff_Wh).format('0,0')}</span>)}
                      </TableCell>
                      <TableCell width={COLUMN_WIDTH.CHARGING_TIME} align="right">{output.amountUsageTime}</TableCell>
                      <TableCell width={COLUMN_WIDTH.CHARGING_TIME_DIFF} align="center">
                        {output.diff_time > 0 ?
                          (<span className={classes.green}><strong>▲</strong>{output.diff_time_str}</span>) :
                          (<span className={classes.red}><strong>▼</strong>{output.diff_time_str}</span>)}
                      </TableCell>
                      <TableCell width={COLUMN_WIDTH.CHARGING_COUNT} align='right'>{numeral(output.cnt).format('0,0')}</TableCell>
                      <TableCell width={COLUMN_WIDTH.CHARGING_COUNT_DIFF} align="center">
                        {output.diff_cnt > 0 ?
                          (<span className={classes.green}><strong>▲</strong>{numeral(output.diff_cnt).format('0,0')}</span>) :
                          (<span className={classes.red}><strong>▼</strong>{numeral(output.diff_cnt).format('0,0')}</span>)}
                      </TableCell>
                      <TableCell width={COLUMN_WIDTH.CHARGING_PRICE} align='right'>{numeral(output.amountPrice).format('0,0')}</TableCell>
                      <TableCell width={COLUMN_WIDTH.CHARGING_PRICE_DIFF} align="center">
                        {output.diff_amountPrice > 0 ?
                          (<span className={classes.green}><strong>▲</strong>{numeral(output.diff_amountPrice).format('0,0')}</span>) :
                          (<span className={classes.red}><strong>▼</strong>{numeral(output.diff_amountPrice).format('0,0')}</span>)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>

            </TableContainer>
          </div>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
};


LastChargingTable.propTypes = {
  className: PropTypes.string
};

let mapStateToProps = (state) => {
  return {
    user: state.user
  };
}


export default connect(mapStateToProps)(LastChargingTable);

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 16
  },
  inner: {
    // minWidth: 800
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  table: {
    // minWidth:650,
  },
  title: {
    fontWeight: 600,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  green: {
    color: theme.palette.success.light,
    fontWeight: 400,
  },
  red: {
    color: colors.red[400],
    fontWeight: 400,
  }
}));
