import React, {useState, useEffect} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Avatar, Typography } from '@material-ui/core';

import { connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import { tempSetUser} from 'actions/user.js';

import { USER_AUTH } from 'common/constants';



const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  avatar: {
    width: 60,
    height: 60
  },
  name: {
    marginTop: theme.spacing(1)
  }
}));


const Profile = props => {
  const { className, tempSetUser, user, ...rest } = props;

  const classes = useStyles();

  const user_json = {
    // name: 'beak san',
    // name: props.user.u_name ,
    // avatar: '/images/avatars/avatar_11.png',
    avatar: '/images/avatars/avatar_default2.png',
    // bio: 'Brain Director',
    // bio: props.user.u_group || '로그아웃 상태',
  };

  const [userState, setUserState] = useState({
    name: props.user.admin_id || '로그아웃 상태',
    avatar: '/images/avatars/avatar_default2.png',
    // bio: props.user.admin_id || '로그아웃 상태',
    // bio: props.user.group || '로그아웃 상태',
  });


  const loadUser = () => {
    const userjson = localStorage.getItem('userinfo');

    if(!userjson) return;

    let local_user = JSON.parse(userjson);

    tempSetUser(local_user);
    setUserState( userState => ({
      name: local_user.admin_name,
      user_auth: local_user.user_auth,
    }));

    // console.log('Profile: userinfo -> dispatch: ', user);
  }


  useEffect(() => {

    //TODO: Routes.js의 loadUser와 중복된다.
    loadUser();

  },[]);


  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Avatar
        alt="Person"
        className={classes.avatar}
        component={RouterLink}
        src={user_json.avatar}
        to="/account"
        variant='square'
      />
      <Typography variant="h4" align='center'>{userState.name}</Typography>
      <Typography variant="body2">{userState.user_auth === USER_AUTH.ADMIN ? '관리자':'회원사'}</Typography>
    </div>
  );
};


Profile.propTypes = {
  className: PropTypes.string
};


const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    tempSetUser},
    dispatch)
}

let mapStateToProps = (state) => {
  return {
    user: state.user
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Profile);
