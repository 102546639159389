import React from "react";
import { makeStyles } from "@material-ui/styles";
import PropTypes from 'prop-types';
import ChargerAddForm from "./ChargerAddForm";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));


const ChargerAdd = (props) => {

  const {className, history, ...rest } = props;
  const classes = useStyles();
 
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <ChargerAddForm history={history} />
      </div>
    </div>
  );
};

ChargerAdd.propTypes = {
  history: PropTypes.object
};

export default ChargerAdd;
