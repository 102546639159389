import React from "react";
import { makeStyles } from "@material-ui/styles";
import { ChargerModelList } from "./components";
import { Link as RouterLink } from "react-router-dom";
import { Button, SvgIcon } from "@material-ui/core";
import {PlusCircle as PlusCircleIcon } from "react-feather";
import { connect, useSelector} from 'react-redux';
import { USER_AUTH } from 'common/constants';

const Notices = (props) => {
  const classes = useStyles();

  //NOTE: 관리자가 아니면, dashboard 화면으로 전이함.
  const userAuth = useSelector((state) => state.user.user_auth);
  if(userAuth === USER_AUTH.MEMBER) {
    window.location.href = '/';
  }


  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <span className={classes.spacer} />
        <Button
          color="primary"
          variant="contained"
          size="large"
          component={RouterLink} to={`/settings/chargermodel/add`}
        >
          <SvgIcon fontSize="small" className={classes.actionIcon}>
            <PlusCircleIcon />
          </SvgIcon>
          충전기모델 추가
        </Button>
      </div>

      <div className={classes.content}>
        <ChargerModelList />
      </div>
    </div>
  );
};

let mapStateToProps = (state) => {
  return {
    user: state.user
  };
}

export default connect(
  mapStateToProps,
)(Notices);


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  actionIcon: {
    marginRight: theme.spacing(1)
  }
}));