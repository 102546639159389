import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Formik } from "formik";
import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Typography,

  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,

  Divider,

  FormControlLabel,
  Checkbox,
} from "@material-ui/core";

import { NEONPV_CONFIG } from "config.js";
import { StationSubRegion } from "./components";
import DaumPostcode from 'react-daum-postcode';
import md5 from 'md5';


const MemberUpdateForm = (props) => {
  const { className, history, memberInfo, ...rest } = props;
  const classes = useStyles();

  const ref = useRef(null);
  const [isOpenPost, setIsOpenPost] = useState(false);

  const onChangeOpenPost = () => {
    setIsOpenPost(!isOpenPost);
  };


  const onCompletePost = (data) => {
    console.log(data);
    let fullAddr = data.address; //도로명 주소
    let extraAddr = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddr += data.bname; //(구) 동 이름
      }
      if (data.buildingName !== '') {
        extraAddr += extraAddr !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      // fullAddr += extraAddr !== '' ? ` (${extraAddr})` : '';
      extraAddr = extraAddr !== '' ? ` (${extraAddr})` : '';
    }

    // ref.current.setFieldValue("memberAddr", data.address);
    ref.current.values.memberPostCode = data.zonecode;
    ref.current.values.memberAddr = data.address;
    ref.current.values.memberAddrDetail = extraAddr;

    setIsOpenPost(false);
  };


  const [openDialog, setOpenDialog] = useState(false);

  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogMessage, setDialogMessage] = useState('');


  const doUpdatePassword = (event, newPassword, passwordConfirm) => {
    event.preventDefault();

    if ( newPassword !== passwordConfirm ) {
      alert("비밀번호가 일치하지 않습니다.");
      return;
    }

    fetch(`${NEONPV_CONFIG.HOSTNAME}/member/updateMemberPassword`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        member_id: memberInfo.member_id,
        member_password: md5(newPassword)
      }),
    })
    .then((response) => {
      return response.json();
    })
    .then((responseData) => {
      if (responseData.resultCode === "000") {
        alert("비밀번호 업데이트에 성공했습니다.");

      } else {
        console.log(responseData);
        //에러
        alert(`비밀번호 업데이트에 실패했습니다.\nError: (${responseData.resultCode}):${responseData.resultMessage}`);
      }
    })
    .catch((error) => {
      console.error(error);
      alert(`비밀번호 업데이트에 실패했습니다.\nError: ${error}`);
    });
  }


  const validate = (values) => {
    let errors = {};

    //비밀번호 일치 여부 check
    if (values.memberPassword !== values.memberPasswordConfirm) {
      errors.memberPasswordConfirm = "비밀번호가 일치하지 않습니다.";
    }

    return errors;
  }


  const handleSubmit = async (event, values) => {
    event.preventDefault();


    fetch(`${NEONPV_CONFIG.HOSTNAME}/member/updateMemberInfo`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        member_id: memberInfo.member_id,
        member_name: values.memberName,
        member_telno: values.memberTelNo,
        member_postcode: values.memberPostCode,
        member_addr1: values.memberAddr,
        member_addr2: values.memberAddrDetail,
        member_admin_name: values.adminName,
        member_admin_telno: values.adminTelNo,
        member_admin_email: values.adminEmail,
        member_note: values.note,
        member_bid: values.member_bid,
        member_bkey: values.member_bkey,

        member_env_integrated: values.member_env_integrated === true ? '1' : '0',
      }),
    })
    .then((response) => {
      return response.json();
    })
    .then((responseData) => {
      if (responseData.resultCode === "000") {
        alert("업데이트에 성공했습니다.");

        // setDialogTitle('성공');
        // setDialogMessage('업데이트에 성공했습니다.');
        // setOpenDialog(true);

        setTimeout(() => {
          history.goBack();
        }, 500);
      } else {
        console.log(responseData);
        //에러
        // alert(`업데이트에 실패했습니다.\nError: ${responseData.resultMessage}`);
        setDialogTitle('주의');
        setDialogMessage(`업데이트에 실패했습니다.\nError: ${responseData.resultMessage}`);
        setOpenDialog(true);
      }
    })
    .catch((error) => {
      console.error(error);
      // alert("업데이트에 실패했습니다.");
      setDialogTitle('주의');
      setDialogMessage(`업데이트에 실패했습니다.\nError: ${error}`);
      setOpenDialog(true);
    });

  };


  /**
   * 회원사 삭제하기
   * 산하에 충전소에 등록되어 있으면, 삭제 안 되도록 한다.
   */
  const handleRemoveMemberInfo = (event) => {
    event.preventDefault();

    fetch(`${NEONPV_CONFIG.HOSTNAME}/member/removeMemberInfo`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        member_id: memberInfo.member_id,
      }),
    })
    .then((response) => {
      return response.json();
    })
    .then((responseData) => {
      if (responseData.resultCode === "000") {
        alert("회원사 정보 삭제에 성공했습니다.");
        setTimeout(() => {
          history.goBack();
        }, 500);
      } else {
        //에러
        alert(`회원사 정보 삭제에 실패했습니다.\n${responseData.resultMessage}`);
      }
    })
    .catch((error) => {
      console.error(error);
      alert("회원사 정보 삭제에 실패했습니다.");
    });
  }


  const handleBack = () => {
    history.goBack();
  };


  return (

    <div>
    <Dialog open={openDialog} onClose={() => { setOpenDialog(false) }}>
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{dialogMessage}</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={() => { setOpenDialog(false) }}>닫기</Button>
      </DialogActions>
    </Dialog>


    <Formik
      innerRef={ref}
      enableReinitialize={false}
      initialValues={{

        memberPassword: '',
        memberPasswordConfirm: '',

        memberName: memberInfo.member_name,
        memberTelNo: memberInfo.member_telno,

        memberPostCode: memberInfo.member_postcode,
        memberAddr: memberInfo.member_addr1,
        memberAddrDetail: memberInfo.member_addr2,

        adminName: memberInfo.member_admin_name,
        adminTelNo: memberInfo.member_admin_telno,
        adminEmail: memberInfo.member_admin_email,

        note: memberInfo.member_note,
        member_bid: memberInfo.member_bid,
        member_bkey: memberInfo.member_bkey,

        member_env_integrated: memberInfo.member_env_integrated === 1 ? true : false,
      }}
      validate={validate}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        isSubmitting,
        touched,
        values,
        setFieldValue
      }) => (
        <form
          className={clsx(classes.root, className)}
          onSubmit={(e) => handleSubmit(e, values)}
        >
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item md={2} xs={4}>
                  <TextField
                    fullWidth
                    label="회원사ID"
                    name="memberId"
                    disabled
                    value={memberInfo.member_id}
                    variant="outlined"
                    inputProps={{ maxLength: 12 }}
                  />
                </Grid>

                <Grid item md={3} xs={6}>
                  <TextField
                    error={Boolean(touched.memberName && errors.memberName)}
                    fullWidth
                    helperText={touched.memberName && errors.memberName}
                    label="회원사이름"
                    // placeholder="회원사이름"
                    name="memberName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.memberName}
                    variant="outlined"
                    inputProps={{ maxLength: 20 }}
                  />
                </Grid>

                <Grid item md={3} xs={6}>
                  <TextField
                    error={Boolean(touched.memberTelNo && errors.memberTelNo)}
                    fullWidth
                    helperText={touched.memberTelNo && errors.memberTelNo}
                    label="회원사 전화번호"
                    // placeholder="회원사 전화번호"
                    name="memberTelNo"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.memberTelNo}
                    variant="outlined"
                    inputProps={{ maxLength: 20 }}
                  />
                </Grid>

                <Grid item md={4} xs={8}></Grid>


                <Grid container item md={2} xs={4} direction="row" alignItems="center">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.member_env_integrated}
                        onChange={handleChange}
                        name="member_env_integrated"
                        color="primary"
                      />
                      }
                      label="환경부 연동"
                    />
                </Grid>

                <Grid item md={3} xs={6}>
                  <TextField
                    error={Boolean(touched.member_bid && errors.member_bid)}
                    fullWidth
                    helperText={touched.member_bid && errors.member_bid}
                    label="환경부기관ID"
                    name="member_bid"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.member_bid}
                    variant="outlined"
                    inputProps={{ maxLength: 2 }}
                    disabled={values.member_env_integrated === false}
                  />
                </Grid>

                <Grid item md={3} xs={6}>
                  <TextField
                    error={Boolean(touched.member_bkey && errors.member_bkey)}
                    fullWidth
                    helperText={touched.member_bkey && errors.member_bkey}
                    label="환경부기관인증키"
                    name="member_bkey"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.member_bkey}
                    variant="outlined"
                    inputProps={{ maxLength: 16 }}
                    disabled={values.member_env_integrated === false}
                  />
                </Grid>

                <Grid item md={4} xs={8}></Grid>



                <Grid item md={3} xs={6}>
                  <TextField
                    error={Boolean(touched.memberPassword && errors.memberPassword)}
                    fullWidth
                    helperText={touched.memberPassword && errors.memberPassword}
                    label="새 비밀번호"
                    type="password"
                    name="memberPassword"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.memberPassword}
                    variant="outlined"
                    inputProps={{ maxLength: 12 }}
                  />
                </Grid>

                <Grid item md={3} xs={6}>
                  <TextField
                    error={Boolean(touched.memberPasswordConfirm && errors.memberPasswordConfirm)}
                    fullWidth
                    helperText={touched.memberPasswordConfirm && errors.memberPasswordConfirm}
                    label="새 비밀번호 확인"
                    type="password"
                    name="memberPasswordConfirm"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.memberPasswordConfirm}
                    variant="outlined"
                    inputProps={{ maxLength: 12 }}
                  />
                </Grid>

                <Grid container item md={2} xs={4}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      size="large"
                      fullWidth
                      onClick={(e) => doUpdatePassword(e, values.memberPassword, values.memberPasswordConfirm)}
                    >
                      변경하기
                    </Button>
                  </Grid>

                <Grid item md={4} xs={8}></Grid>



                <Grid container item md={1} xs={2}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    size="large"
                    fullWidth
                    // onClick={showChargerSearchDialog}
                    onClick={onChangeOpenPost}
                  >검색</Button>
                </Grid>


                {isOpenPost &&
                  <DaumPostcode autoClose onComplete={onCompletePost}
                    className={classes.postCodeStyle}
                  />}


                <Grid item md={1} xs={2}>
                  <TextField
                    error={Boolean(touched.memberPostCode && errors.memberPostCode)}
                    fullWidth
                    helperText={touched.memberPostCode && errors.memberPostCode}
                    label="우편번호"
                    // placeholder="우편번호"
                    name="memberPostCode"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    // required
                    value={values.memberPostCode}
                    variant="outlined"
                    inputProps={{ maxLength: 8 }}
                  />
                </Grid>

                <Grid item md={3} xs={8}>
                  <TextField
                    error={Boolean(touched.memberAddr && errors.memberAddr)}
                    fullWidth
                    helperText={touched.memberAddr && errors.memberAddr}
                    label="주소"
                    // placeholder="주소"
                    name="memberAddr"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.memberAddr}
                    variant="outlined"
                    inputProps={{ maxLength: 60 }}
                    // FormHelperTextProps={{className:classes.helperText}}
                  />
                </Grid>

                <Grid item md={3} xs={4}>
                  <TextField
                    error={Boolean(touched.memberAddrDetail && errors.memberAddrDetail)}
                    fullWidth
                    helperText={touched.memberAddrDetail && errors.memberAddrDetail}
                    label="상세주소"
                    // placeholder="상세주소"
                    name="memberAddrDetail"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    // required
                    value={values.memberAddrDetail}
                    variant="outlined"
                    inputProps={{ maxLength: 60 }}
                  />
                </Grid>

                <Grid item md={4} xs={4}></Grid>

                <Grid item md={2} xs={4}>
                  <TextField
                    error={Boolean(touched.adminName && errors.adminName)}
                    fullWidth
                    helperText={touched.adminName && errors.adminName}
                    label="관리자 이름"
                    // placeholder="관리자 이름"
                    name="adminName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.adminName}
                    variant="outlined"
                    inputProps={{ maxLength: 20 }}
                  />
                </Grid>

                <Grid item md={3} xs={4}>
                  <TextField
                    error={Boolean(touched.adminTelNo && errors.adminTelNo)}
                    fullWidth
                    helperText={touched.adminTelNo && errors.adminTelNo}
                    label="관리자 전화번호"
                    // placeholder="관리자 전화번호"
                    name="adminTelNo"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.adminTelNo}
                    variant="outlined"
                    inputProps={{ maxLength: 20 }}
                  />
                </Grid>

                <Grid item md={3} xs={4}>
                  <TextField
                    error={Boolean(touched.adminEmail && errors.adminEmail)}
                    fullWidth
                    helperText={touched.adminEmail && errors.adminEmail}
                    label="관리자 이메일"
                    // placeholder="관리자 이메일"
                    name="adminEmail"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.adminEmail}
                    variant="outlined"
                    inputProps={{ maxLength: 40 }}
                  />
                </Grid>
                <Grid item md={4} xs={4}></Grid>

                <Grid item md={8} xs={12}>
                  <TextField
                    error={Boolean(touched.note && errors.note)}
                    fullWidth
                    helperText={touched.note && errors.note}
                    label="비고"
                    // placeholder="비고"
                    name="note"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.note}
                    variant="outlined"
                    inputProps={{ maxLength: 100 }}
                  />
                </Grid>

              </Grid>

              <Divider className={classes.divider} variant="inset" />

              <Grid container spacing={2}
                  style={{
                    paddingLeft: 20,
                    paddingRight: 20,
                    marginBottom: 20,
                    // border: '1px solid #e0e0e0',
                  }}>
                  <Grid item md={12} xs={12}
                    style={{
                      marginTop: 10,
                      backgroundColor: "lightgray"
                    }}>
                    <Typography
                      className={classes.dividerInset}
                      // color="colorTextSecondary"
                      display="block"
                      variant="h4"
                      fullWidth
                      align="center"
                    >
                      운영 충전소 목록
                    </Typography>
                  </Grid>
                  <StationSubRegion memberInfo={memberInfo} />
              </Grid>

              <Divider className={classes.divider} variant="fullWidth" />

              <Grid container item className={classes.action}
                direction="row" alignItems="center" justify="center">

                <Grid container item md={6} xs={6}
                  className={classes.action}
                  direction="row" alignItems="center" justify="flex-end">

                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="large"
                    disabled={isSubmitting}
                    className={classes.action}
                  >
                    회원사 수정
                  </Button>

                  <Button
                    variant="outlined"
                    size="large"
                    onClick={handleBack}
                    className={classes.action}
                  >
                    취소
                  </Button>
                </Grid>

                <Grid
                  container
                  direction="row" alignItems="center" justifyContent="flex-end"
                  item md={6} xs={6}>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    onClick={handleRemoveMemberInfo}
                    className={classes.action}
                  >
                    회원사 삭제
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
    </div>
  );
};

MemberUpdateForm.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object,
};


export default MemberUpdateForm;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  action: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },
  dividerInset: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    color: '#616161'
  },
  divider: {
    margin: theme.spacing(2, 0),
    // color: '#616161'
  },
  postCodeStyle: {
    display: 'block',
    position: 'relative',
    top: '0%',
    width: '400px',
    // height: '400px',
    // padding: '7px',
  },
  helperText:{
    color: 'red',
    "&.MuiInputBase-input": {
      // color: theme.palette.secondary.main,
      color: '#11111',
      // height: 60
    }
  }
}));
