import React from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper
} from '@material-ui/core';

// import NumberFormat from 'react-number-format';


const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    // minWidth: 800
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  table: {
    // minWidth:650,
  }
}));


const GainTable = props => {
  const {className, detailData, selectedValue, selectedDate, ...rest} = props;

  const classes = useStyles();


  return (

    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >

    <CardHeader align='center' title="상세정보" />
    <Divider />

    <CardContent className={classes.content}>
      <PerfectScrollbar>
        <div className={classes.inner}>

        <TableContainer component={Paper}>
      
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell width="60px" align="center">No</TableCell>
                <TableCell>모듈명</TableCell>
                <TableCell width="160px" align="right">Gain</TableCell>
                <TableCell width="140px" align="right">누적발전량(kWh)</TableCell>  
                <TableCell width="160px" align="right">일평균발전시간</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {detailData.map(output => (
                <TableRow hover key={output.label_kr}>
                  <TableCell component="th" scope="row" align="center">{output.no}</TableCell>
                  <TableCell component="th" scope="row">{output.label_kr}</TableCell>
                  <TableCell align="right">{output.gain}%</TableCell>
                  {/* <TableCell align="right"><NumberFormat value={output.day_power_gen} displayType={'text'} thousandSeparator={true} prefix={''} /></TableCell> */}
                  <TableCell align='right'>{output.day_power_hour}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          </TableContainer>
        </div>
      </PerfectScrollbar>
    </CardContent>
    </Card>
  );
};


GainTable.propTypes = {
  className: PropTypes.string
};

export default GainTable;