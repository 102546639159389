import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Formik } from "formik";
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Divider,
  Typography
} from "@material-ui/core";

import {global} from "common/global";


const UnlockConnectorForm = (props) => {
  const { className, history, user, ...rest } = props;
  const classes = useStyles();


  const handleSubmit = (event, values) => {
    event.preventDefault();

    var json =
      `{"command":"UnlockConnector",
        "body": {
          "connectorId": ${values.connectorId}
        }
      }`;

    global.ws.send(json);

  };



  return (

    <div>
      <Formik
        enableReinitialize={false}
        initialValues={{
          connectorId: 1
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          isSubmitting,
          touched,
          values,
          setFieldValue
        }) => (
          <form
            className={clsx(classes.root, className)}
            onSubmit={(e) => handleSubmit(e, values)}
          >

            <Card>
              <CardHeader
                title="커넥터 분리(원격)"
                titleTypographyProps={{ color: 'inherit', align: 'center', variant: 'h3' }}
                // subheader="TestCase - 017_1, 017_2, 030, 031"
                // subheaderTypographyProps={{ color: 'inherit', align: 'left', variant: 'h5' }}
              />


              <CardContent>

                {/* <Grid item md={12} spacing={2}>
                  <Typography variant="h6">017_1 - PASS</Typography>
                  <Typography variant="h6">017_2 - PASS</Typography>
                  <Typography variant="h6">030 - PASS</Typography>
                  <Typography variant="h6">031 - PASS</Typography>
                </Grid> */}

                <div className={classes.content}></div>

                <Grid item md={12} spacing={0}>
                  <Typography variant="h4">파라미터</Typography>
                </Grid>
                <Divider variant="inset" />


                <Grid container spacing={2} >

                  <Grid container item md={12} spacing={0}></Grid>

                  <Grid item md={2} xs={4}>
                  <TextField
                      error={Boolean(touched.connectorId && errors.connectorId)}
                      fullWidth
                      helperText={touched.connectorId && errors.connectorId}
                      label="Connector Id"
                      name="connectorId"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.connectorId}
                      variant="outlined"
                      required
                    />
                  </Grid>

                </Grid>


                <Grid container item
                  className={classes.action}
                  direction="row" alignItems="center" justify="center">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="large"
                    disabled={isSubmitting}
                    className={classes.action}
                  >
                    Perform
                  </Button>

                </Grid>
              </CardContent>
            </Card>
          </form>
        )}
      </Formik>
    </div>
  );
};

UnlockConnectorForm.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object,
};


let mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps)(UnlockConnectorForm);


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    direction: "row",
    alignItems: "center",
    justify: "center"
  },
  content2: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  action: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },
  queryField: {
    // width: 300
    flexBasis: 260,
    marginRight: 20,
  },
  bulkAction: {
    marginLeft: theme.spacing(2),
  },

  divider: {
    margin: theme.spacing(2, 0)
  },
}));
