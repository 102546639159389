import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  TextField,
  Checkbox,

  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,

  Box,
  InputAdornment,
  SvgIcon,

  TablePagination,
} from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { NEONPV_CONFIG } from "config.js";
import { withRouter } from "react-router-dom";


const COLUMN_WIDTH2 = {
  STAT: '24%',
  ADDR: '24%', //
  USETIME: '14%', //30
  BUSINESSNAME: '14%', //50
  BUSINESSTEL: '10%', //80
  MEMBERNAME: '10%', //100
}



const StationSubRegion = props => {

  const { className, memberInfo, ...rest } = props;

  const classes = useStyles();

  const [openDialog, setOpenDialog] = useState(false);
  const [queryStationName, setQueryStationName] = useState("");
  const [queryStationAddr, setQueryStationAddr] = useState("");
  const [listData, setListData] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const startRow = (page * rowsPerPage);


  const [selectRowIndex, setSelectRowIndex] = useState();

  const [myStationList, setMyStationList] = useState([]);
  const [lastRowId, setLastRowId] = useState(0);


  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
  };


  async function loadMyStationList() {

    var url = `${NEONPV_CONFIG.HOSTNAME}/member/queryMyStationList?member_id=${memberInfo.member_id}`;
    const opt = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };

    let regionList = [];
    let response = await fetch(url, opt);
    let responseData = await response.json();

    console.log("responseData: ", responseData);

    let rowid = 1;

    if (responseData.resultData){

      responseData.resultData.map(function (row) {
        regionList.push({
          id: rowid++,
          member_id: row.member_id,
          statId: row.statId,
          statName: row.statName,
          statAddr: row.addr,
          statTelNo: row.businessTel
        });

        setLastRowId(rowid);
      });
    }

    setMyStationList(regionList);
  }


  const handleQueryStationNameChange = (event) => {
    event.persist();
    setQueryStationName(event.target.value);
  };

  const handleQueryStationAddrChange = (event) => {
    event.persist();
    setQueryStationAddr(event.target.value);
  };

  /** 충전소 검색창에서의 초기화버튼 */
  const handleClear = () => {
    setQueryStationName("");
    setQueryStationAddr("");
    setListData([]);
  }

  /** 충전소 검색창에서의 검색버튼 */
  const handleStationSearch = (event) => {
    searchStationData(queryStationName, queryStationAddr);
  };

  /**
   * 등록할 충전소 검색.
   * 다만, 이미 타 회원사에 등록된 충전소는 검색되어도, 등록되지 않아야 한다.
   * 허용되면 중복 등록이 될 수 있거나, 기존 회원사 - 충전소 관계가 없어질 수 있다.
   * @param {*} statName 
   * @param {*} addr 
   */
  const searchStationData = (statName, addr) => {

    var url = `${NEONPV_CONFIG.HOSTNAME}/station/queryStationList?`;

    var params = "";
    if (statName) {
      params = "statName=" + statName;
    }

    if (addr) {
      params += params.length > 0 ? "&" : "";
      params += "addr=" + addr;
    }

    url += params;


    fetch(url)
      .then((res) => res.json())
      .then((snapshot) => {
        console.log(snapshot);
        var list = [];
        if (snapshot.resultData != null) {
          snapshot.resultData.forEach((doc) => {
            list.push(doc);
          });
        }
        setListData(list);
      });
  };


  /** 충전소 검색창에서 충전소 열을 클릭 */
  const selectStation = (event, module) => {
    // console.log("selected station: " + JSON.stringify(module));

    const list = [...myStationList];

    list[selectRowIndex] = {
      ...list[selectRowIndex],
      rowChecked: false,
      statId: module.statId,
      statName: module.statName,
      statAddr: module.addr,
      statTelNo: module.businessTel
    }

    setMyStationList(list);

    console.log("list: ", JSON.stringify(list));
    setOpenDialog(false);
  }


  /** 삭제 checkbox 클릭 */
  const checkBoxRowClick = (e, index) => {

    const list = [...myStationList];

    list[index] = {
      ...list[index],
      rowChecked: e.target.checked
    };

    // console.log("list: " + JSON.stringify(list));

    setMyStationList(list);
  }


  const handleAddRow = () => {
    const list = [...myStationList];

    list.push({
      id: lastRowId,
      rowChecked: false,
      statId: '',
      statName: '',
      statAddr: '',
      statTelNo: ''
    });

    setMyStationList(list);
    setLastRowId(lastRowId + 1);
  }


  const handleRemoveBtn = () => {
    const list = [...myStationList];

    const delete_ids = [];

    for (var i = 0; i < list.length; i++) {
      if (list[i].rowChecked == true) {
        if (list[i]._id != '') {
          delete_ids.push(list[i]._id);
        }

        list.splice(i, 1);
        i--;
      }
    }

    setMyStationList(list);

    // if (delete_ids.length > 0) {
    //   fetch(`${NEONPV_CONFIG.HOSTNAME}/device/deleteSubRegions`, {
    //     method: "POST",
    //     headers: { "Content-Type": "application/json" },
    //     body: JSON.stringify({
    //       delete_ids: delete_ids,
    //     }),
    //   })
    //     .then((response) => {
    //       return response.json();
    //     })
    //     .then((responseData) => {
    //       if (responseData.resultCode === "000") {
    //         // alert("영역 저장에 성공했습니다.");

    //       } else {
    //         //에러
    //         console.log(responseData.message);
    //         alert(`영역 삭제에 실패했습니다.\n${responseData.message}`);
    //       }
    //     })
    //     .catch((error) => {
    //       console.error(error);
    //       alert("영역 삭제에 실패했습니다.");
    //     });
    // }

  };



  const handleSaveBtn = () => {

    console.log("myStationList: " + JSON.stringify(myStationList));

    let stationList = [];
    myStationList.forEach((subRegion) => {
      stationList.push({ member_id: memberInfo.member_id, statId: subRegion.statId });
    });

    // console.log(stationList);

    fetch(`${NEONPV_CONFIG.HOSTNAME}/member/setStationInfo`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        member_id: memberInfo.member_id,
        stationList: stationList
      }),
    }).then((response) => {
      return response.json();
    }).then((responseData) => {
      if (responseData.resultCode === "000") {
        alert("운영 충전소 저장에 성공했습니다.");

        setTimeout(() => {
          /** refresh */
          loadMyStationList();
        }, 100);

      } else {
        //에러
        console.log(responseData.message);
        alert(`운영 충전소 저장에 실패했습니다.\n${responseData.message}`);
      }
    })
    .catch((error) => {
      console.error(error);
      alert("운영 충전소 저장에 실패했습니다.");
    });
  }


  useEffect(() => {
    loadMyStationList();
  }, []);

  return (

    <div style={{
      minHeight: 160,
      width: '100%',
      // paddingLeft: 100,
      // alignContent:'center',
      // alignItems:'center',
      // alignSelf:'center'
    }}>

      <Dialog open={openDialog} onClose={() => { setOpenDialog(false) }}
        fullWidth={true}
        maxWidth="lg"
      >
        <DialogTitle align="center"><Typography variant="h3">충전소 검색</Typography></DialogTitle>
        <DialogContent>
          <center><Typography variant="body2">이미 회원사에 등록된 충전소는 등록되지 않습니다.<br/>
          등록을 원할 경우, 해당 회원사관리에서 충전소 등록을 제거해야 할 필요가 있습니다. </Typography></center>

          <Box m={1} p={1}>
            <Box mt={2}
              display="flex"
              alignItems="between"
              justifyContent="start"
              flexGrow={1}
            >
              <TextField
                className={classes.queryField}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                }}
                onChange={handleQueryStationNameChange}
                label="충전소 명칭"
                placeholder="충전소 명칭"
                value={queryStationName}
                variant="outlined"
              />

              <TextField
                className={classes.queryField}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                }}
                onChange={handleQueryStationAddrChange}
                label="충전소 주소"
                placeholder="충전소 주소"
                value={queryStationAddr}
                variant="outlined"
              />


              <Button
                variant="contained"
                color="primary"
                size="large"
                className={classes.bulkAction}
                onClick={handleStationSearch}
              >
                검색
              </Button>

              <Button
              variant="outlined"
              size="large"
              className={classes.bulkAction}
              onClick={handleClear}
            >
              초기화
            </Button>
            </Box>
          </Box>

          <Box m={1} p={1}>
            <PerfectScrollbar>
              <div className={classes.inner}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {/* <TableCell>선택</TableCell> */}
                      <TableCell width={COLUMN_WIDTH2.STAT} align="center">충전소</TableCell>
                      <TableCell width={COLUMN_WIDTH2.ADDR} align="center">주소</TableCell>
                      <TableCell width={COLUMN_WIDTH2.USETIME} align="center">사용시간</TableCell>
                      <TableCell width={COLUMN_WIDTH2.BUSINESSNAME} align="center">주체</TableCell>
                      <TableCell width={COLUMN_WIDTH2.BUSINESSTEL} align="center">전화번호</TableCell>
                      <TableCell width={COLUMN_WIDTH2.MEMBERNAME} align="center">회원사</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {listData.slice(0 + startRow, rowsPerPage + (page * rowsPerPage)).map(module => (

                      <TableRow
                        hover
                        className={classes.tableRow}
                        key={module.statId}
                        // onClick={(event) => { selectStation(event, module) }
                        onClick={(event) => ( module.member_id.length == 0 && selectStation(event, module) ) }
                      >

                        {/* <TableCell>{module.member_id == '' ? 'm' : 'c'}</TableCell> */}
                        <TableCell width={COLUMN_WIDTH2.STAT}>
                          {module.member_id.length == 0 ? module.statName : <Typography style={{color:"lightgray"}}>{module.statName}</Typography>}
                          </TableCell>
                        <TableCell width={COLUMN_WIDTH2.ADDR}>
                          {module.member_id.length == 0 ? <Typography variant="body2">{module.addr}</Typography> : <Typography variant="body2" style={{color:"lightgray"}}>{module.addr}</Typography>}
                          {/* <Typography variant="body2">{module.addr}</Typography> */}
                          </TableCell>
                        <TableCell width={COLUMN_WIDTH2.USETIME}>
                          {/* <Typography variant="body2" style={{color:"lightgray"}}>{module.useTime}</Typography> */}
                          {module.member_id.length == 0 ? <Typography variant="body2">{module.useTime}</Typography> : <Typography variant="body2" style={{color:"lightgray"}}>{module.useTime}</Typography>}
                          </TableCell>
                        <TableCell width={COLUMN_WIDTH2.BUSINESSNAME}>
                          {/* {module.businessName} */}
                          {module.member_id.length == 0 ? <Typography variant="body2">{module.businessName}</Typography> : <Typography variant="body2" style={{color:"lightgray"}}>{module.businessName}</Typography>}
                        </TableCell>
                        <TableCell width={COLUMN_WIDTH2.BUSINESSTEL}>
                          {/* {module.businessTel} */}
                          {module.member_id.length == 0 ? <Typography variant="body2">{module.businessTel}</Typography> : <Typography variant="body2" style={{color:"lightgray"}}>{module.businessTel}</Typography>}
                        </TableCell>
                        <TableCell width={COLUMN_WIDTH2.MEMBERNAME}>
                          {/* {module.member_name} */}
                          {module.member_id.length == 0 ? <Typography variant="body2">{module.member_name}</Typography> : <Typography variant="body2" style={{color:"lightgray"}}>{module.member_name}</Typography>}
                        </TableCell>
                      </TableRow>
                    ))}

                  </TableBody>
                </Table>
              </div>
            </PerfectScrollbar>
          </Box>
        </DialogContent>

        <DialogActions
          style={{ justifyContent: "space-between",
            paddingLeft: 40,
            paddingRight: 40,
          }}
        >

          <Button variant="outlined" color="send" onClick={() => { setOpenDialog(false) }}>닫기</Button>

          <TablePagination
            component="div"
            count={listData.length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />

        </DialogActions>
      </Dialog>


      <Table>
        <TableHead>
          <TableRow>
            <TableCell width={COLUMN_WIDTH.FLAG} align="center">삭제</TableCell>
            <TableCell width={COLUMN_WIDTH.FIND_STAT} align="center">충전소 검색</TableCell>
            <TableCell width={COLUMN_WIDTH.STAT_ID} align="center">충전소ID</TableCell>
            <TableCell width={COLUMN_WIDTH.STAT_NAME} align="center">충전소 명칭</TableCell>
            <TableCell width={COLUMN_WIDTH.STAT_ADDR} align="center">주소</TableCell>
            <TableCell width={COLUMN_WIDTH.STAT_TEL_NO} align="center">전화번호</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {myStationList.map((row, i) => (
            <TableRow
              className={classes.tableRow}
              key={row.id}
            >
              <TableCell width={COLUMN_WIDTH.FLAG} align="center" >
                <Checkbox
                  defaultChecked={false}
                  color="primary"
                  onClick={event => checkBoxRowClick(event, i)}
                  checked={row.rowChecked}
                />
              </TableCell>

              <TableCell width={COLUMN_WIDTH.FIND_STAT}>
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  fullWidth
                  onClick={(e) => {
                    setSelectRowIndex(i);
                    setOpenDialog(true);
                  }}
                >검색</Button>
              </TableCell>

              <TableCell width={COLUMN_WIDTH.STAT_ID}>
                <TextField
                  margin="dense"
                  variant="outlined"
                  value={row.statId}
                  name="StatId"
                  fullWidth
                  disabled
                // onChange={(e) => handleInputChange(e, i)}
                />
              </TableCell>

              <TableCell width={COLUMN_WIDTH.STAT_NAME}>
                <TextField
                  margin="dense"
                  variant="outlined"
                  value={row.statName}
                  name="statName"
                  fullWidth
                  disabled
                // onChange={(e) => handleInputChange(e, i)}
                />
              </TableCell>

              <TableCell width={COLUMN_WIDTH.STAT_ADDR}>
                <TextField
                  margin="dense"
                  variant="outlined"
                  value={row.statAddr}
                  name="statAddr"
                  fullWidth
                  disabled
                // onChange={(e) => handleInputChange(e, i)}
                />
              </TableCell>

              <TableCell width={COLUMN_WIDTH.STAT_TEL_NO}>
                <TextField
                  margin="dense"
                  variant="outlined"
                  value={row.statTelNo}
                  name="statTelNo"
                  fullWidth
                  disabled
                // onChange={(e) => handleInputChange(e, i)}
                />
              </TableCell>
            </TableRow>
          ))}

        </TableBody>
      </Table>


      <Grid container style={{ marginTop: 20 }}>
        <Grid item md={12} align="center">
          <Button
            style={{ marginRight: 30 }}
            variant="contained" align="center" color="default" onClick={handleAddRow}>
            행 추가
          </Button>

          <Button
            style={{ marginRight: 30 }}
            variant="contained" align="center" color="default" onClick={handleRemoveBtn}>
            선택 행 삭제
          </Button>

          <Button
            style={{ marginRight: 30 }}
            variant="contained" align="center" color="primary" onClick={handleSaveBtn}>
            저장
          </Button>
        </Grid>
      </Grid>

    </div>
  );
};


StationSubRegion.propTypes = {
  className: PropTypes.string,
};

export default withRouter(StationSubRegion);


const COLUMN_WIDTH = {
  FLAG: '5%',
  FIND_STAT: '10%', //
  STAT_ID: '10%', //30
  STAT_NAME: '25%', //50
  STAT_ADDR: '30%', //80
  STAT_TEL_NO: '20%', //100
}




const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  tableRow: {
    height: 20,
  },
  queryField: {
    flexBasis: 220,
    marginRight: 20,
  },

  bulkAction: {
    marginLeft: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
}));