
export const QUERY_STATION_MEMBER_NAME = "QUERY_STATION_MEMBER_NAME";
export const QUERY_PARAM_STATION_ID = "QUERY_PARAM_STATION_ID";
export const QUERY_PARAM_STATION_NAME = "QUERY_PARAM_STATION_NAME";
export const QUERY_PARAM_STATION_ADDR = "QUERY_PARAM_STATION_ADDR";
export const QUERY_STATION_ADMIN_NAME = "QUERY_STATION_ADMIN_NAME";


export const QUERY_CHARGER_MEMBER_NAME = "QUERY_CHARGER_MEMBER_NAME";
export const QUERY_PARAM_CHARGER_STAT_ID = "QUERY_PARAM_CHARGER_STAT_ID";
export const QUERY_PARAM_STATION_NAME2 = "QUERY_PARAM_STATION_NAME2";
export const QUERY_PARAM_STATION_ADDR2 = "QUERY_PARAM_STATION_ADDR2";
export const QUERY_PARAM_CHARGER_NO = "QUERY_PARAM_CHARGER_NO";
export const QUERY_PARAM_CHARGER_STATUS = "QUERY_PARAM_CHARGER_STATUS";


export const updateStationMemberName = memberParam1 => {
  return {
    type: QUERY_STATION_MEMBER_NAME,
    payload: memberParam1,
  }
}

export const updateQueryStationId = stationParam1 => {
  return{
    type: QUERY_PARAM_STATION_ID,
    payload: stationParam1
  }
}

export const updateQueryStationName = stationParam1 => {
  return{
    type: QUERY_PARAM_STATION_NAME,
    payload: stationParam1
  }
}

export const updateQueryStationAddr = stationParam1 => {
  return{
    type: QUERY_PARAM_STATION_ADDR,
    payload: stationParam1
  }
}

export const updateStationAdminName = stationParam1 => {
  return {
    type: QUERY_STATION_ADMIN_NAME,
    payload: stationParam1
  }
}


export const updateQueryChargerMemberName = chargerParam1 => {
  return {
    type: QUERY_CHARGER_MEMBER_NAME,
    payload: chargerParam1
  }
}

// 충전기 검색에서 충전소ID
export const updateQueryChargerStatId = param1 => {
  return {
    type: QUERY_PARAM_CHARGER_STAT_ID,
    payload: param1
  }
}

export const updateQueryStationName2 = stationParam1 => {
  return{
    type: QUERY_PARAM_STATION_NAME2,
    payload: stationParam1
  }
}

export const updateQueryStationAddr2 = stationParam1 => {
  return{
    type: QUERY_PARAM_STATION_ADDR2,
    payload: stationParam1
  }
}

export const updateQueryChargerNo = chargerNo => {
  return{
    type: QUERY_PARAM_CHARGER_NO,
    payload: chargerNo
  }
}

export const updateQueryChargerStatus = chargerStatus => {
  return{
    type: QUERY_PARAM_CHARGER_STATUS,
    payload: chargerStatus
  }
}


/**
 * 회원관리 조회 화면, 검색 파라미트
 */
export const QUERY_PARAM_LOGIN_TYPE = "QUERY_PARAM_LOGIN_TYPE";
export const QUERY_PARAM_USER_CARD_NUMBER = "QUERY_PARAM_USER_CARD_NUMBER";
export const QUERY_PARAM_USER_EMAIL = "QUERY_PARAM_USER_EMAIL";
export const QUERY_PARAM_USER_NICKNAME = "QUERY_PARAM_USER_NICKNAME";


export const updateQueryLoginType = param1 => {
  return{
    type: QUERY_PARAM_LOGIN_TYPE,
    payload: param1
  }
}

export const updateQueryUserCardNumber = param1 => {
  return{
    type: QUERY_PARAM_USER_CARD_NUMBER,
    payload: param1
  }
}

export const updateQueryUserEmail = param1 => {
  return{
    type: QUERY_PARAM_USER_EMAIL,
    payload: param1
  }
}

export const updateQueryUserNickname = param1 => {
  return{
    type: QUERY_PARAM_USER_NICKNAME,
    payload: param1
  }
}


/**
 * 충전 이력 조회 검색어
 */
export const QUERY_PARAM_USAGE_MEMBER_NAME = "query_param_usage_member_name";

// 충전기사용 시작 일시
export const QUERY_PARAM_USAGE_CHARGER_START_TIME = "query_param_usage_charger_start_time";
// 충전기사용 종료 일시
export const QUERY_PARAM_USAGE_CHARGER_END_TIME = "query_param_usage_charger_end_time";
// 충전기사용 충전소 이름
export const QUERY_PARAM_USAGE_CHARGER_STAT_NAME = "query_param_usage_charger_stat_name";


export const updateQueryUsageMemberName = param1 => {
  return {
    type: QUERY_PARAM_USAGE_MEMBER_NAME,
    payload: param1
  }
}

export const updateQueryUsageChargerStartTime = param1 => {
  return {
    type: QUERY_PARAM_USAGE_CHARGER_START_TIME,
    payload: param1
  }
}

export const updateQueryUsageChargerEndTime = param1 => {
  return {
    type: QUERY_PARAM_USAGE_CHARGER_END_TIME,
    payload: param1
  }
}

export const updateQueryUsageChargerStatName = param1 => {
  return {
    type: QUERY_PARAM_USAGE_CHARGER_STAT_NAME,
    payload: param1
  }
}

/**
 * 회원 충전 이력 조회
 */
// 회원충전 회원사
export const QUERY_PARAM_USAGE_USER_MEMEBR_NAME = "query_param_usage_user_memebr_name";
// 회원충전 시작 일시
export const QUERY_PARAM_USAGE_USER_START_TIME = "query_param_usage_user_start_time";
// 회원충전 종료 일시
export const QUERY_PARAM_USAGE_USER_END_TIME = "query_param_usage_user_end_time";
// 회원충전 회원 이메일
export const QUERY_PARAM_USAGE_USER_EMAIL = "query_param_usage_user_email";
// 회원충전 회원 닉네임
export const QUERY_PARAM_USAGE_USER_NICKNAME = "query_param_usage_user_nickname";

export const updateQueryUsageUserMemberName = param1 => {
  return {
    type: QUERY_PARAM_USAGE_USER_MEMEBR_NAME,
    paylaod: param1
  }
}

export const updateQueryUsageUserStartTime = param1 => {
  return {
    type: QUERY_PARAM_USAGE_USER_START_TIME,
    payload: param1
  }
}

export const updateQueryUsageUserEndTime = param1 => {
  return {
    type: QUERY_PARAM_USAGE_USER_END_TIME,
    payload: param1
  }
}

export const updateQueryUsageUserEmail = param1 => {
  return {
    type: QUERY_PARAM_USAGE_USER_EMAIL,
    payload: param1
  }
}

export const updateQueryUsageUserNickName = param1 => {
  return {
    type: QUERY_PARAM_USAGE_USER_NICKNAME,
    payload: param1
  }
}



/**
 * 고장신고 조회 검색어
 */
export const QUERY_PARAM_MALFUNCTION_MEMBER_NAME = "query_param_malfunction_member_name";
export const QUERY_PARAM_MALFUNCTION_REPORT_START_TIME = "query_param_malfunction_report_start_time";
export const QUERY_PARAM_MALFUNCTION_REPORT_END_TIME = "query_param_malfunction_report_end_time";
export const QUERY_PARAM_MALFUNCTION_STAT_NAME = "query_param_malfunction_stat_name";
export const QUERY_PARAM_MALFUNCTION_REGION = "query_param_malfunction_region";
export const QUERY_PARAM_MALFUNCTION_STATUS = "query_param_malfunction_status";

export const updateMalfunctionMemberName = param1 => {
  return {
    type: QUERY_PARAM_MALFUNCTION_MEMBER_NAME,
    payload: param1
  }
}

export const updateMalFunctionReportStartTime = param1 => {
  return {
    type: QUERY_PARAM_MALFUNCTION_REPORT_START_TIME,
    payload: param1
  }
}

export const updateMalFunctionReportEndTime = param1 => {
  return {
    type: QUERY_PARAM_MALFUNCTION_REPORT_END_TIME,
    payload: param1
  }
}


export const updateMalFunctionStatName = param1 => {
  return {
    type: QUERY_PARAM_MALFUNCTION_STAT_NAME,
    payload: param1
  }
}

export const updateMalFunctionRegion = param1 => {
  return {
    type: QUERY_PARAM_MALFUNCTION_REGION,
    payload: param1
  }
}

export const updateMalFunctionStatus = param1 => {
  return {
    type: QUERY_PARAM_MALFUNCTION_STATUS,
    payload: param1
  }
}


/**
 * 설치신청 조회 검색어
 */
export const QUERY_PARAM_INSTALLATION_MEMBER_NAME = "query_param_installation_member_name";
export const QUERY_PARAM_INSTALLATION_APPLY_START_TIME = "query_param_installation_apply_start_time";
export const QUERY_PARAM_INSTALLATION_APPLY_END_TIME = "query_param_installation_apply_end_time";
export const QUERY_PARAM_INSTALLATION_APPLICANT = "query_param_installation_applicant";
export const QUERY_PARAM_INSTALLATION_REGION = "query_param_installation_region";
export const QUERY_PARAM_INSTALLATION_STATUS = "query_param_installation_status";

export const updateInstallationMemberName = param1 => {
  return {
    type: QUERY_PARAM_INSTALLATION_MEMBER_NAME,
    payload: param1
  }
}

export const updateInstallationApplyStartTime = param1 => {
  return {
    type: QUERY_PARAM_INSTALLATION_APPLY_START_TIME,
    payload: param1
  }
}

export const updateInstallationApplyEndTime = param1 => {
  return {
    type: QUERY_PARAM_INSTALLATION_APPLY_END_TIME,
    payload: param1
  }
}

export const updateInstallationApplicant = param1 => {
  return {
    type: QUERY_PARAM_INSTALLATION_APPLICANT,
    payload: param1
  }
}

export const updateInstallationRegion = param1 => {
  return {
    type: QUERY_PARAM_INSTALLATION_REGION,
    payload: param1
  }
}

export const updateInstallationStatus = param1 => {
  return {
    type: QUERY_PARAM_INSTALLATION_STATUS,
    payload: param1
  }
}



/**
 * 회원사 조회
 */
export const QUERY_PARAM_MEMBER_NAME = "query_param_member_name";
export const QUERY_PARAM_MEMBER_ADDR = "query_param_member_addr";
export const QUERY_PARAM_MEMBER_ADMIN_NAME = "query_param_member_admin_name";
export const QUERY_PARAM_MEMBER_ADMN_TELNO = "query_param_member_admn_telno";

export const updateMemberName = param1 => {
  return {
    type: QUERY_PARAM_MEMBER_NAME,
    payload: param1
  }
}

export const updateMemberAddr = param1 => {
  return {
    type: QUERY_PARAM_MEMBER_ADDR,
    payload: param1
  }
}

export const updateMemberAdminName = param1 => {
  return {
    type: QUERY_PARAM_MEMBER_ADMIN_NAME,
    payload: param1
  }
}

export const updateMemberAdminTelNo = param1 => {
  return {
    type: QUERY_PARAM_MEMBER_ADMN_TELNO,
    payload: param1
  }
}