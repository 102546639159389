// import React, { useState } from 'react';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, withStyles} from '@material-ui/styles';
import { AppBar, Toolbar, 
  // Badge, 
  Typography,
  Hidden, IconButton } from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';
// import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { doLogout } from 'actions/user.js';
import { VERSION_CONFIG } from 'version.js';


const WhiteTextTypography = withStyles({
  root: {
    color: "#FFFFFF",    
  }
})(Typography);

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
    // backgroundColor: theme.palette.white,
    // color: theme.palette.black,
    // color: "#FFFFFF",
    backgroundColor: "#2a3f54",
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  }
}));

const Topbar = props => {
  const { className, onSidebarOpen, doLogout, ...rest } = props;

  const classes = useStyles();

  // const [notifications] = useState([]);

  const handleLogout = event => {
    props.doLogout();
    window.location.href = '/sign-in';
  }

  const logoImageURL = window.location.hostname == "amanocsms.kr" ? "/images/logo_white_amano.png" : "/images/logo_white.png";

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Toolbar>
        <RouterLink to="/">
          <img
            alt="Logo"
            src={logoImageURL}
            width="160px"
          />
        </RouterLink>

        <div style={{ paddingLeft: "30px", display: 'flex', alignItems: 'top'}}>
          <WhiteTextTypography variant="h4">
            HelloCharger Dashboard
          </WhiteTextTypography>

          <div style={{ paddingLeft: "5px" }}>
            <WhiteTextTypography variant="caption">
              ver:{VERSION_CONFIG.RELEASE_NO}
            </WhiteTextTypography>
          </div>
        </div>

        <div className={classes.flexGrow} />
        
        <Hidden mdDown>
          {/* <IconButton color="inherit">
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton> */}
        
          <IconButton
            className={classes.signOutButton}
            color="inherit"
            onClick={handleLogout}
          >
            <InputIcon />
          </IconButton>
        </Hidden>

        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onSidebarOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  // history: PropTypes.object,
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};


const mapDispatchToProps = dispatch => {
  return bindActionCreators({ doLogout }, dispatch)
}

let mapStateToProps = (state) => {
  return {
    user: state.user
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Topbar);
