import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  TextField,
  Select,
  MenuItem,
  Box,
  Typography
} from "@material-ui/core";
import { NEONPV_CONFIG } from "config.js";
import { withRouter } from "react-router-dom";
import { USER_AUTH } from "common/constants";



const CustomTableCell = ({ row, name, onChange, width, label }) => {
  const classes = useStyles();

  if (row) {
    return (
      <TableCell align="right" width={width} className={classes.tablecell}>
        <TextField
          // siz="small"
          // variant="standard"
          variant="outlined"
          // variant="filled"
          name={name}
          // label={label}
          fullWidth
          align="right"
          value={row[name]}
          onChange={onChange}

          inputProps={{
            style: {
              textAlign: "right",
              padding: 8,
            },
            maxLength: 10,
          }}
        />
      </TableCell>
    );
  } else {
    return (<TableCell></TableCell>);
  }
};


const UnitPriceSubRegion = props => {

  const { match, className, history, statId, member_id, user, ...rest } = props;

  const classes = useStyles();

  const [myUnitPriceList, setMyUnitPriceList] = useState([]);

  const [priceType, setPriceType] = useState('lowPrice1');

  const userAuth = useSelector((state) => state.user.user_auth);

  const handlePriceTypeChange = (event) => {
    setPriceType(event.target.value);
  }

  const handleChange = (event, index) => {
    const val = Number(event.target.value);
    const name = event.target.name;
    
    let newArr = [...myUnitPriceList];
    newArr[index][name] = val;

    setMyUnitPriceList(newArr);
  }

  const handleSaveBtn = () => {
    let priceList = [];
    myUnitPriceList.forEach((item) => {
      priceList.push({ ...item });
    });

    fetch(`${NEONPV_CONFIG.HOSTNAME}/roaming/MemberRoamingGroup`, {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        group_name: match.params.group_name,
        member_id: user.user_auth,
        priceList: priceList
      }),
    }).then((response) => {
      return response.json();
    }).then((responseData) => {
      if (responseData.resultCode === "000") {
        alert("로밍그룹 자사 단가 저장에 성공했습니다.");

      } else {
        //에러
        console.log(responseData.message);
        alert(`로밍그룹 자사 단가에 실패했습니다.\n(${responseData.resultCode}):${responseData.resultMessage}`);
      }
    })
    .catch((error) => {
      console.error(error);
      alert("로밍그룹 자사 단가에 실패했습니다.");
    });
  }

  const handleBack = () => {
    history.goBack();
  };

  //NOTE: 회원사 정보는 로그인한 회원사가 아닌, 충전소정보에 등록된 회원사정보에 기초한다.
  //      만약 등록된 회원사정보가 없다면, default price 표시

  async function loadMyUnitPriceList() {
    var url = `${NEONPV_CONFIG.HOSTNAME}/roaming/MemberRoamingGroup/Detail?group_name=${match.params.group_name}`;
    const opt = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };

    let unitpriceList = [];
    let response = await fetch(url, opt);
    let responseData = await response.json();

    let rowid = 1;
    
    responseData.map(function (row) {
      unitpriceList.push({
        id: rowid++,
        time: row.startTime + ' ~ ' + row.endTime + '시',
        regularPrice: row.regularPrice,
        fastPrice: row.fastPrice,
      });

    });

    setMyUnitPriceList(unitpriceList);
  }

  useEffect(() => {
    loadMyUnitPriceList();
  }, []);



  return (

    <div style={{
      minHeight: 160,
      width: '100%',
      // paddingLeft: 100,
      // alignContent:'center',
      // alignItems:'center',
      // alignSelf:'center'
    }}>

      <Grid container spacing={2}
        direction="row" alignItems="center" justify="center"
        style={{
          paddingLeft: 10,
          paddingRight: 10,
          // marginTop: 20,
          // paddingTop: 20,
          marginBottom: 20,
        }}>

        <Grid container item lg={12} md={12} xs={12}
          direction="row" alignItems="center" justify="center"
          style={{
            marginTop: 10,
            // marginBottom: 10,
            paddingTop: 20,
            paddingBottom: 20,
            backgroundColor: "lightgray"
          }}>
          <Typography
            className={classes.dividerInset}
            // color="colorTextSecondary"
            display="block"
            variant="h4"
            fullWidth
            align="center"
            style={{
              // padding:16,
              display: "block"
            }}
          >
            단가 정보 목록
          </Typography>
        </Grid>
      </Grid>


      <Grid container spacing={1}>

        <Grid item lg={6} md={6} xs={6} >
          <Table size="small"
            style={{borderRight: '1px solid #e0e0e0'}}
            >
            <TableHead>
              <TableRow>
                <TableCell align="center" width={COLUMN_WIDTH.TIMERANGE} >시간대</TableCell>
                <TableCell align="center" width={COLUMN_WIDTH.DEFAULT_PRICE} >완속</TableCell>
                <TableCell align="center" width={COLUMN_WIDTH.OTHER_PRICE}>급속</TableCell>
              </TableRow>
            </TableHead>

            <TableBody >
              {myUnitPriceList.slice(0, 12).map((module, index) => (
                <TableRow className={classes.tableRow} key={module.id}>
                  <TableCell align="center" width={COLUMN_WIDTH.TIMERANGE}>{module.time}</TableCell>

                  <CustomTableCell {...{row: module, name: "regularPrice", onChange: (e => handleChange(e, index)),
                    width: COLUMN_WIDTH.DEFAULT_PRICE, label: '완속'}} />

                  <CustomTableCell {...{row: module, name: "fastPrice", onChange: (e => handleChange(e, index)),
                    width: COLUMN_WIDTH.OTHER_PRICE, label: '급속'}} />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>


        <Grid item lg={6} md={6} xs={6}>
          <Table size="small" style={{borderLeft: '1px solid #e0e0e0'}}>
          <TableHead>
              <TableRow>
                <TableCell align="center" width={COLUMN_WIDTH.TIMERANGE} >시간대</TableCell>
                <TableCell align="center" width={COLUMN_WIDTH.DEFAULT_PRICE} >완속</TableCell>
                <TableCell align="center" width={COLUMN_WIDTH.OTHER_PRICE}>급속</TableCell>
              </TableRow>
            </TableHead>

            <TableBody >
              {myUnitPriceList.slice(12, 24).map((module, index) => (
                <TableRow className={classes.tableRow} key={module.id}>
                  <TableCell align="center" width={COLUMN_WIDTH.TIMERANGE}>{module.time}</TableCell>

                  <CustomTableCell {...{row: module, name: "regularPrice", onChange: (e => handleChange(e, index+12)),
                    width: COLUMN_WIDTH.DEFAULT_PRICE, label: '완속'}} />

                  <CustomTableCell {...{row: module, name: "fastPrice", onChange: (e => handleChange(e, index+12)),
                    width: COLUMN_WIDTH.OTHER_PRICE, label: '급속'}} />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>

      </Grid>

      {userAuth === USER_AUTH.ADMIN ? (<Grid container style={{ marginTop: 20 }}>
        <Grid item md={12} align="center">
          <Button
            style={{ marginRight: 10 }}
            size="large"
            variant="contained" align="center" color="primary" onClick={handleSaveBtn}>
            저장
          </Button>

          <Button
            size="large"
            variant="outlined" align="center" onClick={handleBack}>
            취소
          </Button>

        </Grid>
      </Grid>) : ''}
    </div>
  );
};


UnitPriceSubRegion.propTypes = {
  className: PropTypes.string,
};

let mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps)(withRouter(UnitPriceSubRegion));


const COLUMN_WIDTH = {
  TIMERANGE: '14%',
  DEFAULT_PRICE: '16%',
  OTHER_PRICE: '16%',
  ENV_PRICE: '16%',
  ROAMING_PRICE: '16%',
  LOW_PRESS1: '22%',
}


const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  tableRow: {
    height: 20,
  },
  queryField: {
    flexBasis: 220,
    marginRight: 20,
  },

  bulkAction: {
    marginLeft: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0)
  },

  tablecell: {
    "&.MuiTableCell-root": {
      padding: '8px',
    },
  },
  tableRow: {
    height: "20px",
    padding : "0px",
  },
  textfield: {
    "&.MuiOutlinedInput-input": {
      padding: '8px'
    }
  }
}));