import React from "react";
import { makeStyles } from "@material-ui/styles";
import PropTypes from 'prop-types';
import SendLocalListForm from "./SendLocalListForm";
import {
  Grid,
} from "@material-ui/core";


import {
  CommandNav as CommandNavView,
} from '../..';
import WebSocketForm from "../WebSocketForm";

const SendLocalList = (props) => {

  const {className, history, ...rest } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.content}>

        <Grid container spacing={1}>
          <Grid item lg={2} md={2}>
            <CommandNavView />
          </Grid>

          <Grid item lg={10} md={10}>
            <WebSocketForm />
            <SendLocalListForm history={history} />
          </Grid>

        </Grid>
      </div>
    </div>
  );
};


SendLocalList.propTypes = {
  history: PropTypes.object
};

export default SendLocalList;


const useStyles = makeStyles((theme) => ({
  root: {
    // padding: theme.spacing(2),
  },
  content: {
    // marginTop: theme.spacing(2),
  },
}));
