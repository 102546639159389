import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  InputAdornment,
  SvgIcon,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  TablePagination,
  TableSortLabel,

  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,

  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";

import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker
} from '@material-ui/pickers';

import Autocomplete from '@material-ui/lab/Autocomplete';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import numeral from 'numeral';

import {
  Search as SearchIcon,
} from "react-feather";

import { NEONPV_CONFIG } from "config.js";
import { connect, useSelector } from "react-redux";
import { USER_AUTH } from 'common/constants';
// import NumberFormat from 'react-number-format';
import {CSVLink} from "react-csv";
import { paymentMethodFromCardNo } from 'common/global';


export const payment_method_list = ['-', '회원', '환경부', '로밍회원', '신용카드'];

// // const payment_method_list = ['ALL','신용카드', '환경부회원', '헬로우차저회원', '로밍회원'];
// const payment_method_list = ['-','회원','환경부','신용카드'];

// const getPaymentMethodCode = (payment_method) => {

//   switch (payment_method) {
//     case '-': return 'all';
//     case '회원': return 'hellocharger';
//     case '환경부': return 'env';
//     case '신용카드': return 'credit';
//     default: return -1;
//   }
//   // switch (payment_method) {
//   //   case 'ALL': return 'all';
//   //   case '신용카드': return 'credit';
//   //   case '환경부회원': return 'env';
//   //   case '헬로우차저회원': return 'hellocharger';
//   //   case '로밍회원': return 'roaming';
//   //   default: return -1;
//   // }
// };


const getPaymentMethodLabel = (payment_method_code) => {

  switch (payment_method_code) {
    case '-': return 'ALL';
    case 'hellocharger': return '회원';
    case 'env' : return '환경부';
    case 'credit': return '신용카드';
  }

  // switch (payment_method_code) {
  //   case 'all':
  //     return 'ALL';

  //   case 'credit':
  //     return '신용카드';

  //   case 'env':
  //     return '환경부회원';

  //   case 'hellocharger':
  //     return '헬로우차저회원';
  //   case 'roaming':
  //     return '로밍회원';
  // }

}

const convertStringToYMD = (str) => {
  return str.slice(0, 2) + '-' + str.slice(2, 4) + '-' + str.slice(4, 6) + ' ' +
        str.slice(6, 8) + ':' + str.slice(8, 10) + ':' + str.slice(10, 12);
}


const COLUMN_WIDTH = {
  AuthData: '9%',
  STAT_ID: '5%',
  STAT_NAME: '10%',//26
  CHARGER_ID: '4%', //33
  PAYMENT_METHOD: '6%', //41
  CARD_NO: '6%', //47
  PAY_TYPE: '6%', //53
  AUTH_NUM: '5%', //58
  CHARGING_AMOUNT: '7%',//66
  PAY_AMOUNT: '7%', //74
  FRANCHISE_ID: '7%', //82
  PURCHASE_NAME: '7%', //89
  STATUS: '4%', //94
  MESSAGE: '11%', //100
}


function descendingComparator(a, b, orderBy) {

  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}


function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}


function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


const headCells = [
  {
    id: 'Authdate',
    numeric: false,
    disablePadding: true,
    width: COLUMN_WIDTH.AuthData,
    label: '거래일시',
  },
  {
    id: 'statId',
    numeric: false,
    disablePadding: true,
    width: COLUMN_WIDTH.STAT_ID,
    label: '충전소ID',
  },
  {
    id: 'statname',
    numeric: false,
    disablePadding: false,
    width: COLUMN_WIDTH.STAT_NAME,
    label: '충전소명',
  },
  {
    id: 'chargerId',
    numeric: false,
    disablePadding: true,
    width: COLUMN_WIDTH.CHARGER_ID,
    label: '충전기',
  },
  {
    id: 'payment_method',
    numeric: false,
    disablePadding: true,
    width: COLUMN_WIDTH.PAYMENT_METHOD,
    label: '결제수단',
  },
  {
    id: 'CardNo',
    numeric: false,
    disablePadding: true,
    width: COLUMN_WIDTH.CARD_NO,
    label: '카드번호',
  },
  {
    id: 'PAY_TYPE',
    numeric: false,
    disablePadding: true,
    width: COLUMN_WIDTH.PAY_TYPE,
    label: '구분',
  },
  {
    id: 'AuthNum',
    numeric: false,
    disablePadding: true,
    width: COLUMN_WIDTH.AUTH_NUM,
    label: '승인번호',
  },
  {
    id: 'Wh',
    numeric: true,
    disablePadding: true,
    width: COLUMN_WIDTH.CHARGING_AMOUNT,
    label: '충전량[Wh]',
  },
  {
    id: 'PAY_AMOUNT',
    numeric: true,
    disablePadding: true,
    width: COLUMN_WIDTH.PAY_AMOUNT,
    label: '결제금액[원]',
  },
  {
    id: 'FranchiseID',
    numeric: false,
    disablePadding: true,
    width: COLUMN_WIDTH.FRANCHISE_ID,
    label: '가맹점번호',
  },
  {
    id: 'PurchaseName',
    numeric: false,
    disablePadding: true,
    width: COLUMN_WIDTH.PURCHASE_NAME,
    label: '카드사',
  },
  {
    id: 'Status',
    numeric: false,
    disablePadding: true,
    width: COLUMN_WIDTH.STATUS,
    label: '응답',
  },
  {
    id: 'MESSAGE',
    numeric: false,
    disablePadding: true,
    width: COLUMN_WIDTH.MESSAGE,
    label: '응답메시지',
  },
];


function EnhancedTableHead(props) {
  const { order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align='center'
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            width={headCell.width}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}


EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};


const SalesRawTable = (props) => {
  const { className, user, ...rest } = props;

  const classes = useStyles();

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('Authdate');

  const userAuth = useSelector((state) => state.user.user_auth);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [listData, setListData] = useState([]);
  const [csvData, setCsvData] = useState([]);

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');

  const [memberName, setMemberName] = useState(userAuth !== USER_AUTH.ADMIN ? user.admin_name : '');

  // const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
  const [queryPaymentMethod, setQueryPaymentMethod] = useState("");

  const [paymentStartTime, setPaymentStartTime] = useState(new Date((new Date()).valueOf() - (1000 * 60 * 60 * 24)));
  const [paymentEndTime, setPaymentEndTime] = useState(new Date((new Date()).valueOf()));
  const [queryStatName, setQueryStatName] = useState("");
  const [queryStatID, setQueryStatID] = useState("");
  const [queryChargerID, setQueryChargerID] = useState("");

  const [queryPaymentPriceFrom, setQueryPaymentPriceFrom] = useState('');
  const [queryPaymentPriceTo, setQueryPaymentPriceTo] = useState('');
  const [queryChargerQuantityFrom, setQueryChargerQuantityFrom] = useState('');
  const [queryChargerQuantityTo, setQueryChargerQuantityTo] = useState('');



  const searchData = (memberName, paymentMethod, paymentStartTime, paymentEndTime, statName,
    statID, chargerID,
    paymentPriceFrom, paymentPriceTo,
    chargerQuantityFrom, chargerQuantityTo) => {

    var url = `${NEONPV_CONFIG.HOSTNAME}/sales/salesPaymentList?`;
    var startTime = moment(paymentStartTime).format("YYMMDDHHmm") + "00";
    var endTime = moment(paymentEndTime).format("YYMMDDHHmm") + "00";

    var params = "";

    // 콜센터 계정이라면
    if(user.admin_id == 'callcenter'){
      params += "memberId=castpro&";
    }
    //회원사 계정이라면
    else if (userAuth === USER_AUTH.MEMBER) {
      params += "memberId=" + user.admin_id + "&";
    } 
    // 서브계정
    else if (userAuth === USER_AUTH.SUB_MEMBER){
      params += "memberId2=" + user.admin_id + "&";
    }
    else if (memberName) {
      //관리자 계정이라면
      params += "memberName=" + memberName + "&";
    }

    if(paymentMethod){
      params += "paymentMethod=" + paymentMethod + "&";
    }

    //거래일시
    if (paymentStartTime) {
      params += "paymentStartTime=" + startTime + "&";
    }

    if (paymentEndTime) {
      params += "paymentEndTime=" + endTime + "&";
    }

    if (statName) {
      params += "statName=" + statName + "&";
    }

    if (statID){
      params += "statID=" + statID + "&";
    }

    if (chargerID){
      params += "chargerID=" + chargerID + "&";
    }

    //결제금액
    if (paymentPriceFrom) {
      params += "paymentPriceFrom=" + paymentPriceFrom + "&";
    }

    if (paymentPriceTo) {
      params += "paymentPriceTo=" + paymentPriceTo + "&";
    }

    //충전량
    if (chargerQuantityFrom) {
      params += "chargerQuantityFrom=" + chargerQuantityFrom + "&";
    }

    if (chargerQuantityTo) {
      params += "chargerQuantityTo=" + chargerQuantityTo + "&";
    }

    url += params;


    fetch(url)
      .then((res) => res.json())
      .then((snapshot) => {
        var i = 1;

        var list = [];
        var csvlist = [];
        if (snapshot.resultData != null) {
          snapshot.resultData.forEach((doc) => {
            doc.no = i++;
            list.push(doc);

            csvlist.push({
              '거래일시': '=""' + convertStringToYMD(doc.Authdate) + '""',
              '충전소ID': doc.statId,
              '충전소명': doc.statName,
              '충전기No': '=""' + doc.chargerId + '""',
              '결제수단':  doc.paymentMethod ? getPaymentMethodLabel(doc.paymentMethod) : paymentMethodFromCardNo(doc.CardNo, doc.bid),
              '카드번호': doc.CardNo,
              '구분': getPaymentMessage(doc.messageType, doc.TransType),
              '승인번호': doc.AuthNum,
              '충전량[Wh]': doc.Wh,
              '결제금액[원]': doc.TotalAmount,
              '가맹점번호': '=""' + doc.FranchiseID + '""',
              '카드사': doc.PurchaseName,
              '응답': doc.Status,
              '응답메시지': doc.Message1 + ' ' + doc.Message2,
            });
          });
        }
        setListData(list);
        setCsvData(csvlist);

        if (list.length === 0) {
          setDialogMessage('검색 결과가 없습니다.');
          setOpenDialog(true);
        }

        setPage(0);
      });
  };


  const handleSearch = (event) => {

    if (paymentStartTime != undefined || paymentEndTime != undefined || queryStatName !== '') {
      searchData(memberName, queryPaymentMethod, paymentStartTime, paymentEndTime, queryStatName,
        queryStatID, queryChargerID,
        queryPaymentPriceFrom, queryPaymentPriceTo,
        queryChargerQuantityFrom, queryChargerQuantityTo);
    } else {
      setDialogMessage('검색어를 입력해 주세요.');
      setOpenDialog(true);
    }
  };

  const doKeyPress = (ev) => {
    if (ev.key === 'Enter') {
      handleSearch();
    }
  }


  /**
   * 검색 정보 지우기
   * @param {*} event
   */
  const handleClear = (event) => {
    setMemberName(userAuth !== USER_AUTH.ADMIN ? user.admin_name : '');
    setQueryPaymentMethod("");
    setPaymentStartTime(new Date((new Date()).valueOf() - (1000 * 60 * 60 * 24)));
    setPaymentEndTime(new Date((new Date()).valueOf()));
    setQueryStatName("");
    setQueryStatID("");
    setQueryChargerID("");
    setQueryPaymentPriceFrom('');
    setQueryPaymentPriceTo('');
    setQueryChargerQuantityFrom('');
    setQueryChargerQuantityTo('');
    setListData([]);
  };


  const handleQueryMemberNameChange = (event) => {
    event.persist();
    setMemberName(event.target.value);
  }

  const handleQueryPaymentMethodChange = (event) => {
    event.persist();
    setQueryPaymentMethod(event.target.value);
  }

  const handlePaymentStartTimeChange = (date) => {
    setPaymentStartTime(date);
  };

  const handlePaymentEndtimeChange = (date) => {
    setPaymentEndTime(date);
  };

  const handleQueryStatNameChange = (event) => {
    event.persist();
    setQueryStatName(event.target.value);
  };

  const handleQueryStatIDChange = (event) => {
    event.persist();
    setQueryStatID(event.target.value);
  }

  const handleQueryChargerIDChange = (event) => {
    event.persist();
    setQueryChargerID(event.target.value);
  }

  const handleQueryPaymentPriceFrom = (event) => {
    event.persist();
    setQueryPaymentPriceFrom(event.target.value);
  }

  const handleQueryPaymentPriceTo = (event) => {
    event.persist();
    setQueryPaymentPriceTo(event.target.value);
  }

  const handleQueryChargerQuantityFrom = (event) => {
    event.persist();
    setQueryChargerQuantityFrom(event.target.value);
  }

  const handleQueryChargerQuantitiyTo = (event) => {
    event.persist();
    setQueryChargerQuantityTo(event.target.value);
  }

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
  };


  const getPaymentMessage = (msgType, transType) => {

    if (msgType === '1') {
      if (transType === 't1') { return "선결제"; }
      else if (transType === 'u1') { return "선결제취소" }
      else if (transType === 'f1') { return "선결제취소 실패"}
    }
    else if (msgType === '2') {
      if (transType === 't1') { return "결제"; }
      else if (transType === 'u1') { return "결제취소" }
      else if (transType === 'f1') { return "선결제취소 실패"}
    }
    else {
      return msgType;
    }
  }


  const startRow = (page * rowsPerPage);


  //NOTE: 집계 내용이므로 자동 검색 하지 않음.
  useEffect(() => {
    handleSearch();
    return () => { };
  }, []);



  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };



  return (

    <div>

      <Dialog open={openDialog} onClose={() => { setOpenDialog(false) }}>
        <DialogTitle>주의</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{dialogMessage}</DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" color="secondary" onClick={() => { setOpenDialog(false) }}>닫기</Button>
        </DialogActions>
      </Dialog>

      <Card className={clsx(classes.root, className)} {...rest}>
        <Box m={1} p={2}>
          <Box mt={2} display="flex" alignItems="center" justifyContent="start" flexGrow={1}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>

              <TextField className={classes.queryField_small}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action"><SearchIcon /></SvgIcon>
                    </InputAdornment>
                  )
                }}

                disabled={userAuth !== USER_AUTH.ADMIN}
                onChange={handleQueryMemberNameChange}
                label="운영회원사"
                placeholder="운영회원사"
                value={memberName}
                variant="outlined"
                onKeyUp={(ev) => doKeyPress(ev)}
              />

{/* TODO: UsageChargerTable.js FromControl */}
              {/* <Autocomplete
                id="charger_speed_select"
                options={payment_method_list}
                // width="200px"
                className={classes.payment_method}
                classes={{ option: classes.options, }}
                autoHighlight
                getOptionSelected={(option, value) => option === value}
                value={selectedPaymentMethod}
                renderOption={(option) => (
                  <React.Fragment>
                    {option}
                  </React.Fragment>
                )}

                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="결제수단 선택"
                    variant="outlined"
                    fullWidth
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}

                onChange={(event, newValue) => {
                  if (newValue) {
                    setSelectedPaymentMethod(newValue);
                  }
                }}
              /> */}

            <FormControl sx={{ m: 1, minWidth: 120 }} className={classes.queryField_small}>
              <InputLabel variant="outlined" id="demo-simple-select-label-2">결제수단</InputLabel>

              <Select
                labelId="demo-simple-select-label2"
                id="demo-simple-select-2"
                value={queryPaymentMethod}
                label="결제수단"
                onChange={handleQueryPaymentMethodChange}
                variant="outlined"
              >

                {payment_method_list.map(item => (
                  <MenuItem value={item}>{item}</MenuItem>
                ))}

              </Select>
            </FormControl>

              <KeyboardDateTimePicker
                autoOk
                // maxDate={moment().subtract(0, "days")}
                variant="inline"
                id="date-picker-dialog"
                label="결제시작일시"
                format="yyyy-MM-dd HH:mm"
                fontSize="30px"
                value={paymentStartTime}
                onChange={handlePaymentStartTimeChange}
                KeyboardButtonProps={{ 'aria-label': 'change date', }}
                className={classes.datepicker}
                InputProps={{
                  style: {
                    fontSize: 16,
                    height: 44
                  }
                }}
                style={{
                  width: 180,
                  marginLeft: 20,
                  marginRight: 20,
                }}
              />
              <Typography variant="h5">~</Typography>
              <KeyboardDateTimePicker
                autoOk
                variant="inline"
                id="date-picker-dialog"
                label="결제종료일시"
                format="yyyy-MM-dd HH:mm"
                fontSize="30px"
                value={paymentEndTime}
                onChange={handlePaymentEndtimeChange}
                KeyboardButtonProps={{ 'aria-label': 'change date', }}
                className={classes.datepicker}
                InputProps={{
                  style: {
                    fontSize: 16,
                    height: 44
                  }
                }}
                style={{
                  width: 180,
                  marginLeft: 20,
                  marginRight: 60,
                }}
              />
            </MuiPickersUtilsProvider>

            <TextField
              className={classes.queryField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              onChange={handleQueryStatNameChange}
              label="충전소 명칭"
              placeholder="충전소 명칭"
              value={queryStatName}
              variant="outlined"
              onKeyUp={(ev) => doKeyPress(ev)}
            />


            <TextField
              className={classes.queryField_small}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              onChange={handleQueryStatIDChange}
              label="충전소ID"
              placeholder="충전소ID"
              value={queryStatID}
              variant="outlined"
              onKeyUp={(ev) => doKeyPress(ev)}
            />


            <TextField
              className={classes.queryField_small}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              onChange={handleQueryChargerIDChange}
              label="충전기ID"
              placeholder="충전기ID"
              value={queryChargerID}
              variant="outlined"
              onKeyUp={(ev) => doKeyPress(ev)}
            />
          </Box>

          <Box mt={3} display="flex" alignItems="center" justifyContent="start" flexGrow={1}>
            <TextField
              className={classes.queryField_small}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              onChange={handleQueryPaymentPriceFrom}
              label="결제금액[원]"
              placeholder="결제금액[원]"
              value={queryPaymentPriceFrom}
              variant="outlined"
              onKeyUp={(ev) => doKeyPress(ev)}
            />
            <Typography variant="h5">~</Typography>
            <TextField
              className={classes.queryField_small}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              onChange={handleQueryPaymentPriceTo}
              label="결제금액[원]"
              placeholder="결제금액[원]"
              value={queryPaymentPriceTo}
              variant="outlined"
              style={{ marginLeft: 20 }}
              onKeyUp={(ev) => doKeyPress(ev)}
            />

            <TextField
              className={classes.queryField_small}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              onChange={handleQueryChargerQuantityFrom}
              label="충전전력량[Wh]"
              placeholder="충전전력량[Wh]"
              value={queryChargerQuantityFrom}
              variant="outlined"
              style={{
                marginLeft: 20
              }}
              onKeyUp={(ev) => doKeyPress(ev)}
            />
            <Typography variant="h5">~</Typography>
            <TextField
              className={classes.queryField_small}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              onChange={handleQueryChargerQuantitiyTo}
              label="충전전력량[Wh]"
              placeholder="충전전력량[Wh]"
              value={queryChargerQuantityTo}
              variant="outlined"
              style={{
                marginLeft: 20,
                marginRight: 100
              }}
              onKeyUp={(ev) => doKeyPress(ev)}
            />


            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classes.bulkAction}
              onClick={handleSearch}
            >
              검색
            </Button>

            <Button
              variant="outlined"
              size="large"
              className={classes.bulkAction}
              onClick={handleClear}
            >
              초기화
            </Button>
          </Box>

          <Box mt={2} display="flex" alignItems="center" justifyContent="start" flexGrow={1} mb={0}>
            <Typography variant="caption">※△회원 및 환경부카드 결제 및 △신용카드 실결제 △신용카드 결제취소없는 선결제</Typography>
          </Box>

        </Box>

        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <TableContainer style={{ width: '100%', }}>
                <Table >

                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={listData.length}
                  />

                  <TableBody>
                    {stableSort(listData, getComparator(order, orderBy)).slice(0 + startRow, rowsPerPage + (page * rowsPerPage)).map(module => (
                      <TableRow className={classes.tableRow} >
                        <TableCell align="center" width={COLUMN_WIDTH.AuthData}>{convertStringToYMD(module.Authdate)}</TableCell>
                        <TableCell align="center" width={COLUMN_WIDTH.STAT_ID}><Typography variant="body2">{module.statId}</Typography></TableCell>
                        <TableCell align="left" width={COLUMN_WIDTH.STAT_NAME}><Typography variant="body2">{module.statName}</Typography></TableCell>
                        <TableCell align="center" width={COLUMN_WIDTH.CHARGER_ID}>{module.chargerId}</TableCell>
                        {/* <TableCell align="center" width={COLUMN_WIDTH.PAYMENT_METHOD}><Typography variant="body2">{getPaymentMethodLabel(module.paymentMethod)}</Typography></TableCell> */}
                        <TableCell align="center" width={COLUMN_WIDTH.PAYMENT_METHOD}>
                          <Typography variant="body2">{
                          module.paymentMethod ? getPaymentMethodLabel(module.paymentMethod) : paymentMethodFromCardNo(module.CardNo, module.bid)}</Typography>
                        </TableCell>
                        {/* <TableCell align="center" width={COLUMN_WIDTH.PAYMENT_METHOD}><Typography variant="body2">{module.paymentMethod}</Typography></TableCell> */}
                        <TableCell align="center" width={COLUMN_WIDTH.CARD_NO}>{module.CardNo}</TableCell>
                        <TableCell align="center" width={COLUMN_WIDTH.PAY_TYPE}> {getPaymentMessage(module.messageType, module.TransType)}</TableCell>
                        <TableCell align="center" width={COLUMN_WIDTH.AUTH_NUM}>{module.AuthNum}</TableCell>
                        <TableCell align="right" width={COLUMN_WIDTH.CHARGING_AMOUNT}>{numeral(module.Wh).format('0,0')}</TableCell>
                        <TableCell align="right" width={COLUMN_WIDTH.PAY_AMOUNT}>{numeral(module.TotalAmount).format('0,0')}</TableCell>
                        <TableCell align="left" width={COLUMN_WIDTH.FRANCHISE_ID}>{module.FranchiseID}</TableCell>
                        <TableCell align="center" width={COLUMN_WIDTH.PURCHASE_NAME}>{module.PurchaseName}</TableCell>
                        <TableCell align="center" width={COLUMN_WIDTH.STATUS}>{module.Status}</TableCell>
                        <TableCell align="center" width={COLUMN_WIDTH.MESSAGE}><Typography variant="caption">{module.Message1 + ' ' + module.Message2}</Typography></TableCell>

                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </PerfectScrollbar>
        </CardContent>

        <CardActions className={classes.actions}>

          <Grid item md={6} justify="flex-start">

            <CSVLink
              variant="contained"
              size="large"
              data={csvData}
              filename={`${new Date().toLocaleDateString()}_승인내역.csv`}
              className="btn btn-primary"
              target="_blank"
              disabled={csvData.length === 0}
              data-interception='off'
            >

              <Button
                variant="contained"
                color="secondary"
                size="large"
                className={classes.bulkAction}
                disabled={csvData.length === 0}
                style={{ marginRight: 30 }}
              >
                CSV 다운로드
              </Button>
            </CSVLink>
          </Grid>

          <Grid item md={6} justify="flex-end">

            <TablePagination
              component="div"
              count={listData.length}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleRowsPerPageChange}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[5, 10, 25]}
            />
          </Grid>
        </CardActions>

      </Card>

    </div>
  );
};


SalesRawTable.propTypes = {
  className: PropTypes.string,
};

let mapStateToProps = (state) => {
  return {
    user: state.user
  };
};


export default connect(mapStateToProps)(withRouter(SalesRawTable));


const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    // minWidth: 1050
  },
  nameContainer: {
    // display: 'flex',
    // alignItems: 'center',
    // marginLeft: theme.spacing(1)
  },
  queryField: {
    // width: 300
    flexBasis: 260,
    marginRight: 20,
  },
  queryField_small: {
    flexBasis: 160,
    marginRight: 20,
  },
  payment_method: {
    width: 200,
    marginRight: 20,
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: "flex-end",
  },
  bulkAction: {
    marginLeft: theme.spacing(2),
  },
  datepicker: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
  },
}));