import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Formik } from "formik";
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Divider,
  Typography,
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {global} from "common/global";



const DeleteCertificateForm = (props) => {
  const { className, history, user, ...rest } = props;
  const classes = useStyles();

  const certificateType = ['CentralSystemRootCertificate', 'ManufacturerRootCertificate'];
  const [selectedCertificateType, setSelectedCertificateType] = useState('CentralSystemRootCertificate');


  const handleSubmit = (event, values) => {
    event.preventDefault();

    //SHA256
    //SHA384
    //SHA512
    var json =
      `{"command":"DeleteCertificate",
        "body":
        {
          "certificateHashData": {
            "hashAlgorithm": "SHA256",
            "issuerNameHash": "2222222222222222",
            "issuerKeyHash": "11111111111111",
            "serialNumber": "123456789"
          }
        }
      } `;


    global.ws.send(json);

  };


  const handleBack = () => {
    history.goBack();
  };


  return (

    <div>
      <Formik
        enableReinitialize={false}
        initialValues={{
          certificate: `invalid`
          // certificate: `-----BEGIN CERTIFICATE-----MIIDMjCCAhoCCQDqrL3Gk2aCUzANBgkqhkiG9w0BAQUFADBTMQswCQYDVQQGEwJLUjERMA8GA1UECAwIU2VvbmduYW0xETAPBgNVBAcMCFNlb25nbmFtMRAwDgYDVQQKDAdDYXN0cHJvMQwwCgYDVQQLDANSTkQwHhcNMjIwMzA4MDg0NzQ4WhcNMjMwMzA4MDg0NzQ4WjBjMQswCQYDVQQGEwJJTjEVMBMGA1UECBMMVXR0YXJQcmFkZXNoMQ4wDAYDVQQHEwVOb2lkYTEOMAwGA1UEChMFVkVOSUQxCzAJBgNVBAsTAklUMRAwDgYDVQQDEwdDQlNFUk5PMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAtGndwLI2aYlUyjcSdQXi+nJydng1+7meGAoDLGua/c9Mp0yjssu2pw4cwRSsCLjc1fQaEaDSTlwdkpcz2Kmccoljyvn5rbzz4wYvcea9Nh/XzHIQ/XdIc2BJp+Kj3E0aC5kKWPWiEC0gm9w1Nq6h1arweEupdrIMlMQmlrxNV4wNb/mL1xdbJhUAT+d1NEZRLcRhJQuVKUTHjLj16fuc7gbpLilzxEDy61Og96sbFoubpvy6eX8bIyJUBVEmbflByFfIfzQv9VTpYv48SzFEKDS5hhT8WMSaj2DY94Fcfa1bVGXqjE/SLrh0A8Eh7rc3hP0RTO6r7ZKqu+d/Qc8QMwIDAQABMA0GCSqGSIb3DQEBBQUAA4IBAQBtccEomeBcfft0+mdy/oOKT2QQrXhwhg3ZuvyQ97xM2gcP/kx1f+yVel+NwZ4N34mc/CgSC52QqqdtiM3jxh5BP8yvG+QA3oFWxO/AJRZcDCrK2DB2QR33tdjFInnBiuRE27RqgXBJNA+b+VbBXxP/8FgA/kbmLaFnJWs35eR1C0tPpXlewDvev8l57Gx+J6mxnExS8rEjLSy4+752vsJnABrZhSU0ueu449ESQHhzqduea7LmJNdGTnOnlX1JMRZjOn6rkbaP1tCfTRDdTayGWYGpUuYrVfU6xBfPw7rxB0IatDeef7bR8zqHkPPWoIwsETNSlKHAZ9y5hoyLHlLo——END CERTIFICATE——`
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          isSubmitting,
          touched,
          values,
          setFieldValue
        }) => (
          <form
            className={clsx(classes.root, className)}
            onSubmit={(e) => handleSubmit(e, values)}
          >

            <Card>
              <CardHeader
                title="Delete Certificate"
                titleTypographyProps={{ color: 'inherit', align: 'center', variant: 'h3' }}
                subheader="TestCase - 076"
                subheaderTypographyProps={{ color: 'inherit', align: 'left', variant: 'h5' }}
              />

              <CardContent>

                <Grid item md={12} spacing={2}>
                  <Typography variant="h6">076 - PASS</Typography>
                </Grid>

                <div className={classes.content}></div>

                <Grid item md={12} spacing={0}>
                  <Typography variant="h4">Parameters</Typography>
                </Grid>

                <Divider variant="inset" />


                <Grid container item spacing={2} >

                  <Grid container item md={12} spacing={0}>
                  </Grid>


                  <Grid item md={3} xs={4}>
                    <Autocomplete
                      id="certificateType_select"
                      options={certificateType}
                      classes={{ option: classes.options, }}
                      autoHighlight
                      getOptionSelected={(option, value) => option === value}
                      value={selectedCertificateType}
                      renderOption={(option) => (
                        <React.Fragment>
                          {option}
                        </React.Fragment>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Type"
                          variant="outlined"
                          required
                          fullWidth
                          inputProps={{
                            ...params.inputProps,
                          }}
                        />
                      )}

                      onChange={(event, newValue) => {
                        if (newValue) {
                          setSelectedCertificateType(newValue);
                        }
                      }}
                    />
                  </Grid>


                  <Grid item md={2} xs={4}>
                    <TextField
                      error={Boolean(touched.certificate && errors.certificate)}
                      fullWidth
                      helperText={touched.certificate && errors.certificate}
                      label="certificate"
                      name="certificate"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.certificate}
                      variant="outlined"
                      required
                    />
                  </Grid>

               </Grid>


                <Grid container item
                  className={classes.action}
                  direction="row" alignItems="center" justify="center">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="large"
                    disabled={isSubmitting}
                    className={classes.action}
                  >
                    Perform
                  </Button>

                  <Button
                    variant="outlined"
                    size="large"
                    onClick={handleBack}
                    className={classes.action}
                  >
                    취소
                  </Button>
                </Grid>
              </CardContent>
            </Card>
          </form>
        )}
      </Formik>
    </div>
  );
};

DeleteCertificateForm.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object,
};


let mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps)(DeleteCertificateForm);


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    direction: "row",
    alignItems: "center",
    justify: "center"
  },
  content2: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  action: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },
  queryField: {
    // width: 300
    flexBasis: 260,
    marginRight: 20,
  },
  bulkAction: {
    marginLeft: theme.spacing(2),
  },

  divider: {
    margin: theme.spacing(2, 0)
  },
}));
