import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link as RouterLink, withRouter } from "react-router-dom";
import { makeStyles } from '@material-ui/styles';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  InputAdornment,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  TablePagination,
  TableSortLabel,
  Grid,
} from '@material-ui/core';

import {
  Search as SearchIcon,
} from 'react-feather';

import { NEONPV_CONFIG } from 'config.js';
import moment from 'moment';
import { USER_AUTH } from 'common/constants';
import { connect, useSelector} from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateQueryLoginType, updateQueryUserCardNumber, updateQueryUserEmail, updateQueryUserNickname } from 'actions/query';
import {CSVLink} from "react-csv";


const COLUMN_WIDTH = {
  USER_CARD_NUMBER: '14%',
  USER_CARD_STOP: '6%', //24
  LOGIN_TYPE: '10%', //34
  EMAIL: '14%', //50
  // PASSWORD: '7%', //29
  NICKNAME: '10%', //60
  USER_KIND: '9%', //70
  REGISTER_DATE: '12%', //84
  DELETE_STATUS: '10%', //94
  DELETE_TIME: '12%', //108
}



function descendingComparator(a, b, orderBy) {

  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}


function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}


function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}



const headCells = [
  {
    id: 'user_card_number',
    numeric: false,
    disablePadding: true,
    width:COLUMN_WIDTH.USER_CARD_NUMBER,
    label: '회원카드번호',
  },
  {
    id: 'user_card_stop',
    numeric: false,
    disablePadding: true,
    width:COLUMN_WIDTH.USER_CARD_STOP,
    label: '카드정지',
  },
  {
    id: 'user_login_type',
    numeric: false,
    disablePadding: false,
    width:COLUMN_WIDTH.LOGIN_TYPE,
    label: '로그인타입',
  },
  {
    id: 'user_email',
    numeric: false,
    disablePadding: false,
    width:COLUMN_WIDTH.EMAIL,
    label: '이메일',
  },
  {
    id: 'user_nickname',
    numeric: false,
    disablePadding: false,
    width:COLUMN_WIDTH.NICKNAME,
    label: '닉네임',
  },
  {
    id: 'user_kind',
    numeric: false,
    disablePadding: false,
    width:COLUMN_WIDTH.USER_KIND,
    label: '회원구분',
  },
  {
    id: 'updateTime',
    numeric: false,
    disablePadding: true,
    width:COLUMN_WIDTH.REGISTER_DATE,
    label: '등록일시',
  },
  {
    id: 'delete_status',
    numeric: false,
    disablePadding: false,
    width:COLUMN_WIDTH.DELETE_STATUS,
    label: '삭제여부',
  },
  {
    id: 'delete_time',
    numeric: false,
    disablePadding: true,
    width:COLUMN_WIDTH.DELETE_TIME,
    label: '삭제일시',
  },
];


function EnhancedTableHead(props) {
  const { order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            // align={headCell.numeric ? 'right' : 'center'}
            align='center'
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            width={headCell.width}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {/* {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null} */}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};



/**
 * 회원 조회
 * @param {*} props 
 * @returns 
 */
const UserTable = props => {
  const { className, user, queryParams, ...rest } = props;

  const classes = useStyles();

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('USER_CARD_NUMBER');

  const userAuth = useSelector((state) => state.user.user_auth);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [listData, setListData] = useState([]);
  const [csvData, setCsvData] = useState([]);

  const [memberName, setMemberName] = useState( userAuth === USER_AUTH.MEMBER ? user.admin_name : ''); //회원사라면 로그인한 이름, 관리자면 null
  const [loginType, setLoginType] = useState('');
  const [userCardNumber, setUserCardNumber] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userNickname, setUserNickname] = useState('');


  const searchData = (memberName, user_login_type, user_card_number, user_email, user_nickname) => {

    var url = `${NEONPV_CONFIG.HOSTNAME}/user/queryUserList?`;

    var params = '';
    //회원사 계정이라면, 로그인한 회원사ID로 제한
    if (userAuth === USER_AUTH.MEMBER ) {
      params += "memberId=" + user.admin_id + "&";
    } else if (memberName) {
    //관리자 계정이라면
      params += "memberName=" + memberName + "&";
    }

    if (user_login_type && user_login_type !== 'ALL'){
      params += 'user_login_type='+user_login_type + "&";
    }

    if (user_card_number){
      params += 'user_card_number='+user_card_number + "&";
    }

    if (user_email){
      params += 'user_email='+user_email + "&";
    }

    if (user_nickname){
      params += 'user_nickname='+user_nickname + "&";
    }


    url += params;

    fetch(url)
    .then(res => res.json())
    .then(snapshot => {

      var list  = [];
      var csvlist = [];
      if( snapshot.resultData != null){
        snapshot.resultData.forEach( doc => {
          list.push(doc);

          csvlist.push({
            '회원카드번호': '=""' + doc.user_card_number + '""',
            '카드정지': doc.user_card_stop === 'Y' ? '정지': '사용',
            '로그인타입': doc.user_login_type,
            '이메일': doc.user_email,
            '닉네임': doc.user_nickname,
            '회원구분': doc.user_kind,
            '등록일시': doc.updateTime ? moment(doc.updateTime).format('YYYY-MM-DD HH:mm') : ' ',
            '삭제여부': doc.delete_status,
            '삭제일시': doc.delete_time ? moment(doc.delete_time).format('YYYY-MM-DD HH:mm') : ' '
          });
        });
      }
      setListData( list );
      setCsvData(csvlist);

      setPage(0);
    }
    );
  }


  useEffect( () => {

    if( (queryParams.queryLoginType !== '' && queryParams.queryLoginType !== 'ALL') ||
    queryParams.queryUserCardNumber || queryParams.queryUserEmail || queryParams.queryUserNickname ){
      searchData(memberName, queryParams.queryLoginType, queryParams.queryUserCardNumber,
          queryParams.queryUserEmail, queryParams.queryUserNickname);
    }else{
      handleSearch();
    }

    setLoginType(queryParams.queryLoginType);
    setUserCardNumber(queryParams.queryUserCardNumber);
    setUserEmail(queryParams.queryUserEmail);
    setUserNickname(queryParams.queryUserNickname)

    return () => {};
  }, []);



  const handleSearch = event => {
    searchData(memberName, loginType, userCardNumber, userEmail, userNickname);
  }

  const doKeyPress = (ev) => {
    if (ev.key === 'Enter') {
      handleSearch();
    }
  }



  /**
   * 검색 정보 지우기
   * @param {*} event 
   */
  const handleClear = event => {
    props.updateQueryLoginType('ALL');
    props.updateQueryUserCardNumber('');
    props.updateQueryUserEmail('');
    props.updateQueryUserNickname('');

    setMemberName(userAuth === USER_AUTH.MEMBER ? user.admin_name : '');
    setLoginType('ALL');
    setUserCardNumber('');
    setUserEmail('');
    setUserNickname('');
  }

  const handleQueryMemberNameChange = (event) => {
    event.persist();
    setMemberName(event.target.value);
  }

  const handleLoginTypeChange = (event) => {
    event.persist();
    props.updateQueryLoginType(event.target.value);
    setLoginType(event.target.value);
  }

  const handleUserCardNumber = (event) => {
    event.persist();
    props.updateQueryUserCardNumber(event.target.value);
    setUserCardNumber(event.target.value);
  }

  const handleUserEmailChange = (event) => {
    event.persist();
    props.updateQueryUserEmail(event.target.value);
    setUserEmail(event.target.value);
  }

  const handleUserNicknameChange = (event) => {
    event.persist();
    props.updateQueryUserNickname(event.target.value);
    setUserNickname(event.target.value);
  }

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };


  const startRow = (page*rowsPerPage);


  const userClassifies = [
    {
      value: 'ALL',
      label: 'ALL'
    },
    {
      value: 'user_email',
      label: 'EMAIL'
    },
    {
      value: 'NAVER_ID',
      label: 'NAVER'
    },
  ];


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };


  return (

    <Card className={clsx(classes.root, className)} {...rest} >

      <Box m={1} p={2}>

        <Box mt={2}
          display="flex"
          alignItems="center"
          justifyContent="start"
          flexGrow={1}>

          <TextField className={classes.queryField}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="action"><SearchIcon /></SvgIcon>
                </InputAdornment>
              )
            }}

            disabled={userAuth === USER_AUTH.MEMBER}
            onChange={handleQueryMemberNameChange}
            label="운영회원사"
            placeholder="운영회원사"
            value={memberName}
            variant="outlined"
            onKeyUp={(ev) => doKeyPress(ev)}
          />

          <TextField
            className={classes.queryField}
            label="로그인유형"
            name="loginType"
            onChange={handleLoginTypeChange}
            select
            SelectProps={{ native: true }}
            value={loginType}
            variant="outlined"
          >
            {userClassifies.map(option => (
              <option
                key={option.value}
                value={option.value}
              >
                {option.label}
              </option>
            ))}
          </TextField>


          <TextField className={classes.queryField}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="action"><SearchIcon /></SvgIcon>
                </InputAdornment>
              )
            }}

            onChange={handleUserCardNumber}
            label="회원카드번호"
            placeholder="회원카드번호"
            value={userCardNumber}
            variant="outlined"
            onKeyUp={(ev) => doKeyPress(ev)}
          />


          <TextField className={classes.queryField}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="action"><SearchIcon /></SvgIcon>
                </InputAdornment>
              )
            }}

            onChange={handleUserEmailChange}
            label="이메일"
            placeholder="이메일"
            value={userEmail}
            variant="outlined"
            onKeyUp={(ev) => doKeyPress(ev)}
          />


          <TextField className={classes.queryField}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="action"><SearchIcon /></SvgIcon>
                </InputAdornment>
              )
            }}

            onChange={handleUserNicknameChange}
            label="닉네임"
            placeholder="닉네임"
            value={userNickname}
            variant="outlined"
            onKeyUp={(ev) => doKeyPress(ev)}
          />

          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.bulkAction}
            onClick={handleSearch}
          >
            검색
          </Button>


          <Button
            variant="outlined"
            size="large"
            className={classes.bulkAction}
            onClick={handleClear}
          >
            초기화
          </Button>

        </Box>
      </Box>


      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={listData.length} />

              <TableBody>
                { stableSort(listData, getComparator(order, orderBy)).slice(0 + startRow, rowsPerPage + (page * rowsPerPage)).map(module => (

                  <TableRow
                    className={classes.tableRow}
                    key={module.id}
                    component={RouterLink} to={`/users/update/${module.id}`}>

                    <TableCell width={COLUMN_WIDTH.USER_CARD_NUMBER} align="center">{module.user_card_number}</TableCell>
                    <TableCell width={COLUMN_WIDTH.USER_CARD_STOP} align="center">{module.user_card_stop === 'Y' ? '정지': '사용'}</TableCell>
                    <TableCell width={COLUMN_WIDTH.LOGIN_TYPE} align="center">{module.user_login_type}</TableCell>
                    <TableCell width={COLUMN_WIDTH.EMAIL} align="center">{module.user_email}</TableCell>
                    <TableCell width={COLUMN_WIDTH.NICKNAME} align="center">{module.user_nickname}</TableCell>
                    <TableCell width={COLUMN_WIDTH.USER_KIND} align="center">{module.user_kind}</TableCell>
                    <TableCell width={COLUMN_WIDTH.REGISTER_DATE} align="center">{ module.updateTime ? moment(module.updateTime).format('YYYY-MM-DD HH:mm') : ' '}</TableCell>
                    <TableCell width={COLUMN_WIDTH.DELETE_STATUS} align="center">{module.delete_status}</TableCell>
                    <TableCell width={COLUMN_WIDTH.DELETE_TIME} align="center">{ module.delete_time ? moment(module.delete_time).format('YYYY-MM-DD HH:mm') : ' '}</TableCell>
                  </TableRow>
                ))}

              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>

      <CardActions className={classes.actions}>

        <Grid item md={6} justify="flex-start">

          <CSVLink
            variant="contained"
            size="large"
            data={csvData}
            filename={`${new Date().toLocaleDateString()}_회원관리.csv`}
            className="btn btn-primary"
            target="_blank"
            disabled={csvData.length === 0}
            data-interception='off'
          >

            <Button
              variant="contained"
              color="secondary"
              size="large"
              className={classes.bulkAction}
              disabled={csvData.length === 0}
              style={{ marginRight: 30 }}
            >
              CSV 다운로드
            </Button>
          </CSVLink>
        </Grid>

        <Grid item md={6} justify="flex-end">

          <TablePagination
            component="div"
            count={listData.length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </Grid>
      </CardActions>

    </Card>
  );
};

UserTable.propTypes = {
  className: PropTypes.string,
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    updateQueryLoginType,
    updateQueryUserCardNumber,
    updateQueryUserEmail,
    updateQueryUserNickname}, dispatch);
};

let mapStateToProps = (state) => {
  return {
    queryParams: state.queryParams,
    user: state.user
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserTable));




const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    // minWidth: 1050
  },
  nameContainer: {
    // display: 'flex',
    // alignItems: 'center',
    // marginLeft: theme.spacing(1)
  },
  queryField: {
    // width: 300
    flexBasis: 260,
    marginRight:20,
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  bulkAction: {
    marginLeft: theme.spacing(2)
  },
}));
