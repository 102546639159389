import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  InputAdornment,
  SvgIcon,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";



import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import numeral from 'numeral';


import { NEONPV_CONFIG } from "config.js";
import { connect, useSelector } from "react-redux";
import { USER_AUTH } from 'common/constants';


/**
 * NOTE: 사용하지 않음.
 * @param {*} props 
 * @returns 
 */
const SalesCardCompanyTable = (props) => {
  const { className, user, ...rest } = props;

  const classes = useStyles();

  const userAuth = useSelector((state) => state.user.user_auth);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [listData, setListData] = useState([]);

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');

  const [memberName, setMemberName] = useState( userAuth === USER_AUTH.MEMBER ? user.admin_name : '');
  const [chargeStartTime, setChargeStartTime] = useState(new Date((new Date()).valueOf() - (1000 * 60 * 60 * 24)));
  const [chargeEndTime, setChargeEndTime] = useState(new Date((new Date()).valueOf()));
  const [dayOrMonth, setDayOrMonth] = useState('day');



  const searchData = (stime, etime, dayOrMonth) => {

    var url = `${NEONPV_CONFIG.HOSTNAME}/sales/salesCardCompanyList?`;
    var startTime = moment(stime).format("YYMMDDHHmm");
    var endTime = moment(etime).format("YYMMDDHHmm");

    var params = "";
    // //회원사 계정이라면
    // if (userAuth === 2 ) {
    //   params += "memberId=" + user.admin_id + "&";
    // } else if (memberName) {
    // //관리자 계정이라면
    //   params += "memberName=" + memberName + "&";
    // }

    if (startTime) {
      params += "chargeStartTime=" + startTime + "&";
    }

    if (endTime) {
      params += "chargeEndTime=" + endTime + "&";
    }

    if (dayOrMonth){
      params += "dayOrMonth=" + dayOrMonth + "&";
    }


    url += params;

    fetch(url)
      .then((res) => res.json())
      .then((snapshot) => {
        var i = 1;
        // console.log(snapshot);
        var list = [];
        if (snapshot.resultData != null) {
          snapshot.resultData.forEach((doc) => {
            doc.no = i++;
            list.push(doc);
          });
        }
        setListData(list);

        if (list.length === 0){
          setDialogMessage('검색 결과가 없습니다.');
          setOpenDialog(true);
        }
      });
  };



  const handleSearch = (event) => {

    if (chargeStartTime != undefined || chargeEndTime != undefined ) {
      searchData(chargeStartTime, chargeEndTime, dayOrMonth);
    } else {
      setDialogMessage('검색어를 입력해 주세요.');
      setOpenDialog(true);
    }
  };


  /**
   * 검색 정보 지우기
   * @param {*} event
   */
  const handleClear = (event) => {
    setMemberName(userAuth === USER_AUTH.MEMBER ? user.admin_name : '');
    setChargeStartTime(new Date((new Date()).valueOf() - (1000 * 60 * 60 * 24)));
    setChargeEndTime(new Date((new Date()).valueOf()));
    setDayOrMonth('day');

    setListData ([]);
  };


  const handleDayOrMonthChange = (event) => {
    setDayOrMonth(event.target.value);
  }

  const handleQueryMemberNameChange = (event) => {
    event.persist();
    setMemberName(event.target.value);
  }

  const handleChargeStartTimeChange = (date) => {
    setChargeStartTime(date);
  };

  const handleChargeEndtimeChange = (date) => {
    setChargeEndTime(date);
  };


  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
  };


  const startRow = (page * rowsPerPage);


  //NOTE: 집계 내용이므로 자동 검색 하지 않음.
  useEffect(() => {
    return () => { };
  }, []);


  return (

    <div>

      <Dialog open={openDialog} onClose={() => { setOpenDialog(false) }}>
        <DialogTitle>주의</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{dialogMessage}</DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" color="secondary" onClick={() => { setOpenDialog(false) }}>닫기</Button>
        </DialogActions>
      </Dialog>

      <Card className={clsx(classes.root, className)} {...rest}>
        <Box m={1} p={2}>
          <Box mt={2} display="flex" alignItems="center" justifyContent="start" flexGrow={1}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>

            {/* <TextField className={classes.queryField_small}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action"><SearchIcon /></SvgIcon>
                    </InputAdornment>
                  )
                }}

                disabled={userAuth === 2}
                onChange={handleQueryMemberNameChange}
                label="운영회원사"
                placeholder="운영회원사"
                value={memberName}
                variant="outlined"
              /> */}


              <KeyboardDateTimePicker
                autoOk
                // maxDate={moment().subtract(0, "days")}
                variant="inline"
                id="date-picker-dialog"
                label="충전시작일시"
                format="yyyy-MM-dd HH:mm"
                fontSize="30px"
                value={chargeStartTime}
                onChange={handleChargeStartTimeChange}
                KeyboardButtonProps={{ 'aria-label': 'change date', }}
                className={classes.datepicker}
                InputProps={{
                  style: {
                    fontSize: 16,
                    height: 44
                  }
                }}
                style={{
                  width: 200,
                  marginLeft: 20,
                  marginRight: 20,
                }}
              />
              <Typography variant="h5">~</Typography>
              <KeyboardDateTimePicker
                autoOk
                // maxDate={moment().subtract(0, "days")}
                variant="inline"
                id="date-picker-dialog"
                label="충전종료일시"
                format="yyyy-MM-dd HH:mm"
                fontSize="30px"
                value={chargeEndTime}
                onChange={handleChargeEndtimeChange}
                KeyboardButtonProps={{ 'aria-label': 'change date', }}
                className={classes.datepicker}
                InputProps={{
                  style: {
                    fontSize: 16,
                    height: 44
                  }
                }}
                style={{
                  width: 200,
                  marginLeft: 20,
                  marginRight: 60,
                }}
              />
            </MuiPickersUtilsProvider>


            <RadioGroup
              row
              value={dayOrMonth}
              onChange={handleDayOrMonthChange}
            >
              <FormControlLabel value="day" control={<Radio />} label="일별" labelPlacement="start" />
              <FormControlLabel value="month" control={<Radio />} label="월별" labelPlacement="start" />
            </RadioGroup>

            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classes.bulkAction}
              onClick={handleSearch}
            >
              검색
            </Button>

            <Button
              variant="outlined"
              size="large"
              className={classes.bulkAction}
              onClick={handleClear}
            >
              초기화
            </Button>

          </Box>
        </Box>


        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <TableContainer style={{ width: '100%', }}>
                <Table >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" width={COLUMN_WIDTH.CARD_COMPANY}>카드사</TableCell>
                      <TableCell align="center" width={COLUMN_WIDTH.DAY_OR_MONTH}>일별/월별</TableCell>
                      <TableCell align="center" width={COLUMN_WIDTH.TRANS_AMOUNT}>이용건수</TableCell>
                      <TableCell align="center" width={COLUMN_WIDTH.POWER_AMOUNT}>전력량집계[kWh]</TableCell>
                      <TableCell align="center" width={COLUMN_WIDTH.CHARGING_AMOUNT}>충전금액집계[원]</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {listData.slice(0 + startRow, rowsPerPage + (page * rowsPerPage)).map((module) => (
                      <TableRow className={classes.tableRow} >
                        <TableCell align="center" width={COLUMN_WIDTH.CARD_COMPANY}>{module.purchasename}</TableCell>
                        <TableCell align="center" width={COLUMN_WIDTH.DAY_OR_MONTH}>{module.date1}</TableCell>
                        <TableCell align="center" width={COLUMN_WIDTH.TRANS_AMOUNT}>{module.cnt}</TableCell>
                        <TableCell align="center" width={COLUMN_WIDTH.POWER_AMOUNT}>{numeral(module.sum_kwh).format('0,0.00')}</TableCell>
                        <TableCell align="center" width={COLUMN_WIDTH.CHARGING_AMOUNT}>{numeral(module.sum_amount).format('0,0')}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </PerfectScrollbar>
        </CardContent>

        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={listData.length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </CardActions>
      </Card>

    </div>
  );
};


SalesCardCompanyTable.propTypes = {
  className: PropTypes.string,
};

let mapStateToProps = (state) => {
  return {
    user: state.user
  };
};


export default connect(mapStateToProps)(withRouter(SalesCardCompanyTable));



const COLUMN_WIDTH = {
  CARD_COMPANY: '10%',
  DAY_OR_MONTH: '16%', //16
  TRANS_AMOUNT: '20%', //29
  POWER_AMOUNT: '20%', //37
  CHARGING_AMOUNT: '20%', //45
}


const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    // minWidth: 1050
  },
  nameContainer: {
    // display: 'flex',
    // alignItems: 'center',
    // marginLeft: theme.spacing(1)
  },
  queryField: {
    // width: 300
    flexBasis: 300,
    marginRight: 20,
  },
  queryField_small: {
    flexBasis: 160,
    marginRight: 20,
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: "flex-end",
  },
  bulkAction: {
    marginLeft: theme.spacing(2),
  },
  datepicker: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
  },
}));