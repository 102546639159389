import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link as RouterLink, withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  InputAdornment,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  TablePagination,

  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@material-ui/core";

import Autocomplete from '@material-ui/lab/Autocomplete';


import {
  Search as SearchIcon,
} from "react-feather";

import { NEONPV_CONFIG } from "config.js";

import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

import { updateMalfunctionMemberName,
  updateMalFunctionReportStartTime, updateMalFunctionReportEndTime, updateMalFunctionStatName,
  updateMalFunctionRegion, updateMalFunctionStatus } from "actions/query";


import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import { malfunction_status_list, getMalfunctionStatusCode, getMalfunctionStatusLabel, USER_AUTH } from "common/constants";


const MalfunctionTable = (props) => {
  const { className, user, queryParamMalfunction, ...rest } = props;

  const classes = useStyles();

  const userAuth = useSelector((state) => state.user.user_auth);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [listData, setListData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");

  const [memberName, setMemberName] = useState(userAuth === USER_AUTH.MEMBER ? user.admin_name : '');
  const [reportStartTime, setReportStartTime] = useState(new Date((new Date()).valueOf() - (1000 * 60 * 60 * 24)));
  const [reportEndTime, setReportEndTime] = useState(new Date());

  const [queryStatName, setQueryStatName] = useState("");
  const [queryRegion, setQueryRegion] = useState("");

  const [selectedStatus, setSelectedStatus] = useState('');




  const searchData = (autoload = false, memberName, stime, etime, statname, region, statuscode) => {

    var url = `${NEONPV_CONFIG.HOSTNAME}/malFunction/queryMalfunctionList?`;

    var params = "";

    //회원사 계정이라면
    if (userAuth === USER_AUTH.MEMBER ) {
      params += "memberId=" + user.admin_id + "&";
    } else if (memberName) {
    //관리자 계정이라면
      params += "memberName=" + memberName + "&";
    }

    if (stime) {
      params += "reportStartTime=" + moment(stime).format('YYYY-MM-DD HH:mm') + '&';
    }

    if (etime) {
      params += "reportEndTime=" + moment(etime).format('YYYY-MM-DD HH:mm') + '&';
    }

    if (statname.length > 0) {
      params += "statName=" + statname + '&';
    }

    if (region) {
      params += "region=" + region + '&';
    }

    if (statuscode > 0) {
      params += "status=" + statuscode + '&';
    }

    url += params;

    // console.log(url);


    fetch(url)
      .then((res) => res.json())
      .then((snapshot) => {
        var i = 1;
        var list = [];
        if (snapshot.resultData != null) {
          snapshot.resultData.forEach((doc) => {
            doc.no = i++;
            list.push(doc);
          });
        }
        setListData(list);

        if (autoload === false && list.length === 0){
          setDialogMessage('검색 결과가 없습니다.');
          setOpenDialog(true);
        }

        setPage(0);
      });
  };



  const handleSearch = (event) => {
    if (reportStartTime !== null && reportEndTime !== null) {
      console.log("reportStartTime: " + reportStartTime);
      console.log("reportEndTime: " + reportEndTime);
      console.log("queryStatName: " + queryStatName);

      //'검색'버튼을 클릭하면, 검색어를 Redux에 저장한다.
      props.updateMalfunctionMemberName(memberName);
      props.updateMalFunctionReportStartTime(reportStartTime);
      props.updateMalFunctionReportEndTime(reportEndTime);
      props.updateMalFunctionStatName(queryStatName);
      props.updateMalFunctionRegion(queryRegion);
      props.updateMalFunctionStatus(selectedStatus);

      searchData(false, memberName, reportStartTime, reportEndTime, queryStatName, queryRegion, getMalfunctionStatusCode(selectedStatus));
    } else {
      setDialogMessage('검색어를 입력해 주세요. ');
      setOpenDialog(true);
    }
  };

  const doKeyPress = (ev) => {
    if (ev.key === 'Enter') {
      handleSearch();
    }
  }


  /**
   * 검색 정보 지우기
   * @param {*} event
   */
  const handleClear = (event) => {
    // props.updateMalFunctionReportStartTime(new Date((new Date()).valueOf() - (1000 * 60 * 60 * 24)));
    // props.updateMalFunctionReportEndTime(new Date());
    props.updateMalfunctionMemberName('');
    props.updateMalFunctionReportStartTime('');
    props.updateMalFunctionReportEndTime('');
    props.updateMalFunctionStatName("");
    props.updateMalFunctionRegion("");
    props.updateMalFunctionStatus("");

    setMemberName(userAuth === USER_AUTH.MEMBER ? user.admin_name : '');

    setReportStartTime ( new Date((new Date()).valueOf() - (1000 * 60 * 60 * 24)));
    setReportEndTime( new Date() );

    setQueryStatName("");
    setQueryRegion("");
    setSelectedStatus("");
    setListData([]);
  };


  const handleQueryMemberNameChange = (event) => {
    event.persist();
    setMemberName(event.target.value);
  }

  const handleReportStartTimeChange = (date) => {
    setReportStartTime(date);
  };

  const handleReportEndtimeChange = (date) => {
    setReportEndTime(date);
  };

  const handleQueryStatNameChange = (event) => {
    event.persist();
    setQueryStatName(event.target.value);
  };

  const handleQueryRegionChange = (event) => {
    event.persist();
    setQueryRegion(event.target.value);
  };

  const handleStatusChange = (event, newValue) => {
    event.persist();
    setSelectedStatus(newValue);
  }


  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
  };


  const startRow = (page * rowsPerPage);


  useEffect(() => {

    //queryParamMalfunction.queryMalfunctionMemberName || 는 제외하자
    if (queryParamMalfunction.queryMalfunctionReportStartTime ||
      queryParamMalfunction.queryMalfunctionReportEndTime ||
      queryParamMalfunction.queryMalfunctionStatName ||
      queryParamMalfunction.queryMalfunctionRegion ||
      queryParamMalfunction.queryMalfunctionStatus) {

        searchData(true,
          queryParamMalfunction.queryMalfunctionMemberName,
          queryParamMalfunction.queryMalfunctionReportStartTime,
          queryParamMalfunction.queryMalfunctionReportEndTime,
          queryParamMalfunction.queryMalfunctionStatName,
          queryParamMalfunction.queryMalfunctionRegion,
          getMalfunctionStatusCode(queryParamMalfunction.queryMalfunctionStatus));
    }


    if(userAuth === USER_AUTH.MEMBER) {
      setMemberName(user.admin_name);
    } else {
      setMemberName(queryParamMalfunction.queryMalfunctionMemberName);
    }

    if (queryParamMalfunction.queryMalfunctionReportStartTime) {
      setReportStartTime(queryParamMalfunction.queryMalfunctionReportStartTime);
    }
    if (queryParamMalfunction.queryMalfunctionReportEndTime) {
      setReportEndTime(queryParamMalfunction.queryMalfunctionReportEndTime);
    }

    setQueryStatName(queryParamMalfunction.queryMalfunctionStatName);
    setQueryRegion(queryParamMalfunction.queryMalfunctionRegion);
    setSelectedStatus(queryParamMalfunction.queryMalfunctionStatus);

    return () => { };
  }, []);


  return (

    <div>

      <Dialog open={openDialog} onClose={() => { setOpenDialog(false) }}>
        <DialogTitle>주의</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{dialogMessage}</DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" color="secondary" onClick={() => { setOpenDialog(false) }}>닫기</Button>
        </DialogActions>
      </Dialog>


      <Card className={clsx(classes.root, className)} {...rest}>
        <Box m={1} p={2}>

          <Box mt={2} display="flex" alignItems="center" justifyContent="start">

            <TextField className={classes.queryField_small}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action"><SearchIcon /></SvgIcon>
                    </InputAdornment>
                  )
                }}

                disabled={userAuth === USER_AUTH.MEMBER}
                onChange={handleQueryMemberNameChange}
                label="운영회원사"
                placeholder="운영회원사"
                value={memberName}
                variant="outlined"
                onKeyUp={(ev) => doKeyPress(ev)}
              />

            {/* <Typography variant="h5" >신고일시</Typography> */}
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDateTimePicker
                // locale="ko"
                // disableToolbar
                // margin="dense"
                // showTodayButton={true}
                autoOk
                // maxDate={moment().subtract(0, "days")}
                variant="inline"
                id="date-picker-dialog"
                format="yyyy-MM-dd HH:mm"
                fontSize="30px"
                label="신고일시(시작)"
                required
                value={reportStartTime}
                onChange={handleReportStartTimeChange}
                KeyboardButtonProps={{ 'aria-label': 'change date', }}
                className={classes.datepicker}
                InputProps={{
                  style: {
                    fontSize: 16,
                    height: 44
                  }
                }}
                style={{
                  width: 180,
                  marginLeft: 20,
                  marginRight: 20,
                }}
              />
              <Typography variant="h5">~</Typography>
              <KeyboardDateTimePicker
                // locale="ko"
                // disableToolbar
                // margin="dense"
                // showTodayButton={true}
                autoOk
                // maxDate={moment().subtract(0, "days")}
                variant="inline"
                id="date-picker-dialog"
                // label="조회 기준일"
                format="yyyy-MM-dd HH:mm"
                fontSize="30px"
                label="신고일시(종료)"
                required
                value={reportEndTime}
                onChange={handleReportEndtimeChange}
                KeyboardButtonProps={{ 'aria-label': 'change date', }}
                className={classes.datepicker}
                InputProps={{
                  style: {
                    fontSize: 16,
                    height: 44
                  }
                }}
                style={{
                  width: 180,
                  marginLeft: 20,
                  marginRight: 20,
                }}
              />
            </MuiPickersUtilsProvider>


            <TextField
              className={classes.queryField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              // style={{ width: 80 }}
              onChange={handleQueryStatNameChange}
              label="충전소 명칭"
              placeholder="충전소 명칭"
              value={queryStatName}
              variant="outlined"
              onKeyUp={(ev) => doKeyPress(ev)}
            />

            <TextField
              className={classes.queryField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              onChange={handleQueryRegionChange}
              label="지역"
              placeholder="지역"
              value={queryRegion}
              variant="outlined"
              onKeyUp={(ev) => doKeyPress(ev)}
            />


            <Autocomplete
              id="status_select"
              options={malfunction_status_list}
              classes={{ option: classes.options, }}
              autoHighlight
              getOptionSelected={(option, value) => option === value}
              value={selectedStatus}
              renderOption={(option) => (
                <React.Fragment>
                  {option}
                </React.Fragment>
              )}

              renderInput={(params) => (
                <TextField
                  {...params}
                  label="진행상태"
                  variant="outlined"
                  // fullWidth
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}

              onChange={handleStatusChange}
              // onChange={(event, newValue) => {
              //   if (newValue) {
              //     event.persist();
              //     setSelectedStatus(newValue);
              //     // props.updateMalFunctionStatus(newValue);
              //   }
              // }}

              style={{
                width: 180,
                // marginLeft: 20,
                marginRight: 20,
              }}
            />


            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classes.bulkAction}
              onClick={handleSearch}
            >
              검색
            </Button>

            <Button
              variant="outlined"
              size="large"
              className={classes.bulkAction}
              onClick={handleClear}
            >
              초기화
            </Button>
          </Box>
        </Box>

        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center" width={COLUMN_WIDTH.NO}>No</TableCell>
                    <TableCell align="center" width={COLUMN_WIDTH.REPORT_DATE}>신고일시</TableCell>
                    <TableCell align="center" width={COLUMN_WIDTH.REPORTER}>신고인</TableCell>
                    <TableCell align="center" width={COLUMN_WIDTH.CORPORATE_NAME}>법인명</TableCell>
                    <TableCell align="center" width={COLUMN_WIDTH.REPORTER_TEL_NO}>유선전화</TableCell>
                    <TableCell align="center" width={COLUMN_WIDTH.REPORTER_MOBILE_NO}>휴대폰</TableCell>
                    <TableCell align="center" width={COLUMN_WIDTH.REPORT_EMAIL}>이메일</TableCell>
                    <TableCell align="center" width={COLUMN_WIDTH.REPORT_REGION}>지역</TableCell>
                    <TableCell align="center" width={COLUMN_WIDTH.MEMBER_NAME}>운영회원사</TableCell>
                    <TableCell align="center" width={COLUMN_WIDTH.STAT_NAME}>충전소</TableCell>
                    <TableCell align="center" width={COLUMN_WIDTH.CHARGER_NAME}>충전기이름</TableCell>
                    <TableCell align="center" width={COLUMN_WIDTH.CHARGER_INFO}>충전기정보</TableCell>
                    <TableCell align="center" width={COLUMN_WIDTH.REGISTER_DATE}>접수일시</TableCell>
                    <TableCell align="center" width={COLUMN_WIDTH.STATUS}>상태</TableCell>
                    <TableCell align="center" width={COLUMN_WIDTH.PROCESS_DATE}>처리일시</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {listData.slice(0 + startRow, rowsPerPage + (page * rowsPerPage)).map((module) => (
                    <TableRow
                      className={classes.tableRow}
                      key={module.SeqNo}
                      component={RouterLink}
                      to={`/malfunctions/update/${module.SeqNo}`}
                    >
                      <TableCell width={COLUMN_WIDTH.NO}><Typography variant="body2">{module.no}</Typography></TableCell>
                      <TableCell width={COLUMN_WIDTH.REPORT_DATE}>
                        <div className={classes.nameContainer}>
                          <Typography variant="body2">
                            {moment(module.reportDate).format('YY-MM-DD HH:mm')}
                          </Typography>
                        </div>
                      </TableCell>
                      <TableCell width={COLUMN_WIDTH.REPORTER}><Typography variant="body2">{module.reporter_name}</Typography></TableCell>
                      <TableCell width={COLUMN_WIDTH.CORPORATE_NAME}><Typography variant="body2">{module.corporate_name}</Typography></TableCell>
                      <TableCell width={COLUMN_WIDTH.REPORTER_TEL_NO}><Typography variant="body2">{module.reporter_tel_no}</Typography></TableCell>
                      <TableCell width={COLUMN_WIDTH.REPORTER_MOBILE_NO}><Typography variant="body2">{module.reporter_mobile_no}</Typography></TableCell>
                      <TableCell width={COLUMN_WIDTH.REPORT_EMAIL}><Typography variant="body2">{module.reporter_email}</Typography></TableCell>
                      <TableCell width={COLUMN_WIDTH.REPORT_REGION}><Typography variant="body2">{module.reporter_region}</Typography></TableCell>
                      <TableCell align="center" width={COLUMN_WIDTH.MEMBER_NAME}><Typography variant="body2">{module.member_name}</Typography></TableCell>
                      <TableCell width={COLUMN_WIDTH.STAT_NAME}><Typography variant="body2">{module.statName}</Typography></TableCell>
                      <TableCell width={COLUMN_WIDTH.CHARGER_NAME}><Typography variant="body2">{module.charger_name}</Typography></TableCell>
                      <TableCell width={COLUMN_WIDTH.CHARGER_INFO}><Typography variant="body2">{module.charger_info}</Typography></TableCell>
                      <TableCell width={COLUMN_WIDTH.REGISTER_DATE}><Typography variant="body2">{module.register_date ? moment(module.register_date).format('YY-MM-DD HH:mm') : ''}</Typography></TableCell>
                      <TableCell align="center" width={COLUMN_WIDTH.STATUS}><Typography variant="body2">{getMalfunctionStatusLabel(module.status)}</Typography></TableCell>
                      <TableCell width={COLUMN_WIDTH.PROCESS_DATE}><Typography variant="body2">{module.process_date ? moment(module.process_date).format('YY-MM-DD HH:mm') : ''}</Typography></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>

        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={listData.length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </CardActions>
      </Card>

    </div>
  );
};

MalfunctionTable.propTypes = {
  className: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { updateMalfunctionMemberName,
      updateMalFunctionReportStartTime, updateMalFunctionReportEndTime, updateMalFunctionStatName,
      updateMalFunctionRegion, updateMalFunctionStatus},
    dispatch
  );
};

let mapStateToProps = (state) => {
  return {
    queryParamMalfunction: state.queryParamMalfunction,
    user: state.user
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MalfunctionTable));



const COLUMN_WIDTH = {
  NO: '3%',
  REPORT_DATE: '7%', //10
  REPORTER: '8%', //15
  CORPORATE_NAME: '8%', //23
  REPORTER_TEL_NO: '7%', //30
  REPORTER_MOBILE_NO: '7%', //37
  REPORT_EMAIL: '8%', //45
  REPORT_REGION: '5%', //49
  MEMBER_NAME: '7%', //56
  STAT_NAME: '8%', //64
  CHARGER_NAME: '6%', //69
  CHARGER_INFO: '6%', //75
  REGISTER_DATE: '7%', //82
  STATUS: '5%', //89
  PROCESS_DATE: '7%', //96
}


const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    // minWidth: 1050
  },
  nameContainer: {
    // display: 'flex',
    // alignItems: 'center',
    // marginLeft: theme.spacing(1)
  },
  queryField: {
    flexBasis: 220,
    marginRight: 20,
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: "flex-end",
  },
  bulkAction: {
    marginLeft: theme.spacing(2),
  },
}));
