import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Formik } from "formik";
import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Divider,
} from "@material-ui/core";

import Autocomplete from '@material-ui/lab/Autocomplete';
import { NEONPV_CONFIG } from "config.js";
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';

import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker
} from '@material-ui/pickers';

import { malfunction_status_list, getMalfunctionStatusCode, MAX_LENGTH, USER_AUTH } from "common/constants";

import { bindActionCreators } from 'redux';
import {
  updateOpenDialog,
  updateSelectedStation
} from "actions/settings";

import {StationSearchDialog} from "views/StationSearchDialog";


const MalfunctionAddForm = (props) => {
  const { className, history, user, ...rest } = props;
  const classes = useStyles();

  const selector_openDialog = useSelector((state) => state.settings.openDialog);
  const selector_selectedStationInfo = useSelector((state) => state.settings.selectedStationInfo);

  const [selectedProcessStatus, setSelectedProcessStatus] = useState('');


  //신고일시
  const [reportDate, setReportDate] = useState(new Date());

  //접수일시
  const [registerDate, setRegisterDate] = useState();

  //처리일시
  const [processDate, setProcessDate] = useState();

  const userAuth = useSelector((state) => state.user.user_auth);


  const handleSubmit = (event, values) => {
    event.preventDefault();

    fetch(`${NEONPV_CONFIG.HOSTNAME}/malfunction/createMalfunctionInfo`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        reportDate: reportDate ? moment(reportDate).format("YYYY-MM-DD HH:mm") : null,
        reporter_name: values.reporter_name,
        corporate_name: values.corporate_name,
        reporter_tel_no: values.reporter_tel_no,
        reporter_mobile_no: values.reporter_mobile_no,
        reporter_email: values.reporter_email,
        statId: selector_selectedStationInfo.statId,
        reporter_region: values.reporter_region,
        charger_name: values.charger_name,
        charger_info: values.charger_info,

        process_content: values.process_content,
        manager: values.manager,
        report_content: values.report_content,

        status: getMalfunctionStatusCode(selectedProcessStatus),
        register_date: registerDate ? moment(registerDate).format("YYYY-MM-DD HH:mm") : null,
        process_date: processDate ? moment(processDate).format("YYYY-MM-DD HH:mm") : null,
        member_id: userAuth == USER_AUTH.MEMBER ? user.admin_id : '', //회원사라면 회원사ID, 관리자면 null
      }),
    })
    .then((response) => {
      return response.json();
    })
    .then((responseData) => {
      if (responseData.resultCode === "000") {
        alert("등록에 성공했습니다.");

        props.updateSelectedStation({});

        setTimeout(() => {
          history.goBack();
        }, 500);
      } else {
        //에러
        alert(`등록에 실패했습니다.\n(${responseData.resultCode}):${responseData.resultMessage}`);
      }
    })
    .catch((error) => {
      console.error(error);
      alert("등록에 실패했습니다.");
    });
  };

  const handleBack = () => {
    props.updateSelectedStation({});
    history.goBack();
  };

  const showChargerSearchDialog = () => {
    props.updateOpenDialog(true);
  }


  useEffect(() => {
    props.updateSelectedStation({});
  }, []);


  return (
    <div>
      <StationSearchDialog openDialog={selector_openDialog} />

      <Formik
        enableReinitialize={false}
        initialValues={{
          reporter_name: "", //신고자 이름
          corporate_name: "", //법인명
          reporter_tel_no: "", //신고자 유선전화
          reporter_mobile_no: "", //신고자 휴대전화
          reporter_email: "", //신고자 이메일
          reporter_region: "", //신고자 지역
          charger_name: "", //충전기
          charger_info: "", //충전기 정보

          process_content: "", // 고장 처리 내용
          manager: "", // 처리 담당자
          report_content: "", // 고장 신고 내용
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          isSubmitting,
          touched,
          values,
          setFieldValue
        }) => (
          <form
            className={clsx(classes.root, className)}
            onSubmit={(e) => handleSubmit(e, values)}
          >

            <Card>
              <CardContent>
                <Grid container spacing={2} >
                  <Grid item md={3} xs={4}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDateTimePicker
                        autoOk
                        variant="inline"
                        ampm={false}
                        id="date-picker-dialog"
                        format="yyyy-MM-dd HH:mm"
                        fontSize="30px"
                        label="신고일시"
                        onChange={setReportDate}
                        value={reportDate ? reportDate : null}
                        KeyboardButtonProps={{ 'aria-label': 'change date', }}
                        className={classes.datepicker}
                        InputProps={{
                          style: {
                            fontSize: 16,
                            height: 44
                          }
                        }}
                        style={{
                          width: 200,
                          marginLeft: 20,
                          marginRight: 20,
                        }}
                      />

                    </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid item md={1} xs={2}></Grid>

                  <Grid item md={3} xs={6}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDateTimePicker
                        autoOk={true}
                        variant="inline"
                        ampm={false}
                        id="date-picker-dialog"
                        format="yyyy-MM-dd HH:mm"
                        fontSize="30px"
                        label="접수일시"
                        onChange={setRegisterDate}
                        value={registerDate ? registerDate : null}
                        KeyboardButtonProps={{ 'aria-label': 'change date', }}
                        className={classes.datepicker}
                        InputProps={{
                          style: {
                            fontSize: 16,
                            height: 44
                          }
                        }}
                        style={{
                          width: 200,
                          marginLeft: 20,
                          marginRight: 20,
                        }}
                        />

                    </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid item md={1} xs={2}></Grid>

                  <Grid item md={3} xs={6}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDateTimePicker
                        autoOk={true}
                        variant="inline"
                        ampm={false}
                        id="date-picker-dialog"
                        format="yyyy-MM-dd HH:mm"
                        fontSize="30px"
                        label="처리일시"
                        onChange={setProcessDate}
                        value={processDate ? processDate : null}
                        KeyboardButtonProps={{ 'aria-label': 'change date', }}
                        className={classes.datepicker}
                        InputProps={{
                          style: {
                            fontSize: 16,
                            height: 44
                          }
                        }}
                        style={{
                          width: 200,
                          marginLeft: 20,
                          marginRight: 20,
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>

                <Divider className={classes.divider} variant="inset" />

                <Grid container spacing={2} >
                  <Grid item md={2} xs={4}>
                    <TextField
                      error={Boolean(touched.reporter_name && errors.reporter_name)}
                      fullWidth
                      helperText={touched.reporter_name && errors.reporter_name}
                      label="신고자명"
                      name="reporter_name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.reporter_name}
                      variant="outlined"
                      inputProps={{ maxLength: MAX_LENGTH.REPORTER_NAME }}
                    />
                  </Grid>

                  <Grid item md={3} xs={6}>
                    <TextField
                      error={Boolean(touched.corporate_name && errors.corporate_name)}
                      fullWidth
                      helperText={touched.corporate_name && errors.corporate_name}
                      label="법인명"
                      name="corporate_name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.corporate_name}
                      variant="outlined"
                      inputProps={{ maxLength: MAX_LENGTH.CORPORATE_NAME }}
                    />
                  </Grid>

                  <Grid item md={2} xs={4}>
                    <TextField
                      error={Boolean(touched.reporter_tel_no && errors.reporter_tel_no)}
                      fullWidth
                      helperText={touched.reporter_tel_no && errors.reporter_tel_no}
                      label="유선전화"
                      name="reporter_tel_no"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.reporter_tel_no}
                      variant="outlined"
                      inputProps={{ maxLength: MAX_LENGTH.REPORTER_TEL_NO }}
                    />
                  </Grid>

                  <Grid item md={2} xs={4}>
                    <TextField
                      error={Boolean(touched.reporter_mobile_no && errors.reporter_mobile_no)}
                      fullWidth
                      helperText={touched.reporter_mobile_no && errors.reporter_mobile_no}
                      label="휴대전화"
                      name="reporter_mobile_no"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.reporter_mobile_no}
                      variant="outlined"
                      inputProps={{ maxLength: MAX_LENGTH.REPORTER_MOBILE_NO }}
                    />
                  </Grid>

                  <Grid item md={3} xs={6}>
                    <TextField
                      error={Boolean(touched.reporter_email && errors.reporter_email)}
                      fullWidth
                      helperText={touched.reporter_email && errors.reporter_email}
                      label="이메일"
                      name="reporter_email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.reporter_email}
                      variant="outlined"
                      inputProps={{ maxLength: MAX_LENGTH.REPORTER_EMAIL }}
                    />
                  </Grid>
                </Grid>

                <Divider className={classes.divider} variant="inset" />

                <Grid container spacing={2} className={classes.content} >
                    <Grid container item md={2} xs={2}
                      direction="column"
                      alignItems="center"
                      justify="center"
                    >
                      <Button
                        variant="outlined"
                        color="primary"
                        size="large"
                        fullWidth
                        onClick={showChargerSearchDialog}
                      >
                        충전소 검색
                      </Button>
                    </Grid>

                    <Grid item md={2} xs={4} >
                      <TextField
                        error={Boolean(touched.statId && errors.statId)}
                        fullWidth
                        helperText={touched.statId && errors.statId}
                        label="충전소ID"
                        name="statId"
                        onBlur={handleBlur}
                        onChange={(e) => { handleChange(e); setFieldValue('statId', selector_selectedStationInfo.statId); }}
                        value={selector_selectedStationInfo.statId}
                        variant="outlined"
                        inputProps={{
                          maxLength: MAX_LENGTH.STAT_NAME, readOnly: true,
                        }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>

                    <Grid item md={4} xs={6}>
                      <TextField
                        fullWidth
                        label="충전소 명칭"
                        name="statName"
                        value={selector_selectedStationInfo.statName}
                        variant="outlined"
                        inputProps={{ maxLength: MAX_LENGTH.STAT_NAME, readOnly: true,  }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>

                    <Grid item md={4} xs={6}>
                      <TextField
                        fullWidth
                        label="충전소 주소"
                        name="statAddr"
                        value={selector_selectedStationInfo.addr}
                        variant="outlined"
                        inputProps={{ maxLength: MAX_LENGTH.STAT_ADDR, readOnly: true,  }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>


                    <Grid item md={3} xs={6}>
                      <TextField
                        error={Boolean(touched.charger_info && errors.charger_info)}
                        fullWidth
                        helperText={touched.charger_info && errors.charger_info}
                        label="충전기 정보(20자)"
                        name="charger_info"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.charger_info}
                        variant="outlined"
                        inputProps={{ maxLength: MAX_LENGTH.CHARGER_INFO }}
                      />
                    </Grid>

                    <Grid item md={3} xs={6}>
                      <TextField
                        error={Boolean(touched.process_content && errors.process_content)}
                        fullWidth
                        helperText={touched.process_content && errors.process_content}
                        label="고장처리내용(100자)"
                        name="process_content"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.process_content}
                        variant="outlined"
                        inputProps={{ maxLength: MAX_LENGTH.MALFUNCTION_PROCESS_CONTENT }}
                      />
                    </Grid>

                    <Grid item md={2} xs={4}>
                      <TextField
                        error={Boolean(touched.manager && errors.manager)}
                        fullWidth
                        helperText={touched.manager && errors.manager}
                        label="처리담당자"
                        name="manager"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.manager}
                        variant="outlined"
                        inputProps={{ maxLength: MAX_LENGTH.MALFUNCTION_MANAGER }}
                      />
                    </Grid>
                    <Grid item md={2} xs={4}>
                      <TextField
                        fullWidth
                        label="운영회원사"
                        name="businessName"
                        value={user.admin_name}
                        variant="outlined"
                        inputProps={{ maxLength: 12 }}
                        // disabled={userAuth === 2} // 운영사 로그인 시 입력 불가
                        disabled
                      />
                    </Grid>

                    <Grid item md={12} xs={12}>
                      <TextField
                        error={Boolean(touched.report_content && errors.report_content)}
                        fullWidth
                        multiline
                        minRows={3}
                        helperText={touched.report_content && errors.report_content}
                        label="고장신고내용(1000자)"
                        name="report_content"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.report_content}
                        variant="outlined"
                        inputProps={{ maxLength: MAX_LENGTH.MALFUNCTION_REPORT_CONTENT }}
                      />
                    </Grid>

                </Grid>

                <Divider className={classes.divider} variant="inset" />

                <Grid container spacing={2} >
                  <Grid item md={3} xs={6}>
                    <TextField
                      error={Boolean(touched.reporter_region && errors.reporter_region)}
                      fullWidth
                      helperText={touched.reporter_region && errors.reporter_region}
                      label="지역"
                      name="reporter_region"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.reporter_region}
                      variant="outlined"
                      inputProps={{ maxLength: MAX_LENGTH.REPORTER_REGION }}
                    />
                  </Grid>

                  <Grid item md={2} xs={4}>
                    <TextField
                      error={Boolean(touched.charger_name && errors.charger_name)}
                      fullWidth
                      helperText={touched.charger_name && errors.charger_name}
                      label="충전기이름"
                      name="charger_name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.charger_name}
                      variant="outlined"
                      inputProps={{ maxLength: MAX_LENGTH.CHARGER_NAME }}
                    />
                  </Grid>


                  <Grid item md={2} xs={6}>

                    <Autocomplete
                      id="status_select"
                      options={malfunction_status_list}
                      classes={{ option: classes.options, }}
                      autoHighlight
                      getOptionSelected={(option, value) => option === value}
                      value={selectedProcessStatus}
                      renderOption={(option) => (
                        <React.Fragment>
                          {option}
                        </React.Fragment>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="진행상태"
                          variant="outlined"
                          fullWidth
                          inputProps={{
                            ...params.inputProps,
                          }}
                        />
                      )}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          // console.log(newValue);
                          setSelectedProcessStatus(newValue);
                        }
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid container item
                  className={classes.action}
                  direction="row" alignItems="center" justify="center">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="large"
                    disabled={isSubmitting}
                    className={classes.action}
                  >
                    고장신고하기
                  </Button>

                  <Button
                    variant="outlined"
                    size="large"
                    onClick={handleBack}
                    className={classes.action}
                  >
                    취소
                  </Button>
                </Grid>
              </CardContent>
            </Card>
          </form>
        )}
      </Formik>
    </div>
  );
};

MalfunctionAddForm.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object,
};


const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    updateOpenDialog,
    updateSelectedStation
  }, dispatch);
};

let mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MalfunctionAddForm);


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    direction: "row",
    alignItems: "center",
    justify: "center"
  },
  content2: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  action: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },
  queryField: {
    // width: 300
    flexBasis: 260,
    marginRight: 20,
  },
  bulkAction: {
    marginLeft: theme.spacing(2),
  },

  divider: {
    margin: theme.spacing(2, 0)
  },
}));
