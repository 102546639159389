import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link as RouterLink, withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  InputAdornment,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  TablePagination,
  TableSortLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,

  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";


import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';

import {
  Search as SearchIcon,
} from "react-feather";

import { NEONPV_CONFIG } from "config.js";

import { connect, useSelector} from "react-redux";
import { bindActionCreators } from "redux";

import {
  updateQueryUsageMemberName,
  updateQueryUsageChargerStartTime,
  updateQueryUsageChargerEndTime,
  updateQueryUsageChargerStatName,
  updateQueryChargerStatId } from "actions/query";


import {CSVLink} from "react-csv";
import { USER_AUTH } from 'common/constants';
import { paymentMethodFromCardNo } from 'common/global';



const COLUMN_WIDTH = {
  FLAG: '5%',
  // ORDERNO: '8%',
  TRANS_NO: '10%', //20
  STATION_NAME: '13%', //35
  CHARGER_NO: '8%', //42
  // CHARGER_STATUS: '6%', //47
  CREDIT_CARD_NO: '9%', //57
  CHARGER_QUANTITY: '6%', //62
  CHARGER_PRICE: '7%', //70
  // CHARGER_TIME: '7%', //80
  CHARGE_START_TIME: '10%', //90
  CHARGE_END_TIME: '10%', //90
  PAYMENT_METHOD: '8%', //100
}


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) { return -1; }
  if (b[orderBy] > a[orderBy]) { return 1; }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


const headCells = [
  {
    id: 'flag',
    numeric: false,
    disablePadding: true,
    width:COLUMN_WIDTH.FLAG,
    label: '삭제',
  },
  {
    id: 'TransactionCode',
    numeric: true,
    disablePadding: false,
    width:COLUMN_WIDTH.TRANS_NO,
    label: '거래번호',
  },
  {
    id: 'statName',
    numeric: false,
    disablePadding: false,
    width:COLUMN_WIDTH.STATION_NAME,
    label: '충전소',
  },
  {
    id: 'chargerId',
    numeric: false,
    disablePadding: false,
    width:COLUMN_WIDTH.CHARGER_NO,
    label: '충전기',
  },
  // {
  //   id: 'chargerStatus',
  //   numeric: false,
  //   disablePadding: false,
  //   width:COLUMN_WIDTH.CHARGER_STATUS,
  //   label: '상태',
  // },
  {
    id: 'TransactionCardNo',
    numeric: false,
    disablePadding: true,
    width:COLUMN_WIDTH.CREDIT_CARD_NO,
    label: '카드번호',
  },
  {
    id: 'ChargerQuantity',
    numeric: false,
    disablePadding: true,
    width:COLUMN_WIDTH.CHARGER_QUANTITY,
    label: '충전량[Wh]',
  },
  {
    id: 'ChargerPrice',
    numeric: false,
    disablePadding: false,
    width:COLUMN_WIDTH.CHARGER_PRICE,
    label: '충전요금[원]',
  },
  {
    id: 'ChargeStartTime',
    numeric: false,
    disablePadding: false,
    width:COLUMN_WIDTH.CHARGE_START_TIME,
    label: '충전시작일시',
  },
  {
    id: 'ChargeEndTime',
    numeric: false,
    disablePadding: false,
    width:COLUMN_WIDTH.CHARGE_END_TIME,
    label: '충전종료일시',
  },
  {
    // id: 'PaymentMethod',
    id: 'payMethod',
    numeric: false,
    disablePadding: false,
    width:COLUMN_WIDTH.PAYMENT_METHOD,
    label: '결제수단',
  }
];


function EnhancedTableHead(props) {
  const { order, orderBy, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            // align={headCell.numeric ? 'right' : 'center'}
            align='center'
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            width={headCell.width}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {/* {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null} */}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};



export const payment_method_list = ['-', '회원', '환경부', '로밍회원', '신용카드'];
// export const payment_method_list = ['-', '회원', '환경부', '신용카드'];


/**
 * 충전기사용이력
 * @param {*} props 
 * @returns 
 */
const UsageChargerTable = (props) => {
  const { className, user, queryParamUsage, ...rest } = props;

  const classes = useStyles();

  const userAuth = useSelector((state) => state.user.user_auth);

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('TransactionCode');

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [listData, setListData] = useState([]);
  const [csvData, setCsvData] = useState([]);

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");

  const [memberName, setMemberName] = useState(userAuth === USER_AUTH.ADMIN ? '' : user.admin_name);
  const [chargeStartTime, setChargeStartTime] = useState(new Date((new Date()).valueOf() - (1000 * 60 * 60 * 24)));
  const [chargeEndTime, setChargeEndTime] = useState(new Date((new Date()).valueOf()));
  const [queryStatName, setQueryStatName] = useState("");
  const [queryPaymentMethod, setQueryPaymentMethod] = useState("");
  const [queryStatId, setQueryStatId] = useState('');


  const searchData = (autoload = false, memberName, stime, etime, statName, paymentMethod, statId) => {

    var url = `${NEONPV_CONFIG.HOSTNAME}/usage/usageChargerList?`;

    var startTime = moment(stime).format("YYYY-MM-DD HH:mm");
    var endTime = moment(etime).format("YYYY-MM-DD HH:mm");


    var params = "";

    // 콜센터 계정이라면
    if(user.admin_id == 'callcenter'){
      params += "memberId=castpro&";
    }
    //회원사 계정이라면
    else if (userAuth === USER_AUTH.MEMBER ) {
      params += "memberId=" + user.admin_id + "&";
    } 
    // 서브계정
    else if (userAuth === USER_AUTH.SUB_MEMBER){
      params += "memberId=" + user.admin_id + "&";
    }
    else if (memberName) {
    //관리자 계정이라면
      params += "memberName=" + memberName + "&";
    }

    if (statId) {
      params += "statId=" + statId + "&";
    }

    if (startTime) {
      params += "chargeStartTime=" + startTime + '&';
    }

    if (endTime) {
      params += "chargeEndTime=" + endTime + '&';
    }

    if (statName) {
      params += "statName=" + statName + '&';
    }

    if(paymentMethod){
      params += "paymentMethod=" + paymentMethod + '&';
    }

    url += params;

    fetch(url)
      .then((res) => res.json())
      .then((snapshot) => {
        var list = [];
        var csvlist = [];
        if (snapshot.resultData != null) {
          snapshot.resultData.forEach((doc) => {
            doc.rowChecked = false;
            // doc.payMethod = paymentFromCardNo(doc.TransactionCardNo);
            doc.payMethod = paymentMethodFromCardNo(doc.TransactionCardNo, doc.bid);

            list.push(doc);


            csvlist.push({
              // '주문번호': '=""' + doc.OrderNo + '""',
              '카드승인번호': doc.TransactionCode,
              '충전소': ""+doc.statName,
              '충전기번호':  '=""' + doc.chargerId + '""',
              // '상태': ""+doc.chargerStatus,
              '카드번호': '=""' + doc.TransactionCardNo + '""',
              '충전량': doc.ChargerQuantity,
              '충전요금': doc.ChargerPrice || "",
              '충전시작일시': doc.ChargeStartTime ? moment(doc.ChargeStartTime).format("YYYY-MM-DD HH:mm:ss") : "",
              '충전종료일시': doc.ChargeEndTime ? moment(doc.ChargeEndTime).format("YYYY-MM-DD HH:mm:ss") : "",
              '결제수단': doc.payMethod || "",
              // '결제수단': doc.PaymentMethod || "",
            });
          });
        }
        setListData(list);
        setCsvData(csvlist);

        if (autoload === false && list.length === 0){
          setDialogMessage('검색 결과가 없습니다.');
          setOpenDialog(true);
        }

        setPage(0);
      });
  };


  const handleSearch = (event) => {

    if (memberName !== ''|| chargeStartTime != undefined || chargeEndTime != undefined || queryStatName != '' || queryStatId != ''){
      props.updateQueryUsageMemberName(memberName);
      props.updateQueryUsageChargerStartTime(chargeStartTime);
      props.updateQueryUsageChargerEndTime(chargeEndTime);
      props.updateQueryUsageChargerStatName(queryStatName);

      searchData(false, memberName, chargeStartTime, chargeEndTime, queryStatName, queryPaymentMethod, queryStatId);

    } else {
      setDialogMessage('검색어를 입력해 주세요. ');
      setOpenDialog(true);
    }
  };

  const doKeyPress = (ev) => {
    if (ev.key === 'Enter') {
      handleSearch();
    }
  }


  /**
   * 검색 정보 지우기
   * @param {*} event
   */
  const handleClear = (event) => {

    props.updateQueryUsageMemberName('');
    props.updateQueryUsageChargerStartTime('');
    props.updateQueryUsageChargerEndTime('');
    props.updateQueryUsageChargerStatName('');
    props.updateQueryChargerStatId("");

    setMemberName(userAuth === USER_AUTH.ADMIN ? '' : user.admin_name);
    setChargeStartTime(new Date((new Date()).valueOf() - (1000 * 60 * 60 * 24)));
    setChargeEndTime(new Date((new Date()).valueOf()));
    setQueryStatName("");
    setQueryPaymentMethod("");
    setQueryStatId("");
    setListData([]);
  };

  const handleQueryStatIdChange = (event) => {
    event.persist();
    props.updateQueryChargerStatId(event.target.value);
    setQueryStatId(event.target.value);
  }

  const handleQueryMemberNameChange = (event) => {
    event.persist();
    setMemberName(event.target.value);
  }


  const handleChargeStartTimeChange = (date) => {
    setChargeStartTime(date);
  };


  const handleChargeEndtimeChange = (date) => {
    setChargeEndTime(date);
  };


  const handleQueryNameChange = (event) => {
    event.persist();
    setQueryStatName(event.target.value);
  };

  const handleQueryPaymentMethodChange = (event) => {
    event.persist();
    setQueryPaymentMethod(event.target.value);
  }

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
  };


  const doDelChargeHistory = (statId, chargerId, tsdt, tedt) => {
    fetch(`${NEONPV_CONFIG.HOSTNAME}/charge/unregisterChargeHistory`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        bid: user.bid, //로그인한 계정의 bid
        bkey: user.bkey, //로그인한 계정의 bkey
        statId: statId,
        chargerId : chargerId,
        tsdt : tsdt,
        tedt: tedt,
      }),
    }).then((response) => {
      return response.json();
    }).then((responseData) => {
      if (responseData.resultCode === "000") {
        // alert("충전이력삭제에 성공했습니다.");

        // setTimeout(() => {
        //   /** refresh */
        //   loadMyStationList();
        // }, 100);

      } else {
        //에러
        console.log(responseData.resultMessage);
        // alert(`충전이력삭제에 실패했습니다.\n${responseData.resultMessage}`);
      }
    })
    .catch((error) => {
      console.error(error);
      // alert("충전이력 삭제에 실패했습니다.");
    });
  }


  const handleRemoveBtn = () => {
    const list = [...listData];

    const delete_list = [];

    for (var i = 0; i < list.length; i++) {
      if (list[i].rowChecked == true) {
        if (list[i]._id != '') {
          delete_list.push(list[i]);
        }

        list.splice(i, 1);
        i--;
      }
    }

    for (var i = 0; i < delete_list.length; i++) {
      doDelChargeHistory(delete_list[i].statId, delete_list[i].chargerId,
        moment(delete_list[i].ChargeStartTime).format("YYYYMMDDHHmmss"),
        moment(delete_list[i].ChargeEndTime).format("YYYYMMDDHHmmss"));
    }

    setListData(list);
  };


  const startRow = (page * rowsPerPage);



  useEffect(() => {
    if (queryParamUsage.queryUsageMemberName != '' ||
        queryParamUsage.queryUsageChargerStartTime != '' ||
        queryParamUsage.queryUsageChargerEndTime != ''||
        queryParamUsage.queryUsageChargerStatName != ''||
        queryParamUsage.queryChargerStatId) {

          searchData(true,
            queryParamUsage.queryUsageMemberName,
            queryParamUsage.queryUsageChargerStartTime,
            queryParamUsage.queryUsageChargerEndTime,
            queryParamUsage.queryUsageChargerStatName,
            queryParamUsage.queryChargerStatId);
    }else{
      handleSearch();
    }

    if (queryParamUsage.queryUsageChargerStartTime) {
      setChargeStartTime(queryParamUsage.queryUsageChargerStartTime);
    }

    if (queryParamUsage.queryUsageChargerEndTime){
      setChargeEndTime(queryParamUsage.queryUsageChargerEndTime);
    }

    if (queryParamUsage.queryUsageChargerStatName){
      setQueryStatName(queryParamUsage.queryUsageChargerStatName);
    }

    if(userAuth === USER_AUTH.MEMBER) {
      setMemberName(user.admin_name);
    } 
    else if(userAuth === USER_AUTH.SUB_MEMBER){
      setMemberName(user.admin_name);
    }

    setQueryStatId(queryParamUsage.queryChargerStatId);

    return () => { };
  }, []);


  /** 삭제 checkbox 클릭 */
  const checkBoxRowClick = (e, index, transCode, isChecked) => {

    const list = [...listData];

    // console.log(isChecked);
    // console.log(e.target.checked);
    // console.log(e.target);
    // console.log("before list: " + JSON.stringify(list));

    // list[index] = {
    //   ...list[index],
    //   // rowChecked: e.target.checked
    //   rowChecked: isChecked
    // };

    for (var i = 0; i < list.length; i++) {
      if (list[i].TransactionCode === transCode) {
        list[i].rowChecked = isChecked;
      }
    }

    // console.log("after list: " + JSON.stringify(list));

    setListData(list);
  }


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };


  return (

    <div>

      <Dialog open={openDialog} onClose={() => { setOpenDialog(false) }}>
        <DialogTitle>주의</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{dialogMessage}</DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" color="secondary" onClick={() => { setOpenDialog(false) }}>닫기</Button>
        </DialogActions>
      </Dialog>

      <Card className={clsx(classes.root, className)} {...rest}>
        <Box m={1} p={2}>
          <Box
            mt={2}
            display="flex"
            alignItems="center"
            justifyContent="start"
            flexGrow={1}
          >

            <TextField className={classes.queryField_small}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action"><SearchIcon /></SvgIcon>
                  </InputAdornment>
                )
              }}

              disabled={userAuth !== USER_AUTH.ADMIN}
              onChange={handleQueryMemberNameChange}
              label="운영회원사"
              placeholder="운영회원사"
              value={memberName}
              variant="outlined"
              onKeyUp={(ev) => doKeyPress(ev)}
            />

            <Typography variant="h5" >충전일시</Typography>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                // locale="ko"
                // disableToolbar
                // margin="dense"
                // showTodayButton={true}
                autoOk
                maxDate={moment().subtract(0, "days")}
                variant="inline"
                id="date-picker-dialog"
                // label="조회 기준일"
                format="yyyy-MM-dd HH:mm"
                fontSize="30px"
                value={chargeStartTime}
                onChange={handleChargeStartTimeChange}
                KeyboardButtonProps={{ 'aria-label': 'change date', }}
                className={classes.datepicker}
                InputProps={{
                  style: {
                    fontSize: 16,
                    height: 44
                  }
                }}
                style={{
                  width: 180,
                  marginLeft:20,
                  marginRight:20,
                }}
              />
              <Typography variant="h5">~</Typography>
              <KeyboardDatePicker
                // locale="ko"
                // disableToolbar
                // margin="dense"
                // showTodayButton={true}
                autoOk
                maxDate={moment().subtract(0, "days")}
                variant="inline"
                id="date-picker-dialog"
                // label="조회 기준일"
                format="yyyy-MM-dd HH:mm"
                fontSize="30px"
                value={chargeEndTime}
                onChange={handleChargeEndtimeChange}
                KeyboardButtonProps={{ 'aria-label': 'change date', }}
                className={classes.datepicker}
                InputProps={{
                  style: {
                    fontSize: 16,
                    height: 44
                  }
                }}
                style={{
                  width: 180,
                  marginLeft:20,
                  marginRight:20,
                }}
              />
            </MuiPickersUtilsProvider>

            <TextField className={classes.queryField_small}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action"><SearchIcon /></SvgIcon>
                  </InputAdornment>
                )
              }}

              onChange={handleQueryStatIdChange}
              label="충전소ID"
              placeholder="충전소ID"
              value={queryStatId}
              variant="outlined"
              onKeyUp={(ev) => doKeyPress(ev)}
            />

            <TextField
              className={classes.queryField_small}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              onChange={handleQueryNameChange}
              label="충전소"
              placeholder="충전소"
              value={queryStatName}
              variant="outlined"
              onKeyUp={(ev) => doKeyPress(ev)}
            />


            <FormControl sx={{ m: 1, minWidth: 120 }} className={classes.queryField_small}>
              <InputLabel variant="outlined" id="demo-simple-select-label-2">결제수단</InputLabel>

              <Select
                labelId="demo-simple-select-label2"
                id="demo-simple-select-2"
                value={queryPaymentMethod}
                label="결제수단"
                onChange={handleQueryPaymentMethodChange}
                variant="outlined"
              >

                {payment_method_list.map(item => (
                  <MenuItem value={item}>{item}</MenuItem>
                ))}

              </Select>
            </FormControl>


            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classes.bulkAction}
              onClick={handleSearch}
            >
              검색
            </Button>

            <Button
              variant="outlined"
              size="large"
              className={classes.bulkAction}
              onClick={handleClear}
            >
              초기화
            </Button>
          </Box>
        </Box>

        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={listData.length}
                />

                <TableBody>
                  { stableSort(listData, getComparator(order, orderBy)).slice(0 + startRow, rowsPerPage + (page * rowsPerPage)).map( (module, idx) => (
                    <TableRow
                      className={classes.tableRow}
                      key={module.SeqNo}
                    >

                      <TableCell width={COLUMN_WIDTH.FLAG} align="center" >
                        <Checkbox
                          defaultChecked={false}
                          color="primary"
                          onClick={ event => checkBoxRowClick(event, idx, module.TransactionCode, !module.rowChecked) }
                          checked={ module.rowChecked}
                        />
                      </TableCell>

                      <TableCell align="center" width={COLUMN_WIDTH.TRANS_NO}>{module.TransactionCode}</TableCell>
                      <TableCell align="center" width={COLUMN_WIDTH.STATION_NAME}>{module.statName}</TableCell>
                      <TableCell align="center" width={COLUMN_WIDTH.CHARGER_NO}>{module.chargerId}</TableCell>
                      <TableCell align="center" width={COLUMN_WIDTH.CREDIT_CARD_NO}>{module.TransactionCardNo}</TableCell>
                      <TableCell align="right" width={COLUMN_WIDTH.CHARGER_QUANTITY}>{module.ChargerQuantity ? module.ChargerQuantity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''}</TableCell>
                      <TableCell align="right" width={COLUMN_WIDTH.CHARGER_PRICE}>{module.ChargerPrice ? module.ChargerPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''}</TableCell>
                      <TableCell align="center" width={COLUMN_WIDTH.CHARGE_START_TIME}>{moment(module.ChargeStartTime).utc().format('YY-MM-DD HH:mm')}</TableCell>
                      <TableCell align="center" width={COLUMN_WIDTH.CHARGE_END_TIME}>{moment(module.ChargeEndTime).utc().format('YY-MM-DD HH:mm')}</TableCell>
                      <TableCell align="center" width={COLUMN_WIDTH.PAYMENT_METHOD}>{module.payMethod}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>

        <CardActions className={classes.actions}>
          <Grid item md={6} justify="flex-start">

            <CSVLink
              variant="contained"
              size="large"
              data={csvData}
              filename={`${new Date().toLocaleDateString()}_충전기사용이력.csv`}
              className="btn btn-primary"
              target="_blank"
              disabled={csvData.length === 0}
              data-interception='off'
            >

              <Button
                variant="contained"
                color="secondary"
                size="large"
                className={classes.bulkAction}
                disabled={csvData.length === 0}
                style={{ marginRight: 30 }}
              >
                CSV 다운로드
              </Button>
            </CSVLink>

            <Button
            style={{ marginRight: 30 }}
            variant="contained" align="center" color="error" onClick={handleRemoveBtn}>
            선택 행 삭제
          </Button>
          </Grid>

          <Grid item md={6} justify="flex-end">

            <TablePagination
              component="div"
              count={listData.length}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleRowsPerPageChange}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[5, 10, 25]}
            />
          </Grid>
        </CardActions>
      </Card>

    </div>
  );
};


UsageChargerTable.propTypes = {
  className: PropTypes.string,
};


const mapDispatchToProps = (dispatch) => {
  // return bindActionCreators({ }, dispatch)
  return bindActionCreators(
    {updateQueryUsageMemberName,
      updateQueryUsageChargerStartTime,
      updateQueryUsageChargerEndTime,
      updateQueryUsageChargerStatName,
      updateQueryChargerStatId
    },
    dispatch
  );
};

let mapStateToProps = (state) => {
  return {
    queryParamUsage: state.queryParamUsage,
    user: state.user
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UsageChargerTable));






const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    // minWidth: 1050
  },
  nameContainer: {
    // display: 'flex',
    // alignItems: 'center',
    // marginLeft: theme.spacing(1)
  },
  queryField: {
    // width: 300
    flexBasis: 260,
    marginRight: 20,
  },
  queryField_small: {
    // width: 300
    flexBasis: 190,
    marginRight: 20,
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: "flex-end",
  },
  bulkAction: {
    marginLeft: theme.spacing(2),
  },
  datepicker: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
  },
}));