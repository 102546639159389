import React from "react";
import { makeStyles } from "@material-ui/styles";
import { SalesChargerList } from "./components";


const SalesCharger = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <SalesChargerList />
      </div>
    </div>
  );
};

export default SalesCharger;


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(2),
  }
}));
