import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Formik } from "formik";
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Typography
} from "@material-ui/core";

import {global} from "common/global";


const WebSocketForm = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const [message, setMessage] = useState('');


  console.log( "global: " , global);


  const handleSubmit = (event, values) => {
    event.preventDefault();

    global.webSocket_rul = values.socketUrl;

    global.ws = new WebSocket(values.socketUrl);
    global.ws.onopen = () => {
      console.log('connected');
      setMessage('connected');
    };

    global.ws.onmessage = (event) => {
      console.log(evt);
      console.log(evt.data);
      setMessage(evt.data);
    };

  };


  useEffect(() => {

    if ( global.ws !== null ) {

      switch(global.ws.readyState){
        case 0:
          setMessage('connecting');
          break;
        case 1:
          setMessage('open');
          break;
        case 2:
          setMessage('closing');
          break;
        case 3:
          setMessage('closed');
          break;
      }
    } else {
      setMessage('not connected');
    }

  },[]);


  return (

    <div>
      <Formik
        enableReinitialize={false}
        initialValues={{
          socketUrl: global.webSocket_rul,
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          isSubmitting,
          touched,
          values,
          setFieldValue
        }) => (
          <form
            className={clsx(classes.root, className)}
            onSubmit={(e) => handleSubmit(e, values)}
          >

            <Card>
              <CardHeader
                title="WebSocket Settings"
                titleTypographyProps={{ color: 'inherit', align: 'center', variant: 'h4' }}
              />

              <CardContent>
                <Grid container item spacing={2}
                  direction="row" alignItems="center" justify="center" >
                  <Grid item md={4} xs={8}>
                    <TextField
                      error={Boolean(touched.socketUrl && errors.socketUrl)}
                      fullWidth
                      helperText={touched.socketUrl && errors.socketUrl}
                      label="socketUrl"
                      name="socketUrl"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.socketUrl}
                      variant="outlined"
                      // inputProps={{ maxLength: MAX_LENGTH.APPLICANT }}
                      required
                    />
                  </Grid>

                  <Grid item md={2} xs={4}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      size="large"
                      disabled={isSubmitting}
                      className={classes.action}
                    >
                      연결
                    </Button>
                  </Grid>


                  <Grid item md={12} xs={12}>
                    <Typography variatn="body1" align="center">{message}</Typography>
                    </Grid>

                </Grid>
              </CardContent>
            </Card>
          </form>
        )}
      </Formik>
    </div>
  );
};

WebSocketForm.propTypes = {
  className: PropTypes.string,
};


export default WebSocketForm;


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    direction: "row",
    alignItems: "center",
    justify: "center"
  },
  content2: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  action: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },
  queryField: {
    // width: 300
    flexBasis: 260,
    marginRight: 20,
  },
  bulkAction: {
    marginLeft: theme.spacing(2),
  },

  divider: {
    margin: theme.spacing(2, 0)
  },
}));
