import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Divider, Drawer } from '@material-ui/core';

import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import NotificationsIcon from '@material-ui/icons/Notifications';

// import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
// import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
// import TextFieldsIcon from '@material-ui/icons/TextFields';
// import ImageIcon from '@material-ui/icons/Image';
// import AccountBoxIcon from '@material-ui/icons/AccountBox';
// import SettingsIcon from '@material-ui/icons/Settings';
// import LockOpenIcon from '@material-ui/icons/LockOpen';
// import Compare from '@material-ui/icons/Compare';
import EvStationIcon from '@material-ui/icons/EvStation';
import PowerIcon from '@material-ui/icons/Power';
import AddIcCallIcon from '@material-ui/icons/AddIcCall';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import { connect} from 'react-redux';
import { bindActionCreators } from 'redux';

import { Profile, SidebarNav } from './components';
import { useMediaQuery } from 'react-responsive';


const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    // backgroundColor: "#1a1a1a",
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2),
    // JustifyContent: 'flex-start',
  }
}));



const Sidebar = props => {
  const { open, variant, onClose, className, router, tempSetUser, ...rest } = props;

  const classes = useStyles();

  var accountPage = '';

  const userjson = localStorage.getItem('userinfo');
  if(!userjson) {
    console.log('user storage 없음');

    setTimeout( () => {
      // history.go('/sign-in');
      window.location.href = '/sign-in';
    }, 500);
  }

  var pages = [

      {
        title: '대쉬보드',
        href: '/dashboard',
        icon: <DashboardIcon />
      },
      {
        title: '충전소관리',
        href: '/stations',
        icon: <EvStationIcon />
      },
      {
        title: '충전기관리',
        href: '/chargers',
        icon: <PeopleIcon />
      },
      {
        title: '충전이력조회',
        href: '/usages',
        icon: <PowerIcon />
      },
      {
        title: '설치신청',
        href: '/installations',
        icon: <AddCircleIcon />
      },
      {
        title: '고장신고',
        href: '/malfunctions',
        icon: <AddIcCallIcon />
      },
      {
        title: '매출현황',
        icon: <AddIcCallIcon />,
        items: [
          {
            title: '충전기별',
            href: '/sales/charger',
            icon: <AddIcCallIcon />,
          },
          {
            title: '회원별',
            href: '/sales/user',
            icon: <AddIcCallIcon />,
          },
          {
            title: '회원사별',
            href: '/sales/member',
            icon: <AddIcCallIcon />,
          },
          {
            title: '카드사별',
            href: '/sales/cardcompany',
            icon: <AddIcCallIcon />,
          },
          {
            title: 'IC카드승인내역',
            href: '/sales/approvedDetails',
            icon: <AddIcCallIcon />,
          }

        ],
      },
      {
        title: '회원관리',
        href: '/members',
        icon: <PeopleIcon />
      },
      {
        title: '로밍관리',
        href: '/roaming',
        icon: <PeopleIcon />
      },
      {
        title: '로밍그룹관리',
        href: '/roamingGroup',
        icon: <PeopleIcon />
      },
      {
        title: '서브계정',
        href: '/submember',
        icon: <PeopleIcon />
      },
      {
        title: '공지사항',
        href: '/notice',
        icon: <NotificationsIcon />
      },
      // accountPage,
    ];


  const isPC = useMediaQuery({
    query: "(min-height:900px)"
  })


  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div
        {...rest}
        className={clsx(classes.root, className)}
      >
        <Profile />
        <Divider className={classes.divider} />
        <SidebarNav
          className={classes.nav}
          pages={pages}
        />


      {/* {isPC && (<div style={{position:"absolute", paddingRight:"10px", bottom:"10px", fontSize:"11px", fontWeight:"bold" }}>
          Copyright(c) 2021 Castpro Co., Ltd.
          All rights Reserved
          </div>
          )} */}
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
  router: PropTypes.object
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch)
}

let mapStateToProps = (state) => {
  return {
    user: state.user
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Sidebar);

