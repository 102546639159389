/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef, useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  List, ListItem, Button, colors, Collapse, Typography,
} from '@material-ui/core';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import NotificationsIcon from '@material-ui/icons/Notifications';
import EvStationIcon from '@material-ui/icons/EvStation';
import PowerIcon from '@material-ui/icons/Power';
import AddIcCallIcon from '@material-ui/icons/AddIcCall';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Textsms from '@material-ui/icons/Textsms';

import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import DockIcon from '@material-ui/icons/Dock';
import BusinessIcon from '@material-ui/icons/Business';
import SettingsIcon from '@material-ui/icons/Settings';

import { connect, useSelector } from 'react-redux';
import { USER_AUTH } from 'common/constants';


const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));



const SidebarNav = props => {
  const { pages, className, user, ...rest } = props;
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);

  const [openUsage, setOpenUsage] = useState(false);

  const userAuth = useSelector((state) => state.user.user_auth);

  const handleClick = () => {
    setOpen(!open);
  }

  const handleClick2 = () => {
    setOpen2(!open2);
  }

  const handleUsage = () => {
    setOpenUsage(!openUsage);
  }

  return (
    <List
      className={clsx(classes.root, className)}
    >
      <ListItem className={classes.item} disableGutters key={'대쉬보드'}>
        {userAuth === USER_AUTH.SUB_MEMBER ? '':(
          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink} to={'/dashboard'}>
            <div className={classes.icon}><DashboardIcon /></div>대쉬보드
          </Button>
        )}
      </ListItem>

      <ListItem className={classes.item} disableGutters key={'충전소관리'}>
        <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink} to={'/stations'}>
          <div className={classes.icon}><EvStationIcon /></div>충전소관리
        </Button>
      </ListItem>

      <ListItem className={classes.item} disableGutters key={'충전기관리'}>
        <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink} to={'/chargers'}>
          <div className={classes.icon}><DockIcon /></div>충전기관리
        </Button>
      </ListItem>


      <ListItem className={classes.item} disableGutters key={'충전이력조회'}>
      {userAuth === USER_AUTH.ADMIN ? (
        <>
        <Button activeClassName={classes.active} className={classes.button} onClick={handleUsage}>
          <div className={classes.icon}><PowerIcon /></div>충전기사용이력
        </Button>
        {openUsage ? <ExpandLess /> : <ExpandMore />}
        </>
      ) : (
        <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink} to={'/usages/charger'}>
          <div className={classes.icon}><PowerIcon /></div>충전기사용이력
        </Button>
      )}
      </ListItem>
      <Collapse in={openUsage} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem className={classes.sub_item} key={'충전기사용이력'}>
            <Button activeClassName={classes.active} className={classes.button}
              component={CustomRouterLink} to={'/usages/charger'}>
              <div className={classes.icon}><ArrowRightIcon /></div>충전기사용이력
            </Button>
          </ListItem>
          <ListItem className={classes.sub_item} key={'회원충전이력'}>
          {userAuth === USER_AUTH.ADMIN ? (
            <Button activeClassName={classes.active} className={classes.button}
            component={CustomRouterLink} to={'/usages/user'}>
            <div className={classes.icon}><ArrowRightIcon /></div>회원충전이력
          </Button>
          ):''}
          </ListItem>
        </List>
      </Collapse>


      <ListItem className={classes.item} disableGutters key={'설치신청'}>
      {userAuth === USER_AUTH.SUB_MEMBER ? '':(
        <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink} to={'/installations'}>
          <div className={classes.icon}><AddCircleIcon /></div>설치신청
        </Button>
      )}

        
      </ListItem>

      <ListItem className={classes.item} disableGutters key={'고장신고'}>
      {userAuth === USER_AUTH.SUB_MEMBER ? '':(
        <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink} to={'/malfunctions'}>
          <div className={classes.icon}><AddIcCallIcon /></div>고장신고
        </Button>
      )}
      </ListItem>

      <ListItem className={classes.item} disableGutters key={'매출현황'}>
        <Button activeClassName={classes.active} className={classes.button} onClick={handleClick} >
          <div className={classes.icon}><MonetizationOnIcon /></div>매출현황
        </Button>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem className={classes.sub_item} key={'충전기별'}>
            <Button activeClassName={classes.active} className={classes.button}
              component={CustomRouterLink} to={'/sales/charger'}>
              <div className={classes.icon}><ArrowRightIcon /></div>충전기별
            </Button>
          </ListItem>
          <ListItem className={classes.sub_item} key={'회원별'}>
            <Button activeClassName={classes.active} className={classes.button}
              component={CustomRouterLink} to={'/sales/user'}>
              <div className={classes.icon}><ArrowRightIcon /></div>회원별
            </Button>
          </ListItem>
          <ListItem className={classes.sub_item} key={'회원사별'}>
            <Button activeClassName={classes.active} className={classes.button}
              component={CustomRouterLink} to={'/sales/member'}>
              <div className={classes.icon}><ArrowRightIcon /></div>회원사별
            </Button>
          </ListItem>

          {/* <ListItem className={classes.sub_item} key={'카드사별'}>
          {userAuth === USER_AUTH.MEMBER ? (
            <Button disabled activeClassName={classes.active} className={classes.button}
              component={CustomRouterLink} to={'/sales/cardcompany'}>
              <div className={classes.icon}><ArrowRightIcon /></div>카드사별
            </Button>
          ) : (
            <Button activeClassName={classes.active} className={classes.button}
              component={CustomRouterLink} to={'/sales/cardcompany'}>
              <div className={classes.icon}><ArrowRightIcon /></div>카드사별
            </Button>
          )} 
          </ListItem>*/}

          <ListItem className={classes.sub_item} key={'승인내역'}>
            <Button activeClassName={classes.active} className={classes.button}
              component={CustomRouterLink} to={'/sales/approvedDetails'}>
              <div className={classes.icon}><ArrowRightIcon /></div>승인내역
            </Button>
          </ListItem>
        </List>
      </Collapse>



      <ListItem className={classes.item} disableGutters key={'회원관리'}>
      {userAuth === USER_AUTH.SUB_MEMBER ? '':(
        <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink} to={'/users'}>
          <div className={classes.icon}><PeopleIcon /></div>회원관리
        </Button>
      )}

        
      </ListItem>

      <ListItem className={classes.item} disableGutters key={'로밍관리'}>
      {userAuth === USER_AUTH.SUB_MEMBER ? '':(
        <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink} to={'/roaming'}>
          <div className={classes.icon}><PeopleIcon /></div>로밍관리
        </Button>
      )}
      </ListItem>

      <ListItem className={classes.item} disableGutters key={'로밍그룹관리'}>
      {userAuth !== USER_AUTH.ADMIN ? '':(
        <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink} to={'/roamingGroup'}>
          <div className={classes.icon}><PeopleIcon /></div>로밍그룹관리
        </Button>
      )}
      </ListItem>

      <ListItem className={classes.item} disableGutters key={'서브계정'}>
      {userAuth === USER_AUTH.SUB_MEMBER ? '':(
        <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink} to={'/submember'}>
          <div className={classes.icon}><PeopleIcon /></div>서브계정
        </Button>
      )}
      </ListItem>



      <ListItem className={classes.item} disableGutters key={'설정'}>
        {userAuth === USER_AUTH.SUB_MEMBER ? '': 
        (
          <>
        <Button activeClassName={classes.active} className={classes.button} onClick={handleClick2} >
          <div className={classes.icon}><SettingsIcon /></div>설정
        </Button>
        {open2 ? <ExpandLess /> : <ExpandMore />}
        </>
        )}
      </ListItem>
      <Collapse in={open2} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem className={classes.sub_item} key={'한전공급단가'}>
            <Button activeClassName={classes.active} className={classes.button}
              component={CustomRouterLink} to={'/unitprice/kepco'}>
              <div className={classes.icon}><ArrowRightIcon /></div>한전공급단가
            </Button>
          </ListItem>
          <ListItem className={classes.sub_item} key={'충전단가'}>
            <Button activeClassName={classes.active} className={classes.button}
              component={CustomRouterLink} to={'/unitprice/castpro'}>
              <div className={classes.icon}><ArrowRightIcon /></div>충전단가
            </Button>
          </ListItem>
          <ListItem className={classes.sub_item} key={'환경부'}>
            <Button activeClassName={classes.active} className={classes.button}
              component={CustomRouterLink} to={'/settings/env'}>
              <div className={classes.icon}><ArrowRightIcon /></div>환경부
            </Button>
          </ListItem>
          <ListItem className={classes.sub_item} key={'충전기모델'}>
          {userAuth === USER_AUTH.ADMIN ? (
            
          <Button activeClassName={classes.active} className={classes.button}
          component={CustomRouterLink} to={'/settings/chargermodel'}>
          <div className={classes.icon}><ArrowRightIcon /></div>충전기모델
        </Button>
          ):''}
          </ListItem>
        </List>
      </Collapse>

      <ListItem className={classes.item} disableGutters key={'회원사관리'}>
        {userAuth === USER_AUTH.ADMIN ? (
          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink} to={'/members'}>
            <div className={classes.icon}><BusinessIcon /></div>회원사관리
          </Button>
        ) : ''}
      </ListItem>


      <ListItem className={classes.item} disableGutters key={'공지사항'}>
      {userAuth === USER_AUTH.ADMIN ? (
        <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink} to={'/notice'}>
          <div className={classes.icon}><NotificationsIcon /></div>공지사항
        </Button>
      ) : ''}
      </ListItem>


      <ListItem className={classes.item} disableGutters key={'원격명령'}>
      {userAuth === USER_AUTH.ADMIN ? (
        <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink} to={'/command'}>
          <div className={classes.icon}><Textsms /></div>원격명령
        </Button>
      ) :''}
      </ListItem>

    </List>

  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired
};



let mapStateToProps = (state) => {
  return {
    user: state.user,
  };
}


export default connect(mapStateToProps)(SidebarNav);


const useStyles = makeStyles(theme => ({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 10,
  },
  sub_item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 26,
  },
  button: {
    // color: colors.blueGrey[800],
    color: "#2a3f54",
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium
  },
  icon: {
    color: theme.palette.icon,
    // color: "#2a3f54",
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  active: {
    color: theme.palette.primary.main,
    // color: "#000000",
    // backgroundColor: "#b7b7b7",
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main
      // color: "#000000"
    }
  }
}));