import React from "react";
import { makeStyles } from "@material-ui/styles";
import { UsageUserList } from "./components";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  actionIcon: {
    marginRight: theme.spacing(1)
  }
}));

const UsagesCharger = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <UsageUserList />
      </div>
    </div>
  );
};

export default UsagesCharger;
