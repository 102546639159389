/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef, useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  List, ListItem, Button,
  Card,
  Grid,
  Divider,
} from '@material-ui/core';


import { connect, useSelector } from 'react-redux';
import { USER_AUTH } from 'common/constants';


const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));



const CommandNav = props => {
  const { pages, className, user, ...rest } = props;
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);

  const [openUsage, setOpenUsage] = useState(false);


  //NOTE: 관리자가 아니면, dashboard 화면으로 전이함.
  const userAuth = useSelector((state) => state.user.user_auth);
  if(userAuth === USER_AUTH.MEMBER) {
    window.location.href = '/';
  }


  const handleClick = () => {
    setOpen(!open);
  }

  const handleClick2 = () => {
    setOpen2(!open2);
  }

  const handleUsage = () => {
    setOpenUsage(!openUsage);
  }

  return (

    <div className={classes.root}>
      <div className={classes.content}>


        <Grid container spacing={1}
          align="center" justify="flex-start" direction="row"
        >

          <Grid item spacing={2}>

            <Card
              className={clsx(classes.root, className)}
              {...rest}
            >

              <List
                className={clsx(classes.root, className)}
              >
                <ListItem className={classes.item} disableGutters key={'BootNotification.conf'}>
                  <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink} to={'/command/BootNotification'}>
                    BootNotification.conf
                  </Button>
                </ListItem>

                <ListItem className={classes.item} disableGutters key={'Change Availability'}>
                  <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink} to={'/command/ChangeAvailability'}>
                  충전기 상태 변경
                  </Button>
                </ListItem>

                <ListItem className={classes.item} disableGutters key={'Change Configuration'}>
                  <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink} to={'/command/ChangeConfiguration'}>
                  설정 변경
                  </Button>
                </ListItem>

                <ListItem className={classes.item} disableGutters key={'Clear Cache'}>
                  <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink} to={'/command/ClearCache'}>
                  캐시 초기화
                  </Button>
                </ListItem>

                <ListItem className={classes.item} disableGutters key={'Get Diagnostics'}>
                  <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink} to={'/command/GetDiagnostics'}>
                  로그 전송
                  </Button>
                </ListItem>

                <ListItem className={classes.item} disableGutters key={'Remote Start Transaction'}>
                  <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink} to={'/command/RemoteStartTransaction'}>
                  충전 시작 (원격)
                  </Button>
                </ListItem>

                <ListItem className={classes.item} disableGutters key={'Remote Stop Transaction'}>
                  <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink} to={'/command/RemoteStopTransaction'}>
                  충전 중지(원격)
                  </Button>
                </ListItem>

                <ListItem className={classes.item} disableGutters key={'Reset'}>
                  <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink} to={'/command/Reset'}>
                  충전기 재시작
                  </Button>
                </ListItem>

                <ListItem className={classes.item} disableGutters key={'Unlock Connector'}>
                  <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink} to={'/command/UnlockConnector'}>
                  커넥터 분리(원격)
                  </Button>
                </ListItem>

                <ListItem className={classes.item} disableGutters key={'Update Firmware'}>
                  <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink} to={'/command/UpdateFirmware'}>
                  충전기 펌웨어 업데이트
                  </Button>
                </ListItem>


                <Divider variant="inset" />


                <ListItem className={classes.item} disableGutters key={'Reserve Now'}>
                  <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink} to={'/command/ReserveNow'}>
                  충전 예약
                  </Button>
                </ListItem>


                <ListItem className={classes.item} disableGutters key={'Cancel Reservation'}>
                  <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink} to={'/command/CancelReservation'}>
                  예약 취소
                  </Button>
                </ListItem>

                {/* <ListItem className={classes.item} disableGutters key={'Data Transfer'}>
                  <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink} to={'/command/DataTransfer'}>
                    Data Transfer
                  </Button>
                </ListItem> */}


                <ListItem className={classes.item} disableGutters key={'Get Configuration'}>
                  <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink} to={'/command/GetConfiguration'}>
                    설정 조회
                  </Button>
                </ListItem>


                <ListItem className={classes.item} disableGutters key={'Get Local List Version'}>
                  <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink} to={'/command/GetLocalListVersion'}>
                  사용자 인증 기록 조회
                  </Button>
                </ListItem>

                <ListItem className={classes.item} disableGutters key={'Send Local List'}>
                  <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink} to={'/command/SendLocalList'}>
                  사용자 인증 기록 전송
                  </Button>
                </ListItem>


                <Divider variant="inset" />

                <ListItem className={classes.item} disableGutters key={'Trigger Message'}>
                  <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink} to={'/command/TriggerMessage'}>
                  원격명령
                  </Button>
                </ListItem>


                <ListItem className={classes.item} disableGutters key={'Get Composite Schedule'}>
                  <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink} to={'/command/GetCompositeSchedule'}>
                  충전 통합된 스케줄 조회
                  </Button>
                </ListItem>

                <ListItem className={classes.item} disableGutters key={'Clear Charging Profile'}>
                  <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink} to={'/command/ClearChargingProfile'}>
                  충전 프로필 삭제
                  </Button>
                </ListItem>


                <ListItem className={classes.item} disableGutters key={'Set Charging Profile'}>
                  <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink} to={'/command/SetChargingProfile'}>
                  충전 프로필 설정
                  </Button>
                </ListItem>


                {/* <Divider variant="inset" />

                <ListItem className={classes.item} disableGutters key={'Signed Update Firmware'}>
                  <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink} to={'/command/SignedUpdateFirmware'}>
                    Signed Update Firmware
                  </Button>
                </ListItem>

                <ListItem className={classes.item} disableGutters key={'Get Log'}>
                  <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink} to={'/command/GetLog'}>
                    Get Log
                  </Button>
                </ListItem>

                <ListItem className={classes.item} disableGutters key={'Install Certificate'}>
                  <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink} to={'/command/InstallCertificate'}>
                    Install Certificate
                  </Button>
                </ListItem>

                <ListItem className={classes.item} disableGutters key={'Extended Trigger Message'}>
                  <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink} to={'/command/ExtendedTriggerMessage'}>
                    Extended Trigger Message
                  </Button>
                </ListItem>

                <ListItem className={classes.item} disableGutters key={'Certificate Signed'}>
                  <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink} to={'/command/CertificateSigned'}>
                    Certificate Signed
                  </Button>
                </ListItem>

                <ListItem className={classes.item} disableGutters key={'Delete Certificate'}>
                  <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink} to={'/command/DeleteCertificate'}>
                    Delete Certificate
                  </Button>
                </ListItem>

                <ListItem className={classes.item} disableGutters key={'Get Installed Certificate Ids'}>
                  <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink} to={'/command/GetInstalledCertificateIds'}>
                    Get Installed Certificate Ids
                  </Button>
                </ListItem> */}
              </List>
            </Card>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

CommandNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired
};



let mapStateToProps = (state) => {
  return {
    user: state.user,
  };
}


export default connect(mapStateToProps)(CommandNav);


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(1),
  },
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 10,
  },
  sub_item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 26,
  },
  button: {
    // color: colors.blueGrey[800],
    color: "#2a3f54",
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium
  },
  icon: {
    color: theme.palette.icon,
    // color: "#2a3f54",
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  active: {
    color: theme.palette.primary.main,
    // color: "#000000",
    // backgroundColor: "#b7b7b7",
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main
      // color: "#000000"
    }
  },
  dividerInset: {
    margin: `5px 0 0 ${theme.spacing(9)}px`,
  },
}));