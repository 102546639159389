import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Formik } from "formik";
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Divider,
  Typography,
} from "@material-ui/core";
import {global} from "common/global";
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from "moment";


const GetLogForm = (props) => {
  const { className, history, user, ...rest } = props;
  const classes = useStyles();


  const LogType = ['DiagnosticsLog', 'SecurityLog'];
  const [selectedLogType, setSelectedLogType] = useState('SecurityLog');


  const handleSubmit = (event, values) => {
    event.preventDefault();

    var json =
      `{"command":"GetLog",
        "body":
        {
          "logType": "${selectedLogType}",
          "requestId": ${values.requestId},
          "retries": ${values.retries},
          "retryInterval": ${values.retryInterval},

          "log": {
              "oldestTimestamp": "${values.oldestTimestamp}",
              "latestTimestamp": "${values.latestTimestamp}",
              "remoteLocation": "${values.remoteLocation}"
            }
        }
      } `;

    global.ws.send(json);

  };


  const handleBack = () => {
    history.goBack();
  };


  return (

    <div>
      <Formik
        enableReinitialize={false}
        initialValues={{
          requestId: 1,
          retries: 1,
          retryInterval: 1,
          oldestTimestamp: moment().add(-7, "days").toDate().toISOString(),
          latestTimestamp:moment().add(-10, 'minutes').toDate().toISOString(),
          remoteLocation: '',
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          isSubmitting,
          touched,
          values,
          setFieldValue
        }) => (
          <form
            className={clsx(classes.root, className)}
            onSubmit={(e) => handleSubmit(e, values)}
          >

            <Card>
              <CardHeader
                title="Get Log"
                titleTypographyProps={{ color: 'inherit', align: 'center', variant: 'h3' }}
                subheader="TestCase - 079"
                subheaderTypographyProps={{ color: 'inherit', align: 'left', variant: 'h5' }}
              />

              <CardContent>

                <Grid item md={12} spacing={2}>
                  <Typography variant="h6">079 - PASS</Typography>
                </Grid>

                <div className={classes.content}></div>

                <Grid item md={12} spacing={0}>
                  <Typography variant="h4">Parameters</Typography>
                </Grid>

                <Divider variant="inset" />


                <Grid container item spacing={2} >

                  <Grid container item md={12} spacing={0}>
                  </Grid>

                  <Grid item md={2} xs={4}>
                    <TextField
                      error={Boolean(touched.requestId && errors.requestId)}
                      fullWidth
                      helperText={touched.requestId && errors.requestId}
                      label="requestId"
                      name="requestId"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.requestId}
                      variant="outlined"
                      required
                    />
                  </Grid>


                  <Grid item md={2} xs={4}>
                    <TextField
                      error={Boolean(touched.retries && errors.retries)}
                      fullWidth
                      helperText={touched.retries && errors.retries}
                      label="retries"
                      name="retries"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.retries}
                      variant="outlined"
                      required
                    />
                  </Grid>

                  <Grid item md={2} xs={4}>
                    <TextField
                      error={Boolean(touched.retryInterval && errors.retryInterval)}
                      fullWidth
                      helperText={touched.retryInterval && errors.retryInterval}
                      label="retryInterval"
                      name="retryInterval"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.retryInterval}
                      variant="outlined"
                      required
                    />
                  </Grid>


                  <Grid item md={3} xs={4}>
                    <Autocomplete
                      id="logtype_select"
                      options={LogType}
                      classes={{ option: classes.options, }}
                      autoHighlight
                      getOptionSelected={(option, value) => option === value}
                      value={selectedLogType}
                      renderOption={(option) => (
                        <React.Fragment>
                          {option}
                        </React.Fragment>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Type"
                          variant="outlined"
                          required
                          fullWidth
                          inputProps={{
                            ...params.inputProps,
                          }}
                        />
                      )}

                      onChange={(event, newValue) => {
                        if (newValue) {
                          setSelectedLogType(newValue);
                        }
                      }}
                    />
                  </Grid>

                  <Grid item md={3} xs={4}></Grid>


                  <Grid item md={3} xs={6}>
                    <TextField
                      error={Boolean(touched.oldestTimestamp && errors.oldestTimestamp)}
                      fullWidth
                      helperText={touched.oldestTimestamp && errors.oldestTimestamp}
                      label="oldestTimestamp"
                      name="oldestTimestamp"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.oldestTimestamp}
                      variant="outlined"
                      required
                    />
                  </Grid>

                  <Grid item md={3} xs={6}>
                    <TextField
                      error={Boolean(touched.latestTimestamp && errors.latestTimestamp)}
                      fullWidth
                      helperText={touched.latestTimestamp && errors.latestTimestamp}
                      label="latestTimestamp"
                      name="latestTimestamp"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.latestTimestamp}
                      variant="outlined"
                      required
                    />
                  </Grid>


                  <Grid item md={2} xs={4}>
                    <TextField
                      error={Boolean(touched.remoteLocation && errors.remoteLocation)}
                      fullWidth
                      helperText={touched.remoteLocation && errors.remoteLocation}
                      label="remoteLocation"
                      name="remoteLocation"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.remoteLocation}
                      variant="outlined"
                    />
                  </Grid>

               </Grid>


                <Grid container item
                  className={classes.action}
                  direction="row" alignItems="center" justify="center">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="large"
                    disabled={isSubmitting}
                    className={classes.action}
                  >
                    Perform
                  </Button>

                  <Button
                    variant="outlined"
                    size="large"
                    onClick={handleBack}
                    className={classes.action}
                  >
                    취소
                  </Button>
                </Grid>
              </CardContent>
            </Card>
          </form>
        )}
      </Formik>
    </div>
  );
};

GetLogForm.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object,
};


let mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps)(GetLogForm);


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    direction: "row",
    alignItems: "center",
    justify: "center"
  },
  content2: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  action: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },
  queryField: {
    // width: 300
    flexBasis: 260,
    marginRight: 20,
  },
  bulkAction: {
    marginLeft: theme.spacing(2),
  },

  divider: {
    margin: theme.spacing(2, 0)
  },
}));
