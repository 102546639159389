

export const MAX_LENGTH = {
  REPORTER_NAME : 10,
  APPLICANT : 10,
  CORPORATE_NAME: 20,
  REPORTER_TEL_NO: 20,
  APPLICANT_TEL_NO : 20,
  REPORTER_MOBILE_NO: 16,
  APPLICANT_MOBILE_NO: 16,
  REPORTER_EMAIL: 30,
  APPLICANT_EMAIL: 30,
  SENT_EMAIL1: 30,
  SENT_EMAIL2: 30,

  STAT_ID: 6,
  STAT_NAME: 20,
  STAT_ADDR: 100,
  REPORTER_REGION: 20,
  APPLICANT_REGION: 20,
  APPLICANT_LOT: 10,
  OPERATION_TYPE: 10,

  WORKER: 10,
  INSPECTOR: 20,

  CHARGER_ID : 2,
  CHARGER_NAME: 20,
  CHARGER_INFO: 20,
  CHARGER_SERIALNUMBER: 25,

  CHARGER_IP : 15,
  CHARGER_PORT : 5,
  CHARGER_MAC_ADDR : 17, //Mac 주소
  CHARGER_LTE_ROUTER_NO : 20, //LTE 라우터 번호
  CHARGER_MODEL: 40,
  CHARGER_VERSION: 20,
  CHARGER_FIRMWARE_VERSION: 40,

  MALFUNCTION_REPORT_CONTENT: 1000, // 고장 신고내용
  MALFUNCTION_MANAGER: 10, // 고장 처리 매니저, 담당자
  MALFUNCTION_PROCESS_CONTENT: 100, // 고장 처리 내용
}


export const USER_AUTH = {
    ADMIN : 1, //슈퍼 관리자
    MEMBER: 2, //일반 관리자
    SUB_MEMBER: 3, //일반 관리자 서브계정(계정분리)
}



/** 고장신고 진행상태 */
export const malfunction_status_list = ['-', '접수대기', '고장확인중', '처리중', '완료', '완료(고장아님)'];

export const getMalfunctionStatusCode = (status) => {
  switch (status) {
    case '-': return 0;
    case '접수대기': return 1;
    case '고장확인중': return 2;
    case '처리중': return 3;
    case '완료': return 4;
    case '완료(고장아님)': return 5;
    default: return -1;
  }
};

export const getMalfunctionStatusLabel = (statusCode) => {

  switch(statusCode) {
    case 0: return '-';
    case 1: return '접수대기';
    case 2: return '고장확인중';
    case 3: return '처리중';
    case 4: return  '완료';
    case 5: return '완료(고장아님)';
    default: return '';
  }

}


export const installation_status_list = ['-', '접수대기', '설치중', '설치완료', '설치확인중', '설치취소'];

export const getInstallationStatusCode = (status) => {
  switch(status){
    case '-': return 0;
    case '접수대기': return 1;
    case '설치중': return 2;
    case '설치완료': return 3;
    case '설치확인중': return 4;
    case '설치취소': return 5;
    default: return -1;
  }
}

export const getInstallationStatusLabel = (statusCode) => {
  switch(statusCode){
    case 0: return '-';
    case 1: return '접수대기';
    case 2: return '설치중';
    case 3: return '설치완료';
    case 4: return '설치확인중';
    case 5: return '설치취소';
    default: return '';
  }
}


export const charger_speed_list = ['급속', '완속', '중속'];

export const getChargerTypeLabel = (charger_type_code) => {

  switch (charger_type_code) {
    case 1: return 'B타입(5핀)';
    case 2: return 'C타입(5핀)';
    case 3: return 'BC타입(5핀)';
    case 4: return 'BC타입(7핀)';
    case 5: return 'DC차데모';
    case 6: return 'AC3상';
    case 7: return 'DC콤보';
    case 8: return 'DC차데모 +DC콤보';
    default: return '-';
  }
};


export const charger_type_list = ['B타입(5핀)', 'C타입(5핀)', 'BC타입(5핀)', 'BC타입(7핀)', 'DC차데모', 'AC3상', 'DC콤보', 'DC차데모 +DC콤보'];
export const getChargerTypeCode = (charger_type) => {

  if (charger_type == 'B타입(5핀)') return 1;
  else if (charger_type == 'C타입(5핀)') return 2;
  else if (charger_type == 'BC타입(5핀)') return 3;
  else if (charger_type == 'BC타입(7핀)') return 4;
  else if (charger_type == 'DC차데모') return 5;
  else if (charger_type == 'AC3상') return 6;
  else if (charger_type == 'DC콤보') return 7;
  else if (charger_type == 'DC차데모 +DC콤보') return 8;
  return 0;
};


/** 충전기 상태 */
export const charger_status_list = ['-', '충전대기', '충전중', '고장/점검', '통신장애', '통신미연결'];

export const getChargerStatusCode = (status) => {

  if (status == '충전대기') return 1;
  else if (status == '충전중') return 2;
  else if (status == '고장/점검') return 3;
  else if (status == '통신장애') return 4;
  else if (status == '통신미연결') return 5;
  return 0;
};


export const getChargerStatusLabel = (status_code) => {

  switch (status_code) {
    case 1: return '충전가능';
    case 2: return '충전중';
    case 3: return '고장/점검';
    case 4: return '통신장애';
    case 5: return '통신미연결';
    default: return '-';
  }
};


/** 관리자 유형 */
export const amdin_type_list = ['-', '공공기관', '기업', '공동주택','개인','기타'];

export const getAdminTypeCode = (admin_type) => {
  if (admin_type == '공공기관') return 1;
  else if (admin_type == '기업') return 2;
  else if (admin_type == '공동주택') return 3;
  else if (admin_type == '개인') return 4;
  else if (admin_type == '기타') return 5;
  else return 0;
};

export const getAdminTypeLabel = (admin_type_code) => {
  switch(admin_type_code){
    case 1: return '공공기관';
    case 2: return '기업';
    case 3: return '공동주택';
    case 4: return '개인';
    case 5: return '기타';
    default: return '-';
  }
};


/** 충전소 유형 */
export const station_type_list = ['-','공공시설', '주차시설', '휴게시설', '관광시설', '상업시설', '차량정비시설', '기타시설'];

export const getStationTypeCode = (station_type) => {
  if (station_type == '공공시설') return 1;
  else if (station_type == '주차시설') return 2;
  else if (station_type == '휴게시설') return 3;
  else if (station_type == '관광시설') return 4;
  else if (station_type == '상업시설') return 5;
  else if (station_type == '차량정비시설') return 6;
  else if (station_type == '기타시설') return 7;
  else return 0;
};

export const getStationTypeLabel = (station_type_code) => {
  switch(station_type_code){
    case 1: return '공공시설';
    case 2: return '주차시설';
    case 3: return '휴게시설';
    case 4: return '관광시설';
    case 5: return '상업시설';
    case 6: return '차량정비시설';
    case 7: return '기타시설';
    default: return '-';
  }
};


/** 충전소 상세 유형 */
export const station_detail_type_list = ['-', '군부대', '사업장(교육)', '병원', '학교', '경기장', '아파트', '사찰', '수련원', '경찰서', '기타'];

export const getStationDetailTypeCode = (station_detail_type) => {

  if (station_detail_type == '군부대') return 1;
  else if (station_detail_type == '사업장(교육)') return 2;
  else if (station_detail_type == '병원') return 3;
  else if (station_detail_type == '학교') return 4;
  else if (station_detail_type == '경기장') return 5;
  else if (station_detail_type == '아파트') return 6;
  else if (station_detail_type == '사찰') return 7;
  else if (station_detail_type == '수련원') return 8;
  else if (station_detail_type == '경찰서') return 9;
  else if (station_detail_type == '기타') return 10;
  else return 0;
};

export const getStationDetailTypeLabel = (station_detail_type_code) => {
  switch(station_detail_type_code){
    case 1: return '군부대';
    case 2: return '사업장(교육)';
    case 3: return '병원';
    case 4: return '학교';
    case 5: return '경기장';
    case 6: return '아파트';
    case 7: return '사찰';
    case 8: return '수련원';
    case 9: return '경찰서';
    case 10: return '기타';
    default: return '-';
  }
};


/** 충전소 운영 형태 */
export const station_operation_type_list = ['-', '완전개방', '부분개방'];

export const getStationOperationTypeCode = (station_operation_type) => {
  if (station_operation_type == '완전개방') return 1;
  else if (station_operation_type == '부분개방') return 2;
  else return 0;
};

export const getStationOperationTypeLabel = (station_operation_type_code) => {
  switch(station_operation_type_code){
    case 1: return '완전개방';
    case 2: return '부분개방';
    default: return '-';
  }
};

/** 메일발송 */
export const mail_send_type_list = ['-', '미발송', '발송완료'];
export const getMailSendTypeCode = (mail_send_type) => {
  if (mail_send_type == '미발송') return 1;
  else if (mail_send_type == '발송완료') return 2;
  return 0;
};
export const getMailSendTypeLabel = (mail_send_type_code) => {
  switch(mail_send_type_code){
    case 1: return '미발송';
    case 2: return '발송완료';
    default : return '-';
  }
};


/** 메일발송(실사) */
export const mail_send_work_type_list = ['-', '미발송', '발송완료'];
export const getMailSendWorkTypeCode = (mail_send_work_type) => {
  if (mail_send_work_type == '미발송') return 1;
  else if (mail_send_work_type == '발송완료') return 2;
  return 0;
};

export const getMailSendWorkTypeLabel = (mail_send_work_type_code) => {
  switch(mail_send_work_type_code){
    case 1: return '미발송';
    case 2: return '발송완료';
    default : return '-';
  }
};


/** CASTPRO 충전기 모델 */
// export const charger_model_list = ['-', 'CSC-14000B7(2CH)', 'CSC-14000B7S(2CH)', 'CSC-7000A7(1CH)', 'CSC-7000A7S(1CH)' ];
// export const charger_model_list = ['-', 'CSC-14000B7', 'CSC-7000A7'];
// export const getChargerModelCode = (charger_model) => {
//   if (charger_model == 'CSC-14000B7') return '1';
//   else if (charger_model == 'CSC-7000A7') return '2';
// };

// export const getChargerModelLabel = (charger_model_code) => {
//   switch(charger_model_code){
//     case '1': return 'CSC-14000B7';
//     case '2': return 'CSC-7000A7';
//     default : return '-';
//   }
// }
