import { NEONPV_CONFIG } from "config.js";


export let chargerModelList = [];

export async function loadChargerModelList(){

  if (chargerModelList.length > 0){
    return chargerModelList;
  }

  var url = `${NEONPV_CONFIG.HOSTNAME}/util/getChargerModelList`;

  const opt = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  chargerModelList = [];
  let response = await fetch(url, opt);
  let responseData = await response.json();

  chargerModelList.push('-');

  responseData.resultData.map(function (row) {
    chargerModelList.push( row['chargerModel'] );
  });

  return chargerModelList;
}