import React, { useState, useEffect, useRef } from "react";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Formik } from "formik";
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Divider,
  Typography
} from "@material-ui/core";

import {global} from "common/global";
import Autocomplete from '@material-ui/lab/Autocomplete';

const BootNotificationForm = (props) => {
  const { className, history, user, ...rest } = props;
  const classes = useStyles();

  const myRef = useRef();

  const statusType = ['Accepted', 'Pending', 'Rejected'];
  const [selectedStatusType, setSelectedStatusType] = useState(statusType[0]);

  const [currentTime, setCurrentTime] =  useState(new Date().toISOString());

  const handleSubmit = (event, values) => {
    event.preventDefault();

    var json =
      `{"command":"BootNotification.conf",
        "messageId": "${values.messageId}",
        "body": {
          "status": "${selectedStatusType}",
          "currentTime": "${currentTime}",
          "interval": ${values.interval}
        }
      }`;

    global.ws.send(json);

  };


  const handleNewtime = () => {
    setCurrentTime(new Date().toISOString());
  }



  return (

    <div>
      <Formik
        innerRef={(f) => (myRef.current = f)}
        enableReinitialize={false}
        initialValues={{
          interval: 60,
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          isSubmitting,
          touched,
          values,
          setFieldValue
        }) => (
          <form
            className={clsx(classes.root, className)}
            onSubmit={(e) => handleSubmit(e, values)}
          >

            <Card>
              <CardHeader
                title="BootNotification.conf"
                titleTypographyProps={{ color: 'inherit', align: 'center', variant: 'h3' }}
                // subheader="TestCase - 000, 002, 013, 014, 015, 032_1, 044_1, 044_3, 054, 080"
                // subheaderTypographyProps={{ color: 'inherit', align: 'left', variant: 'h5' }}
              />


              <CardContent>

                {/* <Grid item md={12} spacing={2}>
                  <Typography variant="h6">000 - PASS, status: Accepted</Typography>
                  <Typography variant="h6">002 - PASS, status: Pending, Accepted</Typography>
                  <Typography variant="h6">013 - PASS, status: Accepted</Typography>
                  <Typography variant="h6">014 - PASS, status: Accepted</Typography>
                  <Typography variant="h6">015 (중간) - PASS, status: Accepted</Typography>
                  <Typography variant="h6">032_1 (중간) - PASS, status: Accepted</Typography>
                  <Typography variant="h6">044_1 (중간) - PASS, status: Accepted</Typography>
                  <Typography variant="h6">044_3 (중간) - PASS, status: Accepted</Typography>
                  <Typography variant="h6">054 (중간) - PASS, status: Accepted</Typography>
                  <Typography variant="h6">080 (중간) - PASS, status: Accepted</Typography>
                </Grid> */}

                <div className={classes.content}></div>

                <Grid item md={12} spacing={0}>
                  <Typography variant="h4">파라미터</Typography>
                </Grid>

                <Divider variant="inset" />


                <Grid container spacing={2} >

                  <Grid container item md={12} spacing={0}></Grid>

                  <Grid container item md={12} spacing={2}
                    direction="row" alignItems="center" justify="start">


                    <Grid item md={2} xs={4}>
                      <TextField
                        error={Boolean(touched.messageId && errors.messageId)}
                        fullWidth
                        helperText={touched.messageId && errors.messageId}
                        label="messageId"
                        name="messageId"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.messageId}
                        variant="outlined"
                        // inputProps={{ maxLength: MAX_LENGTH.APPLICANT }}
                        required
                      />
                    </Grid>


                    <Grid item md={2} xs={4}>
                      <Autocomplete
                        id="update_select"
                        options={statusType}
                        classes={{ option: classes.options, }}
                        autoHighlight
                        getOptionSelected={(option, value) => option === value}
                        value={selectedStatusType}
                        renderOption={(option) => (
                          <React.Fragment>
                            {option}
                          </React.Fragment>
                        )}

                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Type"
                            variant="outlined"
                            required
                            fullWidth
                            inputProps={{
                              ...params.inputProps,
                            }}
                          />
                        )}

                        onChange={(event, newValue) => {
                          if (newValue) {
                            setSelectedStatusType(newValue);
                          }
                        }}
                      />
                    </Grid>


                    <Grid item md={2} xs={4}>
                      <TextField
                        fullWidth
                        label="currentTime"
                        name="currentTime"
                        onBlur={handleBlur}
                        onChange={(val) => setCurrentTime(val.target.value)}
                        value={currentTime}
                        variant="outlined"
                        required
                      />
                    </Grid>


                    <Grid item md={2} xs={4}>
                      <Button
                        variant="outlined"
                        size="large"
                        onClick={handleNewtime}
                        className={classes.action2}
                      >
                        갱신
                      </Button>
                    </Grid>


                    <Grid item md={2} xs={4}>
                      <TextField
                        error={Boolean(touched.interval && errors.interval)}
                        fullWidth
                        helperText={touched.interval && errors.interval}
                        label="interval"
                        name="interval"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.interval}
                        variant="outlined"
                        required
                      />
                    </Grid>
                  </Grid>

                </Grid>


                <Grid container item
                  className={classes.action}
                  direction="row" alignItems="center" justify="center">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="large"
                    disabled={isSubmitting}
                    className={classes.action}
                  >
                    Perform
                  </Button>

                </Grid>
              </CardContent>
            </Card>
          </form>
        )}
      </Formik>
    </div>
  );
};

BootNotificationForm.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object,
};


let mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps)(BootNotificationForm);


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    direction: "row",
    alignItems: "center",
    justify: "center"
  },
  content2: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  action: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },

  action2: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
 },

  queryField: {
    // width: 300
    flexBasis: 260,
    marginRight: 20,
  },
  bulkAction: {
    marginLeft: theme.spacing(2),
  },

  divider: {
    margin: theme.spacing(2, 0)
  },
}));
