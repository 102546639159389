import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Formik } from "formik";
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Grid,
  makeStyles,
  Divider,

  Typography,
  TextField
} from "@material-ui/core";

import Autocomplete from '@material-ui/lab/Autocomplete';
import {global} from "common/global";



const ChangeConfigurationForm = (props) => {
  const { className, history, user, ...rest } = props;
  const classes = useStyles();

  const configuration_key_list = [
    'MeterValueSampleInterval',
    'AuthorizationKey',
    'SecurityProfile',
    'LocalAuthListEnabled',
    'ClockAlignedDataInterval',
    'LocalPreAuthorize',
    'ConnectionTimeout',
    'MinimumStatusDuration',
    'StopTransactionOnEVSideDisconnect',
    'UnlockConnectorOnEVSideDisconnect',
    'AuthorizationCacheEnabled',
    'AuthorizeRemoteTxRequests',
    'AllowOfflineTxForUnknownId',
    'LocalAuthorizeOffline',
    'StopTransactionOnInvalidId'
  ];


  const [selectedKey, setSelectedKey] = useState('');


  const configuration_value_list = [
    '0',
    '-1',
    '60',
    'false',
    'true'
  ];

  const [selectedValue, setSelectedValue] = useState('');



  const handleClear = () => {
    setSelectedKey('');
    setSelectedValue('');
  }



  const handleSubmit = (event, values) => {
    event.preventDefault();

    var json = `{"command":"ChangeConfiguration",
      "body": {
        "key": "${selectedKey}",
        "value": "${selectedValue}"
      } }`;

    global.ws.send(json);
  };


  return (

    <div>
      <Formik
        enableReinitialize={false}
        initialValues={{
          inputedKey: '',
          inputedValue: ''
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          isSubmitting,
          touched,
          values,
          setFieldValue
        }) => (
          <form
            className={clsx(classes.root, className)}
            onSubmit={(e) => handleSubmit(e, values)}
          >

            <Card>
            <CardHeader
                title="설정 변경"
                titleTypographyProps={{ color: 'inherit', align: 'center', variant: 'h3' }}
                // subheader="TestCase - 002, 021, 040_1, 040_2, 073, 083, 084"
                // subheaderTypographyProps={{ color: 'inherit', align: 'left', variant: 'h5' }}
              />


              <CardContent>


              {/* <Grid item md={12} spacing={2}>
                  <Typography variant="h6">002(중간실행) - PASS</Typography>
                  <Typography variant="h6">021 - PASS</Typography>
                  <Typography variant="h6">040_1 - PASS</Typography>
                  <Typography variant="h6">040_2 - PASS</Typography>
                  <Typography variant="h6">073 - PASS</Typography>
                  <Typography variant="h6">083 - NO MENU</Typography>
                  <Typography variant="h6">084 - NO MENU</Typography>
                </Grid> */}

                <div className={classes.content}></div>

                <Grid item md={12} spacing={0}>
                  <Typography variant="h4">파라미터</Typography>
                </Grid>

                <Divider variant="inset" />


                <Grid container spacing={2} >
                <Grid container item md={12} spacing={0}></Grid>


                <Grid container item md={12} spacing={2}
                  direction="row" alignItems="center" justify="start"
                  >
                    <Grid item md={4} xs={8} >
                      <Autocomplete
                        freeSolo
                        autoSelect
                        id="configuration_key_list"
                        options={configuration_key_list}
                        classes={{ option: classes.options, }}
                        autoHighlight
                        getOptionSelected={(option, value) => option === value}
                        value={selectedKey}
                        renderOption={(option) => (
                          <React.Fragment>
                            {option}
                          </React.Fragment>
                        )}

                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Key"
                            variant="outlined"
                            fullWidth
                            // className={classes.action2}
                            inputProps={{
                              ...params.inputProps,
                            }}
                          />
                        )}

                        onChange={(event, newValue) => {
                          if (newValue) {
                            setSelectedKey(newValue);
                          }
                        }}
                      />
                    </Grid>


                    <Grid item md={4} xs={8}>
                      <Autocomplete
                        freeSolo
                        autoSelect
                        id="configuration_value_list"
                        options={configuration_value_list}
                        classes={{ option: classes.options, }}
                        autoHighlight
                        getOptionSelected={(option, value) => option === value}
                        value={selectedValue}
                        renderOption={(option) => (
                          <React.Fragment>
                            {option}
                          </React.Fragment>
                        )}

                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="value"
                            variant="outlined"
                            fullWidth
                            // className={classes.action2}
                            inputProps={{
                              ...params.inputProps,
                            }}
                          />
                        )}

                        onChange={(event, newValue) => {
                          if (newValue) {
                            setSelectedValue(newValue);
                          }
                        }}
                      />
                    </Grid>


                    <Grid item md={4} xs={12}>
                      <Button
                        variant="outlined"
                        size="large"
                        onClick={handleClear}
                        className={classes.action2}
                      >
                        지우기
                      </Button>
                    </Grid>
                  </Grid>

                </Grid>


                <Grid container item
                  className={classes.action}
                  direction="row" alignItems="center" justify="center">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="large"
                    disabled={isSubmitting}
                    className={classes.action}
                  >
                    Perform
                  </Button>

                </Grid>
              </CardContent>
            </Card>
          </form>
        )}
      </Formik>
    </div>
  );
};

ChangeConfigurationForm.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object,
};


let mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps)(ChangeConfigurationForm);


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    direction: "row",
    alignItems: "center",
    justify: "center"
  },
  content2: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  action: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },

  action2: {
    marginLeft: theme.spacing(4),
 },

  queryField: {
    // width: 300
    flexBasis: 260,
    marginRight: 20,
  },
  bulkAction: {
    marginLeft: theme.spacing(2),
  },

  divider: {
    margin: theme.spacing(2, 0)
  },
}));
